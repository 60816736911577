import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    Typography
} from "@mui/material";

export const ModalCarga = ({open}) => {
    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ padding: "0px", width: "540px" }}>
                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center" }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        data-testid="dialogModal"
                        sx={{ m: 1, textAlign: "center", padding: "0" }}
                    ></Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        data-testid="dialogModal"
                        sx={{ m: 1, textAlign: "center", padding: "0", height: "300px" }}
                    >
                        <CircularProgress className="spinnerGreen"></CircularProgress>
                        <Typography className="contentDialogLoading">
                            Subiendo Formulario 2 Sin Firmar
                        </Typography>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}