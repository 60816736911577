import { Grid } from "@mui/material";
import GetMotivo from "./GetMotivo";
import { Fragment } from "react";

const Exceptuar = ({
    error,
    setError,
    dataTermino,
    setDataTermino,
    loading
}) => {
    const motivoIngresado = (value) => {
        setDataTermino({ ...dataTermino, motivo: parseInt(value) });
        setError({ ...error, motivo: null });
    };

    const comentarioIngresado = (event) => {
        if (event.target.value.length <= 300) {
            setDataTermino({ ...dataTermino, comentario: event.target.value });
            setError({ ...error, comentario: null });
        }
    };

    return (
        <Fragment>
            <Grid item xs={12}>
                <label htmlFor="motivo">Motivo</label>
                <GetMotivo
                    motivoIngresado={motivoIngresado}
                    disabled={loading}
                    value={dataTermino.motivo || ""}
                    error={error.motivo}
                />
            </Grid>
            <Grid item xs={12}>
                <label>Observaciones</label>
                <textarea
                    data-testid="inputObservacionesHT"
                    id="comentarioModal"
                    cols="30"
                    rows="4"
                    className="form-control"
                    placeholder="Cuéntanos tus observaciones (tentativo)"
                    maxLength="300"
                    disabled={loading}
                    value={dataTermino.comentario || ""}
                    onChange={(event) => {
                        comentarioIngresado(event);
                    }}
                ></textarea>
                <label className="text-end">{dataTermino.comentario ? dataTermino.comentario.length : 0}/300</label>
                <span className="errorSpan">{error.comentario || ""} </span>
            </Grid>
        </Fragment>
    );
};

export default Exceptuar;
