import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Typography
} from "@mui/material";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import { fecha, Hora } from "../../../../utils/fechaUtils";

export const VerHitos = ({ open, setOpen, datos }) => {
    const closeModal = () => { setOpen(false) };
    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            onClose={closeModal}
            maxWidth="md"
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle>
                <Grid
                    className="dialogModal"
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    Hitos de N ° {datos.hitoInicio.idDespliegue}
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    className="dialogModal"
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Grid
                        sx={{
                            padding: "5px",
                            margin: "0",
                            width: "100%",
                        }}
                        container
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sx={{
                                border: "1px solid #909AA1",
                                padding: "12px",
                                borderRadius: "8px",
                                width: "49%",
                            }}
                        >
                            <DialogContentText>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        width: "96%!important",
                                        margin: "2%!important",
                                        padding: "2%!important",
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <h3>Hito de Inicio</h3>
                                        <span>
                                            <b>Descripción del Hito</b>
                                        </span>
                                        <h4>
                                            {
                                                datos.hitoInicio.datosHito?.nombreHito?.descripcion
                                            }
                                        </h4>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <Typography>
                                                <b>Canasta</b>
                                            </Typography>
                                            <Typography>
                                                {datos.hitoInicio.datosHito
                                                    .intervencion !== null
                                                    ? datos.hitoInicio.datosHito
                                                        .problemaSalud +
                                                    datos.hitoInicio.datosHito
                                                        .intervencion +
                                                    datos.hitoInicio.datosHito
                                                        .grupo_prestacion
                                                    : "N/A"}
                                            </Typography>

                                            <Typography>
                                                <b>Fecha del evento</b>
                                            </Typography>
                                            <Typography>
                                                {fecha(datos.hitoInicio.fechaEvento)}
                                            </Typography>

                                            <Typography>
                                                <b>RUT del prestador</b>
                                            </Typography>
                                            <Typography>
                                                {datos.hitoInicio.prestador}
                                            </Typography>

                                            <Typography>
                                                <b>Región</b>
                                            </Typography>
                                            <Typography>
                                                {datos.hitoInicio.region}
                                            </Typography>

                                            <Typography>
                                                <b>Comuna</b>
                                            </Typography>
                                            <Typography>
                                                {datos.hitoInicio.comuna}
                                            </Typography>
                                        </FormControl>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <Typography>
                                                    <b>Creador</b>
                                                </Typography>
                                                <span>{datos.hitoInicio.creador}</span>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>
                                            <b>Prestaciones</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoInicio.datosHito
                                                .cod_prestacion !== null
                                                ? datos.hitoInicio.datosHito.cod_prestacion
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Hora del evento</b>
                                        </Typography>
                                        <Typography>
                                            {Hora(datos.hitoInicio.fechaEvento)}
                                        </Typography>

                                        <Typography>
                                            <b>Razón social o nombre</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoInicio.razonSocial}
                                        </Typography>
                                        <Typography>
                                            <b>Motivo</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoInicio.motivo
                                                ? datos.hitoInicio.motivo
                                                : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <Typography>
                                                <b>Observaciones</b>
                                            </Typography>

                                            <span>
                                                {datos.hitoInicio.observacion
                                                    ? datos.hitoInicio.observacion
                                                    : "N/A"}
                                            </span>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                border: "1px solid #909AA1",
                                borderRadius: "8px",
                                width: "49%",
                            }}
                        >
                            <DialogContentText>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        width: "96%!important",
                                        margin: "2%!important",
                                        padding: "2%!important",
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <h3>Hito de Término</h3>
                                        <span>
                                            <b>Descripción del Hito</b>
                                        </span>
                                        <h4>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.datosHito
                                                    .nombreHito.descripcion
                                                : "N/A"}
                                        </h4>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>
                                            <b>Canasta</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.datosHito
                                                    .intervencion !== null
                                                    ? datos.hitoTermino.datosHito
                                                        .problemaSalud +
                                                    datos.hitoTermino.datosHito
                                                        .intervencion +
                                                    datos.hitoTermino.datosHito
                                                        .grupo_prestacion
                                                    : "N/A"
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Fecha del evento</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? fecha(
                                                    datos.hitoTermino
                                                        .fechaEvento
                                                )
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>RUT del prestador</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.prestador?
                                                datos.hitoTermino.prestador:"N/A"
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Región</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.region?
                                                datos.hitoTermino.region:"N/A"
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Comuna</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.comuna?
                                                datos.hitoTermino.comuna:"N/A"
                                                : "N/A"}
                                        </Typography>
                                        <Typography>
                                            <b>Creador</b>
                                        </Typography>

                                        <span>
                                            {datos.hitoTermino &&
                                                datos.hitoTermino.creador
                                                ? datos.hitoTermino.creador
                                                : "N/A"}
                                        </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>
                                            <b>Prestaciones</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino != null
                                                ? datos.hitoTermino.datosHito
                                                    .cod_prestacion !== null
                                                    ? datos.hitoTermino.datosHito
                                                        .cod_prestacion
                                                    : "N/A"
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Hora del evento</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? Hora(datos.hitoTermino.fechaEvento)
                                                : "N/A"}
                                        </Typography>

                                        <Typography>
                                            <b>Razón social o nombre</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino !== null
                                                ? datos.hitoTermino.razonSocial?
                                                datos.hitoTermino.razonSocial:"N/A"
                                                : "N/A"}
                                        </Typography>
                                        <Typography>
                                            <b>Motivo</b>
                                        </Typography>
                                        <Typography>
                                            {datos.hitoTermino &&
                                                datos.hitoTermino.motivo?
                                                datos.hitoTermino.motivo
                                                : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <Typography>
                                                <b>Observaciones</b>
                                            </Typography>
                                            <span>
                                                {datos.hitoTermino &&
                                                    datos.hitoTermino.observacion
                                                    ? datos.hitoTermino
                                                        .observacion
                                                    : "N/A"}
                                            </span>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="left"
                >
                    <SecondaryButton
                        data-testid="cerrarModalHitos"
                        variant=" text"
                        color="default"
                        onClick={closeModal}
                    >
                        Cerrar
                    </SecondaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}