import { useState } from "react";
import "../../assets/App.css";
import "../../assets/Repositorio.css";
import {
  Breadcrumbs,
  Grid,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FiltroCasos } from "./components/FiltroCasos";
import { Link } from "react-router-dom";
import { ExportarBandeja } from "./components/ExportarBandeja";
import { BuscadorCasos } from "./components/BuscadorCasos";

function TodosLosCasos({ correo, sucursal, rol }) {
  const [campos, setCampos] = useState({});
  const [error, setError] = useState({});
  const [numeroCasos, setNumeroCasos] = useState(0);
  const [filtroAvanzado, setFiltroAvanzado] = useState(false);

  return (
    <div>
      <div className="container Main">
        <label className="titulo1"> Bandeja de casos</label>
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            to="/"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
              cursor: "pointer",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Bandeja de casos
          </Typography>
        </Breadcrumbs>
      </div>

      <BuscadorCasos
        filtro={filtroAvanzado}
        setFiltro={setFiltroAvanzado}
        campos={campos}
        setCampos={setCampos}
        error={error}
        setError={setError}
        setNumeroCasos={setNumeroCasos}
      />

      <div className="container">
        <div className="card-box">
          <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
            sx={{ padding: "0 5px 5px", margin: "0", width: "100%" }}
          >
            <Grid item xs={6} sx={{ paddingTop: "0!important" }}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <label htmlFor="nroCasos" className="titulo2">
                  Bandeja de casos
                </label>
                <input
                  type="text"
                  className="form-control inputNumeroCasos d-none"
                  id="nroCasos"
                  disabled={true}
                  value={numeroCasos || ""}
                />

                <select
                  className="form-select form-select-sm selectCasos d-none"
                  disabled
                  id="casos"
                  //onChange={detectaCambio}
                  value={campos["casos"] || ""}
                >
                  <option defaultValue={"TODOS"} value="TODOS">
                    Ver todos los casos
                  </option>
                  <option value="PEND">Mis casos pendientes</option>
                </select>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ padding: "0 0 6px!important" }}>
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                sx={{ padding: "0 10px" }}
              >
                <ExportarBandeja campos={campos} casos={numeroCasos} />
              </Grid>
            </Grid>
          </Grid>

          <FiltroCasos
            data={campos}
            contador={numeroCasos}
            setContador={setNumeroCasos}
            correo={correo}
            sucursal={sucursal}
            rol={rol}
          />
        </div>
      </div>
    </div>
  );
}

export default TodosLosCasos;
