import { cuentaprestadores, verificarcasoexistente } from "../../../../utils/queries";
import { useState } from "react";
import { client } from "../../../../utils/apollo";

export const useGescaeinfoHook = (props, navigate) => {

  const [tipoAccion, setTipoAccion] = useState(null);
  const [casoHito, setCasoHito] = useState(null);

  const [selection] = useState(null);
  //modales
  const [modalAccion, setModalAccion] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [dataDuplicado, setDataDuplicado] = useState({});
  const [modalCaso,setModalCaso] = useState (false);
  const campos = props.data.getSolicitudesGCbyFkfun[0];
  let patologia, resolutor;

  function validaAutoriza(cie) {
    var isNormal = true;
    if(campos.fkProblemaSalud === 19){
      isNormal = false;
    }
    client
      .query({
        query: cuentaprestadores,
        variables: {
          folio: parseInt(sessionStorage.getItem("fkFun")),
          filtraSB: isNormal
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      })
      .then((result) => {
        let cant = result.data.cuentaprestadores;
        if (cant.contDR > 0 && cie !== null) {
          navigate("/Formulario2", { state: campos });
        } else {
          document.getElementById("btnSinCompletar").click();
          return;
        }
      });
  }

  function validaReabrir() {

    client
      .query({
        query: verificarcasoexistente,
        variables: {
          problemaSalud: campos.fkProblemaSalud,
          rut: campos.rutPaciente,
          folio: campos.fkFun
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      })
      .then((result) => {
        if (result.data.verificarCasoExistente[0] !== 0 && !campos.verificado) {
          setModalCaso(true);
          setDataDuplicado({
            duplicado: result.data.verificarCasoExistente[0],
            nombrePS : campos.tipoCaso ==='GES'?campos.nombrepat.GlosaSis:campos.diagnosticoCaec,
            psFolio: campos.fkProblemaSalud,
            preing: result.data.verificarCasoExistente[1] === 8 ? true : false,
            verificado: true
          })
        }else{
          setModalAccion(true);
        }
      });
  }

  function selecOpt(opt) {
    switch (opt) {
      case "autorizar":
        validaAutoriza(campos.fkCie10);
        break;
      case "rechazar":
        setTipoAccion(1);
        setModalAccion(true);
        break;
      case "derivar":
        break;
      case "cerrar":
        if (props.data.getSolicitudesGCbyFkfun[0].estadoHito && (props.data.getSolicitudesGCbyFkfun[0].estadoHito.id === 3 || props.data.getSolicitudesGCbyFkfun[0].estadoHito.id === 4 || props.data.getSolicitudesGCbyFkfun[0].estadoHito.id === 9) && !props.deshabilitaInputs) {
          setCasoHito(true);
          setModalAviso(true);
        } else {
          setTipoAccion(3);
          setModalAccion(true);
        }
        break;
      case "anular":
        setTipoAccion(2);
        setModalAccion(true);
        break;
      case "reabrir":
        setTipoAccion(4);
        validaReabrir();
        break;
      default:
        break;
    }
  }

  return {
    selecOpt,
    tipoAccion,
    casoHito,
    campos,
    patologia,
    resolutor,
    selection,
    modalAccion,
    setModalAccion,
    modalAviso,
    setModalAviso,
    modalCaso,
    dataDuplicado,
    setModalCaso,
    validaAutoriza
  }
}