import icon_warningOrange from "../../../../components/icons/icon_warningOrange.svg";
import icon_error from "../../../../components/icons/icon_errorModal.svg";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { eliminaRegLEP } from "../../../../utils/queries";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { SecondaryButton } from "../../../../components/Buttons/Botones";

export const ModalEliminarLEP = ({
    onOpen,
    onClose,
    setSnackData,
    lep,
    refetch,
    usuario,
    sucursal
}) => {
    const [eliminaRegLep, { loading }] = useMutation(eliminaRegLEP, {
        onCompleted: () => {
            setSnackData({
                abrir: true,
                mensaje: "El registro LEP [" + lep.prestacion + "] ha sido eliminado correctamente.",
                severidad: "success",
            });
            onClose();
            refetch();
        },
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Ha ocurrido un error al intentar eliminar el registro LEP. Por favor, inténtelo de nuevo más tarde.",
            severidad: "error",
        })
    });
    return (
        <Dialog
            justifyContent="center"
            data-testid="EliminarCanastaDialog"
            maxWidth="md"
            open={onOpen}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ paddingBottom: "0" }}>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px"
                    }}
                >
                    <img alt="img" src={icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2">
                ¿Seguro de eliminar el registro LEP [{lep.prestacion}]?
                </div>
            </DialogTitle>
            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
            Al eliminar este registro LEP, no podrá recuperar los datos <br></br> eliminados.
            </DialogContentText>
            <DialogActions
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ justifyContent: "center!important", margin: "5px 5px 15px 5px" }}
            >
                <BotonCarga
                    testid="eliminarLEP"
                    label="Eliminar"
                    sx={{ marginRight: "10px" }}
                    loading={loading}
                    toDo={() => eliminaRegLep({
                        variables: {
                            id: parseInt(lep.id),
                            usuario: usuario,
                            sucursal: sucursal
                        }
                    })}
                />
                <SecondaryButton
                    data-testid="closeModal"
                    disabled={loading}
                    onClick={onClose}
                >
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};
