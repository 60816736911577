import { useState } from "react";
import icon_nube from "../../../components/icons/icon_nube.svg";
import PrimaryButton, { SecondaryButton } from "../../../components/Buttons/Botones";
import icon_infoBig from "../../../components/icons/icon_infoBig.svg";
import { IconInfo } from "../../../components/icons/iconosEsencial";
import Success from "../../../components/icons/Success.svg";
import icon_print from "../../../components/icons/icon_printB.svg";
import icon_warningOrange from "../../../components/icons/icon_warningOrange.svg";
import "../../../assets/App.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  autoriza,
  activacaso,
  guardaform,
  cargararchivo2,
  activacasoCaec,
  consultaCanastaasignadas
} from "../../../utils/queries";
import { useMsal } from "@azure/msal-react";
import { format } from "date-fns";
import { activarCanasta, activarPrestador } from "../../../utils/Utils";
import { IframeForm2 } from "./components/IFrameForm2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ModalDrag } from "../../../components/DragDrop/ModalDrag";
import { Subida } from "../../../components/Dialog/Subida";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import { ModalErrorCarga } from "./components/ModalErrorCarga";
import { ModalCarga } from "./components/ModalCarga";

function cargaForm2GestDoc(fkFun, rutPaciente, subirDoc, guardaDB, nombreArchivo, archivo, tipoForm, tipoDoc, pasoFinal) {
  let folio = fkFun.toString();
  var reader = new FileReader();
  if (archivo !== "error") {
    reader.readAsDataURL(archivo);
    reader.onload = function () {
      const base64WithoutPrefix = reader.result.substr(`data:${archivo.type};base64,`.length);
      subirDoc({
        variables: {
          idProceso: "11",
          idTipoTrans: "2002",
          idTipoDoc: tipoDoc,
          folioTrans: folio,
          fechaTrans: format(new Date(), "MM-dd-yyyy"),
          rutBenef: rutPaciente,
          folioDoc: folio,
          nombreDoc: nombreArchivo,
          base64: base64WithoutPrefix
        },
        onCompleted: (result) => {
          guardaDB({
            variables: {
              link: result.crearArchivo2.documentId,
              tipoDoc: tipoForm,
              folio: parseInt(fkFun),
            },
            onCompleted: () => {
              pasoFinal(null);
            },
            onError: (error) => {
              console.log("ERROR GUARDADB: ", error);
              pasoFinal("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
            }
          });
        },
        onError: (error) => {
          console.log("ERROR SUBIDA FORM2: ", error);
          pasoFinal("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
        }
      })
    };
  } else {
    pasoFinal("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
  }
}

export function SolicitudFormulario2({ sucursal, correo }) {
  const location = useLocation();
  const [modalAutorizar, setModalAutorizar] = useState(false);
  const [modalAutorizado, setModalAutorizado] = useState(false);
  const [modalVolverCaso, setModalVolverCaso] = useState(false);
  const [modalCargaForm, setModalCargaForm] = useState(false);
  const [modalCarga, setModalCarga] = useState(false);
  const [modalErrorCarga, setModalErrorCarga] = useState(false);
  const [errorForm, setErrorForm] = useState(null);
  const [estadoBoton, setEstadoBoton] = useState(location.state.bloquearReautorizacion || false);
  const [fkFun] = useState(location.state.fkFun || "");
  const [deducible, setDeducible] = useState(0);
  const { accounts } = useMsal();
  const [openFinal, setOpenFinal] = useState(false);
  const [form2, setForm2] = useState(null);
  const [Form2F, setForm2F] = useState(null);
  const [subirDoc, { loading: cargaF2 }] = useMutation(cargararchivo2);
  const [guardaDB, { loading: guardaF2 }] = useMutation(guardaform);
  const navigate = useNavigate();

  if (!location.state) {
    navigate("/TodosLosCasos");
  }

  const [activaPrestadoresCanasta] = useLazyQuery(consultaCanastaasignadas,
    {
      onCompleted: (result) => {
        const datos=result.getCanastasasignadas.canastas

        datos.forEach(element=>{
          activarPrestador(element.id,element.idBeneficios,accounts[0].name,sucursal,fkFun)
        })
      },
      onError: (error) => {
        console.log("ERROR ACTIVACION PRESTADORES: ", error.message)
        throw error
      }
    });


  const [AutorizaCaso, { loading }] = useMutation(autoriza,
    {
      onCompleted: () => {
        navigate("/Formulario2", { state: { fkEstadoSolicitudIsapre: 4, bloquearReautorizacion: true, ...location.state } });
        if (location.state.tipoCaso === "GES") {
          if (location.state.origen !== 3) {
            ActivaCaso({
              variables: {
                codigoPatologia: parseInt(location.state.fkProblemaSalud),
                fechaAperturaCaso: new Date(),
                rutBeneficiario: location.state.rutPaciente,
                numeroCaso: parseInt(fkFun),
                usuario: accounts[0].name,
                sucursal: sucursal,
              },
            })
          }else{
            activaPrestadoresCanasta({variables: {
              folio: parseInt(fkFun),
              order: "idDespliegue",
              dir: "ASC",
              aplicaVigencia:true
            }})
          }
        }
        if (location.state.tipoCaso === "CAEC") {
          ActivaCasoCaec({
            variables: {
              rutBeneficiario: location.state.rutPaciente,
              numeroCaso: parseInt(fkFun),
              usuario: accounts[0].name,
              sucursal: sucursal,
            },
          })
        }
        let doc = "Formulario2_SF_" + fkFun + ".pdf";
        setModalAutorizar(false);
        cargaForm2GestDoc(
          fkFun,
          location.state.rutPaciente,
          subirDoc,
          guardaDB,
          doc,
          form2,
          "form2",
          "2006",
          finCargaF2
        );
        //refetch();
      },
      onError: (error) => {
        setEstadoBoton(false);
        console.log("ERROR AUTORIZA CASO: ", error.message)
      }
    });

  const [ActivaCaso] = useLazyQuery(activacaso,
    {
      onCompleted: (result) => {
        activarCanasta(parseInt(fkFun), accounts[0].name, "")
        if (result.activacaso && result.activacaso.mensajeError === "") {
          console.log("activacion exitosa")
        } else {
          console.log("ERROR ACTIVA CASO: ", result.activacaso)
        }
      },
      onError: (error) => {
        console.log("ERROR ACTIVA CASO: ", error.message)
      }
    });

  const [ActivaCasoCaec] = useLazyQuery(activacasoCaec,
    {
      onCompleted: (result) => {
        //activarCanasta(parseInt(fkFun), accounts[0].name, "")
        if (result.activacaso.mensajeError === "") {
          console.log("activacion exitosa")
        } else {
          console.log("ERROR ACTIVA CASO CAEC: ", result.activacaso.mensajeError)
        }
      },
      onError: (error) => {
        console.log("ERROR ACTIVA CASO CAEC: ", error.message)
      }
    });

  function autorizarCaso() {
    //notificarNuevoCaso(folio);
    setEstadoBoton(true);
    AutorizaCaso({
      variables: {
        folio: parseInt(fkFun),
        usuario: accounts[0].name,
        correo: correo,
        sucursal: sucursal,
        deducible: deducible
      },
    });
  }

  const abrirModalAutorizar = () => {
    setModalAutorizar(true);
  };

  const cerrarModales = () => {
    setModalAutorizar(false);
    setModalAutorizado(false);
  };

  const finCargaF2 = (doc) => {
    if (doc) {
      setModalErrorCarga(true);
    } else {
      setModalAutorizado(true);
    }
  }

  const abrirModalAnterior = () => {
    if (estadoBoton) {
      volverAtras();
    } else {
      setModalVolverCaso(true);
    }
  };

  const cerrarModalAnterior = () => {
    setModalVolverCaso(false);
  };

  const abrirModalSubidaForm = () => {
    setModalCargaForm(true);
  };

  function pasoFinal(texto) {
    setErrorForm(texto)
    setModalCarga(false);
    setOpenFinal(true);
  }

  function imprimirIframe() {
    printPreview("archivo64");
    setModalAutorizado(false);
    setModalAutorizar(false);
  }

  function irABandeja() {
    setOpenFinal(false);
    navigate("/TodosLosCasos");
  }

  function volverAtras() {
    cerrarModalAnterior();
    navigate("/GesCaeInformacion");
  }


  const printPreview = (data, type = "application/pdf") => {
    let iframe = document.getElementById("iframeSolicitud").contentWindow;
    iframe.focus();
    iframe.print();
  };

  return (
    <>
      <div className="container Main">
        <label className="titulo1"> Solicitar activación GES</label>
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            to="/TodosLosCasos"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
              cursor: "pointer",
            }}
          >
            Bandeja de Casos
          </Link>
          <Link
            underline="always"
            color="inherit"
            to="/GesCaeInformacion"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Información de paciente
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Formulario 2
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="container">
        <div className="card-box">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "10px 22px", margin: "0", width: "100%" }}
          >
            <label className="title">Firmar formulario 2</label>

            <label className="title">Folio: {fkFun}</label>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ padding: "0 22px", margin: "0", width: "100%" }}
          >
            <label className="textoNormal">
              Recuerda imprimir el Formulario Normativo Nº 2 para ser firmado
              y luego cargar el documento.
            </label>
          </Grid>
          <Divider sx={{ margin: "15px 0" }}></Divider>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "8px", margin: "0", width: "100%" }}
          >
            <Grid item xs={4}>
              <label className="title">Previsualización formulario Nº2</label>
            </Grid>
            <Grid item xs={4}>
              <SecondaryButton
                disabled={!estadoBoton}
                className="btn btn-secondary"
                sx={{ margin: "0 5px 0 0" }}
                onClick={imprimirIframe}
              >
                Imprimir formulario
              </SecondaryButton>
              <PrimaryButton
                disabled={!estadoBoton}
                className="btn btn-primary"
                onClick={abrirModalSubidaForm}
              >
                <img
                  alt=""
                  src={icon_nube}
                  className="imgNubeArchivo"
                  style={{ marginRight: "7px" }}
                />
                Subir formulario firmado
              </PrimaryButton>
            </Grid>
          </Grid>
          <IframeForm2 info={location.state} setForm2={setForm2} setDeducible={setDeducible} />
          <Divider sx={{ margin: "15px 0" }}></Divider>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "0 16px 16px", margin: "0", width: "100%" }}
          >
            <SecondaryButton
              data-testid="botonVolverForm"
              className="btn btn-secondary"
              onClick={abrirModalAnterior}
            >
              Volver
            </SecondaryButton>
            <PrimaryButton
              data-testid="botonAutorizacion"
              disabled={estadoBoton || loading || location.state.bloquearReautorizacion || !form2}
              className="btn btn-primary"
              onClick={abrirModalAutorizar}
            >
              Confirmar Autorización
            </PrimaryButton>
          </Grid>
        </div>
      </div>

      {/* Dialog Ingresar Solicitud */}
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalAutorizar}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
          }}
          className="titulo2"
        >
          <IconInfo sx={{ width: "50px", height: "50px", marginTop: "35px", marginBottom: "27px" }} /><br></br>
          ¿Estás seguro que quieres autorizar?
        </DialogTitle>

        <DialogContentText
          alignContent="center"
          sx={{ m: 0, textAlign: "center", paddingBottom: "15px", width: "540px" }}
        >
          Vas a autorizar el Caso N° {fkFun}
        </DialogContentText>

        <DialogActions sx={{ mb: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            alignContent="center"
          >
            {" "}
            <SecondaryButton
              variant=" text"
              className="btn btn-secondary"
              disabled={estadoBoton}
              onClick={cerrarModales}
            >
              Cancelar
            </SecondaryButton>
            <BotonCarga
              id="guardarHI"
              testid="ingresaSolTest"
              label="Autorizar"
              toDo={autorizarCaso}
              loading={loading}
            />
          </Grid>
        </DialogActions>

      </Dialog>

      {/* dialogo 2 solicitud Ingresada OK */}

      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalAutorizado}
        onClose={cerrarModales}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
          <img alt="img2" src={Success}></img>
        </Grid>
        <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
          Autorizado con éxito
        </DialogTitle>
        <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            El caso N° {fkFun} ha sido autorizado. Recuerda notificar al
            beneficiario a su correo electónico.
          </DialogContentText>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            En breve podrás imprimir el formulario.
          </DialogContentText>

          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <PrimaryButton
                data-testid="botonImprimirForm2"
                onClick={imprimirIframe}>
                <img
                  src={icon_print}
                  alt="imprimir Archivo"
                  className="imgCargaArchivo"
                />{" "}
                Imprimir formulario
              </PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Dialog 3 boton anterior */}
      <Dialog
        className="dialogModalBig"
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalVolverCaso}
        onClose={cerrarModales}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid
          className="dialogModal"
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
        >
          <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
            <img alt="img2" src={icon_infoBig}></img>
          </Grid>
          <DialogTitle className="tituloModal" sx={{ padding: "9px" }}>
            ¿Seguro que quieres cancelar la solicitud?
          </DialogTitle>
          <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              Vas a cancelar la solicitud.
            </DialogContentText>
          </DialogContent>
        </Grid>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <PrimaryButton
              data-testid="noVolverForm"
              variant=" text"
              color="default"
              onClick={volverAtras}
            >
              Cancelar solicitud
            </PrimaryButton>
            <SecondaryButton
              data-testid="siVolverForm"
              variant=" text"
              color="default"
              onClick={cerrarModalAnterior}
            >
              Volver
            </SecondaryButton>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Dialog 4 Ingresar archivo */}
      <ModalDrag
        modal={modalCargaForm}
        setModal={setModalCargaForm}
        files={Form2F}
        setFiles={setForm2F}
        next={() => {
          setModalCargaForm(false);
          setModalCarga(true);
          cargaForm2GestDoc(
            fkFun,
            location.state.rutPaciente,
            subirDoc,
            guardaDB,
            "Formulario2_F_" + fkFun + ".pdf",
            Form2F[0].file,
            "form2_firmado",
            "2008",
            pasoFinal);
        }}
        maxFiles={1}
      />
      {/* dialogo 5 solicitud Ingresada OK */}
      <Subida modal={modalCarga} />
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openFinal}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
          <img alt="img2" src={errorForm ? icon_warningOrange : Success}></img>
        </Grid>
        <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
          {errorForm ? "Archivo no cargado" : "Archivo cargado con éxito"}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            {errorForm ? errorForm : "Se ha cargado la versión firmada del formulario con folio " + fkFun}
            <span className="errorSpan">{ }</span>
          </DialogContentText>

          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <PrimaryButton
                variant=" text"
                color="default"
                onClick={irABandeja}
              >
                Ir a bandeja de casos
              </PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ModalCarga open={cargaF2 || guardaF2} />
      <ModalErrorCarga open={modalErrorCarga} onClose={() => { setModalErrorCarga(false); setModalAutorizado(true); }} fkFun={fkFun} />
    </>
  );
}

export default SolicitudFormulario2;