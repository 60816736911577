import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client"
import { intsan } from "../../../../utils/queries";
import { useState } from "react";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

function Intervencion({
    arancel,
    deshabilitaInputs,
    estado,
    patologia,
    value,
    setValue,
}) {
    const [opciones, setOpciones] = useState([{ label: "Todos", value: "" }]);

    useQuery(intsan, {
        variables: { patologia: patologia, decreto: arancel },
        onCompleted: (data) => {
            let array = [{ label: "Todos", value: "" }];
            data.getIntSanbyPat.forEach((val) => {
                array.push({ label: val.nombre, value: val.codigo })
            });
            setOpciones(array);
        }
    });

    return (
        <Grid item xs={4}>
            <label className="labelForm">Intervención sanitaria</label>
            <SelectPersonalizado
                id={"intervencion"}
                inputTest={"testIntervencion"}
                value={value}
                setValue={setValue}
                options={opciones}
                disabled={estado === 5 || estado === 6 || estado === 7 || estado === 10 || deshabilitaInputs ? true : false}
            />
        </Grid>
    );
}

export default Intervencion;