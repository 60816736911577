import { useState } from "react";
import {
  FormControl,
  Grid,
} from "@mui/material";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";
import { getYear, getMonth } from "date-fns";
import Mensajero from "../../../components/Snackbar/Mensajero";
import {
  generarReporte,
  subereporteTxt,
  subereporteXlsx,
} from "../../../utils/queries";
import { useMsal } from "@azure/msal-react";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import { parse } from 'zipson';
import { buildInfo036 } from '../utils/reporte036';
import { buildInfo037 } from '../utils/reporte037';
import { buildInfo038 } from '../utils/reporte038';
import { buildInfo075 } from '../utils/reporte075';
import { buildInfo105 } from '../utils/reporte105';
import { buildTxtXlsx } from '../utils/buildTxtXlsx';
import { reportes, meses } from "../Reporteria.jsx";
import { useLazyQuery } from "@apollo/client";

export function SolicitudReporte() {
  const [tipoReporte, setTipoReporte] = useState(null);
  const [mesReporte, setMesReporte] = useState(null);
  const [anioReporte, setAnioReporte] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const { accounts } = useMsal();
  const [generaDatosReporte, { fetchMore }] = useLazyQuery(generarReporte, { fetchPolicy: "network-only" });
  const [subirTXT] = useLazyQuery(subereporteTxt, { fetchPolicy: "network-only" });
  const [subirXLSX] = useLazyQuery(subereporteXlsx, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      MuestraMensaje(
        "Archivos cargados correctamente, revise la tabla reporte.",
        "success"
      );
      setTipoReporte(null);
      setCargando(false);
      setMesReporte(null);
      setAnioReporte(null);
      const btnRefetch = document.getElementById("btnRefetchTable");
      if (btnRefetch) {
        btnRefetch.click();
      }
    },
    onError: (error) => {
      console.error(error.message);
      MuestraMensaje(
        "Archivos no generados, intente nuevamente",
        "error"
      );
      setCargando(false);
    }
  });

  function calculaMes() {
    let mes = getMonth(new Date());

    if (mes === 0) {
      return meses.slice(11, 12);
    }
    return meses.slice(mes - 1, mes);
  }

  function calculaAnio() {
    let anio = getYear(new Date());
    let mes = getMonth(new Date());
    if (mes === 0) {
      anio -= 1;
    }
    return [{ label: anio.toString(), value: anio.toString() }];
  }

  function MuestraMensaje(mensaje, severidad) {
    setSnackData({
      abrir: true,
      mensaje: mensaje,
      severidad: severidad,
    });
  }

  function cierraMensaje() {
    setSnackData({
      abrir: false,
      mensaje: "",
      severidad: "info",
    });
  }

  function getDatosReportes() {
    if (tipoReporte === null || mesReporte === null || anioReporte === null) {
      MuestraMensaje("Debe rellenar los campos solicitados", "warning");
      setCargando(false);
      return;
    }

    MuestraMensaje("Se está generando el reporte, por favor espere", "info");
    setCargando(true);
    generaDatosReporte({
      variables: {
        idReporte: tipoReporte.toString(),
        mm: mesReporte.toString(),
        anio: anioReporte.toString(),
        offSet: 0,
        limit: 5000,
      },
      errorPolicy: "all",
      onCompleted: (result) => {
        if (result.generarReporte.error === "Sin datos") {
          MuestraMensaje(
            "No hay datos para el reporte seleccionado",
            "error"
          );
          setCargando(false);
        } else {
          procesaDatosRep(result);
        }
      },
      onError: (error) => {
        console.log(error);
        MuestraMensaje("Archivos no generados, intente nuevamente", "error");
        setCargando(false);
      }
    })
  }

  async function procesaDatosRep(result) {
    const reporte = result.generarReporte;
    const fecha = mesReporte + anioReporte;
    const tipoDoc = reporte.tipoDoc;
    const codAseguradora = reporte.codAseguradora;
    
    let tempReport = parse(reporte.info);
    while (tempReport.length < reporte.count) {
      const { data } = await fetchMore({
        variables: {
          idReporte: tipoReporte.toString(),
          mm: mesReporte.toString(),
          anio: anioReporte.toString(),
          offSet: tempReport.length,
          limit: 5000,
        }
      });
      tempReport = [
        ...tempReport,
        ...parse(data.generarReporte.info)
      ];
    }

    const infoReporte = tempReport;
    let infoBase64;

    switch (tipoReporte) {
      case "036":
        infoBase64 = await buildTxtXlsx(
          mesReporte,
          anioReporte,
          buildInfo036(codAseguradora, fecha, infoReporte)
        );
        break;

      case "037":
        infoBase64 = await buildTxtXlsx(
          mesReporte,
          anioReporte,
          buildInfo037(codAseguradora, fecha, infoReporte)
        );
        break;

      case "038":
        infoBase64 = await buildTxtXlsx(
          mesReporte,
          anioReporte,
          buildInfo038(codAseguradora, fecha, infoReporte)
        );
        break;

      case "075":
        infoBase64 = await buildTxtXlsx(
          mesReporte,
          anioReporte,
          buildInfo075(codAseguradora, fecha, infoReporte)
        );
        break;

      case "105":
        infoBase64 = await buildTxtXlsx(
          mesReporte,
          anioReporte,
          buildInfo105(codAseguradora, fecha, infoReporte)
        );
        break;
      default: return;
    }

    if (typeof infoBase64 === 'object') {
      cierraMensaje();
      MuestraMensaje(
        "Cargando el reporte TXT al gestor documental. Espere un momento...",
        "info"
      );
      subirTXT({
        variables: {
          nombreTxtArchivo: reporte.nombreTxtArchivo,
          base64Txt: infoBase64.txtb64,
          tipoDoc: tipoDoc,
          periodo:
            mesReporte.toString() + "-" + anioReporte.toString(),
          creador: accounts[0].name,
        },
        onCompleted: (result) => {
          const txtIDReporte = result.subeReporteTxt;
          cierraMensaje();
          MuestraMensaje(
            "Cargando el reporte XLSX al gestor documental. Espere un momento...",
            "info"
          );
          subirXLSX({
            variables: {
              nombreTxtArchivo: reporte.nombreTxtArchivo,
              idTxtArchivo: txtIDReporte,
              nombreXlsxArchivo: reporte.nombreXlsxArchivo,
              base64Xlsx: infoBase64.xlsxb64,
              tipoDoc: tipoDoc,
              periodo:
                mesReporte.toString() +
                "-" +
                anioReporte.toString(),
              creador: accounts[0].name,
            }
          })
        },
        onError: (error) => {
          console.error(error);
          MuestraMensaje(
            "Archivos no generados, intente nuevamente",
            "error"
          );
          setCargando(false);
        }
      })
    } else {
      console.error(
        "Datos generados no son objeto."
      );
      MuestraMensaje(
        "Archivos no generados, intente nuevamente",
        "error"
      );
      setCargando(false);
    }
  }
  return (
    <div className="container">
      <Mensajero data={snackData} setState={setSnackData} />
      <Grid
        sx={{ padding: "5px 15px", margin: "0", width: "100%" }}
        container
        spacing={2}
        className="noMargin"
      >
        <Grid item xs={12}>
          <h3 className="title">Reportes</h3>
          <label>Aquí puedes consultar por diferentes tipos de reportes</label>
        </Grid>
        <Grid item xs={4}>
          <label className="labelForm">Reporte</label>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <SelectPersonalizado
              inputTest={"tipoReporte"}
              value={tipoReporte}
              setValue={setTipoReporte}
              options={reportes}
              placeholder={"Elegir reporte"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <label className="labelForm">Periodo</label>
          <FormControl sx={{ width: "98%!important" }}>
            <SelectPersonalizado
              inputTest={"mesReporte"}
              value={mesReporte}
              setValue={setMesReporte}
              options={calculaMes()}
              placeholder={"Elegir periodo"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <label className="labelForm">Año</label>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <SelectPersonalizado
              inputTest={"anioReporte"}
              value={anioReporte}
              setValue={setAnioReporte}
              options={calculaAnio()}
              placeholder={"Elegir año"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <BotonCarga
              testid="generarReporte"
              sx={{ margin: "5px 10px 20px!important" }}
              boxSX={{ '& > button': { m: 1 } }}
              label="Generar reporte"
              toDo={getDatosReportes}
              loading={cargando}
              setLoading={setCargando}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}