import React from "react";
import { createRoot } from 'react-dom/client';
import "./assets/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from './router/App';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { ApolloProvider } from "@apollo/client";
import { client } from "./utils/apollo";

const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event));
  }
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <App instance={msalInstance} />
      </ApolloProvider>
    </MsalProvider>
  </React.StrictMode>
);
