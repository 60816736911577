import React, { useState } from "react";
import "../../../../assets/Repositorio.css";
import "../../../../assets/App.css";
import { useQuery, NetworkStatus } from "@apollo/client";
import { stableSort, getComparator } from "../../../../components/Table/utils";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { prestadoresAsignados } from "../../../../utils/queries";
import { useMsal } from "@azure/msal-react";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { MenuPrestadores } from "./components/MenuPrestadores";
import Mensajero from "../../../../components/Snackbar/Mensajero";
import { NoResult } from "../../../../components/NoData/NoResults";
import { fecha, fechaHora } from "../../../../utils/fechaUtils";

const TablaPrestadoresCaec = ({
  deshabilitaInputs,
  data,
  sucursal,
  setDataPrestadores
}) => {
  const estado = data.fkEstadoSolicitudIsapre;
  const { accounts } = useMsal();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("idDespliegue");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noReset, setNoReset] = useState(false);
  const [alertData, setAlertData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const { data: presAsignados, loading, error, refetch, networkStatus } = useQuery(prestadoresAsignados, {
    variables: {
      folio: data.fkFun,
      offset: rowsPerPage * page,
      limit: rowsPerPage,
      order: orderBy,
      dir: order.toUpperCase(),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      setDataPrestadores(res.getPrestadoresAsignados.prestadores);
    }
  });
  const headCellsPrestadores = [
    {
      id: "idDespliegue",
      sorted: true,
      label: "N°",
      sticky: true
    },
    {
      id: "prestador",
      sorted: true,
      label: "RUT Prestador",
    },
    {
      id: "nombrePrestador",
      sorted: true,
      label: "Nombre Prestador",
    },
    {
      id: "direccionPrestador",
      sorted: true,
      label: "Dirección Prestador",
    },
    {
      id: "fechaInicio",
      sorted: true,
      label: "Fecha Inicio",
    },
    {
      id: "fechaTermino",
      sorted: true,
      label: "Fecha Término",
    },
    {
      id: "vigencia",
      sorted: true,
      label: "Estado",
    },
    {
      id: "transitorio",
      sorted: true,
      label: "Transitorio",
    },
    {
      id: "tipoPrestador",
      sorted: true,
      label: "Asignación",
    },
    {
      id: "createdby",
      sorted: true,
      label: "Creado Por",
    }
  ];
  if (loading && (networkStatus !== NetworkStatus.refetch)) {
    if (!noReset && page !== 0) {
      setPage(0);
    }
    return (
      <div className="container">
        <SkeletonTable test={"loading-caecPrestadores"} />
      </div>);
  }

  if (error) return <pre data-testid="errorMessage">Error: {error.message}</pre>;

  const nextPage = () => {
    setNoReset(true);
  }

  return (
    <div className="container">
      <label className="titulo2 p-4">Historial prestadores</label>
      <Box sx={{ width: "100%" }} data-testid="container">
        <Mensajero data={alertData} setState={setAlertData} />
        <Button id="recargaPrest" data-testid="refetchPresCaec" hidden={true} onClick={() => { refetch(); }} />
        {!presAsignados || presAsignados.getPrestadoresAsignados.total === 0 ?
          <NoResult
            titulo={"Sin Prestadores"}
            subtitulo={"Aún no haz establecido ningún prestador."}
          /> :
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <CabeceraTabla
                  order={order}
                  setOrder={setOrder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  cabecera={headCellsPrestadores}
                />
                <TableBody>
                  {stableSort(presAsignados.getPrestadoresAsignados.prestadores, getComparator(order, orderBy))
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          <TableCell
                            className="tableBodyIzquierda"
                            align="center"
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.idDespliegue}
                          </TableCell>
                          <TableCell align="left" className="fila">
                            {row.prestador}
                          </TableCell>
                          <TableCell align="right" className="fila">
                            {row.nombrePrestador}
                          </TableCell>
                          <TableCell align="right" className="fila">
                            {row.direccionPrestador}
                          </TableCell>
                          <TableCell align="center" className="fila">
                            {fecha(row.fechaInicio)}
                          </TableCell>
                          <TableCell align="center" className="fila">
                            {row.fechaTermino
                              ? fecha(row.fechaTermino)
                              : null}
                          </TableCell>
                          <TableCell align="left" className="fila">
                            {row.vigencia === true
                              ? "Vigente"
                              : "No vigente"}
                          </TableCell>
                          <TableCell align="center" className="fila">
                            {row.transitorio
                              ? "Si"
                              : row.tipoPrestador === "DR"
                                ? "-"
                                : "No"}
                          </TableCell>
                          <TableCell align="center" className="fila">
                            {row.tipoPrestador === "DR" ? (
                              <label className="tagEstadoCasos tagAutorizado">
                                RED
                              </label>
                            ) : (
                              <label className="tagEstadoCasos tagCerrado">
                                Fuera de RED
                              </label>
                            )}
                          </TableCell>
                          <TableCell align="center" className="fila">
                            {row.createdby} {fechaHora(row.createdAt)}
                          </TableCell>
                          <TableCell
                            className="tableBodyAcciones sombraAcciones"
                            align="right"
                          >
                            <MenuPrestadores
                              estado={estado}
                              prestador={row}
                              accounts={accounts}
                              folio={data.fkFun}
                              canastasAsignadas={[]}
                              isCaec={true}
                              prestadores={presAsignados.getPrestadoresAsignados.prestadores}
                              deshabilitaInputs={deshabilitaInputs}
                              setAlertData={setAlertData}
                              sucursal={sucursal}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid alignItems="center">
              <Paginador
                opciones={[5, 10, 15]}
                data={presAsignados.getPrestadoresAsignados.total}
                servPag={true}
                loadNextPage={nextPage}
                itemPorPagina={rowsPerPage}
                pagina={page}
                setPagina={setPage}
                setLineasPagina={setRowsPerPage}
              />
            </Grid>
          </Paper>}
      </Box>
    </div>
  );
};

export default TablaPrestadoresCaec;
