import { useState } from "react";
import "../../../assets/App.css";
import { updtsolicitud } from "../../../utils/queries";
import { Chip, Grid, Stack } from "@mui/material";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { useMutation } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import { IconAmbulance, IconDoctor, IconHospital } from "../../../components/icons/iconosEsencial";
import InformacionPaciente from "./components/InformacionPaciente";
import { fechaConvertida, parseFecha } from "../../../utils/fechaUtils";
import { formatRut } from "chilean-rutify";

export function InformacionDelCaso({ deshabilitaInputs, info, sucursal, refetch }) {
  const [informacionPaciente, setInformacionPaciente] = useState({
    mailBenef: info.emailPaciente,
    nroBenef: info.telPaciente,
    celularBenef: info.celPaciente,
    mailRep: info.emailRepresentante,
    nroRep: info.telRepresentante,
    celularRep: info.celRepresentante,
    fechaAutoriza: info.fechaAutorizacionSoliciut,
    fechaInicioBenef: parseFecha(info.fVigenciaBeneficiario),
    cie10: info.fkCie10,
    cies10: info.tipoCaso === "GES" ? info.nombrepat.cie10 : [],
    marca: info.marca,
    fechaInicio: info.fechaInicioMarca,
    fechaTermino: info.fechaCierre,
    motivoCierre: info.descCausal,
    comentarioMarca: info.comentarioMarca,
  });

  const { accounts } = useMsal();
  const [chipValue, setChipValue] = useState(0);
  const [mensajero, setMensajero] = useState({
    abrir: false,
    severidad: "success",
    mensaje: "",
  });


  const handleClick = (newValue) => {
    setChipValue(newValue);
  };

  const [actualizaInfo, { loading }] = useMutation(updtsolicitud, {
    onCompleted: () => {
      setMensajero({
        abrir: true,
        severidad: "success",
        mensaje: "Datos almacenados exitosamente!",
      });
      refetch();
    },
    onError: (error) => {
      console.log(error.message)
      setMensajero({
        abrir: true,
        severidad: "error",
        mensaje: "No se guardaron los datos, intente nuevamente.",
      });
    }
  });

  function actualizarInfo() {
    let cambiafecha = false;
    if (info.fechaAutorizacionSoliciut !== informacionPaciente.fechaAutoriza) {
      cambiafecha = true;
    }
    actualizaInfo({
      variables: {
        fkFun: info.fkFun,
        celPaciente: parseInt(informacionPaciente.celularBenef),
        telPaciente: parseInt(informacionPaciente.nroBenef),
        emailPaciente: informacionPaciente.mailBenef,
        emailRepresentante: informacionPaciente.mailRep,
        telRepresentante: parseInt(informacionPaciente.nroRep),
        celRepresentante: parseInt(informacionPaciente.celularRep),
        fechaCierre: informacionPaciente.fechaTermino,
        causalCierre: informacionPaciente.motivoCierre,
        fkCie10: informacionPaciente.autoComplete ? informacionPaciente.autoComplete.codigo : null,
        marca: informacionPaciente.marca,
        fechaInicioMarca: informacionPaciente.fechaInicio,
        comentarioMarca: informacionPaciente.comentarioMarca,
        fechaAutorizacionSoliciut: informacionPaciente.fechaAutoriza,
        usuario: accounts[0].name,
        sucursal: sucursal,
        cambiafecha: cambiafecha
      },
    });
  }

  return (
    <div className="container" id="todo" data-testid="containerInfoPac">
      <Mensajero data={mensajero} setState={setMensajero} />
      <Grid
        sx={{ padding: "0px 24px 20px 14px", margin: "0", width: "100%" }}
        container
        spacing={2}
        id="paciente"
      >
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Chip
              className={chipValue === 0 ? "chipSelected" : "chipDefault"}
              color="secondary"
              label="Información del paciente"
              onClick={() => handleClick(0)}
            />
            <Chip
              hidden={info.tipoCaso === "CAEC"}
              className={chipValue === 1 ? "chipSelected" : "chipDefault"}
              color="secondary"
              label="Datos institución"
              onClick={() => handleClick(1)}
            />
            <Chip
              hidden={info.tipoCaso === "CAEC"}
              className={chipValue === 2 ? "chipSelected" : "chipDefault"}
              color="secondary"
              label="Datos médico tratante"
              onClick={() => handleClick(2)}
            />
            <Chip
              className={chipValue === 3 ? "chipSelected" : "chipDefault"}
              color="secondary"
              label="Datos hospitalización"
              onClick={() => handleClick(3)}
            />
          </Stack>
        </Grid>
        {chipValue === 0 ?
          <InformacionPaciente
            informacionPaciente={informacionPaciente}
            setInformacionPaciente={setInformacionPaciente}
            deshabilitaInputs={deshabilitaInputs}
            info={info}
            actualizarInfo={actualizarInfo}
            loading={loading}
          />
          : null}
        {chipValue === 1 ?
          <Grid item xs={12} m="24px">
            <Grid container
              padding="16px 15px 14px 16px"
              className="grayContainer"
              spacing={2}>
              <Grid item xs={12} >
                <label className="grayTitle" mb="0">
                  <IconHospital pt="10px" /> Datos institución
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  RUT Institución
                </label>
                <label className="subtitle">
                  {formatRut(info.rutPrestadorInstitucional)}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Dirección
                </label>
                <label className="subtitle">
                  {info.direccionPrestadorInstitucional}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Razón social
                </label>
                <label className="subtitle">
                  {info.razonSocialPrestador}
                </label>
              </Grid>
            </Grid>
          </Grid> : null}
        {chipValue === 2 ?
          <Grid item xs={12} m="24px">
            <Grid container
              padding="16px 15px 14px 16px"
              className="grayContainer"
              spacing={2}>
              <Grid item xs={12} >
                <label className="grayTitle" mb="0">
                  <IconDoctor /> Médico tratante
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  RUT Médico
                </label>
                <label className="subtitle">
                  {formatRut(info.rutMedicoTratante)}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Nombre completo
                </label>
                <label className="subtitle">
                  {info.nombreMedicoTratante}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Especialidad
                </label>
                <label className="subtitle">
                  {info.especialidadOtroMedicoTratante ? "Otro" : info.especialidadMedicoTratante}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Otro
                </label>
                <label className="subtitle">
                  {info.especialidadOtroMedicoTratante ? info.especialidadMedicoTratante : "-"}
                </label>
              </Grid>
              <Grid item xs={8}>
                <label className="title">
                  Tratamiento actual
                </label>
                <label className="subtitle">
                  {info.tratamiento}
                </label>
              </Grid>
            </Grid>
          </Grid>
          : null}
        {chipValue === 3 ?
          <Grid item xs={12} m="24px">
            <Grid container
              padding="16px 15px 14px 16px"
              className="grayContainer"
              spacing={2}>
              <Grid item xs={12} >
                <label className="grayTitle" mb="0">
                  <IconAmbulance /> Datos hospitalización
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Hospitalización
                </label>
                <label className="subtitle">
                  {info.declaraHospitalizacion ? "Sí" : "No"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Fecha hospitalización desde
                </label>
                <label className="subtitle">
                {info.declaraHospitalizacion ? fechaConvertida(info.fechaHospitalizacionDesde) : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Fecha hospitalización hasta
                </label>
                <label className="subtitle">
                  {info.declaraHospitalizacion ? info.fechaHospitalizacionHasta ? fechaConvertida(info.fechaHospitalizacionHasta): "-" : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  RUT Institución
                </label>
                <label className="subtitle">
                {info.declaraHospitalizacion ? formatRut(info.rutInstitucionHopsitalizacion) : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Dirección
                </label>
                <label className="subtitle">
                {info.declaraHospitalizacion ? info.direccionHospitalizacion : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Razón social
                </label>
                <label className="subtitle">
                {info.declaraHospitalizacion ? info.razonSocialInstitucionHopsitalizacion : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Traslado
                </label>
                <label className="subtitle">
                  {info.declaraTraslado ? "Sí" : "No"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Traslado desde
                </label>
                <label className="subtitle">
                  {info.declaraTraslado ? info.origenDestinoTraslado : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Traslado hasta
                </label>
                <label className="subtitle">
                  {info.declaraTraslado ? info.destinoTraslado : "-"}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label className="title">
                  Urgencia Vital
                </label>
                <label className="subtitle">
                  {info.declaraUrgenciaVital ? "Sí" : "No"}
                </label>
              </Grid>
            </Grid>
          </Grid> : null}
      </Grid>
    </div>
  );
}
