import {
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { parseISO } from "date-fns";
import GetHitoTermino from "./GetHitoTermino";
import Exceptuar from "./Exceptuar";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useState } from "react";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";

export const ModalAsignaHito = ({
    modalSelectHito,
    cerrarModal,
    verificaCambioModal,
    hitoInicio,
    problemaSalud,
    deshabilitaInputs,
    dataTermino,
    setDataTermino,
    loading
}) => {
    const [errorData, setErrorData] = useState({});

    const seteaDatosHito = (hito) => {
        let dataHito = {};
        if (hito) {
            dataHito.id = hito.id;
            dataHito.descripcion = hito.nombreHito.descripcion;
            dataHito.codPrestacion = hito.cod_prestacion || null;
            if (dataHito.descripcion === 'Cierre de hito con excepción') {
                dataHito.exceptuar = true;
            } else {
                dataHito.exceptuar = false;
            }
            if (hito.intervencion !== null) {
                dataHito.canasta =
                    hito.problemaSalud +
                    hito.intervencion +
                    hito.grupo_prestacion +
                    " - " +
                    hito.nombreHito.descripcion;
            }
            setDataTermino({
                ...dataTermino,
                ...dataHito,
                hitoT: dataHito.id,
                motivo: null,
                comentario: null
            });
            setErrorData({ ...errorData, hitoT: null });
        }
    }

    const verificaData = () => {
        let error = {};
        if (!dataTermino.hitoT) {
            error.hitoT = "Debe seleccionar un hito de termino";
        }
        if (dataTermino.descripcion === 'Cierre de hito con excepción') {
            if (!dataTermino.motivo) {
                error.motivo = "Debe seleccionar un motivo de termino";
            }
        }
        if (!dataTermino.fechaEvento) {
            error.fechaEvento = "Debe seleccionar una fecha de evento";
        }
        setErrorData(error);
        if (Object.keys(error).length === 0) {
            verificaCambioModal();
        }
    }

    return (
        <Dialog
            data-testid="primerModal"
            tabIndex={-1}
            id="primerModal"
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={modalSelectHito}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle>
                <Grid
                    className="dialogModal"
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Typography className="modalTitle">Asignar Hito de Término</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    className="dialogModal"
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Grid
                        sx={{ padding: "5px", margin: "0", width: "100%", paddingLeft: "10px" }}
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <h3>Detalles de Hito</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <label>Descripción del hito</label>
                            <FormControl
                                sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                            >
                                <GetHitoTermino
                                    problemaSalud={problemaSalud}
                                    dataHito={hitoInicio}
                                    Hito={dataTermino.hitoT}
                                    setHito={seteaDatosHito}
                                    deshabilitaInputs={deshabilitaInputs || loading}
                                />
                            </FormControl>
                            <span className="errorSpan">{errorData.hitoT || ""}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={dataTermino.descripcion === 'Cierre de hito con excepción'}>
                                <Exceptuar
                                    error={errorData}
                                    setError={setErrorData}
                                    dataTermino={dataTermino}
                                    setDataTermino={setDataTermino}
                                    loading={loading}
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={6}  >
                            <label>Canasta</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} >
                                <input
                                    data-testid="inputCanasta"
                                    type="text"
                                    className="form-control inputText"
                                    name="canasta"
                                    id="canasta "
                                    placeholder=" "
                                    value={dataTermino.canasta || ""}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label>Prestaciones</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} >
                                <input
                                    data-testid="inputPrestaciones"
                                    value={dataTermino.prestacion || ""}
                                    type="text"
                                    className="form-control inputText"
                                    placeholder=" "
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label>Fecha del evento</label>
                            <DatePicker
                                id="cambioFechaHT"
                                testid="cambioFechaHT"
                                minDate={parseISO(hitoInicio.fechaEvento)}
                                value={dataTermino.fechaEvento || null}
                                error={errorData.fechaEvento}
                                onChange={(newValue) => {
                                    setDataTermino({ ...dataTermino, fechaEvento: newValue });
                                    setErrorData({ ...errorData, fechaEvento: null });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Hora del evento</label>
                            <FormControl
                                sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={es}
                                >
                                    <Grid sx={{ m: 0.5 }}>
                                        <DesktopTimePicker
                                            label=""
                                            value={dataTermino.fechaEvento || null}
                                            disabled={loading}
                                            inputProps={{ "data-testid": "cambioHoraHT" }}
                                            onChange={(newValue) => {
                                                setDataTermino({ ...dataTermino, fechaEvento: newValue });
                                                setErrorData({ ...errorData, fechaEvento: null });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} sx={{ width: "100%" }} onKeyDown={(e) => { e.preventDefault() }} />
                                            )}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <SecondaryButton
                        data-testid="btnCancelar"
                        variant="text"
                        className="btn btn-secondary btn-lg"
                        disabled={loading}
                        onClick={cerrarModal}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        id="continuarbtn"
                        testid="btnContinuar"
                        label={dataTermino.descripcion === "Cierre de caso" || dataTermino.descripcion === 'Cierre de hito con excepción' ? "Finalizar" : "Continuar"}
                        toDo={verificaData}
                        loading={loading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>);
}