import { useState } from "react";
import {
  consultaCanastaasignadas,
  prestadoresAsignados,
} from "../../../../utils/queries.js";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/Table.css";
import { getComparator, stableSort } from "../../../../components/Table/utils";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { useQuery, NetworkStatus } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador.jsx";
import { useMsal } from "@azure/msal-react";
import { MenuPrestadores } from "./components/MenuPrestadores.jsx";
import { NoResult } from "../../../../components/NoData/NoResults";
import { fechaHora, fecha } from "../../../../utils/fechaUtils.js";

export function PrestadoresEstablecidos({
  useQueryHook = useQuery,
  useMsalHook = useMsal,
  deshabilitaInputs,
  recarga,
  setRecarga,
  alertData,
  setAlertData,
  sucursal,
  ...props
}) {
  const folio = props.folio;
  const estado = props.estado;
  const [ordenPrestadores, setOrdenPrestadores] = useState("desc");
  const [ordenarPorPrestadores, setOrdenarPorPrestadores] = useState("idDespliegue");
  const [pagPrestadores, setPagPrestadores] = useState(0);
  const [lineasPorPagPrestadores, setLineasPorPagPrestadores] = useState(5);
  const [resetPagPrestadores, setResetPagPrestadores] = useState(false);
  const { accounts } = useMsalHook();

  const { data, loading, error, refetch, networkStatus } = useQueryHook(prestadoresAsignados, {
    variables: {
      folio: folio,
      offset: lineasPorPagPrestadores * pagPrestadores,
      limit: lineasPorPagPrestadores,
      order: ordenarPorPrestadores,
      dir: ordenPrestadores.toUpperCase()
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const { data: canastasAsignadas, loading: cargandoCanastas, refetch: refetchCanastas, networkStatus: netStatusCanastas } = useQueryHook(
    consultaCanastaasignadas,
    {
      variables: {
        folio: props.folio,
        order: "idDespliegue",
        dir: "ASC",
        aplicaVigencia:false
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  if ((loading || cargandoCanastas) && (networkStatus !== NetworkStatus.refetch) && (netStatusCanastas !== NetworkStatus.refetch)) {
    if (pagPrestadores !== 0 && !resetPagPrestadores) {
      setPagPrestadores(0);
      setResetPagPrestadores(false);
    }
    return <SkeletonTable test={"loading-prestadores"} />;
  }

  if (error) return <pre data-testid="errorMessage">{error.message}</pre>;

  const managePages = () => {
    setResetPagPrestadores(true);
  }

  const cabeceraPrestadores = [
    {
      id: "idDespliegue",
      sorted: true,
      sticky: true,
      label: "N°",
    },
    {
      id: "idcanastaAsignada",
      sorted: true,
      label: "Canasta Asociada",
    },
    {
      id: "prestador",
      sorted: true,
      label: "RUT Prestadores" /* antes estaba (RUT Prestadores) Franco */,
    },
    {
      id: "nombrePrestador",
      sorted: true,
      label: "Nombre",
    },
    {
      id: "direccionPrestador",
      sorted: true,
      label: "Dirección",
    },
    {
      id: "fechaInicio",
      sorted: true,
      label: "Fecha Inicio",
    },
    {
      id: "fechaTermino",
      sorted: true,
      label: "Fecha Término",
    },
    {
      id: "vigencia",
      sorted: true,
      label: "Estado",
    },
    {
      id: "transitorio",
      sorted: true,
      label: "Transitorio",
    },
    {
      id: "tipoPrestador",
      sorted: true,
      label: "Asignación",
    },
    {
      id: "createdby",
      sorted: true,
      label: "Creado por",
    },
  ];

  return (
    <Box sx={{ width: "100%" }} data-testid="container">
      <Button id="recargaPrest" hidden={true} onClick={() => { refetch(); refetchCanastas() }} />
      {!data || data.getPrestadoresAsignados.total === 0 ?
        <NoResult
          titulo={"Sin Prestadores"}
          subtitulo={"Aún no se han establecido prestadores."}
        /> :
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <CabeceraTabla
                order={ordenPrestadores}
                orderBy={ordenarPorPrestadores}
                setOrder={setOrdenPrestadores}
                setOrderBy={setOrdenarPorPrestadores}
                cabecera={cabeceraPrestadores}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(
                  data.getPrestadoresAsignados.prestadores,
                  getComparator(ordenPrestadores, ordenarPorPrestadores)
                )
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell
                          className="tableBodyIzquierda"
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.idDespliegue}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.canasta}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.prestador}
                        </TableCell>
                        <TableCell align="left" className="fila" sx={{minWidth:'300px'}}>
                          {row.nombrePrestador}
                        </TableCell>
                        <TableCell align="left" className="fila" sx={{minWidth:'300px'}}>
                          {row.direccionPrestador}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {fecha(row.fechaInicio)}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.fechaTermino
                            ? fecha(row.fechaTermino)
                            : null}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.vigencia === true ? "Vigente" : "No vigente"}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.transitorio
                            ? "Sí"
                            : row.tipoPrestador === "DR"
                              ? "-"
                              : "No"}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.tipoPrestador === "DR" ? (
                            <label className="tagEstadoCasos tagAutorizado">
                              RED
                            </label>
                          ) : (
                            <label className="tagEstadoCasos tagCerrado">
                              Fuera de RED
                            </label>
                          )}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.createdby} {fechaHora(row.createdAt)}
                        </TableCell>
                        <TableCell
                          className="tableBodyAcciones sombraAcciones"
                          align="left"
                        >
                          <MenuPrestadores
                            estado={estado}
                            prestador={row}
                            accounts={accounts}
                            folio={folio}
                            canastasAsignadas={canastasAsignadas}
                            prestadores={data.getPrestadoresAsignados.prestadores}
                            deshabilitaInputs={deshabilitaInputs}
                            alertData={alertData}
                            setAlertData={setAlertData}
                            sucursal={sucursal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.getPrestadoresAsignados.total}
              servPag={true}
              loadNextPage={managePages}
              itemPorPagina={lineasPorPagPrestadores}
              pagina={pagPrestadores}
              setPagina={setPagPrestadores}
              setLineasPagina={setLineasPorPagPrestadores}
            />
          </Grid>
        </Paper>}
    </Box>
  );
}
