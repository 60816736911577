import { useState } from "react";
import { parseISO } from "date-fns";
import validator from "validator";
import icon_user from "../../../../components/icons/icon_user.svg";
import icon_userPlus from "../../../../components/icons/icon_userPlus.svg";
import { Grid } from "@mui/material";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { IconMedicCase } from "../../../../components/icons/iconosEsencial";
import Cie10 from "./Cie10";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";

export default function InformacionPaciente({
    info,
    informacionPaciente,
    setInformacionPaciente,
    deshabilitaInputs,
    actualizarInfo,
    loading
}) {
    const [errorText, setErrorText] = useState({});
    const [desactivaValida, setDesactivaValida] = useState(false);

    function DetectaCambio(e) {
        let nombre = e.target.id;
        let valor = e.target.value;
        let fechaMarca = informacionPaciente.fechaInicio;
        if (
            nombre === "celularBenef" ||
            nombre === "nroBenef" ||
            nombre === "nroRep" ||
            nombre === "celularRep"
        ) {
            valor.replace(/[^0-9]/g, "");
            if (valor.length > 9 || valor.length < 9) {
                setErrorText({
                    ...errorText,
                    [nombre]: "Número de teléfono inválido",
                });
                setDesactivaValida(true);
            } else {
                setErrorText({
                    ...errorText,
                    [nombre]: "",
                });
                setDesactivaValida(false);
            }
        }
        if (nombre === "mailBenef" || nombre === "mailRep") {
            if (!validator.isEmail(valor)) {
                setErrorText({
                    ...errorText,
                    [nombre]: "Correo inválido",
                });
                setDesactivaValida(true);
            } else {
                setErrorText({
                    ...errorText,
                    [nombre]: "",
                });
                setDesactivaValida(false);
            }
        }
        if (nombre === "marca" && valor === "N/A") {
            fechaMarca = null;
        }
        setInformacionPaciente({
            ...informacionPaciente,
            [nombre]: valor,
            fechaInicio: fechaMarca,
        });
    }

    return (
        <Grid item xs={12}>
            <Grid container
                spacing={2}>
                <Grid item xs={12}>
                    <label className="tituloGris">
                        <img src={icon_user} alt=""></img>Datos personales beneficiario
                    </label>
                </Grid>

                <Grid item xs={4}>
                    <label className="labelFormFlex">
                        Correo electrónico
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        data-testid="emailPaciente"
                        type="mail"
                        className="form-control inputText"
                        placeholder="Ej: ejemplo@correo.com"
                        id="mailBenef"
                        error={errorText.mailBenef ? true : undefined}
                        value={informacionPaciente.mailBenef || ""}
                        onChange={DetectaCambio}
                    />
                    <span className="errorSpan">{errorText.mailBenef}</span>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelFormFlex">
                        Teléfono particular
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        data-testid="numeroPaciente"
                        className="form-control inputText"
                        id="nroBenef"
                        placeholder="Ej: 2 123456789"
                        value={informacionPaciente.nroBenef || ""}
                        onChange={DetectaCambio}
                        error={errorText.nroBenef ? true : undefined}
                        inputProps={{ maxLength: 9 }}
                    />
                    <span className="errorSpan">{errorText.nroBenef}</span>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelFormFlex" htmlFor="celPaciente">
                        Teléfono celular <span className="obligatorio">*</span>
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        className="form-control inputText"
                        data-testid="celularPaciente"
                        id="celularBenef"
                        placeholder="Ej: 9 123456789"
                        value={informacionPaciente.celularBenef || ""}
                        error={errorText.celularBenef ? true : undefined}
                        onChange={DetectaCambio}
                        inputProps={{ maxLength: 9 }}
                    />
                    <span className="errorSpan">{errorText.celularBenef}</span>
                </Grid>

                <Grid item xs={12}>
                    <label className="tituloGris">
                        <img src={icon_userPlus} alt=""></img>Datos personales representante
                    </label>
                </Grid>

                <Grid item xs={4}>
                    <label className="labelFormFlex" htmlFor="emailRepresentante">
                        Correo electrónico
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        type="text"
                        className="form-control inputText"
                        id="mailRep"
                        data-testid="emailRep"
                        placeholder="Ej: ejemplo@correo.com"
                        value={informacionPaciente.mailRep || ""}
                        error={errorText.mailRep ? true : undefined}
                        onChange={DetectaCambio}
                    />
                    <span className="errorSpan">{errorText.mailRep}</span>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelFormFlex" htmlFor="telRepresentante">
                        Teléfono particular <span className="obligatorio">*</span>
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        type="text"
                        className="form-control inputText"
                        id="nroRep"
                        data-testid="telefRep"
                        placeholder="Ej: 2 123456789"
                        value={informacionPaciente.nroRep || ""}
                        error={errorText.nroRep ? true : undefined}
                        onChange={DetectaCambio}
                        inputProps={{ maxLength: 9 }}
                    />
                    <span className="errorSpan">{errorText.nroRep}</span>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelFormFlex" htmlFor="celRepresentante">
                        Teléfono celular
                    </label>
                    <ThemeTextField
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        type="telephone"
                        className="form-control inputText"
                        id="celularRep"
                        data-testid="celularRep"
                        placeholder="Ej: 9 123456789"
                        value={informacionPaciente.celularRep || ""}
                        error={errorText.celularRep ? true : undefined}
                        onChange={DetectaCambio}
                        inputProps={{ maxLength: 9 }}
                    />
                    <span className="errorSpan">{errorText.celularRep}</span>
                </Grid>

                <Grid item xs={12}>
                    <label className="tituloGris">
                        <IconMedicCase />Datos del caso médico
                    </label>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm" htmlFor="fechaAutorizacionSolicitud">
                        Fecha de autorización
                    </label>
                    <DatePicker
                        id="fechaAutoriza"
                        testid="fechaAutoriza"
                        minDate={informacionPaciente.fechaInicioBenef}
                        maxDate={parseISO(info.fechaRecepcionSolicitud)}
                        value={informacionPaciente.fechaAutoriza}
                        noClear
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        onChange={(newValue) => {
                            setInformacionPaciente({
                                ...informacionPaciente,
                                fechaAutoriza: newValue,
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Cie10
                        tipoCaso={info.tipoCaso}
                        info={info}
                        informacionPaciente={informacionPaciente}
                        setInformacionPaciente={setInformacionPaciente}
                        deshabilitaInputs={deshabilitaInputs || loading}
                    />
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm" htmlFor="asignaMarca">
                        Asignar marca <span className="opcional">(opcional)</span>
                    </label>
                    <select
                        disabled={
                            info.fkEstadoSolicitudIsapre === 4 ||
                                info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                info.fkEstadoSolicitudIsapre === 9 ||
                                deshabilitaInputs || 
                                loading
                                ? 1
                                : 0
                        }
                        data-testid="asignaMarca"
                        name="asignaMarca"
                        id="marca"
                        className="form-select form-select-sm selectDefault"
                        value={informacionPaciente.marca || ""}
                        onChange={DetectaCambio}
                    >
                        <option value="0">Seleccione</option>
                        <option value="LRS">LRS</option>
                        <option value="02">02</option>
                        <option value="HD">HD</option>
                        <option value="Caso sensible">Caso sensible</option>
                        <option value="N/A">No Aplica</option>
                    </select>
                </Grid>

                <Grid item xs={4}>
                    <label className="labelForm" htmlFor="fechaInicioMarca">
                        Fecha de inicio Marca <span className="opcional">(opcional)</span>
                    </label>
                    <DatePicker
                        id="fechaInicio"
                        testid="fechaInicio"
                        minDate={parseISO(info.fechaRecepcionSolicitud)}
                        value={informacionPaciente.fechaInicio}
                        disabled={
                            info.fkEstadoSolicitudIsapre === 4 ||
                                info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                info.fkEstadoSolicitudIsapre === 9 ||
                                informacionPaciente.marca === "N/A" ||
                                deshabilitaInputs || 
                                loading
                                ? true
                                : false
                        }
                        onChange={(newValue) => {
                            setInformacionPaciente({
                                ...informacionPaciente,
                                fechaInicio: newValue
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <label>Comentario</label>
                    <textarea
                        disabled={
                            info.fkEstadoSolicitudIsapre === 4 ||
                                info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                info.fkEstadoSolicitudIsapre === 9 ||
                                deshabilitaInputs || 
                                loading
                                ? 1
                                : 0
                        }
                        id="comentarioMarca"
                        cols="30"
                        rows="4"
                        className="form-control inputText"
                        placeholder="Escribe comentario"
                        value={informacionPaciente.comentarioMarca || ""}
                        onChange={DetectaCambio}
                        maxLength="100"
                    ></textarea>
                </Grid>
            </Grid>
            <Grid item xs={12} mt="46px">
                <Grid container direction="row" justifyContent="end">
                    <BotonCarga
                        id="guardaDatosPac"
                        testid="guardaDatosPac"
                        sx={{ marginRight: "8px!important" }}
                        boxSX={{ "& > button": { m: 1 } }} campos
                        label="Guardar información"
                        toDo={actualizarInfo}
                        loading={loading}
                        disabled={
                            info.fkEstadoSolicitudIsapre === 5 ||
                                info.fkEstadoSolicitudIsapre === 6 ||
                                info.fkEstadoSolicitudIsapre === 7 ||
                                desactivaValida ||
                                deshabilitaInputs
                                ? true
                                : false
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}