import {
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { Localidad } from "../../../../components/Localidad/Localidad";
import BuscaPrestador from "../../../../components/BuscaPrestador/BuscaPrestador";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useState } from "react";

export const ModalPrestador = ({
    modalPrestador,
    verificaCambioModal,
    volverSelectHito,
    dataTermino,
    setDataTermino,
    loading
}) => {
    const [errorPres, setErrorPres] = useState({});

    const actualizaDatosPres = (dir, luat, rz, rut) => {
        setDataTermino({
            ...dataTermino,
            prestador: rut,
            razonSocial: rz,
            direccionPrestador: dir,
            luatPrestador: luat
        });
        setErrorPres({
            ...errorPres,
            rutInsti: null
        });
    };

    const detectaCambioComboregiones = (valores) => {
        setDataTermino({
            ...dataTermino,
            region: valores.idReg,
            comuna: null
        });
        setErrorPres({
            ...errorPres,
            region: null
        });
    }

    const detectaCambioCombocomuna = (e) => {
        setDataTermino({
            ...dataTermino,
            comuna: e.idCom
        });
        setErrorPres({
            ...errorPres,
            comuna: null
        });
    }

    const validaDataPres = () => {
        let error = {};
        if (!dataTermino.prestador) {
            error.rutInsti = "Debe seleccionar un prestador";
        }
        if (!dataTermino.direccionPrestador && !error.rutInsti) {
            error.rutInsti = "Debe seleccionar una dirección";
        }
        if (!dataTermino.region) {
            error.region = "Debe seleccionar una region";
        }
        if (!dataTermino.comuna) {
            error.comuna = "Debe seleccionar una comuna";
        }
        setErrorPres(error);
        if (Object.keys(error).length === 0) {
            verificaCambioModal();
        }
    }
    return (
        <Dialog
            className="dialogModalBig"
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={modalPrestador}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle>
                <Grid
                    className="dialogModal"
                    container
                    direction="column"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Typography className="modalTitle">Asignar Hito de Término</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    className="dialogModalAlt"
                    container
                    direction="column"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Grid
                        sx={{ padding: "14px", width: "100%" }}
                        container
                        spacing={2}
                    >
                        <BuscaPrestador
                            razonSocial={dataTermino.razonSocial}
                            rutInsti={dataTermino.prestador}
                            luat={dataTermino.luatPrestador}
                            direccion={dataTermino.direccionPrestador}
                            error={errorPres}
                            asignaPrestador={actualizaDatosPres}
                            disabled={loading}
                            isModal={true}
                        />
                        <Grid item xs={12}>
                            <Divider sx={{ color: "#D2D6D9" }} />
                        </Grid>
                        <Localidad
                            region={dataTermino.region}
                            regionError={errorPres.region}
                            setRegion={detectaCambioComboregiones}
                            comuna={dataTermino.comuna}
                            comunaError={errorPres.comuna}
                            setComuna={detectaCambioCombocomuna}
                            requerido={true}
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <SecondaryButton
                        data-testid="btnVolver"
                        variant=" text"
                        disabled={loading}
                        onClick={volverSelectHito}
                    >
                        Volver
                    </SecondaryButton>
                    <BotonCarga
                        id="continuarbtn"
                        testid="btnAsignarFinal"
                        label={"Finalizar"}
                        toDo={validaDataPres}
                        loading={loading}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}