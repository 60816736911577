import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';


const Botones = styled(Button)({

  color: "#FFFFFF",
  fontStyle: 'normal',
  fontWeight: 'bold',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: ' 0px 2px 2px rgba(121, 133, 142, 0.25)',
  backgroundColor: "#00837A",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "179px",
  width: "auto",


  '&:hover': {
    color: "#FFFFFF",
    backgroundColor: '#4DC0B8',
    boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)'
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
    boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
  },
  "&:disabled": {
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#E9EBEC"
  }
});


export const SecondaryButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
});

export const MediumButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "40px",
  minWidth: "50px",
  width: "103px",


  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});

export const AsignadoButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: 'none',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }

});

export const ButtonWithoutBorder = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: 'none',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: 'none',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: 'none',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "unset",
  }

});
export const TransparentButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: 'none',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "transparent",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: 'none',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: 'transparent',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "transparent"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: 'none',
    color: "#A6AEB3",
    boxShadow: 'none',
    backgroundColor: "transparent",
  }

});
export const ButtonDisabled = styled(Button)({
  color: "#909AA1",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: 'none',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "none",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: 'none',
    boxSizing: 'border-box',
    color: "none",
    backgroundColor: 'transparent',
    cursor: "inherit"
  },
  '&:active': {
    color: "none",
    backgroundColor: "transparent",
    cursor: "inherit"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: 'none',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }

});
export const ButtonIcon = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "40px",
  alignContent: "center",
  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
    backgroundColor: "#FFFFFF"
  }
});


export const IconOutlined = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '0',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "30px",
  alignContent: "center",
  width: "20px",
  padding: "5px 9px",
  minWidth: "30px",



  '&:hover': {
    border: '0',
    backgroundColor: "transparent",
  },
  '&:active': {
    color: "#FFFFFF",
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    backgroundColor: "#FFFFFF",
    opacity: ".5"
  }
});

export const OutlinedButton = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '0',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "30px",
  alignContent: "center",
  width: "133px",
  padding: "5px 9px",
  minWidth: "30px",
  textDecoration: "underline",



  '&:hover': {
    border: '0',
    backgroundColor: "transparent",
  },
  '&:active': {
    color: "#FFFFFF",
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    backgroundColor: "#FFFFFF",
    opacity: ".5"
  }
});

export const OutlinedButton2 = styled(Button)({
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '0',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "30px",
  alignContent: "center",
  width: "133px",
  padding: "5px 9px",
  minWidth: "30px",
  textDecoration: "none",



  '&:hover': {
    border: '0',
    backgroundColor: "transparent",
  },
  '&:active': {
    color: "#FFFFFF",
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #67737C',
    color: "#67737C",
    backgroundColor: "#FFFFFF",
    opacity: ".5"
  }
});
export default Botones