import { useDropzone } from 'react-dropzone';
import { SecondaryButton } from "../Buttons/Botones";
import { UploadGreen, UploadGray } from "../../components/icons/iconosEsencial";
import ErrorText from './components/ErrorText';
import { CircularProgress } from '@mui/material';

export const BotonCargaArchivo = ({ disabled, cargando, toDo, mutation, maxSize = 5000, msgError, setUploading, usuario, sucursal }) => {
  const onDrop = (accFiles, rejFiles) => {
    if (accFiles[0]) {
      setUploading(true);
      toDo(accFiles[0], mutation, msgError, usuario, sucursal);
    } else {
      msgError(ErrorText(rejFiles[0].errors, true));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: disabled || cargando,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    maxSize: maxSize * 1024, // 5MB
  });

  return (
    <div {...getRootProps()}>
      <input data-testid="fileDropButton" {...getInputProps()} />
      <SecondaryButton
        sx={{ pl: "16px !important", pr: "16px !important", width: "179px" }}
        disabled={disabled || cargando}
      >
        {cargando ? <CircularProgress className="spinnerGreen" /> :
          <>{disabled ? <UploadGray sx={{ pt: "2px" }} /> :
            <UploadGreen sx={{ pt: "2px" }} />} Cargar archivo</>}
      </SecondaryButton>
    </div>
  )
}