import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import PrimaryButton from "../../../../components/Buttons/Botones";
import TablaCambiosV from "./TablaCambiosV"; 
import { logVdmc } from "../../../../utils/queries";

const HistorialCambiosV = ({
    onOpen,
    onClose,
    dataV
}) => {
    const [logs, setLogs] = useState([]);
    const [getLogsVDMC, { loading }] = useLazyQuery(logVdmc, {
        onCompleted: (res) => setLogs(res.logVDMC),
        onError: () => setLogs([{error: null}]),
    });

    if (onOpen && !loading && logs.length === 0) {
        getLogsVDMC({
            variables: {
                id: dataV.id
            }
        });
    }

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Historial de cambios
                <br></br>
                <label className="subtitleModal">
                    A continuación se muestra una lista de todas las modificaciones realizadas en este registro.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ margin: "0", width: "100%", border: "1px solid #E9EBEC", borderRadius: "8px" }}
                    container
                    spacing={2}
                >
                    <Grid xs={12}>
                        <TablaCambiosV data={logs} loading={loading} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    data-testid="cerrarHistorial" 
                    onClick={()=>{
                        onClose();
                        setLogs([]);
                    }}
                >
                    Cerrar
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default HistorialCambiosV;
