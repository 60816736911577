import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import {
    Grid,
    Stack
} from "@mui/material";
import SkeletonBar from "../../../components/Skeleton/SkeletonBar";
import { SkeletonTableWithExport } from "../../../components/Skeleton/SkeletonTableWithExport";

export default function LoaderMedicamentos() {
    return (
        <Grid container>
            <Grid item xs={6} pr="24px">
                <div className="container">
                    <Grid container p="24px">
                        <Grid item xs={12}>
                            <Grid item xs={12} pb="10px">
                                <SkeletonBar
                                    width={180}
                                    height={18}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SkeletonBar
                                    width={371}
                                    height={16}
                                />
                            </Grid>
                            <Grid item pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                <SkeletonBar
                                    width={180}
                                    height={40}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="container">
                    <Grid container p="24px">
                        <Grid item xs={12}>
                            <Grid item xs={12} pb="10px">
                                <SkeletonBar
                                    width={180}
                                    height={18}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SkeletonBar
                                    width={371}
                                    height={16}
                                />
                            </Grid>
                            <Grid item pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                <Stack direction={"row"}>
                                    <SkeletonBar
                                        width={180}
                                        height={40}
                                    />

                                    <Stack direction={"column"} pl="16px" spacing="10px">
                                        <SkeletonBar
                                            width={200}
                                            height={14}
                                        />
                                        <SkeletonBar
                                            width={200}
                                            height={14}
                                        />
                                    </Stack>

                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <SkeletonTableWithExport rows={6}/>
        </Grid>
    );
}