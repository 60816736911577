import { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { TableBodyLoader } from "../../../components/Skeleton/SkeletonTableWithExport";
import { CabeceraTabla } from "../../../components/TableHeader/TableHeader";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { ParseHoraLogsPS, fechaConvertida } from "../../../utils/fechaUtils";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { NoResult } from "../../../components/NoData/NoResults";

export default function TablaCambios({ data, loading }) {
    const [paginaPS, setPagPS] = useState(0);
    const [logPorPagina, setLogPorPagina] = useState(3);
    const cabeceraPS = [
        { id: "usuario", sorted: true, label: "Usuario" },
        { id: "fechaHora", sorted: true, label: "Fecha y hora" },
        { id: "descripcion", sorted: true, label: "Descripción", noActions: true },
    ];

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <CabeceraTabla
                        cabecera={cabeceraPS}
                    />
                    {loading ? <TableBodyLoader largo={logPorPagina+1} ancho={1} isPS/> :
                        <TableBody>
                            {data && data.logs && data.logs.length > 0 ?
                                data.logs.slice(
                                    paginaPS * logPorPagina,
                                    paginaPS * logPorPagina + logPorPagina
                                  ).map((log, index) =>
                                    <TableRow
                                        data-testid="withData"
                                        key={index}
                                    >
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {log.LoginAutorizacion}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "5%" }}
                                            align="left"
                                        >
                                            <label className="labelnroCaso">
                                                {fechaConvertida(log.FechaIngreso)}
                                            </label>
                                            <label className="labeltipoCaso">{ParseHoraLogsPS(log.FechaIngreso)}</label>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {verificaLargo(log.descripcion, 50)}
                                        </TableCell>
                                    </TableRow>): null}

                        </TableBody>}
                </Table>
            </TableContainer>
            {!loading && (!data || !data.total || data.total === 0) ?
                <NoResult titulo={"Sin resultados"} subtitulo={"No se encontraron resultados para esta tabla"} noDivider />
                : null}
            <Grid alignItems="center" pb="15px" pt="24px">
                {loading ? null :
                    <Paginador
                        opciones={[3, 5, 10]}
                        data={data?.total || 0}
                        pagina={paginaPS}
                        setPagina={setPagPS}
                        itemPorPagina={logPorPagina}
                        setLineasPagina={setLogPorPagina}
                    />}
            </Grid>
        </>
    );
}