import { Skeleton } from "@mui/material";

const SkeletonBar = ({ width, height }) => {
    return (
        <Skeleton
            sx={{ bgcolor: '#F2F5F9' }}
            variant="rounded"
            animation="wave"
            width={width}
            height={height}
        />
    );
}

export default SkeletonBar;