import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import { Arancel } from "./Arancel";

const BuscarPS = ({
    onOpen, onClose, filtros, setFiltros, isEliminado
}) => {
    const [configFiltros, setConfigFiltros] = useState(filtros);
    const estadosPS = [{ label: "Vigente", value: 2 }, { label: "No vigente", value: 1 }]
    const cambioInputPS = (event) => {
        let name = event.target.name;
        let value = name === "numero" ? parseInt(event.target.value.replace(/[^0-9]/g, "")) : event.target.value.replace(/[']+/g, "").trim();
        setConfigFiltros({
            ...configFiltros,
            [event.target.id]: value
        });
    };

    if (onOpen === false && configFiltros !== filtros) {
        setConfigFiltros(filtros);
    }
    
    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Buscar Problemas de Salud
                <br></br>
                <label className="subtitleModal">
                    Seleccione los criterios de búsqueda para encontrar Problemas de Salud específicos.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">Codigo PS</label>
                        <FormControl >
                            <TextField
                                placeholder="Ingresar Codigo PS"
                                id="Codigo"
                                name="numero"
                                data-testid="codigoPS"
                                value={configFiltros.Codigo || ""}
                                onChange={cambioInputPS}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Glosa</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar Glosa"
                                id="glosa"
                                data-testid="glosaPS"
                                value={configFiltros.glosa || ""}
                                onChange={cambioInputPS}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Arancel</label>
                        <Arancel
                            id="arancel"
                            data-testid="arancelPS"
                            value={configFiltros.arancel}
                            setValue={(val)=>{
                                setConfigFiltros({
                                    ...configFiltros,
                                    arancel: val
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Estado</label>
                        <SelectPersonalizado
                            placeholder={isEliminado ? "ELIMINADO" : "Seleccionar un estado"}
                            placeholderHidden
                            disabled={isEliminado}
                            value={configFiltros.estado}
                            setValue={(value) =>
                                setConfigFiltros({
                                    ...configFiltros,
                                    estado: value
                                })}
                            options={estadosPS}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="24px"
                    pl="25px"
                    pr="10px"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        onClick={() => {
                            setConfigFiltros(filtros);
                            onClose();
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="btnCheckDatos"
                        variant=" text"
                        color="default"
                        disabled={filtros === configFiltros}
                        onClick={() => {
                            setFiltros({ ...configFiltros, pag: 0 });
                            onClose();
                        }}
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default BuscarPS;
