import { useState } from "react";
import {
    style,
    iconStyle,
} from "../../../../../components/Snackbar/components/Styles";
import { editaFechaPrestadores, listaPrestadoresPorCanasta } from "../../../../../utils/queries.js";
import { SecondaryButton } from "../../../../../components/Buttons/Botones";
import { isSameDay } from "date-fns";
import {
    Alert,
    Box,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
} from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import AsignaPrestador from "../AsignaPrestador/AsignaPrestador";
import BotonCarga from "../../../../../components/Buttons/BotonCarga";
import Mensaje from "./MensajeEditaPres";
import { parseFecha } from "../../../../../utils/fechaUtils";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import BuscaPrestador from "../../../../../components/BuscaPrestador/BuscaPrestador.jsx";

const EditarPrestador = ({
    open,
    setOpen,
    canastaUse,
    status,
    estado,
    setStatus,
    prestador,
    usuario,
    folio,
    fechaAutorizacion,
    setAlertData,
    isCaec,
    sucursal
}) => {

    const [errorfechainicio, setErrorfechainicio] = useState("");
    const [dataPrestador, setDataPrestador] = useState(prestador);
    const [continuarDes, setContinuarDes] = useState(false);
    const [farmacia, setFarmacia] = useState(false);
    const [hidden, setHidden] = useState(false);
    var isPostAsignado = false;
    const [buscaPrestadores, { loading: buscandoPrestadores }] = useLazyQuery(listaPrestadoresPorCanasta, {
        variables: {
            idcanastaAsignada: canastaUse ? parseInt(canastaUse[0].id) : 0,
            conVigencia: false
        },
        fetchPolicy: "network-only"
    });
    const [editaPrestador, { loading: guardandoPrestador }] = useMutation(editaFechaPrestadores);

    if (status === 3 && farmacia === false) {
        setFarmacia(true);
        setContinuarDes(true);
    }
    function abrirModalPost() {
        verificaFecha(dataPrestador.fechaTermino);
        isPostAsignado = true;
    }
    
    if(!open && prestador !== dataPrestador){
        setDataPrestador(prestador);
    }

    function verificaFecha(fecha) {
        if (!isCaec && prestador.tipoPrestador === "DR") {
            buscaPrestadores({
                onCompleted: (data) => {
                    const prestTermino = data.getPrestadoresAsignadosxcanasta.filter((pres) =>
                        pres.tipoPrestador === "DR" &&
                        pres.id !== prestador.id &&
                        pres.prestador !== "76031071-9" &&
                        ((canastaUse[0].fechaFin !== null &&
                            isSameDay(parseFecha(pres.fechaTermino), parseFecha(canastaUse[0].fechaFin))
                        ) || canastaUse[0].fechaFin === pres.fechaTermino)
                    );
                    if (prestTermino.length === 0) {
                        if (canastaUse[0].fechaFin !== null) {
                            if (!isSameDay(parseFecha(fecha), parseFecha(canastaUse[0].fechaFin))) {
                                setStatus(1);
                                setContinuarDes(true);
                                setHidden(false);
                            } else {
                                VerificaCorrecto();
                            }
                        } else {
                            if (fecha === canastaUse[0].fechaFin) {
                                VerificaCorrecto();
                            } else {
                                setStatus(2);
                                setContinuarDes(true);
                                setHidden(false);
                            }

                        }

                    } else {
                        VerificaCorrecto();
                    }
                },
                onError: (error) => {
                    console.log(error.message);
                    setHidden(false);
                }
            })
        }
    }

    function VerificaCorrecto() {
        if (hidden) {
            editaPrestadores();
        } else {
            setContinuarDes(false);
            setStatus(0);
        }
    }

    function abrirModalEditar() {
        setHidden(true);
    }

    const handleCloseEditar = () => {
        const recargaPrest = document.getElementById("recargaPrest");
        setOpen(false);
        setHidden(false);
        setStatus(0);
        setContinuarDes(false);
        setFarmacia(false);
        if (recargaPrest) {
            recargaPrest.click();
        }
        setDataPrestador(prestador);
    };

    const editaPrestadores = () => {
        editaPrestador({
            variables: {
                id: parseInt(prestador.id),
                fechaInicio: dataPrestador.fechaInicio,
                fechaTermino: dataPrestador.fechaTermino,
                luat: dataPrestador.luat,
                direccion: dataPrestador.direccionPrestador,
                usuario: usuario.name,
                sucursal: sucursal,
            },
            onCompleted: () => {
                setAlertData({
                    abrir: true,
                    mensaje: isPostAsignado ?
                        "La fecha fue editada y el nuevo prestador fue establecido con éxito."
                        : "Prestador editado correctamente.",
                    severidad: "success",
                });
                handleCloseEditar();
            },
            onError: (error) => {
                console.log(error.message)
                setAlertData({
                    abrir: true,
                    mensaje: "No se editadó el prestador, intente nuevamente.",
                    severidad: "error",
                });
                setHidden(false);
            }
        })

    }

    const updateData = (nom, luat, rz, rut) => {
        setDataPrestador({
            ...dataPrestador,
            luat: luat,
            direccionPrestador: nom
        });
    }

    return (
        <Grid
            justifyContent="space-around"
            alignItems="center"
            sx={{
                "& .MuiTextField-root": { m: 1 },
            }}
        >
            <Dialog
                maxWidth="md"
                open={open}
                hidden={hidden}
                width="810px"
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <DialogTitle
                    alignContent="center"
                    sx={{ padding: "20px 13px 0px 13px", textAlign: "center" }}
                >
                    <label className="titulo2">Editar Fechas Prestadores</label>
                </DialogTitle>

                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center", width: "100%" }}
                >
                    <label className="labelForm">
                        Aquí puedes editar la dirección, fecha de inicio y/o término del prestador.
                    </label>
                </DialogContentText>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <DialogContent sx={{ padding: "5px 10 10px!important" }}>
                    <Grid
                        container
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <BuscaPrestador
                            razonSocial={dataPrestador.nombrePrestador}
                            rutInsti={dataPrestador.prestador}
                            luat={dataPrestador.luat}
                            error={{}}
                            disabled={farmacia}
                            direccion={dataPrestador.direccionPrestador}
                            asignaPrestador={updateData}
                            isModal
                            isEditaPres
                        />
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha de inicio</label>
                            <DatePicker
                                id="fechaInicioEditar"
                                testid="fechaInicioEditar"
                                minDate={!isCaec ? parseFecha(canastaUse[0].fechaInicio) : undefined}
                                maxDate={!isCaec && canastaUse[0].fechaFin !== null ? parseFecha(canastaUse[0].fechaFin) : undefined}
                                disabled={farmacia}
                                noClear
                                value={dataPrestador.fechaInicio}
                                error={errorfechainicio}
                                onChange={(newValue) => {
                                    isPostAsignado = false;
                                    setDataPrestador({
                                        ...dataPrestador,
                                        fechaInicio: newValue
                                    });
                                    setErrorfechainicio("");
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha de término</label>
                            <DatePicker
                                id="fechaTerminoEditar"
                                testid="fechaTerminoEditar"
                                minDate={dataPrestador.fechaInicio}
                                maxDate={!isCaec && canastaUse[0].fechaFin !== null ? parseFecha(canastaUse[0].fechaFin) : undefined}
                                value={dataPrestador.fechaTermino}
                                disabled={farmacia}
                                onChange={(newValue) => {
                                    isPostAsignado = false;
                                    setDataPrestador({
                                        ...dataPrestador,
                                        fechaTermino: newValue
                                    });
                                    setErrorfechainicio("");
                                    verificaFecha(newValue);
                                }}
                            />
                        </Grid>
                        <FormControl
                            sx={{
                                width: "99%!important",
                                margin: "10px 0",
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <Collapse in={status !== 0}>
                                    <Grid
                                        container
                                        alignItems="center"
                                    >
                                        <Grid item xs={farmacia ? 12 : 9}>
                                            <Alert
                                                severity="warning"
                                                sx={style("warning")}
                                                iconMapping={iconStyle("warning")}
                                            >
                                                <Mensaje msg={status} />
                                            </Alert>
                                        </Grid>
                                        {isCaec || farmacia ? null :
                                            <Grid item xs={3}>
                                                <AsignaPrestador
                                                    isEditaPres={true}
                                                    EditaPresTrans={abrirModalEditar}
                                                    postAsignar={abrirModalPost}
                                                    fechaInicio={dataPrestador.fechaTermino}
                                                    fechaTermino={canastaUse[0].fechaFin}
                                                    estadoSolicitud={estado}
                                                    folio={folio}
                                                    fechaAu={fechaAutorizacion}
                                                    data={canastaUse[0]}
                                                    volver={() => setHidden(false)}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Collapse>
                            </Box>
                        </FormControl>

                    </Grid>
                </DialogContent>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <DialogActions sx={{
                    margin: "0",
                    width: "100%",
                    padding: "20px 27px 18px 20px!important",
                }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <SecondaryButton
                            onClick={handleCloseEditar}
                            disabled={guardandoPrestador || buscandoPrestadores}
                        >
                            Cancelar
                        </SecondaryButton>
                        <BotonCarga
                            testid="btnGuardarItem"
                            disabled={continuarDes}
                            loading={guardandoPrestador || buscandoPrestadores}
                            toDo={editaPrestadores}
                            label="Continuar"
                        />
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default EditarPrestador;