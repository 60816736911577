import { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Breadcrumbs,
    Box,
    Typography,
    Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FileGreen } from "../../components/icons/iconosEsencial";
import BotonCarga from "../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { traerform } from "../../utils/queries";
import { saveAs } from "file-saver";
import Mensajero from "../../components/Snackbar/Mensajero";

export function Formularios() {
    const [loadingF1, setLoadingF1] = useState(false);
    const [loadingF2, setLoadingF2] = useState(false);
    const [mensaje, setMensaje] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });

    const [getPlantilla, { loading }] = useLazyQuery(traerform);

    const descargaArchivo = async (type) => {
        setMensaje({
            abrir: true,
            mensaje: "Generando descarga...",
            severidad: "info",
        });
        if(type===1){
            setLoadingF1(true);
        } else {
            setLoadingF2(true);
        }
        getPlantilla({
            variables: {
                plantilla: type
            },
            onCompleted: async (res) => {
                const base64ConForm = atob(res.traerPlantilla.replace(/['"]+/g, ""));
                let length = base64ConForm.length;
                let out = new Uint8Array(length);
                while (length--) {
                    out[length] = base64ConForm.charCodeAt(length);
                }
                const blob = new Blob([out], { type: "application/pdf" });
                saveAs(blob, "Formulario_" + type + ".pdf");

                setMensaje({
                    abrir: true,
                    mensaje: "Descarga generada, revise sus archivos descargados",
                    severidad: "success",
                });
                if(type===1){
                    setLoadingF1(false);
                } else {
                    setLoadingF2(false);
                }
            },
            onError: (error) => {
                console.log("error resp: " + error);
                setMensaje({
                    abrir: true,
                    mensaje: "No se generó la descarga, intente nuevamente",
                    severidad: "error",
                });
                if(type===1){
                    setLoadingF1(false);
                } else {
                    setLoadingF2(false);
                }
            }
        });
    }
    return (
        <div data-testid="reporteria">
            <Mensajero data={mensaje} setState={setMensaje} />
            <div className="rowInformacion">
                <div className="container Main">
                    <label className="titulo1"> Formularios</label>
                    <Breadcrumbs
                        sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                        aria-label="breadcrumb"
                        className="breadcrum"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Link
                            underline="always"
                            color="inherit"
                            to={loading ? "#" : "/"}
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "400",
                                margin: "0px,8px",
                                cursor: "pointer",
                            }}
                        >
                            Inicio
                        </Link>
                        <Typography
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "-1px",
                                lineHeight: "24px",
                            }}
                        >
                            Formularios
                        </Typography>
                    </Breadcrumbs>
                    <Grid container>
                        <Grid xs={12}>
                            <Typography className="subTexto">Seleccione un formulario para descargar</Typography>
                        </Grid>
                        <Grid xs={6} pr="32px">
                            <Box className="container" textAlign="center" pb="42px !important">
                                <FileGreen sx={{ height: "50px", width: "50px", marginTop: "45px", marginBottom: "35px" }} />
                                <Typography className="nombreForm" mb="16px">Formulario 1</Typography>
                                <Typography className="subtitleModal" mb="16px">Formulario de ingreso de solicitudes GES/CAEC.</Typography>
                                <BotonCarga
                                    testid="descargaForm1"
                                    label={"Descargar"}
                                    toDo={() => descargaArchivo('form1')}
                                    loading={loadingF1}
                                    disabled={loading}
                                    variant='secondary'
                                />
                            </Box>
                        </Grid>

                        <Grid xs={6}>
                            <Box className="container" textAlign="center" pb="42px !important">
                                <FileGreen sx={{ height: "50px", width: "50px", marginTop: "45px", marginBottom: "35px" }} />
                                <Typography className="nombreForm" mb="16px">Formulario 2</Typography>
                                <Typography className="subtitleModal" mb="16px">Formulario de autorización de solicitudes GES/CAEC.</Typography>
                                <BotonCarga
                                    testid="descargaForm2"
                                    label={"Descargar"}
                                    toDo={() => descargaArchivo('form2')}
                                    loading={loadingF2}
                                    disabled={loading}
                                    variant='secondary'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>

        </div>
    );
}

export default Formularios;
