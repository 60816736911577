import { useEffect, useState } from "react";
import icon_nube from "../../../components/icons/icon_nube.svg";
import "../../../assets/App.css";
import { formatRut } from "chilean-rutify";
import {
  guardaform,
  registerSolicitud,
  cargararchivo2,
  saveBitacora,
  generarFormulario1,
  actualizaCaso
} from "../../../utils/queries";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PrimaryButton, { SecondaryButton } from "../../../components/Buttons/Botones";
import Success from "../../../components/icons/Success.svg";
import icon_print from "../../../components/icons/icon_printB.svg";
import icon_warningOrange from "../../../components/icons/icon_warningOrange.svg";
import { useMsal } from "@azure/msal-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { ErrorModalIcon, IconInfo } from "../../../components/icons/iconosEsencial";
import { useQuery, useMutation } from "@apollo/client";
import { cargaOpcionales } from "./utils/cargaOpcionales";
import { ModalDrag } from "../../../components/DragDrop/ModalDrag";
import { Subida } from "../../../components/Dialog/Subida";
import Loader from "../../../components/Skeleton/Loader";
import Mensajero from "../../../components/Snackbar/Mensajero";

function Iframe(props) {
  let fkFun = props.data;
  const [date] = useState(new Date());
  const { loading } = useQuery(generarFormulario1, {
    variables: {
      id: fkFun ? fkFun.toString() : "",
      nombreBeneficiario: props.infoForm.afiliado?props.infoForm.afiliado.primerNombre + " " + (props.infoForm.afiliado.segundoNombre ? props.infoForm.afiliado.segundoNombre + " " : "") + props.infoForm.afiliado.primerApellido + " " + props.infoForm.afiliado.segundoApellido:'',
      rutBeneficiario: props.infoForm.afiliado?formatRut(props.infoForm.afiliado.rut): '',
      nombrePaciente: props.infoForm.nombrePaciente + " " + props.infoForm.apellido1Paciente + " " + props.infoForm.apellido2Paciente,
      rutPaciente: formatRut(props.infoForm.rutPaciente),
      dirPaciente: props.infoForm.dirPaciente + (props.infoForm.dir2Paciente ? ", " + props.infoForm.dir2Paciente + "." : "."),
      ciudadPaciente: props.infoForm.ciudadNombre,
      telPaciente: props.infoForm.telPaciente,
      telOfiPaciente: " - ",
      celPaciente: props.infoForm.celPaciente,
      diagnosticoGes: props.infoForm.fkProblemaSalud ? props.infoForm.fkProblemaSalud + " - " + props.infoForm.diagnosticoNombre : null,
      diagnosticoCaec: props.infoForm.diagnosticoCaec,
      tratamiento: props.infoForm.tratamientoActual,
      medicoTratante: props.infoForm.nombreMedico,
      especialidadMedTrat: props.infoForm.especialidad === "-1" ? props.infoForm.otro : props.infoForm.especialidad,
      razonSocialPrestador: props.infoForm.razonSocial,
      hospSi: props.infoForm.chkHospi === "si" ? "X" : "",
      hospNo: props.infoForm.chkHospi === "si" ? "" : "X",
      fechaDesdeHosp: props.infoForm.fechaDesde ? format(props.infoForm.fechaDesde, "dd/MM/yyyy") : "-",
      fechaHastaHosp: props.infoForm.fechaHasta ? format(props.infoForm.fechaHasta, "dd/MM/yyyy") : "-",
      razonSocialHosp: props.infoForm.razonSocialHospi,
      trasSi: props.infoForm.chkTraslado === "si" ? "X" : "",
      trasNo: props.infoForm.chkTraslado === "no" ? "X" : "",
      origenTraslado: props.infoForm.trasDesde || "-",
      destinoTraslado: props.infoForm.trasHasta || "-",
      nombreRepresentante: props.infoForm.nombreRepresentante + " " + props.infoForm.apellido1Representante + " " + props.infoForm.apellido2Representante,
      rutRepresentante: props.infoForm.pasaporteRepresentante ? props.infoForm.pasaporteRepresentante : formatRut(props.infoForm.rutRepresentante),
      tipoRepresentante: "",
      telRepresentante: props.infoForm.telefonoRepresentante,
      telOfiRepresentante: " - ",
      celRepresentante: props.infoForm.celularRepresentante,
      sucursal: props.sucursal,
      createUser: props.user,
      fechaRecepSolicitud: format(date, "dd/MM/yyyy"),
      horaRecepSolicitud: format(date, "HH:mm"),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (formData) => {
      let data = formData.generarFormulario1.base64;
      let base64 = atob(data);
      let length = base64.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = base64.charCodeAt(length);
      }
      const file = new Blob([out], { type: "application/pdf" });
      if (fkFun && !props.base64) {
        props.setBase64(file);
      }
      let url = URL.createObjectURL(file)
      props.setUrl(url);
    },
    onError: (err) => {
      console.log(err);
      props.setBase64("error");
    }
  });

  return (
    <div className="row">
      <div id="divIframe" data-testid="iframePDF" className="col divIframe">
        {
          loading ?
            <Loader /> :
            <iframe
              id="iframeSolicitud"
              title="prueba"
              src={props.url}
              height="100%"
              width="100%"
            />
        }
      </div>
    </div>
  );
}

export function SolicitudGesCae({ useLocationHook = useLocation, useMsalHook = useMsal, sucursal, correo, blockBack }) {
  const [modalConfirmaGeneracion, setModalConfirmaGeneracion] = useState(false);
  const [modalFolioGenerado, setModalFolioGenerado] = useState(false);
  const [openArchivo, setOpenArchivo] = useState(false);
  const [fkFun, setFkFun] = useState(null);
  const [openFinal, setOpenFinal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { accounts } = useMsalHook();
  const location = useLocationHook();
  const [campos, setCampos] = useState({ val: null });
  const [docError, setDocError] = useState([]);
  const [errorForm, setErrorForm] = useState(null);
  const [modalCarga, setModalCarga] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalCargaFormF, setModalCargaFormF] = useState(false);
  const [form1, setForm1] = useState(null);
  const [form1F, setForm1F] = useState(null);
  const [base64, setBase64] = useState(null);
  const [f1cargado, setF1Cargado] = useState(false);
  const [errorSF, setErrorSF] = useState(false);
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const navigate = useNavigate();

  const [subirDoc, { loading: loadSubirDoc }] = useMutation(cargararchivo2);

  const [guardarFormBD, { loading: LoadGuardaDoc }] = useMutation(guardaform);

  const [registrarBitacora] = useMutation(saveBitacora);

  const [registrarSolicitud] = useMutation(registerSolicitud, {
    onCompleted: (data) => {
      blockBack(false);
      setFkFun(data.registerSolicitudSGC.fkFun);
      setForm1(null);
      setTimeout(() => {
        navigate("/SolicitudGesCae", { state: { bloquearIngreso: true, campos: { fkFun: data.registerSolicitudSGC.fkFun, ...campos } }, replace: true });
      }, 500);
      cargaOpcionales(
        subirDoc,
        registrarBitacora,
        data.registerSolicitudSGC.fkFun,
        campos.rutPaciente.replace(/[^0-9kK-]/g, ""),
        campos.files,
        campos.filesOpt,
        accounts[0].name,
        docError,
        setDocError,
        sucursal);
    },
    onError: (error) => {
      console.log(error.message)
      transicionError();
      setSnackData({
        abrir: true,
        mensaje: "Error al ingresar caso, intente nuevamente",
        severidad: "error",
      });
    }
  });

  const [actualizaSolicitud] = useMutation(
    actualizaCaso, {
    onCompleted: () => {
      blockBack(false);
      setFkFun(campos.fkFun);
      setForm1(null);
      setTimeout(() => {
        navigate("/SolicitudGesCae", { state: { bloquearIngreso: true, campos: campos }, replace: true });
      }, 500);
      cargaOpcionales(
        subirDoc,
        registrarBitacora,
        campos.fkFun,
        campos.rutPaciente.replace(/[^0-9kK-]/g, ""),
        null,
        campos.filesOpt,
        accounts[0].name,
        docError,
        setDocError,
        sucursal)
    },
    onError: (error) => {
      console.log(error.message)
      transicionError();
      setSnackData({
        abrir: true,
        mensaje: "Error al ingresar caso, intente nuevamente",
        severidad: "error",
      });
    }
  }
  );

  if (!loaded) {
    setCampos(location.state.campos);
    if (location.state.bloquearIngreso) {
      setFkFun(location.state.campos.fkFun)
      blockBack(false);
    } else {
      blockBack(true);
    }
    setLoaded(true);
  }

  const transicionCarga = () => {
    setModalConfirmaGeneracion(false);
    setModalCarga(true);
  };

  const transicionError = () => {
    setModalCarga(false);
  };

  const handleClickOpenArchivo = () => {
    setOpenArchivo(true);
  };

  function generaSolicitud() {
    transicionCarga();
    const fechaRegistro = new Date();
    registrarSolicitud({
      variables: {
        sexoBeneficiario: campos.genero,
        nombreCotizante: campos.afiliado?campos.afiliado.primerNombre + (campos.afiliado.segundoNombre ? " " + campos.afiliado.segundoNombre + " " : " ") + campos.afiliado.primerApellido + " " + campos.afiliado.segundoApellido:"",
        plan: campos.afiliado?campos.afiliado.plan:"",
        fechaNacBen: campos.fechaNac,
        rutPaciente: campos.rutPaciente.replace(/[^0-9kK-]/g, ""),
        rutCotizante: campos.afiliado?campos.afiliado.rut.replace(/[^0-9kK-]/g, ""):"",
        fVigenciaBeneficiario: campos.afiliado?campos.afiliado.inicioVigencia:"",
        nombrePaciente: campos.nombrePaciente,
        apellido1Paciente: campos.apellido1Paciente,
        apellido2Paciente: campos.apellido2Paciente,
        tipoCaso: campos.tipoCaso,
        codLocalidadPaciente: campos.comuna,
        celPaciente: parseInt(campos.celPaciente),
        telPaciente: parseInt(campos.telPaciente),
        dirPaciente: campos.dirPaciente,
        dir2Paciente: campos.dir2Paciente,
        emailPaciente: campos.emailPaciente,
        rutRepresentante: campos.pasaporteRepresentante ? campos.pasaporteRepresentante : campos.rutRepresentante.replace(/[^0-9kK-]/g, ""),
        fkTipoRepresentante: parseInt(campos.solicita),
        fkTipoDocRepresentante: campos.tipoDocumento === "rut" ? 1 : 2,
        nombreRepresentante: campos.nombreRepresentante,
        apellido1Representante: campos.apellido1Representante,
        apellido2Representante: campos.apellido2Representante,
        emailRepresentante: campos.emailRepresentante,
        telRepresentante: parseInt(campos.telefonoRepresentante),
        celRepresentante: parseInt(campos.celularRepresentante),
        fkProblemaSalud: parseInt(campos.fkProblemaSalud),
        diagnosticoCaec: campos.diagnosticoCaec,
        fechaRecepcionSolicitud: fechaRegistro,
        fkTipoAntecedente: parseInt(campos.fkTipoAntecedente),
        antecedenteMedIdGD: 1,
        fkEventoAdministrativoDeclarado: parseInt(
          campos.eventoAdmin
        ),
        fechaEventoAdministrativo: campos.fechaEventoAdm,
        rutPrestadorInstitucional: campos.rutInsti.replace(/[^0-9kK-]/g, ""),
        razonSocialPrestador: campos.razonSocial,
        rutMedicoTratante: campos.rutMedico.replace(/[^0-9kK-]/g, ""),
        nombreMedicoTratante: campos.nombreMedico,
        especialidadMedicoTratante: campos.especialidad === "-1" ? campos.otro : campos.especialidad,
        especialidadOtroMedicoTratante: campos.especialidad === "-1" ? true : false,
        tratamiento: campos.tratamientoActual,
        declaraHospitalizacion: campos.chkHospi === "si",
        fechaHospitalizacionDesde: campos.fechaDesde,
        fechaHospitalizacionHasta: campos.fechaHasta,
        rutInstitucionHopsitalizacion: campos.rutInstiHosp ? campos.rutInstiHosp.replace(/[^0-9kK-]/g, "") : null,
        razonSocialInstitucionHopsitalizacion: campos.razonSocialHospi,
        declaraTraslado: campos.chkTraslado === "si" ? true : false,
        origenDestinoTraslado: campos.trasDesde,
        destinoTraslado: campos.trasHasta,
        fkEstadoSolicitudIsapre: 1,
        fechaSolicitudIsapre: fechaRegistro,
        fechaAutorizacionSoliciut: fechaRegistro,
        fkCausalRechazo: null,
        fechaCierre: null,
        fkCausalCierre: null,
        beneficiarioNotificado: true,
        fechaNotificacionBeneficiario: fechaRegistro,
        fkTipoResolucionBeneficiario: null,
        fechaResolucionBeneficiario: null,
        declaraUrgenciaVital: campos.chkUrgencia === "si" ? true : false,
        direccionPrestadorInstitucional: campos.direccion,
        luatPrestadorInstitucional: campos.luat || "888",
        direccionHospitalizacion: campos.direccionHospi,
        luatHospitalizacion: campos.rutInstiHosp ? campos.luatHospi || "888" : null,
        sucursal: sucursal,
        createdUser: accounts[0].name,
        updatedUser: accounts[0].name,
      },
    })
  }

  function actualizarSolicitud() {
    transicionCarga();
    const fechaActualizacion = location.state.fechaRecepcionSolicitud ? location.state.fechaRecepcionSolicitud : new Date();
    actualizaSolicitud({
      variables: {
        folio: campos.fkFun,
        rutCotizante: campos.afiliado?campos.afiliado.rut.replace(/[^0-9kK-]/g, ""):"",
        plan: campos.afiliado?campos.afiliado.plan:"",
        nombreCotizante: campos.afiliado?campos.afiliado.primerNombre + " " + campos.afiliado.primerApellido + " " + campos.afiliado.segundoApellido:"",
        sexoBeneficiario: campos.genero,
        fechaNacBen: campos.fechaNac,
        fVigenciaBeneficiario: campos.afiliado?campos.afiliado.inicioVigencia:null,
        codLocalidadPaciente: campos.comuna,
        nombrePaciente: campos.nombrePaciente,
        apellido1Paciente: campos.apellido1Paciente,
        apellido2Paciente: campos.apellido2Paciente,
        celPaciente: parseInt(campos.celPaciente),
        telPaciente: parseInt(campos.telPaciente),
        dirPaciente: campos.dirPaciente,
        dir2Paciente: campos.dir2Paciente,
        emailPaciente: campos.emailPaciente,
        rutRepresentante: campos.pasaporteRepresentante ? campos.pasaporteRepresentante : campos.rutRepresentante.replace(/[^0-9kK-]/g, ""),
        fkTipoRepresentante: parseInt(campos.solicita),
        fkTipoDocRepresentante: campos.tipoDocumento === "rut" ? 1 : 2,
        nombreRepresentante: campos.nombreRepresentante,
        apellido1Representante: campos.apellido1Representante,
        apellido2Representante: campos.apellido2Representante,
        emailRepresentante: campos.emailRepresentante,
        telRepresentante: parseInt(campos.telefonoRepresentante),
        celRepresentante: parseInt(campos.celularRepresentante),
        tipoCaso: campos.tipoCaso,
        fkProblemaSalud: parseInt(campos.fkProblemaSalud),
        diagnosticoCaec: campos.diagnosticoCaec,
        fechaRecepcionSolicitud: fechaActualizacion,
        fkTipoAntecedente: parseInt(campos.fkTipoAntecedente),
        antecedenteMedIdGD: 1,
        fkEventoAdministrativoDeclarado: parseInt(
          campos.eventoAdmin
        ),
        fechaEventoAdministrativo: campos.fechaEventoAdm,
        rutPrestadorInstitucional: campos.rutInsti.replace(/[^0-9kK-]/g, ""),
        razonSocialPrestador: campos.razonSocial,
        rutMedicoTratante: campos.rutMedico.replace(/[^0-9kK-]/g, ""),
        nombreMedicoTratante: campos.nombreMedico,
        especialidadMedicoTratante: campos.especialidad === "-1" ? campos.otro : campos.especialidad,
        especialidadOtroMedicoTratante: campos.especialidad === "-1" ? true : false,
        tratamiento: campos.tratamientoActual,
        declaraHospitalizacion: campos.chkHospi === "si",
        fechaHospitalizacionDesde: campos.fechaDesde,
        fechaHospitalizacionHasta: campos.fechaHasta,
        rutInstitucionHopsitalizacion: campos.rutInstiHosp ? campos.rutInstiHosp.replace(/[^0-9kK-]/g, "") : null,
        razonSocialInstitucionHopsitalizacion: campos.razonSocialHospi,
        declaraTraslado: campos.chkTraslado === "si" ? true : false,
        origenDestinoTraslado: campos.trasDesde,
        destinoTraslado: campos.trasHasta,
        fkEstadoSolicitudIsapre: 1,
        fechaAutorizacionSoliciut: fechaActualizacion,
        fkCausalRechazo: null,
        fechaCierre: null,
        fkCausalCierre: null,
        beneficiarioNotificado: true,
        fechaNotificacionBeneficiario: fechaActualizacion,
        fkTipoResolucionBeneficiario: null,
        fechaResolucionBeneficiario: null,
        declaraUrgenciaVital: campos.chkUrgencia === "si" ? true : false,
        direccionPrestadorInstitucional: campos.direccion,
        luatPrestadorInstitucional: campos.luat,
        direccionHospitalizacion: campos.direccionHospi,
        luatHospitalizacion: campos.luatHospi,
        sucursal: sucursal,
        userEmail: correo,
        createdUser: accounts[0].name,
        updatedUser: accounts[0].name,
      },
    });
  }

  useEffect(() => {
    if (base64 && fkFun && !loadSubirDoc && !LoadGuardaDoc && !f1cargado) {
      setF1Cargado(true);
      const nombre = "Formulario1_SF_" + fkFun + ".pdf";
      cargaForm(nombre, base64, campos.rutPaciente);
    }
  }, [base64, fkFun, loadSubirDoc, LoadGuardaDoc, f1cargado, campos, setF1Cargado]);

  function cargaForm(nombreArchivo, file, rut, formFirmado) {
    var fileRead = new FileReader();
    if (file !== "error") {
      fileRead.readAsDataURL(file);
      fileRead.onload = function () {
        subirDoc({
          variables: {
            idProceso: "11",
            idTipoTrans: "2001",
            idTipoDoc: "2001",
            folioTrans: fkFun.toString(),
            fechaTrans: format(new Date(), "MM-dd-yyyy"),
            rutBenef: rut.replace(/[^0-9kK-]/g, ""),
            folioDoc: fkFun.toString(),
            nombreDoc: nombreArchivo,
            base64: fileRead.result.substr(`data:${file.type};base64,`.length)
          },
          onCompleted: (data) => {
            var firmado = formFirmado ? "form1_firmado" : "form1";
            guardarFormBD({
              variables: {
                link: data.crearArchivo2.documentId,
                tipoDoc: firmado,
                folio: fkFun,
              },
              onCompleted: () => {
                if (formFirmado) {
                  transicion("");
                } else {
                  transicionActivacion();
                }
              },
              onError: () => {
                if (formFirmado) {
                  transicion("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
                } else {
                  var temp = docError;
                  temp.push({ name: nombreArchivo });
                  setDocError(temp);
                  setErrorSF(true);
                  transicionActivacion();
                }
              }
            })
          },
          onError: (error) => {
            console.log(error.message);
            if (formFirmado) {
              transicion("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
            } else {
              var temp = docError;
              temp.push({ name: nombreArchivo });
              setDocError(temp);
              setErrorSF(true);
              transicionActivacion();
            }
          }
        })
      }
    } else {
      if (formFirmado) {
        transicion("No se pudo cargar el formulario firmado, reintente desde bandeja de casos.");
      } else {
        var temp = docError;
        temp.push({ name: nombreArchivo });
        setDocError(temp);
        setErrorSF(true);
        transicionActivacion();
      }
    }
  }

  function imprimirIframe() {
    setModalFolioGenerado(false);
    printPreview();
  }

  function irABandeja() {
    setOpenFinal(false);
    navigate("/TodosLosCasos", { replace: true });
  }

  const printPreview = () => {
    let iframe = document.getElementById("iframeSolicitud").contentWindow;
    iframe.focus();
    iframe.print();
  };

  function transicionActivacion() {
    setModalCarga(false);
    if (docError.length > 0) {
      setModalError(true);
    } else {
      setModalFolioGenerado(true);
    }
  }
  function transicion(texto) {
    setErrorForm(texto);
    setModalCargaFormF(false);
    setOpenFinal(true);
  }

  return (
    <>
      <div className="container Main" data-testid="containerForm1">
        <label className="titulo1"> Solicitar activación GES</label>
        <Mensajero data={snackData} setState={setSnackData} />
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            underline="always"
            color="inherit"
            to="/"
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Link
            id="volverPaciente"
            underline="always"
            color="inherit"
            to="/FormularioIngreso"
            state={{ campos }}
            replace={true}
            onClick={() => blockBack(false)}
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Solicitar activación GES
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Formulario 1
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="container">
        <div className="card-box">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "10px 22px", margin: "0", width: "100%" }}
          >
            <label className="title">Firmar formulario 1</label>

            <label className="title">Folio: {fkFun}</label>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ padding: "0 22px", margin: "0", width: "100%" }}
          >
            <label className="textoNormal">
              Recuerda imprimir el Formulario Normativo nº 1 para ser firmado
              por el beneficiario y luego cargar el documento.
            </label>
          </Grid>
          <Divider sx={{ margin: "15px 0" }} />
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "8px", margin: "0", width: "100%" }}
          >
            <Grid item xs={4}>
              <label className="title">Previsualización formulario nº1</label>
            </Grid>
            <Grid
              item
              container
              xs={6}
              direction="row"
              justifyContent="flex-end"
              sx={{ "padding-right": "8px" }}
            >
              <SecondaryButton
                disabled={fkFun === null ? true : false}
                className="btn btn-secondary"
                sx={{ margin: "0 5px 0 0" }}
                onClick={imprimirIframe}
              >
                Imprimir formulario
              </SecondaryButton>
              <PrimaryButton
                disabled={fkFun === null ? true : false}
                className="btn btn-primary"
                onClick={handleClickOpenArchivo}
              >
                <img
                  alt=""
                  src={icon_nube}
                  className="imgNubeArchivo"
                  style={{ marginRight: "7px" }}
                />
                Subir formulario firmado
              </PrimaryButton>
            </Grid>
          </Grid>
          <Iframe
            data={fkFun}
            user={accounts[0].name}
            infoForm={campos}
            url={form1}
            setUrl={setForm1}
            base64={base64}
            setBase64={setBase64}
            sucursal={sucursal}
          />
          <Divider sx={{ margin: "15px 0" }} />
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "0 16px 16px", margin: "0", width: "100%" }}
          >
            <SecondaryButton
              disabled={!!fkFun || location.state.bloquearIngreso}
              className="btn btn-secondary"
              onClick={() => {
                blockBack(false);
                setTimeout(() => document.getElementById("volverPaciente").click(), 500);
              }}
            >
              Anterior
            </SecondaryButton>
            <PrimaryButton
              disabled={!!fkFun || location.state.bloquearIngreso}
              data-testid="botonAutorizacion"
              className="btn btn-primary"
              onClick={() => setModalConfirmaGeneracion(true)}
            >
              Ingresar solicitud
            </PrimaryButton>
          </Grid>
        </div>

      </div>
      <div id="dialogs">
        {/* Dialog Ingresar Solicitud */}
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={modalConfirmaGeneracion}
          onClose={() => setModalConfirmaGeneracion(false)}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >

          <DialogTitle className="tituloModal" sx={{ paddingBottom: "10px", paddingTop: "25px" }}>
            <IconInfo sx={{ width: "50px", height: "50px", marginBottom: "10px" }} /><br />
            Solicitud de incorporación
          </DialogTitle>
          <DialogContent sx={{ width: "580px", paddingTop: "0px !important" }}>
            <DialogContentText
              alignContent="center"
              sx={{ textAlign: "center", paddingBottom: "10px" }}
            >
              Vas a ingresar la solicitud.
            </DialogContentText>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                alignContent="center"
              >
                <SecondaryButton
                  variant=" text"
                  className="btn btn-secondary"
                  onClick={() => setModalConfirmaGeneracion(false)}
                >
                  Cancelar
                </SecondaryButton>
                <PrimaryButton
                  variant=" text"
                  color="default"
                  data-testid="ingresoSol"
                  onClick={campos.fkFun ? actualizarSolicitud : generaSolicitud}
                >
                  Ingresar solicitud
                </PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/*Modal Loading*/}
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={modalCarga}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <DialogContent sx={{ padding: "0px", width: "540px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              ></Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0", height: "300px" }}
              >
                <CircularProgress className="spinnerGreen"></CircularProgress>
                <Typography className="contentDialogLoading">
                  {!fkFun ? "Generando solicitud" : "subiendo Documentos"}
                </Typography>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Modal  Error en la carga de archivos*/}
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={modalError}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <DialogContent sx={{ padding: "20px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              ></Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                data-testid="dialogModal"
                sx={{ m: 1, textAlign: "center", padding: "0" }}
              >
                <ErrorModalIcon
                  sx={{ width: "42px", height: "42px" }}
                ></ErrorModalIcon>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <DialogTitle className="tituloModal" sx={{ padding: "0 0 6px" }}>
                  Error en la carga de archivos
                </DialogTitle>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
              >
                {docError.length > 1 ?
                  <>
                    <Typography> No se cargaron los archivos:</Typography>
                    {docError.map((doc) =>
                      <Typography>
                        <strong>{doc.name}</strong>
                      </Typography>
                    )}
                  </>
                  : <Typography>
                    No se cargó el archivo:<strong>{docError.map((doc) => doc.name)}</strong>
                  </Typography>
                }
                <Typography>
                  {errorSF ? docError.length > 1 ?
                    <>
                      <label>Puedes intentar cargar el formulario nuevamente desde bandeja de casos,</label>
                      <label>y el(los) archivo(s) adjunto(s) desde la sección bitácora</label>
                    </>
                    : "Puedes intentar cargar el formulario nuevamente desde bandeja de casos."
                    : "Puedes intentar cargarlo nuevamente desde la sección Bitacora."}
                </Typography>
              </Grid>
            </DialogContentText>
            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  setModalError(false);
                  setModalFolioGenerado(true);
                }}
              >
                <PrimaryButton>Continuar</PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* dialogo solicitud Ingresada OK */}
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={modalFolioGenerado}
          onClose={() => setModalFolioGenerado(false)}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
            <img alt="img2" src={Success}></img>
          </Grid>
          <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
            Solicitud ingresada correctamente
          </DialogTitle>
          <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              Recuerda notificar al beneficiario a su correo electrónico. En breve podrás imprimir el formulario.
            </DialogContentText>

            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <PrimaryButton
                  data-testid="imprimirFrame"
                  onClick={imprimirIframe}
                >
                  <img
                    src={icon_print}
                    alt="imprimir Archivo"
                    className="imgCargaArchivo"
                  />{" "}
                  Imprimir formulario
                </PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* Dialog Ingresar archivo */}
        <ModalDrag
          modal={openArchivo}
          setModal={setOpenArchivo}
          files={form1F}
          setFiles={setForm1F}
          next={() => {
            setOpenArchivo(false);
            setModalCargaFormF(true);
            cargaForm("Formulario1_F_" + fkFun, form1F[0].file, campos.rutPaciente, true);
          }}
          maxFiles={1}
        />
        <Subida modal={modalCargaFormF} />

        {/* dialogo 4 solicitud Ingresada firmada OK */}
        <Dialog
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={openFinal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
            <img alt="img2" src={errorForm ? icon_warningOrange : Success}></img>
          </Grid>
          <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
            {errorForm ? "Archivo no cargado" : "Archivo cargado con éxito"}
          </DialogTitle>
          <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
            <DialogContentText
              alignContent="center"
              sx={{ m: 1, textAlign: "center" }}
            >
              {errorForm ? errorForm : "Se ha cargado la versión firmada del formulario con folio " + fkFun}
            </DialogContentText>

            <DialogActions>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <PrimaryButton
                  variant=" text"
                  color="default"
                  onClick={irABandeja}
                >
                  Ir a bandeja de casos
                </PrimaryButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
export default SolicitudGesCae;