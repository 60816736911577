import { parseEstado, matchCie10 } from "./utils";

function parseaXlsx(cod, fecha, general){

    const datos = [];
    datos.push(["Código Aseguradora","Período al que se acumula la información","N° Correlativo Solicitud GES","Fecha de Solicitud GES",
        "Tipo de identificación del Solicitante GES","RUT Solicitante GES","DV del RUT del Solicitante GES","Identificación alternativa del Solicitante GES","Tipo de identificación del Cotizante", "RUT Cotizante",
        "DV del RUT del Cotizante","Identificación alternativa del Cotizante","Tipo de Identificación del Beneficiario","RUT Beneficiario","DV del RUT del Beneficiario","Identificación alternativa del Beneficiario",
        "Sexo del Beneficiario","Fecha Nacimiento del Beneficiario","Problema de Salud","Evento administrativo que respalda la Solicitud","Fecha del Evento Administrativo que respalda la Solicitud","Resolución de la Aseguradora",
        "Fecha de resolución de la Aseguradora","Causal de rechazo de la Solicitud por parte de la Aseguradora","Notificación al Beneficiario de la Resolución de la Aseguradora","Fecha de Notificación al Beneficiario de la Resolución de la Aseguradora",
        "RUT Prestador Designado","DV del RUT del Prestador Designado","Nombre Prestador Designado","Resolución del Beneficiario","Causal de rechazo del Beneficiario","Identificación Única GES","Diagnóstico del Beneficiario",
        "Rama del Problema de Salud","Estado del Caso GES","Causal de Cierre del Caso GES"]);

    general.forEach(async (dato)=>{
        const estadoSolicitud = parseEstado(dato.estadoSolicitud);
        const opcion1 = estadoSolicitud !== "E" ? dato.fechaCambioEstado : "01011800";
        const opcion2 = estadoSolicitud === "R" ? dato.causalRechazo : "0";
        const campo32 = estadoSolicitud === "A" ? "108" + dato.folio : "0";
        const campo35 = estadoSolicitud !== "A" ? "X" : dato.causalCierre === 0 ? "A" : "C";
        const campo36 = campo35 === "C" ? dato.causalCierre : "0";
        const notif25 = estadoSolicitud === "A" || estadoSolicitud === "R" ? "S" : "X";
        const campo27 = estadoSolicitud === "A" ? dato.rutPrestador : "0";
        const campo28 = estadoSolicitud === "A" ? dato.divPrestador : "0";
        const campo29 = estadoSolicitud === "A" ? dato.nombrePrestador : "";
        const campo30 = notif25 === "S" ? "A" : "X"; 
        const campo33 = estadoSolicitud === "A" && parseInt(dato.fechaCambioEstado.substr(4,4)) > 2013 ? dato.cie10 : "0";
        const campo34 = [9, 14, 45].includes(dato.problemaSalud) && estadoSolicitud === "A" ? matchCie10(dato.cie10) : "0";
        const campo26 = notif25 === "X" || notif25 === "N" ? "01011800" : dato.fechaCambioEstado;
        
        datos.push([ 
            cod, 
            fecha, 
            dato.folio, 
            dato.fechaRecepcion, 
            dato.tipoRepresentante, 
            dato.rutRepresentante,
            dato.divRepresentante,
            "0",
            dato.tipoCotizante,
            dato.rutTitular,
            dato.divTitular,
            "0",
            "1",
            dato.rutPaciente,
            dato.divPaciente,
            "0",
            dato.sexoBenef,
            dato.fechaNacimiento,
            dato.problemaSalud,
            dato.eventoAdm,
            dato.fechaEvento,
            estadoSolicitud,
            opcion1,
            opcion2,
            notif25,
            campo26,
            campo27,
            campo28,
            campo29,
            campo30,
            "0",
            campo32,
            campo33,
            campo34,
            campo35,
            campo36
            ]);
        })
    return datos;
}

function parseaDatos(cod, fecha, general){

    var datos = "";
    general.forEach((dato)=>{
        const estadoSolicitud = parseEstado(dato.estadoSolicitud);
        const opcion1 = estadoSolicitud !== "E" ? dato.fechaCambioEstado : "01011800";
        const opcion2 = estadoSolicitud === "R" ? dato.causalRechazo : "0";
        const campo32 = estadoSolicitud === "A" ? "108" + dato.folio : "0";
        const campo35 = estadoSolicitud !== "A" ? "X" : dato.causalCierre === 0 ? "A" : "C";
        const campo36 = campo35 === "C" ? dato.causalCierre : "0";
        const notif25 = estadoSolicitud === "A" || estadoSolicitud === "R" ? "S" : "X";
        const campo27 = estadoSolicitud === "A" ? dato.rutPrestador : "0";
        const campo28 = estadoSolicitud === "A" ? dato.divPrestador : "0";
        const campo29 = estadoSolicitud === "A" ? dato.nombrePrestador : "";
        const campo30 = notif25 === "S" ? "A" : "X"; 
        const campo33 = estadoSolicitud === "A" && parseInt(dato.fechaCambioEstado.substr(4,4)) > 2013 ? dato.cie10 : "0";
        const campo34 = [9, 14, 45].includes(dato.problemaSalud) && estadoSolicitud === "A" ? matchCie10(dato.cie10) : "0";
        const campo26 = notif25 === "X" || notif25 === "N" ? "01011800" : dato.fechaCambioEstado;

        datos += cod 
            + "|" + fecha
            + "|" + dato.folio 
            + "|" + dato.fechaRecepcion
            + "|" + dato.tipoRepresentante
            + "|" + dato.rutRepresentante
            + "|" + dato.divRepresentante
            + "|0"
            + "|" + dato.tipoCotizante
            + "|" + dato.rutTitular
            + "|" + dato.divTitular
            + "|0"
            + "|1"
            + "|" + dato.rutPaciente
            + "|" + dato.divPaciente
            + "|0"
            + "|" + dato.sexoBenef
            + "|" + dato.fechaNacimiento
            + "|" + dato.problemaSalud
            + "|" + dato.eventoAdm
            + "|" + dato.fechaEvento
            + "|" + estadoSolicitud
            + "|" + opcion1
            + "|" + opcion2
            + "|" + notif25
            + "|" + campo26
            + "|" + campo27
            + "|" + campo28
            + "|" + campo29
            + "|" + campo30
            + "|0"
            + "|" + campo32
            + "|" + campo33
            + "|" + campo34
            + "|" + campo35
            + "|" + campo36
            + "\n";
    });

    return datos;
}

export function buildInfo036(cod, fecha, data) {
    return {
        "txt": parseaDatos(cod, fecha, data),
        "xlsx": parseaXlsx(cod, fecha, data)
    }
}