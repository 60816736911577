import { Fragment } from "react";
import { Grid, Chip } from "@mui/material";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { Cruz } from "../../../../components/icons/iconosEsencial";

export function ChipsV({ filtros, setFiltros }) {
    const handleDelete = (cod) => {
        setFiltros({ ...filtros, [cod]: null, pag: 0 });
    };

    const clearFiltros = () => {
        setFiltros({
            ...filtros,
            patologia: null,
            codcanasta: null,
            formulaActiva: null,
            codigoSB: null,
            descripcion: null,
            estado: null,
            pag: 0
        })
    };
    return (
        <Fragment>
            {filtros.arancel && filtros.arancel !== null ?
                            <Chip sx={{ mr: "16px" }} label="Arancel" variant="outlined" />
                            : null}
            {filtros && (
                (filtros.patologia && filtros.patologia !== null) ||
                (filtros.codcanasta && filtros.codcanasta !== null) ||
                (filtros.formulaActiva && filtros.formulaActiva !== null) ||
                (filtros.codigoSB && filtros.codigoSB !== null) ||
                (filtros.descripcion && filtros.descripcion !== null) ||
                (filtros.estado && filtros.estado !== null)) ?
                <Fragment>
                    <Grid item xs={12}>
                        <label className="filtroFont" >Resultados filtrados por:</label>{" "}
                    </Grid>
                    <Grid item xs={12} pt="12px">
                        {filtros.patologia && filtros.patologia !== null ?
                            <Chip sx={{ mr: "16px" }} label="CódigoPS" variant="outlined" onDelete={() => handleDelete("patologia")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.codcanasta && filtros.codcanasta !== null ?
                            <Chip sx={{ mr: "16px" }} label="Canasta" variant="outlined" onDelete={() => handleDelete("codcanasta")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.formulaActiva && filtros.formulaActiva !== null ?
                            <Chip sx={{ mr: "16px" }} label="FórmulaActiva" variant="outlined" onDelete={() => handleDelete("formulaActiva")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.codigoSB && filtros.codigoSB !== null ?
                            <Chip sx={{ mr: "16px" }} label="CódigoSB" variant="outlined" onDelete={() => handleDelete("codigoSB")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.descripcion && filtros.descripcion !== null ?
                            <Chip sx={{ mr: "16px" }} label="Descripción" variant="outlined" onDelete={() => handleDelete("descripcion")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.estado && filtros.estado !== null ?
                            <Chip sx={{ mr: "16px" }} label="Vigencia" variant="outlined" onDelete={() => handleDelete("estado")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        <OutlinedButton2 sx={{ textDecoration: "underline", textDecorationThickness: "from-font", pl: "12px" }} onClick={clearFiltros}>
                            Limpiar filtros
                        </OutlinedButton2>
                    </Grid>
                </Fragment> : null}
        </Fragment>
    )
}