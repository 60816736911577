import { Snackbar, Alert } from "@mui/material";
import { style, iconStyle } from "./components/Styles";

function Mensajero({ data, setState }) {
  const vertical = "top";
  const horizontal = "center";

  const cerrarMensajero = (event, razon) => {
    if (razon === "clickaway") {
      return;
    }
    setState({
      ...data,
      abrir: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      data-testid="snackTest"
      open={data.abrir}
      autoHideDuration={3000}
      onClose={cerrarMensajero}
      key={vertical + horizontal}
    >
      <Alert
        data-testid="alertTest"
        onClose={cerrarMensajero}
        severity={data.severidad}
        sx={style(data.severidad)}
        iconMapping={iconStyle(data.severidad)}
      >
        {data.mensaje}
      </Alert>
    </Snackbar>
  );
}

export default Mensajero;
