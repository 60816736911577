import { useState } from "react";
import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { OutlinedButton2 } from "../../../components/Buttons/Botones";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { CabeceraTabla } from "../../../components/TableHeader/TableHeader.jsx";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { fechaConvertida } from "../../../utils/fechaUtils";
import BuscarMedicamentos from "./BuscarMedicamentos";
import { TableBodyLoader } from "../../../components/Skeleton/SkeletonTableWithExport";
import { FiltrosME } from "./FiltrosME";
import { MenuME } from "./MenuME";
import { NoResult } from "../../../components/NoData/NoResults";
import ExportarME from "./ExportarME";
import { hasJGESCAEC, isMantenedor } from "../../../utils/hasPermisions";
import { formatRut } from "chilean-rutify";

const TagMed = ({ estado }) => {
    switch (true) {
        case estado === "Vigente":
            return <label className="tagEstadoCasos tagIngresado">Vigente</label>
        case estado === "No Vigente":
            return <label className="tagEstadoCasos tagRevision">No Vigente</label>
        default:
            return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
}

export default function TablaMedicamentos({ data, filtros, setFiltros, loading, setSnackData, eliminado, refetch, rol, usuario, sucursal }) {
    const [modalBuscar, setModalBuscar] = useState(false);

    const cabeceraMed = [
        { id: "datosBeneficiario", label: "Datos Beneficiario" },
        { id: "canasta", label: "Canasta" },
        { id: "fechaInicio", label: "Fecha inicio" },
        { id: "fechaTermino", label: "Fecha término" },
        { id: "sku", label: "SKU" },
        { id: "nombreMedicamento", label: "Nombre medicamento" },
        { id: "fechaUserCreacion", label: "Creado por" },
        { id: "estado", label: "Estado" },
        { id: "acciones", label: "Acciones", noActions: true },
    ];

    return (
        <div className="container">
            <BuscarMedicamentos onOpen={modalBuscar} onClose={() => setModalBuscar(false)} filtros={filtros} setFiltros={setFiltros} eliminado={eliminado}/>
            <Grid container p="24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">
                        <label className="titulo2" style={{ paddingTop: "10px" }}>Medicamentos asignados</label>{" "}
                        <Stack direction={"row"} >
                            <div style={{ paddingTop: "10px" }}>
                                <OutlinedButton2
                                    className="noStyle"
                                    style={{ border: "none" }}
                                    onClick={() => setModalBuscar(true)}
                                    disabled={loading}
                                >
                                    Ver filtros
                                </OutlinedButton2>
                            </div>
                            <ExportarME filtros={filtros} setSnackData={setSnackData} loadingTabla={loading} />
                        </Stack>
                    </Stack>
                </Grid>
                <FiltrosME filtros={filtros} setFiltros={setFiltros} eliminado={eliminado} />
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <CabeceraTabla
                        cabecera={cabeceraMed}
                    />
                    {loading ? <TableBodyLoader largo={filtros.limit} /> :
                        <TableBody>
                            {data && data.getmedicamentosExcepcion && parseInt(data.getmedicamentosExcepcion.total) > 0 ?
                                data.getmedicamentosExcepcion.ME.map((med) =>
                                    <TableRow
                                        data-testid="withData"
                                        key={med.id}
                                    >
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "5%" }}
                                            align="left"
                                        >
                                            <label className="labelnroCaso">
                                                {formatRut(med.rut)}
                                            </label>
                                            <label className="labeltipoCaso">{verificaLargo(med.nombrePaciente, 18)}</label>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "16%" }}
                                            align="left"
                                        >
                                            <label className="labelnroCaso">
                                                {med.canasta}
                                            </label>
                                            <label className="labeltipoCaso">{verificaLargo(med.descripcionCanasta, 18)}</label>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {fechaConvertida(med.fechaInicio)}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {(med.fechaTermino && fechaConvertida(med.fechaTermino)) || null}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {med.sku}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {verificaLargo(med.descripcionmed, 18)}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            <label className="labelnroCaso">
                                                {fechaConvertida(med.fechaCreacion)}
                                            </label>
                                            <label className="labeltipoCaso">{verificaLargo(med.usuarioCreador, 18)}</label>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "5%" }}
                                            align="left"
                                        >
                                            <TagMed estado={eliminado ? "Eliminado" : med.estado}/>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            <MenuME
                                                disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}
                                                row={med}
                                                setSnackData={setSnackData}
                                                eliminado={eliminado}
                                                refetch={refetch}
                                                sucursal={sucursal}
                                                usuario={usuario}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                                : null}
                        </TableBody>}
                </Table>
            </TableContainer>
            {!loading && (!data || !data.getmedicamentosExcepcion || parseInt(data.getmedicamentosExcepcion.total) === 0) ?
                <NoResult titulo="Sin Resultados" subtitulo="No se encontraron registros para esta tabla" noDivider />
                : null}
            <Grid alignItems="center" pb="15px" pt="24px">
                {loading ? null :
                    <Paginador
                        opciones={[5, 10, 15]}
                        data={(data && parseInt(data.getmedicamentosExcepcion.total)) || 0}
                        pagina={filtros.pag}
                        setPagina={(v) => setFiltros({ ...filtros, pag: v })}
                        itemPorPagina={filtros.limit}
                        original={filtros}
                        cambioLineasPag={setFiltros}
                    />}
            </Grid>
        </div>
    );
}