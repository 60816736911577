import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { convertirEstados } from "./CamposUtils";
import { fecha, parseFecha } from "./fechaUtils";
import { procesaPrestadores } from "../components/ArchivosGestor/utils/procesaPrestadores";

const buildReport = (section, results) => {
  const data = [];
  switch (section) {
    case 'Casos':
      data.push(["Nro Caso", "Tipo Caso", "Rut Beneficiario", "Nombre Beneficiario", "Diagnóstico", "Cod. CIE 10", "Descripción CIE 10", "Prestador","Fecha Solicitud","Fecha Recepcion",
        "Estado", "Hospitalización", "Vía Ingreso", "Hito", "Motivo de Cierre", "Fecha de Cierre", "Plan", "Fecha de Vigencia", "Creado por"]);
      break;
    case 'Bonificaciones':
      data.push(["Id Caso Canasta", "Codigo Canasta", "Nro. Caso", "Descripcion Canasta", "Descripcion Prestacion",
        "Prestacion","Tipo Documento", "Fecha Gasto", "Folio Bono", "Monto Total venta", "Monto Total Prestación", "Monto Bonificado", "Copago",
        "Periodicidad", "Tiempo Periodicidad", "Fecha Inicio", "Fecha Término", "Estado"]);
      break;
    default: return;
  };

  switch (section) {
    case 'Casos':
      results.forEach((result) => {

        const diagnostico = result.tipoCaso === "GES"
          ? result.fkProblemaSalud + " - " + (result.nombrepat && result.nombrepat.GlosaSis
            ? result.nombrepat.GlosaSis
            : null)
          : result.diagnosticoCaec;
        let prestador = null;
        if (result.prestadores && result.prestadores.prestadores.length > 0) {
          let temp = procesaPrestadores(result.prestadores.prestadores, result.fkProblemaSalud);
          if(temp && temp.length > 0){
            prestador = temp[0].nombrePrestador;
          }
        }
        data.push([
          result.fkFun,
          result.tipoCaso,
          result.rutPaciente,
          result.nombrePaciente + " " + result.apellido1Paciente + " " + result.apellido2Paciente,
          diagnostico,
          result.fkCie10,
          result.cie,
          prestador,
          fecha(result.fechaSolicitudIsapre),
          fecha(result.fechaRecepcionSolicitud),
          convertirEstados(result.fkEstadoSolicitudIsapre),
          result.declaraHospitalizacion ? result.razonSocialInstitucionHopsitalizacion : "No",
          result.origenR,
          result.estadoHito ? result.estadoHito.descripcion : null,
          result.motivoCierre ? result.motivoCierre : "",
          result.fechaCierre ? format(parseFecha(result.fechaCierre), "dd/MM/yyyy") : "",
          result.plan,
          result.fVigenciaBeneficiario ? fecha(result.fVigenciaBeneficiario) : "",
          result.createdUser
        ]);
      });
      break;
    case 'Bonificaciones':
      results.forEach((result) => {
        data.push([
          result.idCasoCanasta,
          result.codigoCanasta,
          result.folio,
          result.descripcionCanasta,
          result.descripcionPrestacion,
          result.prestacion,
          result.tipoDocumento,
          result.fechaGasto,
          result.folioBono,
          result.montoTotalBoleta,
          result.montoTotalPrestacion,
          result.montoBonificado,
          result.copago,
          result.periodicidad,
          result.tPeriodicidad,
          result.fechaInicio,
          result.fechaFin,
          result.estado === 'ACTIVE' ? 'ACTIVO' : result.estado === 'INACTIVE' ? 'CERRADO' : 'ANULADO'
        ])
      });
      break;
    default: return;
  }

  return data;
}

export const buildExcel = async (section, results, rut) => {
  try {
    const workbook = new Workbook();
    switch (section) {
      case 'Casos':
        workbook.creator = "Casos";
        break;
      case 'Bonificaciones':
        workbook.creator = "Bonificaciones";
        break;
      default:
        throw Error("Opcion invalida")

    };
    workbook.addWorksheet("Datos");

    const worksheet = workbook.getWorksheet(1);
    worksheet.addRows(buildReport(section, results));

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const name = `${section}${rut ? "_" + rut : ""}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
      saveAs(blob, name);
    });

    return true;
  } catch (error) {
    console.log("ERROR: ", error.message);
    return null;
  }
};
