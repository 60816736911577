import { Fragment } from "react";
import { Grid, Chip } from "@mui/material";
import { OutlinedButton2 } from "../../../components/Buttons/Botones";
import { Cruz } from "../../../components/icons/iconosEsencial";

export function FiltrosME({ filtros, setFiltros }) {
    const handleDelete = (cod) => {
        setFiltros({ ...filtros, [cod]: null, pag: 0 });
    };

    const clearFiltros = () => {
        setFiltros({
            ...filtros,
            rut: null,
            canasta: null,
            codigoME: null,
            nombreMed: null,
            pag: 0
        })
    };

    return (
        <Fragment>
            {filtros && (
                (filtros.rut && filtros.rut !== null) ||
                (filtros.canasta && filtros.canasta !== null) ||
                (filtros.codigoME && filtros.codigoME !== null) ||
                (filtros.nombreMed && filtros.nombreMed !== null)) ?
                <Fragment>
                    <Grid item xs={12}>
                        <label className="filtroFont" >Resultados filtrados por:</label>{" "}
                    </Grid>
                    <Grid item xs={12} pt="12px">
                        {filtros.rut && filtros.rut !== null ?
                            <Chip sx={{ mr: "16px" }} label="Datos Beneficiario" variant="outlined" onDelete={() => handleDelete("rut")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.canasta && filtros.canasta !== null ?
                            <Chip sx={{ mr: "16px" }} label="Canasta" variant="outlined" onDelete={() => handleDelete("canasta")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.codigoME && filtros.codigoME !== null ?
                            <Chip sx={{ mr: "16px" }} label="SKU" variant="outlined" onDelete={() => handleDelete("codigoME")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.nombreMed && filtros.nombreMed !== null ?
                            <Chip sx={{ mr: "16px" }} label="Nombre Medicamento" variant="outlined" onDelete={() => handleDelete("nombreMed")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        <OutlinedButton2 sx={{ textDecoration: "underline", textDecorationThickness: "from-font", pl: "12px" }} onClick={clearFiltros}>
                            Limpiar filtros
                        </OutlinedButton2>
                    </Grid>
                </Fragment> : null}
        </Fragment>
    )
}