import { Fragment, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { historialBonos } from "../../../../utils/queries";
import { buildExcel } from "../../../../utils/buildExcel";
import Mensajero from "../../../../components/Snackbar/Mensajero";
import BotonCarga from "../../../../components/Buttons/BotonCarga";

export const ExportarBonificaciones = ({ rut, bonos }) => {
    const [cargando, setCargando] = useState(false);

    const [getBonificaciones] = useLazyQuery(historialBonos, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onCompleted: (result) => generarExcel(result),
        onError: (error) => {
            console.log(error.message);
            cerrarMensaje();
            mostrarMensaje("Archivo no generado, intente nuevamente.", "error");
            setCargando(false);
        }
    });

    const [snackData, setSnackData] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });

    const mostrarMensaje = (mensaje, severidad) => setSnackData({
        abrir: true,
        mensaje: mensaje,
        severidad: severidad,
    });

    const cerrarMensaje = () => setSnackData({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });

    const generarExcel = async (result) => {
        cerrarMensaje();

        if (result && result.getBonos) {
            await buildExcel('Bonificaciones', result.getBonos, rut);
            mostrarMensaje("Archivo generado, revise descargas.", "success");
        } else {
            mostrarMensaje("Datos no son válidos, favor reintente.", "error");
        };

        setCargando(false);
    };

    const executeQuery = () => {
        setCargando(true);
        mostrarMensaje("Exportando bonificaciones, por favor espere", "info");
        getBonificaciones({
            variables: {
                rut: rut,
                orden: "ASC",
                limit: 500,
                offset: 0,
            }
        })
    };

    return (
        <Fragment>
            <Mensajero data={snackData} setState={setSnackData} />
            <BotonCarga
                testid="exportarBonificaciones"
                sx={{ marginRight: "8px!important" }}
                boxSX={{ "& > button": { m: 1 } }} campos
                label="Exportar Bonificaciones"
                toDo={executeQuery}
                loading={cargando}
                variant='secondary'
                disabled={bonos}
            />
        </Fragment>
    )
}