import { useState } from "react";
import { useQuery } from "@apollo/client";
import { filtroorigen } from "../../../utils/queries";
import { Grid } from "@mui/material";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";

export const parseText = (val) => {
    switch (val) {
        case "GC": return "Gestor de casos";
        case "SV": return "Sucursal Virtual";
        case "SF": return "Sucursal Física";
        case "APP": return "Aplicación Móvil";
        default: return "Opción no definida";
    }
}

const Origenes = ({ value, setValue }) => {
    const [opciones, setOpciones] = useState([{ label: "Todos", value: "" }]);

    useQuery(filtroorigen, {
        onCompleted: (data) => {
            let array = [{ label: "Todos", value: "" }];
            data.filtroOrigenSGC.forEach((val) => {
                array.push({ label: parseText(val.descripcion), value: val.id })
            });
            setOpciones(array);
        }
    });

    return (
        <Grid item xs={4}>
            <label className="labelForm">Origen (SV o Gestor de casos)</label>
            <SelectPersonalizado
                id={"origenCaso"}
                inputTest={"origenCaso"}
                value={value}
                setValue={setValue}
                options={opciones}
                placeholder={"Seleccione origen"}
            />
        </Grid>
    );
}

export default Origenes;