export function procesaPrestadores(resPrestadores, ps) {
    const prestadoresRaw = parseInt(ps) === 19 ? resPrestadores : resPrestadores.filter((pres) => pres.prestador !== "76031071-9");
    var duplicado = false;
    if(prestadoresRaw.length === 0){
        return [];
    }
    if (prestadoresRaw && (!prestadoresRaw[1] || (prestadoresRaw[0].nombrePrestador === prestadoresRaw[1].nombrePrestador && prestadoresRaw[0].direccionPrestador === prestadoresRaw[1].direccionPrestador))) {
        duplicado = true;
    }

    if (prestadoresRaw.length > 1 && !duplicado) {
        if (prestadoresRaw[0].tipoPrestador === "DR" && prestadoresRaw[1].tipoPrestador === "DR") {
            return prestadoresRaw;
        } else if (prestadoresRaw[0].tipoPrestador === "DR" && prestadoresRaw[1].tipoPrestador !== "DR") {
            if (prestadoresRaw[1].transitorio === true) {
                return [prestadoresRaw[0]];
            } else {
                return [prestadoresRaw[1]];
            }
        } else if (prestadoresRaw[0].tipoPrestador !== "DR" && prestadoresRaw[1].tipoPrestador === "DR") {
            if (prestadoresRaw[0].transitorio === true) {
                return [prestadoresRaw[1]];
            } else {
                return [prestadoresRaw[0]];
            }
        }
    } else {
        return [prestadoresRaw[0]];
    }
}