import {
  Collapse,
  Grid
} from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { consultaPrestadoresCanasta, opcionesPrestador } from "../../../../../../utils/queries";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import OpcionesPrestador from "./OpcionesPrestador";
import { Localidad } from "../../../../../../components/Localidad/Localidad";
import { useState } from "react";
import BotonCarga from "../../../../../../components/Buttons/BotonCarga";
import AsignaFechas from "./AsignaFechas";

const ModalRegion = ({
  useAsignaPrestadorHook = useAsignaPrestadorContext,
  canasta,
  tipoCaso,
  isCaec,
  prestadoresAsignados,
  folio,
  isEditaPres,
  fechaAutorizacionSolicitud,
  estadoSolicitud,
  data,
  sucursal
}) => {
  const {
    region,
    cambiaRegion,
    activarTablaPrestadores,
    dataPrestador,
    handleDatosPrestador,
    selRegion,
    setSelRegion
  } = useAsignaPrestadorHook();

  const [prestadores, setPrestadores] = useState([]);
  const [getPrestadoresGES, { loading: loadingGes }] = useLazyQuery(consultaPrestadoresCanasta, {
    onCompleted: (data) => {
      setPrestadores(data.getPDLRbycanasta);
      setSelRegion(true);
    }
  });

  const [getPrestadoresCAEC, { loading: loadingCaec }] = useLazyQuery(opcionesPrestador, {
    onCompleted: (data) => {
      setPrestadores(data.getPDLR);
      setSelRegion(true);
    }
  });

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="0 20px 0 20px"
    >
      <Grid item xs={12}>
        <label className="titulo2">Dentro de la RED</label>
      </Grid>
      <Localidad
        region={region}
        setRegion={cambiaRegion}
        regionOnly={true}
        disabled={dataPrestador.rutPrestador !== null}
      />
      <BotonCarga
        testid="btnActivarTablaPrestadores"
        sx={{
          marginTop: "19px",
          width: "170px",
          height: "56px",
          minWidth: "80px",
        }}
        label="Buscar"
        toDo={() => {
          setPrestadores([]);
          handleDatosPrestador(
            null,
            null,
            null,
            null,
          );
          activarTablaPrestadores();
          if (tipoCaso === "GES") {
            if (region !== 0) {
              getPrestadoresGES({
                variables: {
                  canasta: canasta.canasta,
                  region: region
                }
              });
            }
          } else {
            getPrestadoresCAEC();
          }
        }}
        loading={loadingGes || loadingCaec}
        disabled={region === 0 || region === "0" || dataPrestador.rutPrestador !== null}
      />
      <OpcionesPrestador
        prestadores={prestadores}
        loading={loadingGes || loadingCaec}
        prestadoresAsignados={(canasta && canasta.prestadoresAsignados) || prestadoresAsignados || []}
        selRegion={selRegion}
        isEditaPres={isEditaPres}
        isCaec={isCaec}
      />
      <Grid item xs={12}>
        <Collapse in={dataPrestador.rutPrestador !== null}>
          <AsignaFechas
            tipoCaso={tipoCaso}
            folio={folio}
            isEditaPres={isEditaPres}
            fechaAutorizacionSolicitud={fechaAutorizacionSolicitud}
            estadoSolicitud={estadoSolicitud}
            canasta={canasta}
            sucursal={sucursal}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default ModalRegion;
