import { Fragment, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalEliminarV } from "./ModalEliminarV";
import HistorialCambiosV from "./HistorialCambiosV";
import { isMantenedor, hasJGESCAEC } from "../../../../utils/hasPermisions";

export const MenuV = ({ dataV, setSnackData, refetch, isDeleted, rol, sucursal, usuario }) => {
    const [modalEliminarV, setModalEliminarV] = useState(false);
    const [modalHistorialV, setModalHistorialV] = useState(false);
    const [AnclaMenu, setAnclaMenu] = useState(null);
    const open = Boolean(AnclaMenu);

    const AbrirMenuVDMC = (event) => {
        setAnclaMenu(event.currentTarget);
    };

    const CerrarMenuVDMC = () => {
        setAnclaMenu(null);
    };

    const modalEliminarVDMC = () => {
        CerrarMenuVDMC();
        setModalEliminarV(true);
    };

    const modalHistorialVDMC = () => {
        CerrarMenuVDMC();
        setModalHistorialV(true);
    };
    return (
        <Fragment>
            <ModalEliminarV
                onOpen={modalEliminarV}
                onClose={() => setModalEliminarV(false)}
                dataV={dataV}
                setSnackData={setSnackData}
                refetch={refetch} 
                sucursal={sucursal} 
                usuario={usuario} 
            />
            <HistorialCambiosV
                onOpen={modalHistorialV}
                onClose={() => setModalHistorialV(false)}
                dataV={dataV}
            />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={AbrirMenuVDMC}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
            className="menuForm"
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={AnclaMenu}
                open={open}
                onClose={CerrarMenuVDMC}
                 
            >
                <MenuItem hidden={!isDeleted} onClick={modalHistorialVDMC}>
                    Historial de cambios
                </MenuItem>
                <MenuItem disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))} hidden={isDeleted} onClick={modalEliminarVDMC}>
                    Eliminar
                </MenuItem>
            </Menu>
        </Fragment>
    )
}