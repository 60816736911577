import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import {
    Grid,
    Skeleton,
    Stack
} from "@mui/material";
import SkeletonBar from "../../../components/Skeleton/SkeletonBar";

export const LoaderHistorial = () => (
    <Grid container p="24px" data-testid="loaderHistorial">
        <Grid item xs={12}>
            <Grid container p="0px 24px 24px 24px">
                <Grid item xs={12}>
                    <SkeletonBar
                        width={218}
                        height={18}
                    />
                </Grid>
                <Grid item xs={12} pt="8px">
                    <SkeletonBar
                        width={500}
                        height={16}
                    />
                </Grid>
                <Grid item xs={12} pt="24px">
                    <SkeletonBar
                        width={170}
                        height={14}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} p="0px 24px 24px 24px">
                <Grid
                    container
                    border="1px solid #D2D6D9"
                    borderRadius="8px"
                    padding="16px"
                    style={{
                        maxHeight: "450px",
                        paddingRight: "8px"
                    }}
                >
                    <Grid container sx={{ marginBottom: "20px" }}>
                        <Grid item xs={1} textAlign="right" pr='16px' style={{ flexBasis: "5%", maxWidth: "5%" }}>
                            <Skeleton
                                animation="wave"
                                sx={{ bgcolor: '#F2F5F9' }}
                                variant="circular"
                                width={16}
                                height={16}
                            />
                        </Grid>
                        <Grid item xs={11} textAlign="left" style={{ flexBasis: "95%", maxWidth: "95%" }}>
                            <Stack direction={"column"} spacing={"4px"}>
                                <SkeletonBar
                                    width={100}
                                    height={14}
                                />
                                <SkeletonBar
                                    width={200}
                                    height={14}
                                />
                                <Stack direction={"row"} spacing={"16px"}>
                                    <SkeletonBar
                                        width={200}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={90}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={150}
                                        height={14}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginBottom: "20px" }}>
                        <Grid item xs={1} textAlign="right" pr='16px' style={{ flexBasis: "5%", maxWidth: "5%" }}>
                            <Skeleton
                                animation="wave"
                                sx={{ bgcolor: '#F2F5F9' }}
                                variant="circular"
                                width={16}
                                height={16}
                            />
                        </Grid>
                        <Grid item xs={11} textAlign="left" style={{ flexBasis: "95%", maxWidth: "95%" }}>
                            <Stack direction={"column"} spacing={"4px"}>
                                <SkeletonBar
                                    width={100}
                                    height={14}
                                />
                                <SkeletonBar
                                    width={200}
                                    height={14}
                                />
                                <Stack direction={"row"} spacing={"16px"}>
                                    <SkeletonBar
                                        width={200}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={90}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={150}
                                        height={14}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginBottom: "20px" }}>
                        <Grid item xs={1} textAlign="right" pr='16px' style={{ flexBasis: "5%", maxWidth: "5%" }}>
                            <Skeleton
                                animation="wave"
                                sx={{ bgcolor: '#F2F5F9' }}
                                variant="circular"
                                width={16}
                                height={16}
                            />
                        </Grid>
                        <Grid item xs={11} textAlign="left" style={{ flexBasis: "95%", maxWidth: "95%" }}>
                            <Stack direction={"column"} spacing={"4px"}>
                                <SkeletonBar
                                    width={100}
                                    height={14}
                                />
                                <SkeletonBar
                                    width={200}
                                    height={14}
                                />
                                <Stack direction={"row"} spacing={"16px"}>
                                    <SkeletonBar
                                        width={200}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={90}
                                        height={14}
                                    />
                                    <SkeletonBar
                                        width={150}
                                        height={14}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
export const SkeletonHeader = () => (
    <Grid item xs={12}>
        <div className="container" data-testid="skeletonHeaderVDMC">
            <Grid container p="24px">
                <Grid item xs={8} pb="10px">
                    <Stack direction={"column"} spacing={"10px"}>
                        <SkeletonBar
                            width={180}
                            height={18}
                        />
                        <SkeletonBar
                            width={371}
                            height={16}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={4} direction={"row"} sx={{ minWidth: "190px !important" }} textAlign={"right"}>
                    <Stack direction={"row"}>

                        <Stack direction={"column"} pr="16px" spacing="10px">
                            <SkeletonBar
                                width={200}
                                height={14}
                            />
                            <SkeletonBar
                                width={200}
                                height={14}
                            />
                        </Stack>
                        <SkeletonBar
                            width={180}
                            height={40}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </div>
    </Grid>
)