import { Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import { TextModal } from "../Dialog/TextModal";

export const TextWithModal = ({ text, length }) => {
    const [modalDescripcion, setModalDescripcion] = useState(false);

    if (text.length > length) {
        var shorted = text.slice(0, length);
        return (
            <Grid>
                <TextModal
                    open={modalDescripcion}
                    handleClose={() => setModalDescripcion(false)}
                    text={text}
                />
                <Typography>{shorted}...
                    <Link
                        data-testid="vermasBtn"
                        onClick={() => setModalDescripcion(true)}
                        sx={{color: "#01A49A"}}
                    >
                        [Ver más]
                    </Link>
                </Typography>
            </Grid>
        );
    } else {
        return text;
    }
}