import { useQuery } from "@apollo/client";
import { useState } from "react";
import { motivosexcepcion } from "../../../../utils/queries";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

export default function GetMotivo({
  value,
  motivoIngresado,
  disabled,
  error
}) {
  const [motivos, setMotivos] = useState([{ label: "Cargando...", value: "" }]);

  useQuery(motivosexcepcion, {
    onCompleted: (res) => {
      let tempArray = [];
      res.getMotivosExcepcion.forEach((dato) => {
        tempArray.push({ label: dato.descripcion, value: dato.id })
      });
      setMotivos(tempArray);
    },
    onError: () => {
      setMotivos([{ label: "Error cargando opciones, favor recargar", value: "" }]);
    }
  });

  return (
    <SelectPersonalizado
      inputName={"descripcionHitoTermino"}
      inputTest="motivoHito"
      value={value}
      error={error}
      setValue={motivoIngresado}
      options={motivos}
      placeholder={"Selecciona un motivo"}
      disabled={disabled}
    />
  )
}
