import { Grid } from "@mui/material";

export default function Mensaje({ msg }) {
    switch (msg) {
        case 1: return (
            <Grid>
                <label>La fecha de término establecida no concuerda con la fecha de término de la canasta.
                    <br />Para continuar actualiza la fecha de término o agrega un nuevo prestador.</label>
            </Grid>
        );
        case 2: return (
            <Grid>
                <label>La canasta a la que pertenece este prestador no tiene fecha de término.
                    <br />Para continuar agrega un nuevo prestador</label>
            </Grid>
        );
        case 3: return (
            <Grid>
                <label>No es posible editar la fecha ya que el prestador corresponde a farmacia.</label>
            </Grid>
        );
        default: return "default";
    }
}