import { useState } from "react";
import "../../../../assets/App.css";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import { useMutation } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { AsignarHito } from "../../../../utils/queries";
import { isValid, parseISO, format } from "date-fns";
import PrimaryButton, {
  SecondaryButton,
} from "../../../../components/Buttons/Botones";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField
} from "@mui/material";
import Success from "../../../../components/icons/Success.svg";
import { useMsal } from "@azure/msal-react";
import SelectHito from "./SelectHito";
import BuscaPrestador from "../../../../components/BuscaPrestador/BuscaPrestador";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { Localidad } from "../../../../components/Localidad/Localidad";

export function AsignaHitoInicio({
  deshabilitaInputs,
  estado,
  fechaAutorizacion,
  folio,
  salud
}) {
  //variables
  const [hito, setHito] = useState("");
  const [canasta, setCanasta] = useState("");
  const [prestacion, setPrestacion] = useState("");
  const [fechaEvento, setFechaEvento] = useState(null);

  const [region, setRegion] = useState(null);
  const [comuna, setComuna] = useState(null);

  const [razonSocial, setRazonSocial] = useState(null);
  const [Direccion, SetDireccion] = useState(null);
  const [rutPrestador, setRutPrestador] = useState(null);
  const [luat, setLuat] = useState(null);
  const [resetInputPres, setResetInputPres] = useState(false);

  //Errores
  const [descripcionHitoError, setDescripcionHitoError] = useState(null);
  const [prestadorError, setPrestadorError] = useState(null);
  const [fechaEventoError, setFechaEventoError] = useState(null);
  const [regionError, setRegionError] = useState(null);
  const [comunaError, setComunaError] = useState(null);
  const [dialogOpenprimero, setDialogOpenprimero] = useState(false);
  const { accounts } = useMsal();

  const [guardarHito, { loading }] = useMutation(AsignarHito, {
    onCompleted: () => {
      clearAll();
      abrirPrimerModal();
      const btnTabla = document.getElementById('recargaTablaHitos');
      if (btnTabla) {
        btnTabla.click();
      }
    },
    onError: (err) => {
      console.log(err.message);
    }
  });

  function checkDatos() {
    let error = false;
    if (hito === null || hito === "") {
      setDescripcionHitoError("Debe ingresar una descripción del hito");
      error = true;
    } else {
      setDescripcionHitoError(null);
    }

    if (rutPrestador && razonSocial && Direccion) {
      setPrestadorError(null);
    } else {
      setPrestadorError("Debe completar los datos faltantes del prestador.");
      error = true;
    }

    if (!isValid(fechaEvento)) {
      setFechaEventoError("Ingrese fecha de inicio");
      error = true;
    } else {
      setFechaEventoError(null);
    }
    if (region === null) {
      setRegionError("Ingrese una Región");
      error = true;
    } else {
      setRegionError(null);
    }
    if (comuna === null) {
      setComunaError("Ingrese una Comuna");
      error = true;
    } else {
      setComunaError(null);
    }

    if (!error) {
      guardarHito({
        variables: {
          folio: folio,
          idHito: parseInt(hito),
          prestador: rutPrestador.replace(/[^0-9kK-]/g, ""),
          razonSocial: razonSocial,
          region: region,
          ciudad: null,
          comuna: comuna,
          fechaEvento: fechaEvento,
          horaEvento: format(fechaEvento, "HH:mm"),
          motivo: null,
          exceptuar: false,
          observacion: null,
          luatPrestador: luat,
          direccionPrestador: Direccion,
          creador: accounts[0].name,
        },
      });
    }
  }

  const actualizaDatosPres = (dir, luatz, rz, rut) => {
    SetDireccion(dir);
    setLuat(luatz);
    setRazonSocial(rz);
    setRutPrestador(rut);
    setPrestadorError(null);
  };

  function detectaCambioComboregiones(valores) {
    setRegion(valores.idReg);
    setComuna(null);
    setRegionError(null);
  }

  function detectaCambioCombocomuna(e) {
    setComuna(e.idCom);
    setComunaError(null);
  }

  const clearAll = () => {
    setHito("");
    setCanasta("");
    setPrestacion("");
    setFechaEvento(null);
    setRegion("");
    setComuna("");
    setLuat(null);
    setRutPrestador(null);
    setRazonSocial(null);
    SetDireccion(null);
    setResetInputPres(true);
    setDescripcionHitoError(null);
    setPrestadorError(null);
    setFechaEventoError(null);
    setRegionError(null);
    setComunaError(null);
  };

  const abrirPrimerModal = () => {
    setDialogOpenprimero(true);
  };

  const cerrarPrimerModal = () => {
    setDialogOpenprimero(false);
  };

  const setError = () => {
    setDescripcionHitoError(null);
  }

  return (
    <div className="container">
      <Grid
        sx={{ padding: "0px 24px 20px 14px", margin: "0", width: "100%" }}
        container
        spacing={2}
        id="paciente"
      >
        <Grid item xs={12}>
          <label className="AltTitulo4">Información del hito</label>
          <br />
          <label className="AltTitulo3">Hito de inicio</label>
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="">Descripción del hito (Requerido)</label>
          <SelectHito
            deshabilitaInputs={deshabilitaInputs}
            disabled={
              loading ||
                estado === 5 ||
                estado === 6 ||
                estado === 7 ||
                estado === 10||
                deshabilitaInputs
                ? true
                : false
            }
            estado={estado}
            salud={salud}
            setHito={setHito}
            setError={setError}
            setPrestacion={setPrestacion}
            setCanasta={setCanasta}
            hito={hito}
          />
          <span className="errorSpan"> {descripcionHitoError} </span>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={6}>
          <label htmlFor="canasta">Canasta</label>
          <input
            type="text"
            className="form-control inputText"
            name="canasta"
            data-testid="detalleCanasta"
            id="canasta "
            placeholder=" "
            //onChange={(data) => setCanasta(data)}
            value={canasta}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="prestacion">Prestaciones</label>
          <FormControl sx={{ width: "99%!important", margin: "0 1% 1% 0" }}>
            <input
              value={prestacion}
              type="text"
              className="form-control inputText"
              placeholder=" "
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="fechaEvento">Fecha del evento (Requerido)</label>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={es}
          >
            <Grid sx={{ m: 0.5 }}>
              <DatePicker
                disabled={
                  loading ||
                    estado === 5 ||
                    estado === 6 ||
                    estado === 7 ||
                    estado === 10||
                    deshabilitaInputs
                    ? true
                    : false
                }
                type="date"
                label="DD  /  MM  /  AAAA"
                minDate={parseISO(fechaAutorizacion)}
                clearable
                clearText="Limpiar Fecha"
                inputProps={{ "data-testid": "cambioFecha" }}
                allowSameDateSelection={true}
                showDaysOutsideCurrentMonth={true}
                value={fechaEvento}
                onChange={(newValue) => {
                  setFechaEvento(newValue);
                  setFechaEventoError(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <span className="errorSpan"> {fechaEventoError} </span>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="horaEvento">Hora del evento (Requerido)</label>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={es}
          >
            <Grid sx={{ m: 0.5 }}>
              <TimePicker
                disabled={
                  loading ||
                    estado === 5 ||
                    estado === 6 ||
                    estado === 7 ||
                    estado === 10||
                    deshabilitaInputs
                    ? true
                    : false
                }
                type="time"
                label="HH  :  MM "
                inputProps={{ "data-testid": "cambioHora" }}
                value={fechaEvento}
                onChange={(newValue) => {
                  setFechaEvento(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} pb="0" mb="0" mt="14px">
          <label className="titulo2" style={{ marginBottom: "0" }}>Datos del Prestador</label>
        </Grid>
        <BuscaPrestador
          razonSocial={razonSocial || ""}
          rutInsti={rutPrestador || ""}
          luat={luat || ""}
          direccion={Direccion || ""}
          error={{ rutInsti: prestadorError }}
          asignaPrestador={actualizaDatosPres}
          deshabilitaInputs={deshabilitaInputs}
          resetInputPres={resetInputPres}
          setResetInputPres={setResetInputPres}
          disabled={loading ||
            estado === 5 ||
            estado === 6 ||
            estado === 7 ||
            estado === 10||
            deshabilitaInputs
            ? true
            : false}
        />
        <Localidad
          disabled={
            loading ||
              estado === 5 ||
              estado === 6 ||
              estado === 7 ||
              estado === 10||
              deshabilitaInputs
              ? true
              : false}
          region={region}
          regionError={regionError}
          setRegion={detectaCambioComboregiones}
          comuna={comuna}
          comunaError={comunaError}
          setComuna={detectaCambioCombocomuna}
          requerido={true}
        />
        <Grid item xs={12} pt="24px !important">
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SecondaryButton
              disabled={
                loading ||
                  estado === 5 ||
                  estado === 6 ||
                  estado === 7 ||
                  estado === 10||
                  deshabilitaInputs
                  ? true
                  : false
              }
              type="button"
              onClick={clearAll}
            >
              Limpiar datos
            </SecondaryButton>
            <BotonCarga
              id="guardarHI"
              testid="botonGuardaHito"
              sx={{ marginRight: "8px!important" }}
              boxSX={{ "& > button": { m: 1 } }} campos
              label="Guardar"
              toDo={checkDatos}
              loading={loading}
              disabled={estado === 5 ||
                estado === 6 ||
                estado === 7 ||
                estado === 10||
                deshabilitaInputs
                ? true
                : false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        tabIndex={-1}
        id="Modal1"
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={dialogOpenprimero}
        onClose={cerrarPrimerModal}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid sx={{ m: 1, textAlign: "center" }}>
          <img alt="img2" src={Success}></img>
        </Grid>
        <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
          Hito asignado
        </DialogTitle>
        <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
          <DialogContentText
            alignContent="center"
            sx={{ m: 1, textAlign: "center" }}
          >
            Tu hito fue cargado con éxito.
          </DialogContentText>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <PrimaryButton
                variant=" text"
                color="default"
                data-testid="cerrarModalHito"
                onClick={cerrarPrimerModal}
              >
                Cerrar
              </PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}