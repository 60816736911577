import { TablePagination, ThemeProvider } from "@mui/material";
import { paginatorTheme } from "./stylePagination";

export function Paginador({
  setPagina,
  servPag,
  loadNextPage,
  setLineasPagina,
  opciones,
  data,
  itemPorPagina,
  pagina,
  cambioLineasPag,
  original
}) {

  const cambioPagina = (event, newPage) => {
    setPagina(newPage);
    if (servPag) {
      loadNextPage();
    }
  };

  const cambioLineas = (event) => {
    if (cambioLineasPag) {
      cambioLineasPag({ ...original, pag: 0, limit: parseInt(event.target.value, 10) })
    } else {
      setLineasPagina(parseInt(event.target.value, 10));
      setPagina(0)
    }
  };

  return (
    <ThemeProvider theme={paginatorTheme}>
      <TablePagination
        rowsPerPageOptions={opciones}
        labelRowsPerPage="Mostrar por página"
        component="div"
        count={data}
        rowsPerPage={itemPorPagina}
        page={pagina}
        onPageChange={cambioPagina}
        onRowsPerPageChange={cambioLineas}
        labelDisplayedRows={
          (e) => {
            return '' + (pagina + 1) + ' de ' + (Math.ceil(data / itemPorPagina))
          }
        }
      />
    </ThemeProvider>
  )
}