import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material";
import { parseISO } from "date-fns";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import PrimaryButton, { SecondaryButton } from "../../../../../../components/Buttons/Botones";
import OpcionesFuera from "./OpcionesFuera";
import BuscaPrestador from "../../../../../../components/BuscaPrestador/BuscaPrestador";
import { DatePicker } from "../../../../../../components/DatePicker/DatePicker";
import Aviso from "../../../../../../components/Alert/Aviso";

const ModalFueraDeRed = ({
  useAsignaPrestadorHook = useAsignaPrestadorContext,
  fechaAutorizacionSolicitud,
  tipoCaso,
  fkFun,
  canasta,
  estadoSolicitud,
  sucursal,
  isCaec,
  postguardar,
  setMensajeroData
}) => {
  const {
    modalFueraRed,
    cerrarFueraRed,
    dataPrestador,
    motivoFueraRed,
    handleMotivoRed,
    fechaInicio,
    handleFechaInicio,
    fechaTermino,
    handleFechaTermino,
    estadoTransitorio,
    observacion,
    observacionIngresada,
    checkDatos,
    rutError,
    motivoError,
    transitorioError,
    observacionError,
    guardando,
    setDataPrestador,
    setRutError,
    setModalInicio
  } = useAsignaPrestadorHook();

  const { rutPrestador, razonSocial, direccion, luat } = dataPrestador;
  const fechaFin = canasta.fechaFin ? parseISO(canasta.fechaFin) : undefined;
  const fechaCanasta = canasta && canasta.fechaInicio ? parseISO(canasta.fechaInicio) : parseISO(fechaAutorizacionSolicitud);
  const [isDupe, setIsDupe] = useState(false);

  const actualizaDatosPres = (dir, luats, rz, rut) => {
    setDataPrestador({
      ...dataPrestador,
      rutPrestador: rut,
      razonSocial: rz,
      direccion: dir,
      luat: luats
    });
    setRutError(null);
    if (canasta && rut) {
      const parsedrut = rut.replace(/[^0-9kK-]/g, "").trim();
      const existente = canasta.prestadoresAsignados.find(pres => pres.prestador === parsedrut && pres.vigencia === true && pres.luat !== "888");
      if (existente) {
        setIsDupe(true);
      } else {
        setIsDupe(false);
      }
    }
  };
  return (
    <Dialog
      className="dialogModalBig2"
      open={modalFueraRed}
      onClose={cerrarFueraRed}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <DialogTitle className="titulo2" textAlign="center" pb="16px">
        Prestador fuera de la RED
        <label className="subtitleModal">
          Ya que has establecido un prestador dentro de RED te invitamos
          a establecer uno fuera de la RED.
        </label>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          sx={{ padding: "5px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <BuscaPrestador
            razonSocial={razonSocial || ""}
            rutInsti={rutPrestador || ""}
            luat={luat || ""}
            direccion={direccion || ""}
            directions={null}
            error={{ rutInsti: rutError }}
            asignaPrestador={actualizaDatosPres}
            detectaCambio={() => { }}
            deshabilitaInputs={false}
          />
          <Grid item xs={12} sx={{ paddingTop: "0%!important" }}>
            <Aviso
              mensaje={"El prestador seleccionado ya se encuentra asignado y en estado vigente."} open={isDupe} severidad='error' noClose
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <label className="labelForm">Motivo fuera de RED </label>
            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
              <select
                data-testid="selectOpcionesFuera"
                className="form-select form-select-sm selectDefault"
                value={motivoFueraRed}
                onChange={handleMotivoRed}
              >
                <option value={0}>Selecciona un motivo</option>
                <OpcionesFuera />
              </select>
            </FormControl>
            {motivoError ? (
              <label className="text-danger">
                Dede seleccionar un Motivo
              </label>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <label className="labelForm">Fecha de inicio</label>
            <DatePicker
              id="fechaIniciopres"
              testid="fechaIniciopres"
              minDate={fechaCanasta}
              maxDate={fechaFin}
              value={fechaInicio}
              noClear
              onChange={(newValue) => {
                handleFechaInicio(
                  newValue,
                  parseISO(fechaAutorizacionSolicitud)
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <label className="labelForm">Fecha de término</label>
            <DatePicker
              id="fechaTerminoPres"
              testid="fechaTerminoPres"
              minDate={fechaInicio || fechaCanasta}
              value={fechaTermino}
              onChange={(newValue) => {
                handleFechaTermino(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <label className="labelForm">Transitorio</label>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={estadoTransitorio}
              data-testid="radioTransitorio"
            >
              <FormControlLabel value={true} control={<Radio />} label="Si" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {transitorioError ? (
              <label className="text-danger">
                Debe Seleccionar un Transitorio
              </label>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <label className="labelForm">Observaciones</label>
            <FormControl sx={{ width: "99.5%!important", margin: "0 1% 0 0" }}>
              <textarea
                data-testid="textareaObservacion"
                id=""
                cols="30"
                rows="4"
                className="form-control"
                placeholder="Cuéntanos tus observaciones"
                maxLength="300"
                value={observacion || ""}
                onChange={observacionIngresada}
              />
            </FormControl>
            {observacionError ? (
              <label className="text-danger">
                Debe ingresar una observación con trasitorio
              </label>
            ) : null}
            <label className="text-end">
              {observacion != null ? observacion.length : 0}/300
            </label>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SecondaryButton
            variant=" text"
            color="default"
            data-testid="btnCloseModal"
            disabled={guardando}
            onClick={() => {
              setIsDupe(false);
              cerrarFueraRed();
              if (!isCaec) {
                setModalInicio(true);
              }
            }}
          >
            Volver
          </SecondaryButton>
          <PrimaryButton
            data-testid="btnCheckDatos"
            variant=" text"
            color="default"
            disabled={guardando || isDupe}
            onClick={() => {
              checkDatos(
                tipoCaso,
                fkFun,
                canasta,
                estadoSolicitud,
                sucursal,
                postguardar,
                setMensajeroData);
            }}
          >
            Establecer prestador
          </PrimaryButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFueraDeRed;
