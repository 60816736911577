import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { parseISO } from "date-fns";
import {
  IconButton,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import EditarPrestador from "./EditarPrestador";
import { EliminarPrestador } from "./EliminarPrestador";

export const MenuPrestadores = ({
  prestador,
  estado,
  accounts,
  folio,
  canastasAsignadas,
  deshabilitaInputs,
  setAlertData,
  isCaec,
  sucursal
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [fechaInicioCanasta, setFechaInicioCanasta] = useState(null);
  const [fechaTerminoCanasta, setFechaTerminoCanasta] = useState(null);
  const [status, setStatus] = useState(0);
  const canastaUse = canastasAsignadas?.getCanastasasignadas?.canastas?.filter((can) => parseInt(can.id) === prestador?.idcanastaAsignada);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const abrirModal = () => {
    setModalEliminar(true);
  };

  const abrirModalEditar = () => {
    if (!isCaec) {
      const canasta = canastasAsignadas.getCanastasasignadas.canastas.find(
        (can) => parseInt(can.id) === prestador.idcanastaAsignada
      );
      if (canasta && canasta.fechaInicio) {
        setFechaInicioCanasta(parseISO(canasta.fechaInicio));
      }
      if (canasta && canasta.fechaFin) {
        setFechaTerminoCanasta(parseISO(canasta.fechaFin));
      }
      if (prestador.prestador === "76031071-9") {
        setStatus(3);
      }
    }
    setOpenEditar(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Grid>
      <IconButton
        data-testid="btnMenu"
        id="long-button"
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(accion) => {
          handleClick(accion);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="menuForm"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
        
          disabled={
            estado === 4 ||
              estado === 5 ||
              estado === 6 ||
              estado === 7 ||
              estado === 9 ||
              estado === 10 ||
              (!isCaec && prestador.prestador === "76031071-9") ||
              deshabilitaInputs
              ? true
              : false
          }
          data-testid="menuItem"
          onClick={() => {
            handleClose();
            abrirModal();
          }}
        >
          Eliminar
        </MenuItem>
        <MenuItem
          disabled={
            estado === 5 ||
              estado === 6 ||
              estado === 7 ||
              estado === 10 ||
              deshabilitaInputs ||
              (prestador.vigencia === false && estado !== 1)
              ? true
              : false
          }
          data-testid="menuItemEditar"
          onClick={() => {
            handleClose();
            abrirModalEditar();
          }}
        >
          Editar
        </MenuItem>
      </Menu>
      <EditarPrestador
        open={openEditar}
        setOpen={setOpenEditar}
        canastaUse={canastaUse}
        fechaInicioCanasta={fechaInicioCanasta}
        fechaTerminoCanasta={fechaTerminoCanasta}
        status={status}
        setStatus={setStatus}
        prestador={prestador}
        usuario={accounts[0]}
        folio={folio}
        estado={estado}
        setAlertData={setAlertData}
        isCaec={isCaec}
        sucursal={sucursal}
      />
      <EliminarPrestador
        open={modalEliminar}
        setOpen={setModalEliminar}
        prestador={prestador}
        setAlertData={setAlertData}
      />
    </Grid>
  );
};
