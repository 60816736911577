import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";

import icon_warningOrange from "../../../../components/icons/icon_warningOrange.svg";
import { useMutation } from "@apollo/client";
import { eliminahitosasignados } from "../../../../utils/queries";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
export const EliminarHito = ({
    open,
    setOpen,
    hito,
    setSnackData,
    usuario,
    sucursal
}) => {
    const [EliminarHito, { loading }] = useMutation(eliminahitosasignados, {
        onCompleted: () => {
            cerrarModal();
            setSnackData({
                abrir: true,
                mensaje: "Hito eliminado correctamente.",
                severidad: "success",
            });
            const recargaTabla = document.getElementById('recargaTablaHitos');
            if (recargaTabla) {
                recargaTabla.click();
            }
        },
        onError: (error) => {
            console.log(error.message)
            cerrarModal();
            setSnackData({
                abrir: true,
                mensaje: "No se eliminó el hito asignado, intente nuevamente.",
                severidad: "error",
            });
        }
    });
    const cerrarModal = () => {
        setOpen(false);
    };

    const handleEliminar = () => {
        EliminarHito({
            variables: {
                idHito: parseInt(hito.hitoInicio.id),
                usuario: usuario,
                sucursal: sucursal
            }
        })
    };
    let mensaje = hito.hitoTermino ? `y término ` : "";

    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            data-testid="popUpModal"
            maxWidth="md"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            {" "}
            <DialogContent sx={{ overflow: "unset" }}>
                <Grid container
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px"
                    }}
                >
                    <Grid xs={12}>
                        <img alt="img" src={icon_warningOrange}></img>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogTitle className="tituloModal" sx={{ padding: "0 24px" }}>
                ¿Estás seguro que deseas eliminar el hito de <br></br>inicio {mensaje} N° {hito.hitoInicio.idDespliegue}?
            </DialogTitle>
            <DialogContent >
                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center" }}
                >
                    Será eliminado de la sección hitos asignados
                </DialogContentText>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        pb="14px"
                        pt="8px"
                    >
                        <BotonCarga
                            testid="confirmarRechazoPrestador"
                            loading={loading}
                            toDo={handleEliminar}
                            label="Eliminar hito"
                        />

                        <SecondaryButton
                            data-testid="btnAnotherModal"
                            variant="text"
                            disabled={loading}
                            onClick={cerrarModal}

                        >
                            Cancelar
                        </SecondaryButton>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>

    );
};