import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import PrimaryButton from "../Buttons/Botones";
import { DragAndDrop } from "./DragAndDrop";

export const ModalDrag = (props) => {
  const handleClose = () => {
    props.setModal(false);
    props.setFiles(undefined);
  };

  return (
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        data-testid="modalDragDrop"
        open={props.modal}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogTitle className="tituloModal" sx={{ padding: "20px 0 10px 0", fontSize:"28px"}}>
          Subir formulario
        </DialogTitle>
        <DialogContent sx={{ padding: "0 20px" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{ padding: "0 20px" }}
          >
            <Grid item xs={12}  sx={{ margin: "15px 0"}}>
              <DragAndDrop 
                files={props.files} 
                setFiles={props.setFiles} 
                maxFiles={props.maxFiles}
                disabled={props.files&&props.files.length === props.maxFiles ? true : false}
              />
            </Grid>
            <Typography sx={{ padding: "15px",fontFamily:"interstate",color:"#67737C",fontSize:"14px" }}>
              Formatos válidos: GIF, JPEG, JPG, PNG, PDF (5 MB Máximo)
            </Typography>
          </Grid>

          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PrimaryButton
                data-testid="modalDragDropContinuar"
                disabled={props.files&&props.files.length > 0 ? false : true}
                onClick={props.next}
              > 
                Continuar
              </PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
  );
};

export default ModalDrag;
