import { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Breadcrumbs,
    Box,
    Typography,
    Grid,
    Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Mensajero from "../../components/Snackbar/Mensajero";
import { SecondaryButton } from "../../components/Buttons/Botones";
import { useLazyQuery } from "@apollo/client";
import { traerform } from "../../utils/queries";
import base64toBlob from "../../utils/Base64";
import { saveAs } from "file-saver";
import BotonCarga from "../../components/Buttons/BotonCarga";
import LEPIcon from "../../components/icons/LEPIcon.svg";
import CanastaICon from "../../components/icons/canastasIcon.svg";
import SaludIcon from "../../components/icons/saludIcon.svg";
import VDMCIcon from "../../components/icons/vdmcIcon.svg";
export default function MantenedorMenu({ rol, sucursal, usuario, test = true }) {
    const navigate = useNavigate();
    const [descargaPlantilla, setDescargaPlantilla] = useState({});
    const [mensaje, setMensaje] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });

    const [getPlantilla] = useLazyQuery(traerform);

    const descargaArchivo = async (type) => {
        setDescargaPlantilla(oldState => ({ ...oldState, [type]: true }));
        setMensaje({
            abrir: true,
            mensaje: "Generando descarga...",
            severidad: "info",
        });
        getPlantilla({
            variables: {
                plantilla: type
            },
            onCompleted: async (res) => {
                const base64ConForm = res.traerPlantilla.replace(/['"]+/g, "");
                const platBlob = base64toBlob(base64ConForm, "plantilla.xlsx");
                saveAs(platBlob, "CargaMasiva_" + type + ".xlsx");

                setMensaje({
                    abrir: true,
                    mensaje: "Descarga generada, revise sus archivos descargados",
                    severidad: "success",
                });
                setDescargaPlantilla(oldState => ({ ...oldState, [type]: false }));
            },
            onError: (error) => {
                console.log("error resp: " + error);
                setMensaje({
                    abrir: true,
                    mensaje: "No se generó la descarga, intente nuevamente",
                    severidad: "error",
                });
                setDescargaPlantilla(oldState => ({ ...oldState, [type]: false }));
            }
        });
    }

    return (
        <div data-testid="reporteria">
            <Mensajero data={mensaje} setState={setMensaje} />
            <div className="rowInformacion">
                <div className="container Main">
                    <label className="titulo1"> Mantenedor</label>
                    <Breadcrumbs
                        sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                        aria-label="breadcrumb"
                        className="breadcrum"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Link
                            underline="always"
                            color="inherit"
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "400",
                                margin: "0px,8px",
                                cursor: "pointer",
                            }}
                        >
                            Inicio
                        </Link>
                        <Typography
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "-1px",
                                lineHeight: "24px",
                            }}
                        >
                            Mantenedor
                        </Typography>
                    </Breadcrumbs>
                    <Grid container>
                        <Grid item md={6} lg={4} sm={6} pr="32px">
                            <Box className="container" textAlign="center" pb="12px !important" pt="26px !important">
                                <img src={VDMCIcon} alt=""/>
                                <Typography className="nombreForm" mb="16px" pt="16px">Vademecum</Typography>
                                <Typography className="subtitleModal" mb="16px">Información actualizada de <br /> medicamentos.</Typography>
                                <Stack pb="5%" direction="column" justifyContent="center" alignItems="center">
                                    <SecondaryButton
                                        data-testid="navigateVademecum"
                                        sx={{ width: "50%" }}
                                        onClick={() => navigate("/Vademecum")}
                                    >
                                        Ir a módulo
                                    </SecondaryButton>
                                    <BotonCarga
                                        sx={{ mt: "16px", width: "50%" }}
                                        label={'Plantilla'}
                                        loading={descargaPlantilla['VDMC']}
                                        toDo={() => descargaArchivo('VDMC')}
                                        variant='noBorder'
                                        testid={'VDMC'}
                                        download
                                    />
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item md={6} lg={4} pr="32px" sm={6}>
                            <Box className="container" textAlign="center" pb="12px !important" pt="26px !important">
                                <img src={SaludIcon} alt=""/>
                                <Typography className="nombreForm" mb="16px" pt="16px">Problemas de Salud</Typography>
                                <Typography className="subtitleModal" mb="16px">Información confiable sobre <br /> enfermedades en chile.</Typography>
                                <Stack pb="5%" direction="column" justifyContent="center" alignItems="center">
                                    <SecondaryButton
                                        data-testid="navigatePS"
                                        sx={{ width: "50%" }}
                                        onClick={() => navigate("/ProblemasSalud")}
                                    >
                                        Ir a módulo
                                    </SecondaryButton>
                                    <BotonCarga
                                        sx={{ mt: "16px", width: "50%" }}
                                        label={'Plantilla'}
                                        loading={descargaPlantilla['PS']}
                                        toDo={() => descargaArchivo('PS')}
                                        variant='noBorder'
                                        testid={'PS'}
                                        download
                                    />
                                </Stack>

                            </Box>
                        </Grid>
                        <Grid item md={6} lg={4} sm={6} pr="32px">
                            <Box className="container" textAlign="center" pb="12px !important" pt="26px !important">
                                <img src={CanastaICon} alt=""/>
                                <Typography className="nombreForm" mb="16px" pt="16px">Canastas</Typography>
                                <Typography className="subtitleModal" mb="16px">Oferta de prestaciones de salud <br /> garantizadas.</Typography>
                                <Stack pb="5%" direction="column" justifyContent="center" alignItems="center">
                                    {<SecondaryButton
                                        sx={{ width: "50%" }}
                                        data-testid="navigateCanastas"
                                        disabled={test}
                                        onClick={() => navigate("/Canastas")}
                                    >
                                        Ir a módulo
                                    </SecondaryButton>}
                                    <BotonCarga
                                        sx={{ mt: "16px", width: "50%" }}
                                        label={'Plantilla'}
                                        loading={descargaPlantilla['CANASTA']}
                                        toDo={() => descargaArchivo('CANASTA')}
                                        variant='noBorder'
                                        testid={'CANASTA'}
                                        download
                                    />
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={4} sm={6} pr="32px">
                            <Box className="container" textAlign="center" pb="12px !important" pt="26px !important">
                                <img src={LEPIcon} alt=""/>
                                <Typography className="nombreForm" mb="16px" pt="16px">LEP</Typography>
                                <Typography className="subtitleModal" mb="16px">Lista de prestaciones médicas <br></br> garantizadas.</Typography>
                                <Stack pb="5%" direction="column" justifyContent="center" alignItems="center">
                                    <SecondaryButton
                                        sx={{ width: "50%" }}
                                        data-testid="navigateLEP"
                                        onClick={() => navigate("/LEP")}
                                    >
                                        Ir a módulo
                                    </SecondaryButton>
                                    <BotonCarga
                                        sx={{ mt: "16px", width: "50%" }}
                                        label={'Plantilla'}
                                        loading={descargaPlantilla['LEP']}
                                        toDo={() => descargaArchivo('LEP')}
                                        variant='noBorder'
                                        testid={'LEP'}
                                        download
                                    />
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}


