import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { docAsociados } from "../../../utils/queries";
import { ButtonIcon } from "../../../components/Buttons/Botones";
import { IconFile } from "../../../components/icons/iconosEsencial";
import { Menu, MenuItem } from "@mui/material";
import { Opciones } from "./Opciones";
import SkeletonBar from "../../../components/Skeleton/SkeletonBar";
import { isContralor } from "../../../utils/hasPermisions";

export const MenuArchivos = ({ data, mensajero, setMensajero, correo,sucursal, rol }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [form1, setForm1] = useState(null);
  const [form1F, setForm1F] = useState(null);
  const [form2, setForm2] = useState(null);
  const [form2F, setForm2F] = useState(null);

  const [getDocs, { loading }] = useLazyQuery(docAsociados, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setForm1(result.getDocumentosasociados.form1);
      setForm1F(result.getDocumentosasociados.form1_firmado);
      setForm2(result.getDocumentosasociados.form2);
      setForm2F(result.getDocumentosasociados.form2_firmado);
    }
  });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    obtenerDocsFolio();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const obtenerDocsFolio = () => {
    let fkFun = data.fkFun;
    let folio = parseInt(fkFun);
    getDocs({
      variables: {
        folio: folio,
      }
    });
    handleClose();
  };
  const estado = data.fkEstadoSolicitudIsapre;
  return (
    <div>
      <ButtonIcon
        id="basic-button"
        onClick={handleClick}
        data-testid="botonArchivos"
      >
        <IconFile />
      </ButtonIcon>
      <Menu
        className="menuForm"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          "data-testid": "menuArchivosTest"
        }}
      >
        <MenuItem style={{ width: "230px!important" }}>
          {loading ?
            <SkeletonBar
              width={230}
              height={40}
            />
            :
            <Opciones
              form={form1}
              sinFirmar={true}
              num={1}
              upd={obtenerDocsFolio}
              fkFun={data.fkFun}
              estado={estado}
              correo={correo}
              sucursal={sucursal}
            />}
        </MenuItem>
        <MenuItem style={{ width: "230px" }}>
          {loading ?
            <SkeletonBar
              width={230}
              height={40}
            /> :
            <Opciones
              form={form1F}
              sinFirmar={false}
              num={1}
              disabled={isContralor(rol) || estado === 5 || estado === 6 || estado === 8 ? true : false}
              upd={obtenerDocsFolio}
              fkFun={data.fkFun}
              rutPaciente={data.rutPaciente}
              mensajero={mensajero}
              setMensajero={setMensajero}
              correo={correo}
              sucursal={sucursal}
            />}
        </MenuItem>
        <MenuItem style={{ width: "230px" }}>
          {loading ?
            <SkeletonBar
              width={230}
              height={40}
            /> :
            <Opciones
              form={form2}
              sinFirmar={true}
              num={2}
              upd={obtenerDocsFolio}
              fkFun={data.fkFun}
              estado={estado}
              correo={correo}
              sucursal={sucursal}
            />}
        </MenuItem>
        <MenuItem style={{ width: "230px" }}>
          {loading ?
            <SkeletonBar
              width={230}
              height={40}
            /> :
            <Opciones
              form={form2F}
              sinFirmar={false}
              num={2}
              disabled={isContralor(rol) || !form2 || estado === 5 || estado === 6 || estado === 8 ? true : false}
              upd={obtenerDocsFolio}
              fkFun={data.fkFun}
              rutPaciente={data.rutPaciente}
              mensajero={mensajero}
              setMensajero={setMensajero}
              correo={correo}
              sucursal={sucursal}
            />}
        </MenuItem>
      </Menu>
    </div>
  );
}
