import { format } from "date-fns";

export function cargaOpcionales (subirDoc, registraBitacora, folio, rut, antecedente, adicionales, usuario, docError, setDocError, sucursal){
    var reader = new FileReader();
    try{
      if(antecedente || (Array.isArray(adicionales) && adicionales.length > 0)){
        var array = adicionales;
        var objeto;
        if(antecedente){
          var temp = antecedente;
          objeto = temp.shift();
        } else {
          objeto = array.shift();
        }
          reader.readAsDataURL(objeto.file);
          reader.onload = function() {
            var nombreDoc = antecedente ? "AntecedentePresentado_"+folio :"AntecedenteAdicional_"+folio;
            subirDoc({
              variables: {
                idProceso: "11",
                idTipoTrans: "2001",
                idTipoDoc: "2001",
                folioTrans: folio.toString(),
                fechaTrans: format(new Date(), "MM-dd-yyyy"),
                rutBenef: rut,
                folioDoc: folio.toString(),
                nombreDoc: nombreDoc,
                base64: reader.result.substr(`data:${objeto.file.type};base64,`.length),
              },
              onCompleted: (data)=>{
                var clasificacion = antecedente ? 4 : 5;
                registraBitacora({      
                  variables: {
                    folio_solicitud: folio,
                    rutPrestador: "",
                    razonSocial: "",
                    comentario: "",
                    createdby: usuario,
                    fechaCompromiso: null,
                    tipoDoc: 0,// no se cual tipo doc
                    documento: data.crearArchivo2.documentId,
                    clasificacion: clasificacion,
                    sucursal: sucursal
                  }, 
                  onCompleted: ()=>{
                    cargaOpcionales(
                      subirDoc, 
                      registraBitacora, 
                      folio, 
                      rut,
                      null,
                      adicionales, 
                      usuario, 
                      docError, 
                      setDocError,
                      sucursal);
                  },
                  onError: ()=>{
                    var temp = docError;
                    temp.push(objeto.file);
                    setDocError(temp);
                    cargaOpcionales(subirDoc, registraBitacora, folio,rut, null, adicionales, usuario, docError, setDocError, sucursal);
                  }     
                })
              },
              onError:()=>{
                var temp = docError;
                temp.push(objeto.file);
                setDocError(temp);
                cargaOpcionales(subirDoc, registraBitacora, folio, rut, null, adicionales, usuario, docError, setDocError, sucursal);
              }
            })          
          };
        }
    } catch (error) {
      console.log(error.message);
      setDocError({name: "Antecedentes/Antecedentes Opcionales"});
    }
  }