import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { CustomInputSelect, menuItemStyle, menuProps } from '../../../../components/select/styledSelect'
import Select from '@mui/material/Select';
import { useQuery } from "@apollo/client";
import { cargarData } from "../../../../utils/queries";

export function SelectOpciones({ tipo, setValue, disabled, error, value, inputName, id, placeholder, tipoCaso }) {
    const theme = useTheme();
    const { data, loading, error: errorOpt } = useQuery(cargarData, { 
        fetchPolicy: "cache-first",
        variables: { codigo: tipo } })

    if (loading) {
        return <MenuItem data-testid="loadingParametro">Cargando...</MenuItem>
    }
    if (errorOpt) {
        return <MenuItem data-testid="errorParametro">Error</MenuItem>
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Select
            displayEmpty
            sx={{ width: '100%' }}
            disabled={disabled}
            error={errorOpt || error || undefined}
            value={value || ""}
            onChange={handleChange}
            input={<CustomInputSelect />}
            MenuProps={menuProps}
            inputProps={{ 'aria-label': 'Without label', "data-testid": inputName, "id": id }}
        >
            <MenuItem disabled value={""} sx={menuItemStyle}>
                <em>{placeholder}</em>
            </MenuItem>
            {data.getParametro.map((dato, index) => {
                if (tipo === "AP" && tipoCaso === "CAEC") {
                    if (dato.PARA_VALOR !== 2002) {
                        return (
                            <MenuItem
                                key={index}
                                sx={menuItemStyle}
                                value={dato.PARA_VALOR}
                                style={{ fontWeight: theme.typography.fontWeightMedium }}
                            >
                                {dato.PARA_DESCRIP}
                            </MenuItem>
                        )
                    } else {
                        return null;
                    }
                } else {
                    return (
                        <MenuItem
                            key={index}
                            sx={menuItemStyle}
                            value={dato.PARA_VALOR}
                            style={{ fontWeight: theme.typography.fontWeightMedium }}
                        >
                            {dato.PARA_DESCRIP}
                        </MenuItem>
                    )
                }
            }
            )}

        </Select>
    );
}