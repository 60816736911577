import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import { ObtenerHistorial } from "../../../../utils/queries";
import { es } from 'date-fns/locale';
import { format } from "date-fns";
import { parseFecha } from "../../../../utils/fechaUtils";
import TextDialogSeguimiento from "./TextDialogSeguimiento";
import { NoResult } from "../../../../components/NoData/NoResults";
import Loader from "../../../../components/Skeleton/Loader";

const ModalSeguimiento = ({ folio, estado }) => {
    const [openSeguimiento, setOpenSeguimiento] = useState(false);

    const [getHistorial, { data, loading }] = useLazyQuery(ObtenerHistorial, {
        variables: {
            solicitud: folio.toString()
        },
        fetchPolicy: "network-only"
    });

    const cerrarModal = () => setOpenSeguimiento(false);

    return (<Grid item xs={5}>
        <SecondaryButton
            sx={{ height: "40px", width: "200px" }}
            disabled={estado === 1}
            data-testid="seguimientoBTN"
            onClick={()=>{
                getHistorial(); 
                setOpenSeguimiento(true);
            }}
        >
            Seguimiento del caso
        </SecondaryButton>
        <Dialog
            fullWidth
            className="dialogModalBig"
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={openSeguimiento}
            onClose={cerrarModal}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" sx={{ pb: "0" }} xs={12} textAlign="center" mt="26px" mb="16px">
                Seguimiento del caso
                <Grid
                    container
                >
                    <Grid item xs={12}>
                        <label className="subtitleModal">
                            Revisa todos los cambios que ha tenido el caso Nº {folio}
                        </label>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className="seguimiento">
            {loading ? <Grid textAlign="center"> <Loader /> </Grid>: 
                <Grid
                    container
                >
                    <Grid
                        sx={{ padding: "5px", margin: "0", width: "100%" }}
                        container
                        spacing={2}
                    >
                        {
                            data && data.getLogAcciones.length > 0 ?
                                data.getLogAcciones.map((log, index) =>
                                    <>
                                        <Grid item xs={1} textAlign="center">
                                            <span className={index === 0 ? "purpleDot" : "grayDot"}></span>
                                        </Grid>
                                        <Grid item xs={11} key={index}>
                                            <p className="titleCase">{TextDialogSeguimiento(log.accion)}</p>
                                            <p className="autorCase">{log.LoginAutorizacion} - {format(parseFecha(log.FechaIngreso), "cccc, d 'de' MMMM y, HH:mm", { locale: es })}</p>
                                            <p className="motiveCase">{log.descripcion}</p>
                                        </Grid>
                                    </>
                                )
                                : <NoResult titulo="No hay registros" subtitulo="Aún no hay cambios de estado en el caso" noDivider/>
                        }
                    </Grid>

                </Grid >
                }
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="cerrarBtn"
                        onClick={cerrarModal}
                    >
                        Cerrar
                    </SecondaryButton>
                </Grid>
            </DialogActions>
        </Dialog >
    </Grid >

    );
};

export default ModalSeguimiento;
