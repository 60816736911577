import React from "react";
import "../../../../../assets/Repositorio.css";
import { useLazyQuery } from "@apollo/client";
import { AsignaPrestadorProvider } from "./context/AsignaPrestadorContext";
import { cuentaprestxcan } from "../../../../../utils/queries";
import { DialogInicio } from "./components/DialogInicio";
import { BotonAsignar } from "./components/BotonAsignar";
import ModalFueraDeRed from "./components/ModalFueraDeRed";
import { BotonEditar } from "./components/BotonEditar";
import InicioCaec from "./components/InicioCaec";

const AsignaPrestador = ({
  useLazyQueryHook = useLazyQuery,
  deshabilitaInputs,
  data,
  isCaec,
  dataPrestadores,
  estadoSolicitud,
  isEditaPres,
  postAsignar,
  fechaInicio,
  fechaTermino,
  fechaAu,
  folio,
  EditaPresTrans,
  volver,
  setMensajeroData,
  refetch,
  sucursal
}) => {
  const tipoCaso = isCaec ? "CAEC" : "GES";
  const [getCuenta, { data: cuentaPres }] = useLazyQueryHook(cuentaprestxcan, {
    variables: { folio: folio, canastaAsignada: data ? parseInt(data.id) : 0 },
    fetchPolicy: "network-only",
  });
  const postguardar = () => {
    if (isEditaPres) {
      postAsignar();
    } else {
      const recargaPrest = document.getElementById("recargaPrest");
      const recargaCont = document.getElementById("recargaCont");
      setMensajeroData({
        abrir: true,
        mensaje: "El prestador fue establecido con éxito.",
        severidad: "success",
      });
      if (recargaPrest) {
        recargaPrest.click();
      }
      if (isCaec) {
        recargaCont.click();
      } else {
        refetch();
      }
    }
  }

  return (
    <AsignaPrestadorProvider>
      {isEditaPres ?
        <BotonEditar
          cuenta={getCuenta}
          fechaInicio={fechaInicio}
          fechaTermino={fechaTermino}
          EditaPresTrans={EditaPresTrans}
        />
        :
        isCaec ?
          <InicioCaec
            folio={folio}
            estado={estadoSolicitud}
            tipoCaso={tipoCaso}
            deshabilitaInputs={deshabilitaInputs}
          />
          :
          <BotonAsignar
            estadoSolicitud={estadoSolicitud}
            cuenta={getCuenta}
            canasta={data}
            deshabilitaInputs={deshabilitaInputs}
          />
      }

      {/* Modal Inicio GES */}
      <DialogInicio
        data={cuentaPres}
        canasta={data || null}
        estadoSolicitud={estadoSolicitud}
        folio={folio}
        tipoCaso={tipoCaso}
        isCaec={isCaec}
        prestadoresAsignados={dataPrestadores}
        isEditaPres={isEditaPres}
        postAsignar={postAsignar}
        setMensajeroData={setMensajeroData}
        refetch={refetch}
        volver={volver}
        fechaAutorizacionSolicitud={fechaAu}
        sucursal={sucursal}
        postguardar={postguardar}
      />

      {/* modal Fuera de red*/}
      <ModalFueraDeRed
        fechaAutorizacionSolicitud={fechaAu}
        estadoSolicitud={estadoSolicitud}
        tipoCaso={tipoCaso}
        fkFun={folio}
        canasta={data}
        sucursal={sucursal}
        isCaec={isCaec}
        postguardar={postguardar}
        setMensajeroData={setMensajeroData}
      />
    </AsignaPrestadorProvider>
  );
};

export default AsignaPrestador;
