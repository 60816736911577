import { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { filtrado } from "../../../../utils/queries";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MediumButton } from "../../../../components/Buttons/Botones";
import { defineEstado } from "../../../../utils/CamposUtils";
import { EnvioFkFun } from "../../../../utils/RedirigeCaso";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";
import { fechaHora } from "../../../../utils/fechaUtils";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { NoResult } from "../../../../components/NoData/NoResults";

export default function CasosPrevios({
    rutPaciente
}) {
    const navigate = useNavigate();
    const [paginaFiltroHistorial, setPaginaFiltroHistorial] = useState(0);
    const [lineasPorPagFiltroHistorial, setLineasPorPagFiltroHistorial] = useState(5);

    const { data, loading } = useQuery(filtrado, {
        fetchPolicy: "network-only",
        variables: {
            rutPaciente: rutPaciente,
            order: "fkFun",
            dir: "desc",
        }
    });
    if (loading) {
        return (
            <Grid item xs={12}>
                <SkeletonTable test="paciente" />
            </Grid>
        )
    }

    return (
        <Grid data-testid="casosPreviosTable" item xs={12}>
            {data && data.getSolicitudesGCbyparam.solicitudes.length > 0 ?
                <Fragment>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>N° de Caso</TableCell>
                                <TableCell>Tipo de Caso</TableCell>
                                <TableCell>Diagnóstico</TableCell>
                                <TableCell>Fecha de solicitud</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Gestionar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.getSolicitudesGCbyparam.solicitudes
                                .slice(
                                    paginaFiltroHistorial * lineasPorPagFiltroHistorial,
                                    paginaFiltroHistorial *
                                    lineasPorPagFiltroHistorial +
                                    lineasPorPagFiltroHistorial
                                )
                                .map((caso, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            {caso.fkFun}
                                        </TableCell>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            {caso.tipoCaso}
                                        </TableCell>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            {caso.tipoCaso === "GES" && caso.nombrepat
                                                ? verificaLargo(
                                                    caso.fkProblemaSalud +
                                                    " - " +
                                                    caso.nombrepat.GlosaSis, 37
                                                )
                                                : verificaLargo(caso.diagnosticoCaec, 37)}
                                        </TableCell>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            {caso.fechaRecepcionSolicitud ? fechaHora(caso.fechaRecepcionSolicitud) : null}
                                        </TableCell>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            {defineEstado(caso.fkEstadoSolicitudIsapre)}
                                        </TableCell>
                                        <TableCell
                                            className=""
                                            align="left"
                                            padding="normal"
                                        >
                                            <MediumButton
                                                data-testid="botonIrCaso"
                                                onClick={() => EnvioFkFun(caso, navigate)}
                                            >
                                                Ir al Caso
                                            </MediumButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Grid alignItems="center">
                        <Paginador
                            opciones={[5, 10, 15]}
                            data={data.getSolicitudesGCbyparam.total}
                            itemPorPagina={lineasPorPagFiltroHistorial}
                            pagina={paginaFiltroHistorial}
                            setPagina={setPaginaFiltroHistorial}
                            setLineasPagina={setLineasPorPagFiltroHistorial}
                        />
                    </Grid>
                </Fragment> :
                <NoResult titulo="Este paciente no tiene historial de casos" subtitulo=""/>
            }
        </Grid>
    );
}