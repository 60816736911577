import { useState } from "react";
import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { TableBodyLoader } from "../../../../components/Skeleton/SkeletonTableWithExport";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import BuscarLEP from "./BuscarLEP";
import { ChipsLEP } from "./ChipsLEP";
import ExportarLEP from "./ExportarLEP";
import { MenuLEP } from "./MenuLEP";
import { NoResult } from "../../../../components/NoData/NoResults";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";

const TagsLEP = ({ estado }) => {
    switch (true) {
        case estado === "Vigente":
            return <label className="tagEstadoCasos tagIngresado">Vigente</label>
        case estado === "No Vigente":
            return <label className="tagEstadoCasos tagRevision">No Vigente</label>
        case estado === "INACTIVE":
            return <label className="tagEstadoCasos tagRechazado">Cerrado</label>
        default:
            return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
}

export default function TablaRegistrosLEP({ data, error, filtros, setFiltros, loading, setSnackData, refetch, isEliminado, rol, usuario, sucursal }) {
    const [modalBuscar, setModalBuscar] = useState(false);
    const cabeceraLEP = [
        { id: "CodPatologia", label: "Patología GES" },
        { id: "codNomCanasta", label: "Canasta Esencial" },
        { id: "codigoGlosa", label: "Prestación", width: "180px" },
        { id: "pabellon", label: "Pabellón" },
        { id: "prestacion", label: "Tipo de Prestación" },
        { id: "AH", label: "A/H" },
        { id: "copago", label: "Copago GES" },
        { id: "periodicidad", label: "Periodicidad & Tiempo" },
        { id: "arancel", label: "Arancel" },
        { id: "codPrest", label: "Código Prest. Minsal" },
        { id: "prestAdicional", label: "Prest. Adicional Esencial" },
        { id: "arancelMinsal", label: "Arancel MINSAL" },
        {
            id: "estado", label: "Estado", sticky: true,
            stickyRight: true
        },
    ];

    return (
        <div className="container">
            <BuscarLEP onOpen={modalBuscar} onClose={() => setModalBuscar(false)} isEliminado={isEliminado} filtros={filtros} setFiltros={setFiltros} />
            <Grid container p="14px 24px 24px 24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">
                        <label className="titulo2" style={{ paddingTop: "10px" }}>Listado específico de prestaciones</label>{" "}
                        <Stack direction={"row"} >
                            <div style={{ paddingTop: "10px" }}>
                                <OutlinedButton2
                                    className="noStyle"
                                    style={{ border: "none" }}
                                    disabled={loading}
                                    onClick={() => setModalBuscar(true)}
                                >
                                    Ver filtros
                                </OutlinedButton2>
                            </div>
                            <ExportarLEP filtros={filtros} setSnackData={setSnackData} loadingTabla={loading} />
                        </Stack>
                    </Stack>
                </Grid>
                <ChipsLEP filtros={filtros} setFiltros={setFiltros} />
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {loading ? <TableBodyLoader largo={(filtros.limit + 1)} isPS ancho={11} sticky /> :
                        <>{data && data.getLepByFilter.totalLEP > 0 ?
                            <>
                                <CabeceraTabla
                                    cabecera={cabeceraLEP}
                                />
                                <TableBody>
                                    {data.getLepByFilter.LEP.map((prest) =>
                                        <TableRow
                                            key={prest.id}
                                        >
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%", minWidth: "200px" }}
                                                align="left"
                                            >
                                                <label className="labelnroCaso">
                                                    {prest.problemaSalud}
                                                </label>
                                                <label className="labeltipoCaso">{verificaLargo(prest.GlosaSis || "", 20)}</label>
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%", minWidth: "200px" }}
                                                align="left"
                                            >
                                                <label className="labelnroCaso">
                                                    {prest.canasta}
                                                </label>
                                                <label className="labeltipoCaso">{verificaLargo(prest.descCanasta || "", 20)}</label>
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%", minWidth: "200px" }}
                                                align="left"
                                            >
                                                <label className="labelnroCaso">
                                                    {prest.prestacion}
                                                </label>
                                                <label className="labeltipoCaso">{verificaLargo(prest.descripcion || "", 20)}</label>
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                {prest.der_Pab || "-"}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                {prest.tipoPrestacion}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                {prest.AH}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                ${prest.copagoGes || 0}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                <label className="labelnroCaso">
                                                    {prest.periodicidad}
                                                </label>
                                                <label className="labeltipoCaso">{prest.tPeriodicidad}</label>
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%" }}
                                                align="left"
                                            >
                                                {prest.decreto}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%" }}
                                                align="left"
                                            >
                                                {prest.codPrestacionMinsal}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%" }}
                                                align="left"
                                            >
                                                {prest.presAdicionalEsencial}
                                            </TableCell>
                                            <TableCell
                                                className="fila"
                                                sx={{ width: "15%" }}
                                                align="left"
                                            >
                                                {prest.arancelMinsal}
                                            </TableCell>
                                            <TableCell
                                                className="tableBodyAsignar"
                                                sx={{ width: "15%" }}
                                                align="left"
                                            >
                                                <TagsLEP estado={isEliminado ? "Eliminado" : prest.estado} />
                                            </TableCell>
                                            <TableCell
                                                className="BodyAcciones"
                                                sx={{ width: "5%" }}
                                                align="left"
                                            >
                                                <MenuLEP 
                                                    disabled={loading} 
                                                    isEliminado={isEliminado}
                                                    lep={prest} 
                                                    setSnackData={setSnackData}
                                                    refetch={refetch}
                                                    sucursal={sucursal}
                                                    usuario={usuario}
                                                    rol={rol}
                                                />
                                            </TableCell>
                                        </TableRow>)}
                                </TableBody>
                            </>
                            : null}</>
                    }
                </Table>
            </TableContainer>
            {!loading && (!data || !data.getLepByFilter.totalLEP || parseInt(data.getLepByFilter.totalLEP) === 0) ?
                <NoResult
                    titulo={error ? "No se pudo cargar la tabla" : "Sin resultados"}
                    subtitulo={error ? "No fue posible mostrar la información debido a un problema de conexión. Intenta recargar la página para solucionar el problema" :"No se encontraron resultados para esta tabla"}
                    noDivider
                    error={error}
                />
                : null}
            <Grid alignItems="center" pb="15px" pt="24px">
                {loading ? null :
                    <Paginador
                        opciones={[10, 20, 30]}
                        data={(data && parseInt(data.getLepByFilter.totalLEP)) || 0}
                        pagina={filtros.pag}
                        setPagina={(v) => setFiltros({ ...filtros, pag: v })}
                        itemPorPagina={filtros.limit}
                        original={filtros}
                        cambioLineasPag={setFiltros}
                    />}
            </Grid>
        </div>
    );
}
