import {
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    CircularProgress,
    Typography
} from "@mui/material"
import "../../assets/Modales.css";

export function Subida({ modal }) {
    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            data-testid="dialogModal"
            open={modal}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },

            }}
        >
            <DialogContent sx={{ padding: "0px" }}>
                <DialogContentText
                    alignContent="center"
                    className="textoSubiendoDocs"
                    sx={{ m: 1, textAlign: "center" }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ m: 1, textAlign: "center", padding: "0", height: "300px" }}
                    >
                        <CircularProgress className="spinnerGreen"></CircularProgress>
                        <Typography className="contentDialogLoading">
                            Cargando Archivo
                        </Typography>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}