import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";


const ITEM_HEIGHT = 48;
export const menuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4,
            borderRadius: 8,
        },
        sx: {
            '&.MuiPaper-root::-webkit-scrollbar': {
                width: '5px  !important'
            },
            '&.MuiPaper-root::-webkit-scrollbar-track': {
                background: 'white  !important',
                borderRadius: '100px  !important',
                margin: '10px  !important',
            },
            '&.MuiPaper-root::-webkit-scrollbar-thumb': {
                background: '#BCC2C6  !important',
                borderRadius: '100px  !important',
            },
        }
    },
};

export const menuItemStyle = {
    fontWeight: '400 !important',
    letterSpacing: '0.0120588em !important',
    color: '#364855 !important',
    borderBottom: '1px solid #E9EBEC !important',
    height: '48px !important',
    paddingLeft: '16px !important',
    '&:focus': {
        backgroundColor: '#E6F6F5 !important'
    },
    '&:active': {
        backgroundColor: '#E6F6F5 !important'
    },
    '&:target': {
        backgroundColor: '#E6F6F5 !important'
    }
}

export const CustomInputSelect = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        width: '100%  !important',
        backgroundColor: '#FFFFFF',
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '15px 0px 16px 16px  !important',
        paddingRight: '24px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none  !important',
        letterSpacing: '0.0120588em  !important',
        '&:focus': {
            borderRadius: 8,
            borderColor: '#5D5FEF',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',ced4da
        },
        '&:error': {
            borderRadius: 8,
            color: '#ced4da',
            borderColor: '#ced4da',
        },
    }
}));

export const CustomInputSelectActions = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        width: '100%',
        backgroundColor: "#00837A",
        border: '1px solid #00837A',
        fontSize: 16,
        color: '#FFFFFF',
        padding: '15px 0px 16px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderRadius: 8,
            borderColor: '#5D5FEF',
        }
    }
}));