import { useState } from "react";
import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { TableBodyLoader } from "../../../../components/Skeleton/SkeletonTableWithExport";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import BuscarC from "./BuscarC";
import { ChipsC } from "./ChipsC";
import ExportarC from "./ExportarC";
import { MenuC } from "./MenuC";
import { NoResult } from "../../../../components/NoData/NoResults";

const TagsC = ({ estado }) => {
    switch (true) {
        case estado === "Vigente":
            return <label className="tagEstadoCasos tagIngresado">Vigente</label>
        case estado === "No Vigente":
            return <label className="tagEstadoCasos tagRevision">No Vigente</label>
        case estado === "INACTIVE":
            return <label className="tagEstadoCasos tagRechazado">Cerrado</label>
        default:
            return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
}

export default function TablaRegistroCanastas({ data, filtros, setFiltros, loading, setSnackData, refetch, rol, usuario, sucursal }) {
    const [modalBuscar, setModalBuscar] = useState(false);
    const cabeceraC = [
        { id: "intervencionSanitaria", label: "Intervención Sanitaria" },
        { id: "problemaSalud", label: "Problema de Salud" },
        { id: "codigoDescripcionIsapre", label: "Código y Descripción Ministerial Isapre", width: "180px" },
        { id: "codigoDescripcionMinisterial", label: "Código y Descripción Ministerial" },
        { id: "arancel", label: "Arancel", noActions: true },
        {
            id: "estado", label: "Estado", sticky: true,
            stickyRight: true
        },
        { id: "acciones", label: "Acciones" },
    ];

    return (
        <div className="container">
            <BuscarC />
            <Grid container p="24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">
                        <label className="titulo2" style={{ paddingTop: "10px" }}>Registro de Canastas</label>{" "}
                        <Stack direction={"row"} >
                            <div style={{ paddingTop: "10px" }}>
                                <OutlinedButton2
                                    className="noStyle"
                                    style={{ border: "none" }}
                                    onClick={() => setModalBuscar(true)}
                                >
                                    Ver filtros
                                </OutlinedButton2>
                            </div>
                            <ExportarC filtros={filtros} setSnackData={setSnackData} loadingTabla={loading} />
                        </Stack>
                    </Stack>
                </Grid>
                <ChipsC filtros={filtros} setFiltros={setFiltros} />
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                {/* <NoResult titulo="Sin Resultados" subtitulo="No se encontraron registros para esta tabla" noDivider search/> */}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                    <CabeceraTabla
                        cabecera={cabeceraC}
                    />

                    <TableBody>

                        <TableRow
                            data-testid="withData"
                        >
                            <TableCell
                                className="fila"
                                sx={{ width: "15%" }}
                                align="left"
                            >
                                Tratamiento
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "15%" }}
                                align="left"
                            >
                                Problema
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "5%" }}
                                align="left"
                            >
                                <label className="labelnroCaso">
                                    204539845324
                                </label>
                                <label className="labeltipoCaso">Descripción</label>
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "5%" }}
                                align="left"
                            >
                                <label className="labelnroCaso">
                                    684685
                                </label>
                                <label className="labeltipoCaso">Descripción ministerial</label>
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "15%" }}
                                align="left"
                            >
                                2022
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "15%" }}
                                align="left"
                            >
                                <TagsC estado="a" />
                            </TableCell>
                            <TableCell
                                className="fila"
                                sx={{ width: "5%" }}
                                align="left"
                            >
                                <MenuC

                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid alignItems="center" pb="15px" pt="24px">
                <Paginador
                    opciones={[5, 10, 15]}
                    itemPorPagina={10}
                />
            </Grid>
        </div>
    );
}