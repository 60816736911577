import { useMutation } from "@apollo/client";
import { cargararchivo2, guardaform } from "../../../utils/queries";
import { IconSubir } from '../../../components/icons/iconosEsencial';
import { Fragment, useState } from "react";
import { ModalDrag } from "../../../components/DragDrop/ModalDrag";
import { Subida } from "../../../components/Dialog/Subida";
import { IconOutlined } from "../../../components/Buttons/Botones";
import { format } from "date-fns";

export function SubirDocForm(props) {
    const [archivo, setArchivo] = useState(undefined);
    const [modal, setModal] = useState(false);
    const [modalCarga, setModalCarga] = useState(false);

    const [guardarDB] = useMutation(
        guardaform, {
        onCompleted: () => {
            setModalCarga(false);
            setArchivo(undefined);
            props.setMensajero({
                abrir: true,
                mensaje: "Tu archivo se cargó con éxito",
                severidad: "success",
            })
            props.upd();
        },
        onError: (e) => {
            console.log(e.message)
            setModalCarga(false);
            setArchivo(undefined);
            props.setMensajero({
                abrir: true,
                mensaje: "No se pudo cargar tu archivo, intentalo nuevamente",
                severidad: "error",
            })
        }
    });

    const [subirForm] = useMutation(
        cargararchivo2, {
        onCompleted: (data) => {
            guardarDB({
                variables: {
                    link: data.crearArchivo2.documentId,
                    tipoDoc: props.doc,
                    folio: props.folio,
                },
            })
        },
        onError: (e) => {
            console.log(e.message)
            setModalCarga(false);
            props.setMensajero({
                abrir: true,
                mensaje: "No se pudo cargar tu archivo, intentalo nuevamente",
                severidad: "error",
            })
        }
    });

    function loadToGD() {
        setModal(false);
        setModalCarga(true);
        var fileRead = new FileReader();
        fileRead.readAsDataURL(archivo[0].file);
        fileRead.onload = ({ target }) => {
            subirForm({
                variables: {
                    idProceso: "11",
                    idTipoTrans: "2001",
                    idTipoDoc: "2001",
                    folioTrans: props.folio.toString(),
                    fechaTrans: format(new Date(), "MM-dd-yyyy"),
                    rutBenef: props.rut,
                    folioDoc: props.folio.toString(),
                    nombreDoc: archivo[0].file.name,
                    base64: target.result.substr(`data:${archivo[0].file.type};base64,`.length),
                },
            })
        }
    }
    return (
        <Fragment>
            <ModalDrag
                modal={modal}
                setModal={setModal}
                files={archivo}
                setFiles={setArchivo}
                next={loadToGD}
                maxFiles={1}
            />
            <Subida modal={modalCarga} />
            {props.disabled ?
                <IconSubir sx={{ opacity: ".5" }} data-testid="subirDisabled" /> :
                <IconOutlined
                    onClick={() => setModal(true)}
                    data-testid="botonModalDrag"
                >
                    <IconSubir />
                </IconOutlined>
            }
        </Fragment>
    )
}