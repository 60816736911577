import { useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/App.css";
import { consultabeneficiarios } from "../../../utils/queries";
import {
  formatRut,
  validRut
} from "chilean-rutify";
import ThemeTextField from "../../../components/Textfield/ThemeTextField";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import {
  Divider,
  FormControl,
  Grid,
  Tabs,
  Tab
} from "@mui/material";
import PrimaryButton from "../../../components/Buttons/Botones";
import { Box } from "@mui/system";
import ResultadoTablas from "./components/ResultadoTablas";
import { useLazyQuery } from "@apollo/client";
import { Bonificaciones } from "./components/Bonificaciones";
import { ExportarBonificaciones } from "./components/ExportarBonificaciones";
import CasosPrevios from "./components/CasosPrevios";
import { TabPanel } from "../../../components/TabPanel/TabPanel";
import Aviso from "../../../components/Alert/Aviso";
import { isContralor } from "../../../utils/hasPermisions";

export function Paciente({ rol }) {
  const [campos, setCampos] = useState({});
  const [error, setError] = useState(null);
  const [rutError, setRutError] = useState(false);
  const [valorTab, setvalorTab] = useState(0);
  const [sinDatos, setSinDatos] = useState(true);
  const [sinBonos, setSinBonos] = useState(true);
  const [casoInvalido, setCasoInvalido] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [severidad, setSeveridad] = useState("info");

  const [buscarPaciente, { loading }] = useLazyQuery(
    consultabeneficiarios,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => cargaDatos(data),
      onError: (err) => {
        console.log(err.message);
        setError("El servicio no está disponible");
        setSeveridad("error");
        setAviso(true);
      }
    }
  );

  function detectaCambio(e) {
    let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
    rut = rut.length > 2 ? formatRut(rut).toUpperCase() : rut;
    setSinDatos(true);
    setCampos({
      rutPaciente: rut,
    });
    setError(null);
    if (rut && !validRut(rut)) {
      setRutError(true);
    } else {
      setRutError(false);
    }
    setAviso(false);
  }

  function cargaDatos(data) {
    if (
      Array.isArray(data.getBeneficiariosbyRut) &&
      data.getBeneficiariosbyRut.length > 0 &&
      data.getBeneficiariosbyRut !== null
    ) {
      let ben = data.getBeneficiariosbyRut;
      const pac = ben.find((ben) => ben.rut === campos.rutPaciente.replace(/[^0-9kK-]/g, ""));
      if (pac) {
        setCampos({
          ...campos,
          nombrePaciente:
            pac.primerNombre +
            (pac.segundoNombre ? " " + pac.segundoNombre : ""),
          apellido1Paciente: pac.primerApellido,
          apellido2Paciente: pac.segundoApellido,
          fechaNac: pac.fechaNacimiento,
          genero: pac.genero,
        });
        setError(null);
        setSinDatos(false);
        setCasoInvalido(false);
      } else {
        setError("RUT ya no existe en servicio beneficiario");
        setSeveridad("error");
        setAviso(true);
        setSinDatos(false);
        setCasoInvalido(true);
      }
    }
    if (Array.isArray(data.getBeneficiariosbyRut) && data.getBeneficiariosbyRut.length === 0) {
      setError("RUT no existe en servicio beneficiario");
      setSeveridad("error");
      setAviso(true);
    }
    if (data.getBeneficiariosbyRut === null && error === null) {
      setError("RUT no posee una póliza vigente");
      setSeveridad("warning");
      setAviso(true);
      setCasoInvalido(true);
      setSinDatos(false);
    }
  }

  function Getbeneficiarios() {
    setSinBonos(true)
    if (validRut(campos.rutPaciente)) {
      buscarPaciente({
        variables: {
          rut: campos.rutPaciente.replace(/[^0-9kK-]/g, ""),
        },
      });
    } else {
      setRutError(true);
    }
  }

  function checkStatus(event) {
    sessionStorage.removeItem("fkFun");
    if (sinDatos || casoInvalido || isContralor(rol)) {
      event.preventDefault();
    }
  }

  return (
    <div data-testid="divPaciente">
      <div className="container Main">
        <label className="titulo1">Búsqueda de beneficiarios</label>
      </div>
      <div className="container">
        <div className="card-box">
          <Grid
            sx={{ padding: "0 20px 20px 10px", margin: "0", width: "100%" }}
            container
            spacing={4}
          >
            <Grid item xs={4}>
              <label className="labelForm">RUT del paciente</label>
              <FormControl
                sx={{ width: "98%!important", margin: "0 1% 0 0" }}
              >
                <ThemeTextField
                  fullWidth
                  placeholder="Ej: 11.111.111-1"
                  data-testid="formInputRut"
                  id="rutPaciente"
                  disabled={loading}
                  error={rutError ? true : undefined}
                  inputProps={{ maxLength: 12, style: { textTransform: "uppercase" } }}
                  onChange={detectaCambio}
                  value={campos.rutPaciente || ""}
                />
                <span className="errorSpan">{rutError ? "Ingrese un RUT válido" : ""}</span>
              </FormControl>
            </Grid>
            <Grid item xs={"auto"}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <BotonCarga
                  testid="formButtonSubmit"
                  sx={{
                    margin: "30px 0 0",
                    width: "218px",
                    height: "55px",
                  }}
                  label="Buscar paciente"
                  toDo={Getbeneficiarios}
                  loading={loading}
                  variant='secondary'
                />
              </Grid>
            </Grid>
            <Grid item xs={1} hidden={sinDatos || casoInvalido}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box width="1px" sx={{ height: "84px" }}>
                  <Divider orientation="vertical" />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={"auto"} container direction="column" hidden={sinDatos || casoInvalido}>
              <Grid sx={{ padding: "16px 0 0" }} container spacing={2}>
                <label className="labelForm">Nombre del paciente</label>
              </Grid>
              <Grid sx={{ padding: "27px 0 0" }} container spacing={2}>
                <label className="tituloGris">
                  {campos.nombrePaciente +
                    " " +
                    campos.apellido1Paciente +
                    " " +
                    campos.apellido2Paciente}
                </label>
              </Grid>
            </Grid>
            <Grid xs={12} sx={{ paddingLeft: "32px" }}>
              <Aviso
                mensaje={error || ""}
                open={aviso}
                setOpen={setAviso}
                severidad={severidad}
                noClose
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="container TabsBack">
        <Box
          className="nav"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            padding: "26px 0 0 0",
          }}
        >
          <Tabs
            value={valorTab}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { background: "#00837A" } }}
            onChange={(event, value) => setvalorTab(value)}
          >
            <Tab label="Casos del paciente" />
            <Tab label="Bonificaciones" />
          </Tabs>
        </Box>
      </div>
      <div className="container">
        <div className="card-box">
          <TabPanel value={valorTab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  sx={{ padding: "20px 30px 0", margin: "0", width: "100%" }}
                >
                  <label className="title">Casos del paciente</label>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="end" alignItems="center">
                  <Link
                    disabled={sinDatos || casoInvalido || isContralor(rol)}
                    id="enlace"
                    data-testid="linkToFormIngreso"
                    key={"enlace"}
                    state={{ campos }}
                    to={"/FormularioIngreso"}
                    onClick={checkStatus}
                  >
                    <PrimaryButton
                      sx={{
                        margin: "10px 20px",
                      }}
                      data-testid="IngresaSolButton"
                      disabled={sinDatos || casoInvalido || isContralor(rol)}
                    >
                      Ingresar solicitud
                    </PrimaryButton>
                  </Link>
                </Grid>
              </Grid>
              {sinDatos ? <ResultadoTablas /> :
                <CasosPrevios rutPaciente={campos.rutPaciente?.replace(/[^0-9kK-]/g, "")} />}
            </Grid>
          </TabPanel>
          <TabPanel value={valorTab} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  sx={{ padding: "20px 30px 0", margin: "0", width: "100%" }}
                >
                  <label className="title">Bonificaciones</label>
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{ padding: "0 0 6px!important" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  sx={{ padding: "20px 20px 0", margin: "0", width: "100%" }}
                >
                  <ExportarBonificaciones rut={campos.rutPaciente?.replace(/[^0-9kK-]/g, "")} bonos={sinBonos} />
                </Grid>
              </Grid>
              {sinDatos ? <ResultadoTablas /> :
                <Bonificaciones rut={campos.rutPaciente?.replace(/[^0-9kK-]/g, "")} setSinBonos={setSinBonos} />}
            </Grid>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
export default Paciente;
