import { useState } from "react";
import {
  FormControl,
  Grid
} from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { saveAs } from "file-saver";
import { getGop } from "../../../utils/queries";
import { getYear, getMonth, getDate, format } from "date-fns";
import { Patologias } from "../../../utils/patologias";
import { GetSGCEstados } from "../../../utils/SGCEstados";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { SecondaryButton } from "../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";

export function TablaGOP() {
  const [patologia, setPatologia] = useState(null);
  const [estadoCaso, setEstadoCaso] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaTermino, setFechaTermino] = useState(null);
  const [exceptuar, setExceptuar] = useState(null);
  const [estadoHito, setEstadoHito] = useState(null);
  const [loading, setLoading] = useState(false);

  const [getDatosGOP] = useLazyQuery(getGop);

  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });

  const clearFilters = () => {
    setPatologia(null);
    setEstadoCaso(null);
    setFechaInicio(null);
    setFechaTermino(null);
    setExceptuar(null);
    setEstadoHito(null);
  }

  function MuestraMensaje(mensaje, severidad) {
    setSnackData({
      abrir: true,
      mensaje: mensaje,
      severidad: severidad,
    });
  }

  function DescargaArchivo() {
    MuestraMensaje("Se está generando el reporte, por favor espere", "info");
    getDatosGOP({
      variables: {
        patologia: patologia,
        estado: estadoCaso,
        fechaInicio: fechaInicio
          ? getYear(fechaInicio) +
          "-" +
          (getMonth(fechaInicio) + 1) +
          "-" +
          getDate(fechaInicio)
          : null,
        fechaTermino: fechaTermino
          ? getYear(fechaTermino) +
          "-" +
          (getMonth(fechaTermino) + 1) +
          "-" +
          getDate(fechaTermino)
          : null,
        exceptuar: exceptuar,
        estadoHito: estadoHito || null
      },
      fetchPolicy: "network",
      onCompleted: (data) => {
        if (data.getReporteGOP.error !== "Sin datos para el reporte") {
          let base64SinForm = data.getReporteGOP.data;
          let base64ConForm = base64SinForm.replace(/['"]+/g, "");
          let nombArchivoConForm = "REPORTE_GOP_" + format(new Date(), "dd_MM_yyyy") + ".XLSX";
          base64ConForm = atob(base64ConForm);
          let length = base64ConForm.length;
          let out = new Uint8Array(length);
          var blob;
          while (length--) {
            out[length] = base64ConForm.charCodeAt(length);
          }
          blob = new Blob([out], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, nombArchivoConForm);
          MuestraMensaje("Reporte generado, revise descargas", "success");
          setLoading(false);
        } else {
          MuestraMensaje(
            "Reporte no generado, sin datos para generar",
            "error"
          );
          setLoading(false);
        }
      },
      onError: (error) => {
        console.log(error.message);
        MuestraMensaje("Reporte no generado, intente nuevamente", "error");
        setLoading(false);
      }
    })
  }
  const exOptions = [{ label: "Todos", value: "" }, { label: "Sí", value: "true" }, { label: "No", value: "false" }];
  const estadoOptions = [{ label: "Todos", value: "" },
  { label: "Cierre en plazo", value: 1 },
  { label: "Cierre fuera de plazo", value: 2 },
  { label: "Abierto fuera plazo", value: 3 },
  { label: "Abierto en Proceso", value: 4 }];
  return (
    <div className="container" data-testid="tablaGop">
      <Mensajero data={snackData} setState={setSnackData} />
      <Grid
        className="noMargin"
        sx={{ padding: "5px 15px", margin: "0", width: "100%" }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <h3 className="title">Reportes</h3>
          <label>Aquí puedes consultar por diferentes tipos de reportes</label>
        </Grid>
        <Grid item xs={6}>
          <label className="labelForm">Patología</label>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <Patologias
              value={patologia || ""}
              setValue={(temp) =>
                setPatologia(temp ? temp.Codigo : null)
              }
              filter
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label className="labelForm">Estado del caso</label>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <GetSGCEstados
              value={estadoCaso || ""}
              setValue={setEstadoCaso}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label className="labelForm">Fecha de inicio</label>
          <DatePicker
            id="fecha"
            testid="fecha"
            maxDate={fechaTermino}
            value={fechaInicio}
            onChange={(newValue) => {
              setFechaInicio(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <label className="labelForm">Fecha de término</label>
          <DatePicker
            id="fechaTermino"
            testid="fechaTermino"
            minDate={fechaInicio}
            value={fechaTermino}
            onChange={(newValue) => {
              setFechaTermino(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <label className="labelForm">Excepción</label>
            <SelectPersonalizado
              id="exceptuar"
              inputTest="exceptuar"
              value={exceptuar}
              setValue={(val) => setExceptuar(val)}
              options={exOptions}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
            <label className="labelForm">Estado Hito</label>
            <SelectPersonalizado
              id="estadoHito"
              inputTest="estadoHito"
              value={estadoHito}
              setValue={(val) => setEstadoHito(val)}
              options={estadoOptions}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <SecondaryButton
              sx={{ margin: "5px 10px 20px!important" }}
              data-testid="limpiaFiltros"
              disabled={(!patologia && !estadoCaso && !fechaInicio && !fechaTermino && !exceptuar && !estadoHito) || loading}
              onClick={clearFilters}
            >
              Limpiar Filtros
            </SecondaryButton>
            <BotonCarga
              testid="descargaGOPTest"
              sx={{ margin: "5px 10px 20px!important" }}
              boxSX={{ '& > button': { m: 1 } }}
              label="Descargar reporte"
              toDo={DescargaArchivo}
              loading={loading}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}