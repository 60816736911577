import React, { useState } from "react";
import {
  canastasxPatologia,
  consultaCanastaasignadas,
} from "../../../../utils/queries.js";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/Table.css";
import { getComparator, stableSort } from "../../../../components/Table/utils";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador.jsx";
import { parseISO } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { NoResult } from "../../../../components/NoData/NoResults";
import { CanastaActiva } from "./components/CanastaActiva.jsx";
import { AsignaCanasta } from "./components/AsignaCanasta.jsx";

export function FiltroCanasta({
  useQueryHook = useQuery,
  useMsalHook = useMsal,
  setAlertData,
  limpiaFiltros,
  filtros,
  deshabilitaInputs,
  sucursal,
  ...props
}) {
  const patologia = props.data;
  const folio = props.folio;
  const estado = props.estado;
  const autorizacion = parseISO(props.fechaAu);
  const [order, setOrder] = useState("desc");
  const [ordenarPorFiltro, setOrdenarPorFiltro] = useState("idDespliegue");
  const [paginaFiltro, setPaginaFiltro] = useState(0);
  const [lineasPorPagFiltro, setLineasPorPagFiltro] = useState(5);
  const [modalCanAsig, setModalCanAsig] = useState(false);
  const { accounts } = useMsalHook();
  const { data, loading, error } = useQueryHook(canastasxPatologia, {
    variables: {
      patologia: patologia,
      intervencion: filtros.intervencion,
      codigo: filtros.codigo,
      descripcion: filtros.descripcion,
      arancel: filtros.arancel
    },
    fetchPolicy: "cache-and-network",

  });
  const { data: canastasAsignadas, refetch } = useQueryHook(
    consultaCanastaasignadas,
    {
      variables: {
        folio: folio,
        order: "idDespliegue",
        dir: "ASC"
      },
      fetchPolicy: "cache-and-network",

    }
  );

  if (loading) {
    if (paginaFiltro !== 0) {
      setPaginaFiltro(0);
    }
    return <SkeletonTable test={"loading-filtro"} />
  }

  if (error) return <pre data-testid="errorFiltro">Error: {error}</pre>;

  const tableData = data.getCanastasbyPat;

  const cabeceraCanasta = [
    {
      id: "codigo",
      label: "Canasta",
    },
    {
      id: "descripcion",
      label: "Descripción",
    },
    {
      id: "vigenciaDesde",
      label: "Fecha Inicio",
    },
    {
      id: "vigenciaHasta",
      label: "Fecha Término",
    },
  ];

  const filasVaciasFiltro =
    paginaFiltro > 0
      ? Math.max(0, (1 + paginaFiltro) * lineasPorPagFiltro - tableData.length)
      : 0;

  return (
    <Box sx={{ width: "100%" }} data-testid="tablaCanastaContainer">
      {!tableData || tableData.length === 0 ?
        <NoResult
          titulo={"Sin Canastas"}
          subtitulo={"No se han encontrado canastas con los filtros seleccionados"}
        /> :
        <Paper sx={{ width: "100%", mb: 2 }}>
          <CanastaActiva modalCanAsig={modalCanAsig} setModalCanAsig={setModalCanAsig} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <CabeceraTabla
                order={order}
                orderBy={ordenarPorFiltro}
                setOrder={setOrder}
                setOrderBy={setOrdenarPorFiltro}
                cabecera={cabeceraCanasta}
                nonsticky={true}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(tableData, getComparator(order, ordenarPorFiltro))
                  .slice(
                    paginaFiltro * lineasPorPagFiltro,
                    paginaFiltro * lineasPorPagFiltro + lineasPorPagFiltro
                  )
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        className="headTable"
                        key={row.codigo}
                      >
                        <TableCell
                          component="th"
                          className="fila"
                          id={labelId}
                          scope="row"
                        >
                          {row.codigo}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.descripcion}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {/*fecha(row.vigenciaDesde)*/}
                        </TableCell>
                        <TableCell align="right" className="fila">
                          {/*fecha(row.vigenciaHasta)*/}
                        </TableCell>
                        <TableCell align="center" className="fila">
                          <AsignaCanasta
                            folio={folio}
                            accounts={accounts}
                            estado={estado}
                            setAlertData={setAlertData}
                            refetch={refetch}
                            canastasAsignadas={canastasAsignadas}
                            setModalCanAsig={setModalCanAsig}
                            row={row}
                            autorizacion={autorizacion}
                            limpiaFiltros={limpiaFiltros}
                            filtros={filtros}
                            deshabilitaInputs={deshabilitaInputs}
                            sucursal={sucursal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {filasVaciasFiltro > 0 && (
                  <TableRow
                    style={{
                      height: 33 * filasVaciasFiltro,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={tableData.length}
              itemPorPagina={lineasPorPagFiltro}
              pagina={paginaFiltro}
              setPagina={setPaginaFiltro}
              setLineasPagina={setLineasPorPagFiltro}
            />
          </Grid>
        </Paper>}
    </Box>
  );
}