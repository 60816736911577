import ContadorRed from "./ContadorRed";

const DentroDeRed = ({
  estado,
  data,
  handleNext,
  deshabilitaInputs
}) => {
  return (
    <div className="border text-center box-red" data-testid="containerDDR">
      <label className="titulo2 d-block">Dentro de la RED</label>

      {/* <ContadorEnRed/> */}
      <ContadorRed amount={data || 0} />
      <button
        data-testid="btnOpenModal"
        disabled={deshabilitaInputs || estado === 5 || estado === 6 || estado === 7 ? 1 : 0}
        className="btn btn-primary btn-lg"
        type="button"
        onClick={handleNext}
      >
        Buscar prestador
      </button>
    </div>
  );
};

export default DentroDeRed;
