import {
    Alert,
    Collapse,
    FormControl,
    Grid,
} from "@mui/material";
import { Localidad } from "../../../../components/Localidad/Localidad";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";
import {
    style,
    iconStyle,
} from "../../../../components/Snackbar/components/Styles";

export default function DatosBeneficiario({
    campos,
    setCampos,
    casoInvalido,
    isKinetycError,
    error,
    setError,
    detectaCambio
}) {
    function CambioRegion(regs) {
        setCampos({
            ...campos,
            region: regs.idReg,
            comuna: null
        });
        setError({
            ...error,
            region: null,
        });
    }
    function CambioComuna(datos) {
        setCampos({
            ...campos,
            comuna: datos.idCom,
            ciudadNombre: datos.nomCom,
        });
        setError({
            ...error,
            comuna: null,
        })
    }

    const AsignaNombre = (nombre) => {
        setCampos({
            ...campos,
            ciudadNombre: nombre
        });
    }
    return (
        <div className="card-box">
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <label className="title">Datos del beneficiario</label>
                </Grid>
                <Grid item xs={3}>
                    <label className="textLabel1">RUT:</label>
                    <label className="textLabel2" htmlFor="numeroCaso">
                        {campos.rutPaciente}
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="textLabel1">Nombres:</label>
                    <label className="textLabel2" htmlFor="fechaSolicitud">
                        {campos.nombrePaciente}
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="textLabel1">Primer apellido:</label>
                    <label className="textLabel2" htmlFor="tipoCaso">
                        {campos.apellido1Paciente}
                    </label>
                </Grid>
                <Grid item xs={3}>
                    <label className="textLabel1">Segundo apellido:</label>
                    <label className="textLabel2" htmlFor="">
                        {campos.apellido2Paciente}
                    </label>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={casoInvalido || isKinetycError} sx={{ width: "99.5%" }}>
                        <Alert
                            severity={isKinetycError? "error" : "info"}
                            sx={style(isKinetycError? "error" : "info")}
                            iconMapping={iconStyle(isKinetycError? "error" : "info")}
                        >
                            {isKinetycError ? "Servicio Beneficiarios no Responde" : "Beneficiario no vigente"}
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Localidad
                    disabled={casoInvalido}
                    region={campos.region}
                    regionError={error.region}
                    setRegion={CambioRegion}
                    comuna={campos.comuna}
                    comunaError={error.comuna}
                    setComuna={CambioComuna}
                    form1set={AsignaNombre}
                />
                <Grid item xs={8}>
                    <label className="labelForm">Domicilio</label>
                    <FormControl sx={{ width: "99%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="beneficiarioFormInputDomicilio"
                            error={error.dirPaciente ? true : undefined}
                            disabled={casoInvalido}
                            id="dirPaciente"
                            placeholder="Ej: Las gardenias 1156"
                            onChange={detectaCambio}
                            value={campos.dirPaciente || ""}
                        />
                        <span className="errorSpan">{error.dirPaciente}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Dpto/Block/Parcela/Otro</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            id="dir2Paciente"
                            data-testid="beneficiarioFormInputBlock"
                            placeholder="Ej: 000001001"
                            disabled={casoInvalido}
                            onChange={detectaCambio}
                            value={campos.dir2Paciente || ""}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <label className="labelForm">Correo electrónico</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="beneficiarioFormInputEmail"
                            name="correo"
                            id="emailPaciente"
                            disabled={casoInvalido}
                            error={error.emailPaciente ? true : undefined}
                            placeholder="Ej: ejemplo@correo.com"
                            onChange={detectaCambio}
                            value={campos.emailPaciente || ""}
                        />
                        <span className="errorSpan">{error.emailPaciente}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Teléfono particular</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="beneficiarioFormInputTelefono"
                            error={error.telPaciente ? true : undefined}
                            name="telefono"
                            id="telPaciente"
                            placeholder="Ej: 2 12345678"
                            disabled={casoInvalido}
                            onChange={detectaCambio}
                            value={campos.telPaciente || ""}
                            inputProps={{ maxLength: 9 }}
                        />
                        <span className="errorSpan">{error.telPaciente}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Teléfono celular</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="beneficiarioFormInputCelular"
                            error={error.celPaciente ? true : undefined}
                            name="telefono"
                            id="celPaciente"
                            placeholder="Ej: 9 12345678"
                            disabled={casoInvalido}
                            onChange={detectaCambio}
                            value={campos.celPaciente || ""}
                            inputProps={{ maxLength: 9 }}
                        />
                        <span className="errorSpan">{error.celPaciente}</span>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}