import { useState } from "react";
import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import ExportarPS from "./ExportarPS";
import { ChipsPS } from "./ChipsPS";
import { MenuPS } from "./MenuPS";
import { TableBodyLoader } from "../../../../components/Skeleton/SkeletonTableWithExport";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import BuscarPS from "./BuscarPS";
import { NoResult } from "../../../../components/NoData/NoResults";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";

const TagsPS = ({ estado }) => {
    switch (true) {
        case estado === "Vigente":
            return <label className="tagEstadoCasos tagIngresado">Vigente</label>
        case estado === "No Vigente":
            return <label className="tagEstadoCasos tagRevision">No Vigente</label>
        default:
            return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
}

export default function TablaProblemasSalud({ data, filtros, setFiltros, loading, setSnackData, refetch, rol, isEliminado, usuario, sucursal }) {
    const [modalBuscar, setModalBuscar] = useState(false);
    const cabeceraPS = [
        { id: "codigoPS", label: "Codigo PS" },
        { id: "arancel", label: "Arancel" },
        { id: "genero", label: "Genero" },
        { id: "estado", label: "Estado" },
        { id: "acciones", label: "Acciones", noActions: true },
    ];

    return (
        <div className="container">
            <BuscarPS onOpen={modalBuscar} onClose={() => setModalBuscar(false)} filtros={filtros} isEliminado={isEliminado} setFiltros={setFiltros} />
            <Grid container p="24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">
                        <label className="titulo2" style={{ paddingTop: "10px" }}>Lista de Problemas de Salud</label>{" "}
                        <Stack direction={"row"} >
                            <div style={{ paddingTop: "10px" }}>
                                <OutlinedButton2
                                    className="noStyle"
                                    style={{ border: "none" }}
                                    onClick={() => setModalBuscar(true)}
                                >
                                    Ver filtros
                                </OutlinedButton2>
                            </div>
                            <ExportarPS filtros={filtros} setSnackData={setSnackData} loadingTabla={loading} />
                        </Stack>
                    </Stack>
                </Grid>
                <ChipsPS filtros={filtros} setFiltros={setFiltros} />
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <CabeceraTabla
                        cabecera={cabeceraPS}
                    />
                    {loading ? <TableBodyLoader largo={filtros.limit} isPS /> :
                        <TableBody>
                            {data && data.getProblemasSaludXfilter.PS && data.getProblemasSaludXfilter.total > 0 ?
                                data.getProblemasSaludXfilter.PS.map((ps) =>
                                    <TableRow
                                        data-testid="withData"
                                    >
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            <label className="labelnroCaso">
                                                {ps.Codigo}
                                            </label>
                                            <label className="labeltipoCaso">{verificaLargo(ps.GlosaSis, 37)}</label>
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {ps.Arancel}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {ps.Genero}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            <TagsPS estado={isEliminado ? "ELIMINADO" :ps.estado} />
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "5%" }}
                                            align="left"
                                        >
                                            <MenuPS
                                                dataPS={ps}
                                                setSnackData={setSnackData}
                                                refetch={refetch}
                                                rol={rol}
                                                isEliminado={isEliminado}
                                                usuario={usuario}
                                                sucursal={sucursal}
                                            />
                                        </TableCell>
                                    </TableRow>) : null}
                        </TableBody>}
                </Table>
            </TableContainer>
            {!loading && (!data || !data.getProblemasSaludXfilter.PS || data.getProblemasSaludXfilter.total === 0) ?
                <NoResult titulo={"Sin resultados"} subtitulo={"No se encontraron resultados para esta tabla"} noDivider />
                : null}
            <Grid alignItems="center" pb="15px" pt="24px">
                {loading ? null :
                    <Paginador
                        opciones={[10, 20, 30]}
                        data={(data && parseInt(data.getProblemasSaludXfilter.total)) || 0}
                        pagina={filtros.pag}
                        setPagina={(v) => setFiltros({ ...filtros, pag: v })}
                        itemPorPagina={filtros.limit}
                        original={filtros}
                        cambioLineasPag={setFiltros}
                    />}
            </Grid>
        </div>
    );
}