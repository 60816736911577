import { useState } from "react";
import { defineEstado } from "../../../utils/CamposUtils";
import icon_info from "../../../components/icons/icon_info.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import PrimaryButton, {
  OutlinedButton2
} from "../../../components/Buttons/Botones";
import imgError from "../../../components/icons/Error.svg";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";
import { CambioEstadoCaso } from "./components/CambioEstadoCaso";
import { useGescaeinfoHook } from "./hooks/gescaeinfoHook";
import { useNavigate } from "react-router-dom";
import {
  IconArrowDown,
  IconArrowUp,
} from "../../../components/icons/iconosEsencial";
import { hasGEP, isContralor, hasJGESCAEC } from "../../../utils/hasPermisions";
import ModalSeguimiento from "./components/ModalSeguimiento";
import Aviso from "../../../components/Alert/Aviso";
import { fecha, fechaHora } from "../../../utils/fechaUtils";
import { formatRut } from "chilean-rutify";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import ModalPrexistencias from "./components/ModalPrexistencias";

export function DetaTabla({ rol, deshabilitaInputs, preexistencias, correo, sucursal, refetch, cargandoDatos, ...props }) {
  const navigate = useNavigate();
  const [acordeon, setAcordeon] = useState(false);
  const {
    selecOpt,
    tipoAccion,
    casoHito,
    campos,
    resolutor,
    modalAccion,
    setModalAccion,
    modalAviso,
    setModalAviso,
    modalCaso,
    setModalCaso,
    dataDuplicado,
    selection
  } = useGescaeinfoHook({ deshabilitaInputs, ...props }, navigate);

  let patologiaComp;
  let resolutorComp = resolutor;

  if (campos.tipoCaso === "GES") {
    patologiaComp = campos.fkProblemaSalud + " - " + campos.nombrepat.GlosaSis;
    resolutorComp = campos.nombrepat.Resolutor;

    if (resolutorComp === 1) {
      resolutorComp = "Agencia";
    } else {
      resolutorComp = "GEP";
    }
  } else {
    resolutorComp = "GEP";
    patologiaComp = campos.diagnosticoCaec;
  }

  let estado = defineEstado(campos.fkEstadoSolicitudIsapre);

  function OpcionesCaso() {
    const permisos = hasGEP(rol) || hasJGESCAEC(rol);
    var deshabilitado = false;
    let texto = "Acciones";
    const estadoSI = props.data.getSolicitudesGCbyFkfun[0].fkEstadoSolicitudIsapre;
    switch (props.data.getSolicitudesGCbyFkfun[0].fkEstadoSolicitudIsapre) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        texto = "Autorizado";
        deshabilitado = true;
        break;
      case 5:
        texto = "Rechazado";
        deshabilitado = true;
        break;
      case 6:
        texto = "Anulado";
        deshabilitado = true;
        break;
      case 7:
        texto = "Cerrado";
        deshabilitado = true;
        break;
      case 9:
        texto = "Re-abierto";
        deshabilitado = true;
        break;
      case 10:
          texto = "Cerrado por sistema";
          deshabilitado = true;
          break;
      default:
        break;
    }

    if (deshabilitaInputs) {
      deshabilitado = true;
    }

    let arrayOpciones = [
      { label: "Autorizar", value: "autorizar", disabled: deshabilitado },
      { label: "Rechazar", value: "rechazar", disabled: deshabilitado },
      { label: "Derivar a GEP", value: "derivar", disabled: true },
      {
        label: "Anular Caso",
        value: "anular",
        disabled:
          estadoSI === 1 && !deshabilitaInputs
            ? false
            : true,
      },
      {
        label: "Cerrar Caso",
        value: "cerrar",
        disabled:
          estadoSI === 4 || estadoSI === 9 ||
            (deshabilitaInputs && ![5, 6, 7].includes(estadoSI))
            ? false
            : true,
      },
      { label: "Re-abrir Caso", value: "reabrir", disabled: !(permisos && estadoSI === 7) },
    ];
    return (
      <SelectPersonalizado
        inputTest="setAcciones"
        value={selection}
        data-testid="selectOpciones"
        setValue={selecOpt}
        options={arrayOpciones}
        placeholder={texto}
        isAcciones={true}
        placeholderHidden={true}
        disabled={isContralor(rol) || cargandoDatos}
      />
    );
  }
  const MueveAcordeon = () => {
    setAcordeon(!acordeon);
  }

  return (
    <div data-testid="container">
      <button
        type="button"
        id="btnSinCompletar"
        data-testid="botonAccion"
        className="btn btn-primary btn-lg oculto"
        onClick={() => setModalAviso(true)}
      />
      <Grid item xs={12}>
        <br></br>
      </Grid>
      <div className="containerAccordion container">
        <Grid
          sx={{ padding: "15px", margin: "5px", width: "100%" }}
          container
          direction="row"
          spacing={2}
        >
          <Grid item xs={6} sx={{ marginTop: "15px" }}>
            <label className="titulo2">Detalles del caso</label>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="end">
              <FormControl
                data-testid="formControlOpcion"
                className="DropAcciones"
                sx={{ width: "160px!important", margin: "0 1% 1% 0" }}
              >
                <OpcionesCaso />
                <CambioEstadoCaso
                  folio={props.data.getSolicitudesGCbyFkfun[0].fkFun}
                  campos={campos}
                  dataDuplicado= {dataDuplicado}
                  modalCaso ={modalCaso}
                  setModalCaso = {setModalCaso}
                  tipoCaso={props.data.getSolicitudesGCbyFkfun[0].tipoCaso}
                  tipo={tipoAccion}
                  modal={modalAccion}
                  setModal={setModalAccion}
                  correo={correo}
                  sucursal={sucursal}
                  refetch={refetch}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Aviso
            open={deshabilitaInputs}
            noClose={true}
            severidad={"info"}
            sx={{ paddingTop: deshabilitaInputs ? "16px" : "0px" }}
            mensaje={"Beneficiario no vigente"}
          />
          <Grid item xs={3}>
            <label className="textLabel1">Nombre beneficiario</label>
            <label className="textLabel2" htmlFor="nombreBeneficiario">
              {campos.nombrePaciente +
                " " +
                campos.apellido1Paciente +
                " " +
                campos.apellido2Paciente}
            </label>
          </Grid>
          <Grid item xs={3}>
            <label className="textLabel1">RUT beneficiario</label>
            <label className="textLabel2" htmlFor="rutBeneficiario">
              {formatRut(campos.rutPaciente)}
            </label>
          </Grid>
          <Grid item xs={3}>
            <label className="textLabel1">Tipo de caso</label>
            <label className="textLabel2" htmlFor="tipoCaso">
              {campos.tipoCaso}
            </label>
          </Grid>
          <Grid item xs={3}>
            <label className="textLabel1">Estado</label>
            <label className="textLabel2" htmlFor="estado">
              {estado}
            </label>
          </Grid>
        </Grid>
        <Accordion
          expanded={acordeon}
          sx={{ borderRadius: "8px!important" }}
        >
          <AccordionSummary>
            <Grid
              sx={{ padding: "0 0 10px 0", margin: "0", width: "100%" }}
              container
              direction="row"
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center">
                  {!acordeon ?
                    <OutlinedButton2 onClick={MueveAcordeon}>
                      <IconArrowDown />
                      Ver más
                    </OutlinedButton2> :
                    <OutlinedButton2 onClick={MueveAcordeon}>
                      <IconArrowUp />
                      Ver menos
                    </OutlinedButton2>
                  }
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              sx={{ padding: "0", margin: "0", width: "100%" }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <label className="textLabel3">Otros Datos</label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">N° de caso</label>
                <label className="textLabel2" htmlFor="numeroCaso">
                  {campos.fkFun}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Fecha de solicitud</label>
                <label className="textLabel2" htmlFor="fechaSolicitud">
                  {fechaHora(campos.fechaRecepcionSolicitud)}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">
                  Inicio de vigencia beneficiario
                </label>
                <label className={campos.fVigenciaBeneficiario ? "textLabel2" : "textLabel2 textEmpty"} htmlFor="vigenciaBeneficiario">
                  {campos.fVigenciaBeneficiario ? fecha(campos.fVigenciaBeneficiario) : "No disponible"}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">
                  Plan
                  <img
                    src={icon_info}
                    alt=""
                    style={{
                      height: "16px",
                      width: "16px",
                      margin: "0 0 3px 3px",
                    }}
                  ></img>
                </label>
                <label className="textLabel2" htmlFor="plan">
                  {campos.plan}
                </label>
              </Grid>

              <Grid item xs={3}>
                <label className="textLabel1">Fecha autorización</label>
                <label className="textLabel2" htmlFor="fechaAutorizacion">
                  {campos.fechaAutorizacionSoliciut ? fecha(campos.fechaAutorizacionSoliciut) : "-"}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Área encargada</label>
                <label className="textLabel2" htmlFor="areaEncargada">
                  {resolutorComp}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Nombre representante</label>
                <label className="textLabel2" htmlFor="nombreRepresentante">
                  {campos.nombreRepresentante +
                    " " +
                    campos.apellido1Representante +
                    " " +
                    campos.apellido2Representante}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">RUT representante</label>
                <label className="textLabel2" htmlFor="rutRepresentante">
                  {formatRut(campos.rutRepresentante)}
                </label>
              </Grid>

              <Grid item xs={3}>
                <label className="textLabel1">N° Solicitud</label>
                <label
                  className={
                    campos.numSolicitudOrigen
                      ? "textLabel2"
                      : "textLabel2 textEmpty"
                  }
                  htmlFor="numSolicitud"
                >
                  {campos.numSolicitudOrigen
                    ? campos.numSolicitudOrigen
                    : "Sin N° Solicitud"}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Deducible</label>
                <label
                  className={
                    campos.deducible
                      ? "textLabel2"
                      : "textLabel2 textEmpty"
                  }
                  htmlFor="numSolicitud"
                >
                  {campos.deducible
                    ? campos.deducible + " UF"
                    : "Sin Informar"}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Problema de salud</label>
                <label className="textLabel2" htmlFor="problemaSalud">
                  {verificaLargo(patologiaComp, 30)}
                </label>
              </Grid>
              <Grid item xs={3}>
                <label className="textLabel1">Preexistencias declaradas</label>
                <label className="textLabel2" htmlFor="preexistencias">
                  {preexistencias ? <ModalPrexistencias prexistencias={preexistencias}/> : "Ninguna"}
                </label>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ color: "#D2D6D9" }} />
              </Grid>
              <ModalSeguimiento
                folio={props.data.getSolicitudesGCbyFkfun[0].fkFun}
                estado={props.data.getSolicitudesGCbyFkfun[0].fkEstadoSolicitudIsapre}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalAviso}
        onClose={() => setModalAviso(false)}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Grid sx={{ m: 1, textAlign: "center", padding: "0 0" }}>
          <img alt="img2" src={imgError}></img>
        </Grid>
        <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
          {casoHito ? "Hito Abierto" : "No has completado"}
        </DialogTitle>
        <DialogContent sx={{ padding: "10px 0px!important" }}>
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{ padding: "0 20px" }}
          >
            <Grid item xs={12}>
              <Typography sx={{ padding: "0 15px", textAlign: "center" }}>
                {casoHito
                  ? "No es posible cerrar el caso porque existen hitos abiertos"
                  : "Aún no has completado la información del paciente, asignado canasta o establecido prestadores."}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ margin: "10px 0" }} />
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PrimaryButton
                data-testid="cerrarNoCompletado"
                onClick={() => {
                  setModalAviso(false);
                  if (casoHito) {
                    props.setTab(2);
                  }
                }}
              >
                {casoHito ? "Ir a Hitos" : "Cerrar"}
              </PrimaryButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
