import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import PrimaryButton from "../../../../components/Buttons/Botones";
import TablaCambios from "../../utils/TablaCambios";
import { logPS } from "../../../../utils/queries";

const HistorialCambios = ({
    onOpen,
    onClose,
    dataPS
}) => {
    const [data, setData] = useState([]);

    const [getLogsPS, { loading }] = useLazyQuery(logPS, {
        onCompleted: (res) => setData(res.logPS),
        onError: () => setData([{error: null}]),
    });

    if (onOpen && !loading && data.length === 0) {
        getLogsPS({
            variables: {
                id: dataPS.id
            }
        });
    }
    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Historial de cambios
                <br></br>
                <label className="subtitleModal">
                    A continuación se muestra una lista de todas las modificaciones realizadas en este registro.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ margin: "0", width: "100%", border: "1px solid #E9EBEC", borderRadius: "8px" }}
                    container
                    spacing={2}
                >
                    <Grid xs={12}>
                        <TablaCambios data={data} loading={loading}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    data-testid="cerrarHistorial"
                    onClick={()=>{
                        setData([]);
                        onClose();
                    }}
                >
                    Cerrar
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default HistorialCambios;
