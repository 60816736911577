import icon_warningOrange from "../../../../../components/icons/icon_warningOrange.svg";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import PrimaryButton from "../../../../../components/Buttons/Botones";

export const CanastaActiva = ({ modalCanAsig, setModalCanAsig }) => {
    return (
        < Dialog
            maxWidth="md"
            open={modalCanAsig}
            onClose={() => setModalCanAsig(false)}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                    width: "600px",
                }}
            >
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "20px 0 0",
                    }}
                >
                    <img alt="img2" src={icon_warningOrange}></img>
                </Grid>
                <DialogTitle sx={{ width: "100%" }}>
                    <Typography className="tituloModal">
                        Esta canasta ya se encuentra activa
                    </Typography>
                </DialogTitle>
            </Grid>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingBottom: "10px" }}
                >
                    <PrimaryButton
                        onClick={() => setModalCanAsig(false)}
                    >
                        Aceptar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog >
    )
}