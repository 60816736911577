import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { getComparator, stableSort } from "../../../components/Table/utils";
import SkeletonTable from "../../../components/Skeleton/SkeletonTable";
import icon_descargar from "../../../components/icons/icon_descargar.svg";
import { useLazyQuery, useQuery } from "@apollo/client";
import infoPurple from "../../../components/icons/infoPurple.svg";
import {
  getUltRep,
  obtenerarchivo,
} from "../../../utils/queries";
import { saveAs } from "file-saver";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { CabeceraTabla } from "../../../components/TableHeader/TableHeader.jsx";
import { reportes, meses } from "../Reporteria.jsx";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { fecha } from "../../../utils/fechaUtils";

export function TablaReportes() {
  const [ordenReportes, setOrdenReportes] = useState("asc");
  const [ordenaReportes, setOrdenaReportes] = useState("");
  const [pagReportes, setPagReportes] = useState(0);
  const [lineasPagReportes, setLineasPagReportes] = useState(5);
  const [txtLoad, setTxtLoad] = useState({});
  const [mensaje, setMensaje] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const [getArchivo] = useLazyQuery(obtenerarchivo);

  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(getUltRep, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    startPolling(60000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (loading) return <div className="container"><SkeletonTable test="loadingTabla" /></div>;

  if (error)
    return <div data-testid="errorTabla">Error cargando datos: {error.message}</div>;

  function descargaArchivo(type, docId) {
    setMensaje({
      abrir: true,
      mensaje: "Generando descarga...",
      severidad: "info",
    });
    const tipo = type;
    const valor = docId;
    getArchivo({
      variables: {
        documentId: valor,
        tipoDoc: "OP",
      },
      onCompleted: (res) => {
        let nombExtArchivoSPO = res.obtenerArchivo.nombExtArchivoSPO;
        let base64SinForm = res.obtenerArchivo.base64;
        let base64ConForm = base64SinForm.replace(/['"]+/g, "");
        let nombArchivoConForm = nombExtArchivoSPO.replace(/['"]+/g, "");
        base64ConForm = atob(base64ConForm);
        let length = base64ConForm.length;
        let out = new Uint8Array(length);
        var blob;
        while (length--) {
          out[length] = base64ConForm.charCodeAt(length);
        }
        if (tipo === "txt") {
          blob = new Blob([out], { type: "text/plain" });
          saveAs(blob, nombArchivoConForm);
          setTxtLoad(oldState => ({ ...oldState, [valor]: false }));
        } else {
          blob = new Blob([out], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, nombArchivoConForm);
          setTxtLoad(oldState => ({ ...oldState, [valor]: false }));
        }
        setMensaje({
          abrir: true,
          mensaje: "Descarga generada, revise sus archivos descargados",
          severidad: "success",
        });
      },
      onError: (error) => {
        console.log("error resp: " + error);
        setMensaje({
          abrir: true,
          mensaje: "No se generó la descarga, intente nuevamente",
          severidad: "error",
        });
        setTxtLoad(oldState => ({ ...oldState, [valor]: false }));
      }
    });
  }

  const cabeceraReporte = [
    { id: "id", sorted: true, label: "N°" },
    { id: "descripcion", sorted: true, label: "Descripción" },
    { id: "periodo", sorted: true, label: "Periodo" },
    { id: "generado", sorted: true, label: "Generado" },
    { id: "descargar", label: "Descargar", noActions: true }
  ];
  return (
    <div className="container" data-testid="tablareporteria">
      <div>
        <label className="titulo2 p-4">Reportes generados</label>
      </div>
      <Mensajero data={mensaje} setState={setMensaje} />
      <button hidden onClick={() => refetch()} id="btnRefetchTable"></button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <CabeceraTabla
            order={ordenReportes}
            setOrder={setOrdenReportes}
            orderBy={ordenaReportes}
            setOrderBy={setOrdenaReportes}
            cabecera={cabeceraReporte}
          />
          <TableBody>
            {data.getUltREporte.length > 0 ? (
              stableSort(
                data.getUltREporte,
                getComparator(ordenReportes, ordenaReportes)
              )
                .slice(
                  pagReportes * lineasPagReportes,
                  pagReportes * lineasPagReportes + lineasPagReportes
                )
                .map((row, index) => {
                  const periodoArray = row.periodo.split("-");
                  let periodTxt = `${meses[parseInt(periodoArray[0]) - 1].label
                    } ${periodoArray[1]}`;
                  const labelId = `tablaReporte-${index}`;
                  return (
                    <TableRow
                      data-testid="withData"
                      key={labelId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className="fila"
                        sx={{ width: "5%" }}
                        align="left"
                      >
                        {data.getUltREporte.indexOf(row) + 1}
                      </TableCell>
                      <TableCell
                        className="fila"
                        sx={{ width: "40%" }}
                        align="left"
                      >
                        {reportes.map((reporte) => {
                          if (reporte.value === row.descripcion) {
                            return reporte.label;
                          }
                          return null;
                        })}
                      </TableCell>
                      <TableCell
                        className="fila"
                        sx={{ width: "15%" }}
                        align="left"
                      >
                        {periodTxt}
                      </TableCell>
                      <TableCell
                        className="fila"
                        sx={{ width: "15%" }}
                        align="left"
                      >
                        <label className="labelnroCaso">
                          {fecha(row.fechaGeneracion)}
                        </label>
                        <label className="labeltipoCaso">{row.creador}</label>
                      </TableCell>

                      <TableCell sx={{ width: "20%" }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="start"
                        >
                          <BotonCarga
                            testid="generaReporteXlsx"
                            sx={{ marginRight: "4px", width: "90px", minWidth: "50px", height: "40px" }}
                            name="excel"
                            toDo={() => {
                              setTxtLoad(oldState => ({ ...oldState, [row.urlXls]: true }));
                              descargaArchivo("excel", row.urlXls)
                            }}
                            loading={txtLoad[row.urlXls]}
                            variant='secondary'
                          >
                            <img className="white-color-svg" src={icon_descargar} alt="" /> Excel
                          </BotonCarga>
                          <BotonCarga
                            testid="generaReporteTxt"
                            sx={{ marginRight: "4px", width: "90px", minWidth: "50px", height: "40px" }}
                            name="excel"
                            toDo={() => {
                              setTxtLoad(oldState => ({ ...oldState, [row.urlTxt]: true }));
                              descargaArchivo("txt", row.urlTxt)
                            }}
                            loading={txtLoad[row.urlTxt]}
                            variant='secondary'
                          >
                            <img className="white-color-svg" src={icon_descargar} alt=""/> Txt
                          </BotonCarga>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow data-testid="noData">
                <TableCell
                  colSpan={10}
                  className=""
                  align="center"
                  padding="normal"
                >
                  <Box
                    sx={{
                      padding: "20px",
                    }}
                  >
                    <img
                      sx={{ padding: "9px 0" }}
                      alt="img2"
                      src={infoPurple}
                    ></img>
                  </Box>
                  <label className="titulo2">Sin Reportes</label>
                  <label>Aún no se ha generado un reporte.</label>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid alignItems="center">
        <Paginador
          opciones={[5, 10, 15]}
          data={data.getUltREporte.length}
          itemPorPagina={lineasPagReportes}
          pagina={pagReportes}
          setPagina={setPagReportes}
          setLineasPagina={setLineasPagReportes}
        />
      </Grid>
    </div>
  );
}