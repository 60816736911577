import { useState } from "react";
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from "@mui/material";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";
import BuscaPrestador from "../../../../components/BuscaPrestador/BuscaPrestador";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";

export default function DatosHospitalizacion({
    campos,
    setCampos,
    casoInvalido,
    error,
    setError,
    deshabilita,
    setDesahilita,
    detectaCambio,
}) {
    const [resetBuscaPres, setResetBuscaPres] = useState(false);
    function detectaCambioRadioHospi(e) {
        setResetBuscaPres(true);
        setCampos({
            ...campos,
            chkHospi: e.target.value,
            fechaDesde: null,
            fechaHasta: null,
            rutInstiHosp: null,
            direccionHospi: null,
            luatHospi: null,
            razonSocialHospi: null
        });
        setError({
            ...error,
            chkHospi: null,
            fechaDesde: null,
            fechaHasta: null,
            rutInstiHosp: null
        });
    }

    function detectaCambioRadioTraslado(e) {
        setCampos({
            ...campos,
            chkTraslado: e.target.value,
            trasDesde: null,
            trasHasta: null,
        });
        setError({
            ...error,
            chkTraslado: null,
            trasDesde: null,
            trasHasta: null,
        });
        if (e.target.value === "si") {
            setDesahilita({
                ...deshabilita,
                traslado: false,
            })
        } else {
            setDesahilita({
                ...deshabilita,
                traslado: true,
            })
        }
    }

    function handlePrestador(dir, luat, rz, rut) {
        setCampos({
            ...campos,
            rutInstiHosp: rut,
            direccionHospi: dir,
            luatHospi: luat,
            razonSocialHospi: rz
        });
        setError({
            ...error,
            rutInstiHosp: null
        });
    }

    return (
        <div className="card-box">
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <label className="title">Datos hospitalización</label>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Hospitalización</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            id="chkHospi"
                            data-testid="chkHospiTest"
                            row
                            style={{ borderColor: error.chkHospi ? "red" : "#ced4da" }}
                            disabled={casoInvalido}
                            value={campos.chkHospi || ""}
                            onChange={detectaCambioRadioHospi}
                        >
                            <FormControlLabel
                                disabled={casoInvalido}
                                value={"si"}
                                control={<Radio data-testid="datosHospFormCheckboxSiHospi" size="small" />}
                                label={"Sí"}
                            />
                            <FormControlLabel
                                //className="form-check-input"
                                disabled={casoInvalido}
                                value={"no"}
                                control={<Radio data-testid="datosHospFormCheckboxNoHospi" size="small" />}
                                label={"No"}
                            />
                        </RadioGroup>
                        <span className="errorSpan">{error.chkHospi}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Fecha desde</label>
                    <DatePicker
                        id="fechaDesde"
                        testid="fechaDesde"
                        value={campos.fechaDesde || null}
                        error={error.fechaDesde}
                        noClear
                        disabled={casoInvalido || !campos.chkHospi || campos.chkHospi === "no"}
                        onChange={(newValue) => {
                            setCampos({
                                ...campos,
                                fechaDesde: newValue
                            });
                            setError({
                                ...error,
                                fechaDesde: null
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Fecha hasta</label>
                    <DatePicker
                        id="fechaHasta"
                        testid="fechaHasta"
                        value={campos.fechaHasta || null}
                        error={error.fechaHasta}
                        noClear
                        disabled={casoInvalido || !campos.chkHospi || campos.chkHospi === "no"}
                        onChange={(newValue) => {
                            setCampos({
                                ...campos,
                                fechaHasta: newValue
                            });
                            setError({
                                ...error,
                                fechaHasta: null
                            });
                        }}
                    />
                </Grid>
                <BuscaPrestador
                    razonSocial={campos.razonSocialHospi}
                    rutInsti={campos.rutInstiHosp}
                    luat={campos.luatHospi}
                    direccion={campos.direccionHospi}
                    error={{ rutInsti: error.rutInstiHosp }}
                    asignaPrestador={handlePrestador}
                    casoInvalido={casoInvalido}
                    resetInputPres={resetBuscaPres}
                    setResetInputPres={setResetBuscaPres}
                    disabled={
                        !campos.chkHospi ||
                        campos.chkHospi === "no" ||
                            casoInvalido
                            ? true
                            : false}
                />
                <Grid item xs={4}>
                    <label className="labelForm">Traslado</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <RadioGroup
                            name="controlled-radio-buttons-group"
                            id="chkTraslado"
                            data-testid="chkTrasladoTest"
                            row
                            disabled={casoInvalido}
                            style={{ borderColor: error.chkTraslado ? "red" : "#ced4da" }}
                            value={campos.chkTraslado || ""}
                            onChange={detectaCambioRadioTraslado}
                        >
                            <FormControlLabel
                                disabled={casoInvalido}
                                value={"si"}
                                control={<Radio data-testid="datosHospFormCheckboxSiTraslado" size="small" />}
                                label={"Sí"}
                            />
                            <FormControlLabel
                                //className="form-check-input"
                                disabled={casoInvalido}
                                value={"no"}
                                control={<Radio data-testid="datosHospFormCheckboxNoTraslado" size="small" />}
                                label={"No"}
                            />
                        </RadioGroup>
                        <span className="errorSpan">{error.chkTraslado}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Desde</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="trasDesde"
                            error={error.trasDesde ? true : undefined}
                            id="trasDesde"
                            disabled={casoInvalido || !campos.chkTraslado || (campos.chkTraslado === "si" ? false : true)}
                            placeholder="Ej: Desde Hospital A"
                            onChange={detectaCambio}
                            value={campos.trasDesde || ""}
                            inputProps={{ maxLength: 30 }}
                        />
                        <span className="errorSpan">{error.trasDesde}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Hasta</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="trasHasta"
                            error={error.trasHasta ? true : undefined}
                            id="trasHasta"
                            disabled={casoInvalido || !campos.chkTraslado || (campos.chkTraslado === "si" ? false : true)}
                            placeholder="Ej: Hasta Hospital B"
                            onChange={detectaCambio}
                            value={campos.trasHasta || ""}
                            inputProps={{ maxLength: 30 }}
                        />
                        <span className="errorSpan">{error.trasHasta}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Urgencia vital</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            row
                            data-testid="chkUrgenciaTest"
                            disabled={casoInvalido}
                            style={{ borderColor: error.chkUrgencia ? "red" : "#ced4da" }}
                            value={campos.chkUrgencia || ""}
                            onChange={detectaCambio}
                        >
                            <FormControlLabel
                                disabled={casoInvalido}
                                value={"si"}
                                control={<Radio id="chkUrgencia" data-testid="datosHospFormCheckboxSiUrgencia" size="small" />}
                                label={"Sí"}
                            />
                            <FormControlLabel
                                //className="form-check-input"
                                disabled={casoInvalido}
                                value={"no"}
                                control={<Radio id="chkUrgencia" data-testid="datosHospFormCheckboxNoUrgencia" size="small" />}
                                label={"No"}
                            />
                        </RadioGroup>
                        <span className="errorSpan">{error.chkUrgencia}</span>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}