import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { exportavdmn } from "../../../../utils/queries";
import { generateExcel } from "../utils/generateExcel";
import { parse } from "zipson";

export default function ExportarV({ filtros, setSnackData, loadingTabla }) {
    const [getDataVDMC, { loading }] = useLazyQuery(exportavdmn, {
        onCompleted: (res) => generateExcel(parse(res.exportaVademecumZipzonSt), setSnackData),
        onError: (error) => setSnackData({
            abrir: true,
            mensaje: "Error al generar excel: " + error.message,
            severidad: "error",
        })
    });

    return (
        <BotonCarga
            testid="exportaVademecum"
            sx={{ pl: "16px !important", pr: "16px !important", width: "auto", minWidth: "179px", mt: "8px" }}
            label="Exportar"
            disabled={loadingTabla}
            toDo={() => {
                setSnackData({
                    abrir: true,
                    mensaje: "Generando descarga...",
                    severidad: "info",
                });
                getDataVDMC({
                    variables: {
                        patologia: filtros.patologia || null,
                        codcanasta: filtros.codcanasta || null,
                        formulaActiva: filtros.formulaActiva || null,
                        codigoSB: filtros.codigoSB || null,
                        descripcion: filtros.descripcion || null,
                        estado: filtros.estado || null,
                        arancel: filtros.arancel || null,
                        eliminado: filtros.eliminado,
                        order: filtros.order,
                        dir: filtros.dir
                    }
                });
            }}
            loading={loading}
            download
            secondary
        />
    );
}