function base64toBlob(base64, nomb) {
    let appType = (nomb && obtenterType(nomb)) || checkMimeTypeFromBase64(base64);

    const bytes = atob(base64);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: appType });
  }

  function obtenterType(nomb) {
    let tmp = nomb.split(".");
    let val = tmp[1];
    let type = null;
    if (val === "pdf") {
      type = "application/pdf";
    } else if (val === "jpeg") {
      type = "image/jpeg";
    } else if (val === "png") {
      type = "image/png";
    } else if (val === "jpg") {
      type = "image/jpg";
    } else if (val === "gif") {
      type = "image/gif";
    } else if (val === "xlsx") {
      type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    return type;
  }

  function checkMimeTypeFromBase64(b64) {
    var signatures = {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg",
      "0M8R4KGxGuEA": "application/msword",
      UEsDBBQABgAIAAAAIQD:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      UEsDBBQABgAIAAAAIQC:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };

    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }

  export default base64toBlob;