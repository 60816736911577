import { useState } from "react";
import "../../../../assets/App.css";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/Table.css";
import { ObtenerArchivoGestor } from "../../../../components/ArchivosGestor/ObtenerArchivoGestor";
import { stableSort, getComparator } from "../../../../components/Table/utils";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { useQuery } from "@apollo/client";
import { format, parseISO } from "date-fns";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import PrimaryButton from "../../../../components/Buttons/Botones";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { leerRegistros } from "../../../../utils/queries";
import { NoResult } from "../../../../components/NoData/NoResults";
import { MenuBitacora } from "./MenuBitacora";
import { parseFecha } from "../../../../utils/fechaUtils";
import { TextWithModal } from "../../../../components/TextWithModal/TextWithModal";

export default function TablaBitacora({
  estado,
  folio,
  setSnackData,
  sucursal,
  isContralor
}) {
  const [ordenBitacora, setOrdenBitacora] = useState("desc");
  const [ordenPorBitacora, setOrdenPorBitacora] = useState("idDespliegue");
  const [pagBitacora, setPagBitacora] = useState(0);
  const [lineasPagBitacora, setLineasPagBitacora] = useState(5);
  const [resetPagBitacora, setResetPagBitacora] = useState(false);

  const { data, loading, error, refetch } = useQuery(leerRegistros, {
    variables: {
      folio: folio,
      offset: lineasPagBitacora * pagBitacora,
      limit: lineasPagBitacora,
      order: ordenPorBitacora,
      dir: ordenBitacora.toUpperCase(),
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    if (pagBitacora !== 0 && !resetPagBitacora) {
      setPagBitacora(0);
      setResetPagBitacora(false);
    }
    return <SkeletonTable test={"loading-bitacora"} />;
  }

  if (error) return <pre data-testid="error-bitacora">Error: {error.message}</pre>;

  const headCells = [
    { id: "idDespliegue", sorted: true, label: "N°", sx: { width: "50px!important" } },
    { id: "clasificacion", sorted: true, label: "Clasificación" },
    { id: "fechaCompromiso", sorted: true, label: "Fecha de Compromiso" },
    { id: "comentario", sorted: true, label: "Comentario", sx: { minWidth: "300px!important" } },
    { id: "createdAt", sorted: true, label: "Creado Por" },
    { id: "", label: "Documentos", sx: { width: "150px!important" } },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Button id="recargaTabla" data-testid="recargaTabla" hidden={true} onClick={() => refetch()} />
      {!data || data.getBitacora.total === 0 ? (
        <NoResult
          titulo={"Sin Historial"}
          subtitulo={"Aún no se ha registrado nada en bitácora"}
        />
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }} data-testid="containerTablaBit">
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <CabeceraTabla
                order={ordenBitacora}
                setOrder={setOrdenBitacora}
                orderBy={ordenPorBitacora}
                setOrderBy={setOrdenPorBitacora}
                cabecera={headCells}
                nonsticky={true}
              />
              <TableBody>
                {stableSort(
                  data.getBitacora.bitacoras,
                  getComparator(ordenBitacora, ordenPorBitacora)
                ).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      className="headTable"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className="fila"
                        sx={{ minWidth: "50px!important", width: "50px!important" }}
                      >
                        {row.idDespliegue}
                      </TableCell>
                      <TableCell align="left" className="fila">
                        {row.descripcionclasificacion || ""}
                      </TableCell>
                      <TableCell align="left" className="fila">
                        {row.fechaCompromiso
                          ? format(parseFecha(row.fechaCompromiso), "dd/MM/y")
                          : null}
                      </TableCell>
                      <TableCell align="left" className="fila" sx={{ minWidth: "300px!important" }}>
                        <TextWithModal text={row.comentario || ""} length={20} />
                      </TableCell>
                      <TableCell align="left" className="fila">
                        {row.createdby} {format(parseISO(row.createdAt), "dd/MM/y HH:mm")}
                      </TableCell>
                      <TableCell align="center" className="fila" sx={{ width: "150px!important" }}>
                        {row.documento ? (
                          <ObtenerArchivoGestor
                            tipoDoc={row.documento}
                            isBitacora={true}
                          />
                        ) : (
                          <PrimaryButton disabled>
                            {" "}
                            Sin Documento{" "}
                          </PrimaryButton>
                        )}
                      </TableCell>
                      <TableCell className="fila" align="right">
                        <MenuBitacora
                          idBit={row.id}
                          setSnackData={setSnackData}
                          refetch={refetch}
                          sucursal={sucursal}
                          isContralor={isContralor}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.getBitacora.total}
              servPag={true}
              loadNextPage={() => setResetPagBitacora(true)}
              itemPorPagina={lineasPagBitacora}
              pagina={pagBitacora}
              setPagina={setPagBitacora}
              setLineasPagina={setLineasPagBitacora}
            />
          </Grid>
        </Paper>
      )}
    </Box>
  );
}