import { useState, useEffect } from "react";
import { CssBaseline, styled } from '@mui/material';
import { FormularioIngreso } from "../pages/IngresoPaciente/FormularioIngreso/FormularioIngreso";
import { unstable_HistoryRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Bienvenida from "../pages/Bienvenida/Bienvenida";
import Paciente from "../pages/IngresoPaciente/IngresoPaciente/Paciente";
import SolicitudGesCae from "../pages/IngresoPaciente/Formulario1/SolicitudGesCae";
import TodosLosCasos from "../pages/BandejaCasos/TodosLosCasos";
import Main from "../pages/CasoGesCaec/Header/Main";
import Reporteria from "../pages/Reporteria/Reporteria";
import SolicitudFormulario2 from "../pages/CasoGesCaec/Formulario2/SolicitudFormulario2";
import AppBarAlemana from "../components/Drawer/AppBarAlemana";
import { useIsAuthenticated } from "@azure/msal-react";
import { DrawerLeft } from "../components/Drawer/drawerLeft";
import { isContralor, isMantenedor } from "../utils/hasPermisions";
import { createBrowserHistory } from 'history';
import Formularios from "../pages/Formularios/Formularios";
import Vademecum from "../pages/Mantenedor/Vademecum/Vademecum";
import { useMsal } from "@azure/msal-react";
import Medicamentos from "../pages/Medicamentos/Medicamentos";
import MantenedorMenu from "../pages/Mantenedor/MantenedorMenu";
import ProblemasSalud from "../pages/Mantenedor/ProblemasSalud/ProblemasSalud";
import MantenedorLEP from "../pages/Mantenedor/LEP/MantenedorLEP";
import MantenedorCanastas from "../pages/Mantenedor/Canastas/MantenedorCanastas";

const drawerWidth = 320;
const Overlay = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: "24px 0px",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
      paddingRight: '30px',
      width: `calc(100% - ${drawerWidth}px)`,
    }),
    ...(!open && {
      marginLeft: '64px',
      width: 'calc(100% - 64px)',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
    }),
  }),
);

const Routers = ({ isAuthorized, rol, sucursal, correo }) => {
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = useState(false);
  const isViewOnly = rol ? isContralor(rol) : null;
  const isRolMantenedor = rol ? rol.length === 1 && isMantenedor(rol) ? true : null : null;
  let history = createBrowserHistory();
  const [block, setBlock] = useState(false);
  const { accounts } = useMsal();
  const usuario = accounts[0]?.name || "";
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let unblock;
    if (block) {
      unblock = history.block((tx) => {
        if (window.confirm(`Si abandonas esta página perderás los datos ingresados ¿deseas continuar?`)) {
          unblock();
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [block, history]);

  return (
    <Overlay open={open}>
      <div data-testid="routerContainer" className="contenedor">
        <Router history={history}>
          <AppBarAlemana />
          <CssBaseline />
          {isAuthenticated && isAuthorized && (
            <DrawerLeft open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} rol={rol} />
          )}
          <Routes className="">
            <Route
              path="/FormularioIngreso"
              element={!isAuthenticated || !isAuthorized || isRolMantenedor ?
                <Bienvenida isAuthorized={false} /> :
                isViewOnly ?
                  <Bienvenida isAuthorized={false} /> :
                  <FormularioIngreso blockBack={setBlock} />
              }
            />
            <Route
              path="/SolicitudGesCae"
              element={!isAuthenticated || !isAuthorized || isRolMantenedor ?
                <Bienvenida isAuthorized={false} /> :
                isViewOnly ?
                  <Bienvenida isAuthorized={false} /> :
                  <SolicitudGesCae rol={rol} correo={correo} sucursal={sucursal} blockBack={setBlock} />
              }
            />
            <Route
              path="/TodosLosCasos"
              element={!isAuthenticated || !isAuthorized || isRolMantenedor ?
                <Bienvenida isAuthorized={false} /> :
                <TodosLosCasos rol={rol} sucursal={sucursal} correo={correo}/>
              }
            />
            <Route
              path="/GesCaeInformacion"
              element={!isAuthenticated || !isAuthorized || isRolMantenedor ?
                <Bienvenida isAuthorized={false} /> :
                <Main rol={rol} sucursal={sucursal} correo={correo}/>
              }
            />
            <Route
              path="/Reporteria"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                isViewOnly || isRolMantenedor ?
                  <Bienvenida isAuthorized={false} /> :
                  <Reporteria rol={rol} />
              }
            />
            <Route
              path="/Vademecum"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <Vademecum rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
            <Route
              path="/Medicamento"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <Medicamentos rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
            <Route
              path="/Formularios"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                isViewOnly || isRolMantenedor ?
                  <Bienvenida isAuthorized={false} /> :
                  <Formularios />
              }
            />
            <Route
              path="/"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={false} /> :
                isRolMantenedor ? <Navigate to="/Mantenedor" /> : <Paciente rol={rol} />
              }
            />
            <Route
              path="/Formulario2"
              element={!isAuthenticated || !isAuthorized || isRolMantenedor ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                isViewOnly ?
                  <Bienvenida isAuthorized={false} /> :
                  <SolicitudFormulario2 rol={rol} correo={correo} sucursal={sucursal} />
              }
            />
            <Route
              path="/Mantenedor"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <MantenedorMenu rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
            <Route
              path="/Canastas"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <MantenedorCanastas rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
            <Route
              path="/ProblemasSalud"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <ProblemasSalud rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
             <Route
              path="/LEP"
              element={!isAuthenticated || !isAuthorized ?
                <Bienvenida isAuthorized={isAuthorized} /> :
                <MantenedorLEP rol={rol} sucursal={sucursal} usuario={usuario} />
              }
            />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <label>404 - Página o recurso no existe</label>
                </main>
              }
            />
          </Routes>
        </Router>
      </div>
    </Overlay>
  );
};

export default Routers;
