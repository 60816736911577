export function VarianteTexto1(props) {
  switch (props.tipo) {
    case 1:
      return "Rechazar";
    case 2:
      return "Anular";
    case 3:
      return "Cerrar";
    case 4:
      return "Re-abrir";
    default:
      return "";
  }
}

export function VarianteTexto2(props) {
  switch (props.tipo) {
    case 1:
      return "Rechazo";
    case 2:
      return "Anulado";
    case 3:
      return "Cerrado";
    case 4:
      return "Re-apertura";
    default:
      return "";
  }
}

export function VarianteTexto3(props) {
  switch (props.tipo) {
    case 1:
      return "Rechazarás";
    case 2:
      return "Anularás";
    case 3:
      return "Cerrarás";
    case 4:
      return "Re-abrirás";
    default:
      return "";
  }
}