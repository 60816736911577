import {
    IconPDFSmall,
    IconPDFSmallRed,
    IconImageSmall,
    IconImageSmallRed,
    IconDocSmall,
    IconDocSmallRed
  } from "../../icons/iconosEsencial";

export function SwitchIcon({type, estado}){
    if(estado){
      switch(true){
        case /image/.test(type):
          return <IconImageSmall/>
        case type === "application/pdf":
          return <IconPDFSmall/>
        default: 
          return<IconDocSmall/>       
      }
    } else {
      switch(true){
        case /image/.test(type):
          return <IconImageSmallRed/>
        case /pdf/.test(type):
          return <IconPDFSmallRed/>
        default: 
          return<IconDocSmallRed/>       
      }
    }
  }