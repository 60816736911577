import React from "react";
import { useAsignaPrestador } from "./useAsignaPrestador";

export const AsignaPrestadorContext = React.createContext({});

export const AsignaPrestadorProvider = ({
  children,
  useAsignaPrestadorHook = useAsignaPrestador,
}) => {
  const value = useAsignaPrestadorHook();

  return (
    <AsignaPrestadorContext.Provider value={value}>
      {children}
    </AsignaPrestadorContext.Provider>
  );
};

export const useAsignaPrestadorContext = () =>
  React.useContext(AsignaPrestadorContext);
