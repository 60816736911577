import { activaCanasta, activaPrestadores, consultaCanastaasignadas, listaPrestadoresPorCanasta } from "./queries";
import { client } from "./apollo";
import { fechaKinetic } from "./fechaUtils";
import { hasAGE, hasGEP, hasJGESCAEC, isContralor, isMantenedor } from "./hasPermisions";

export function activarPrestador(idCanastaAsignada, idben, usuario, sucursal, caso) {
  let prestadores = []
  client
    .query({
      query: listaPrestadoresPorCanasta,
      variables: {
        idcanastaAsignada: parseInt(idCanastaAsignada),
        conVigencia:true
      },
      errorPolicy: "all",
      fetchPolicy: "network-only"
    }).then(result => {
      (result.data.getPrestadoresAsignadosxcanasta).forEach((dato) => {
        prestadores.push({
          "rutPrestador":dato.prestador,
          "luatPrestador":dato.luat,
          "id":dato.id
        })
      })
      //let prestadores=datosCanasta.prestadoresAsignados
      client
        .query({
          query: activaPrestadores,
          variables: {
            usuario: usuario,
            sucursal: sucursal,
            numeroCaso: caso,
            idCasoCanasta: idben,
            prestadores: prestadores,
          },
          errorPolicy: "all",
          fetchPolicy: "network-only"
        }).then(result => {
          console.log(result.data)
        })

    });
}

export function activarCanasta(folio, usuario, sucursal) {
  client
    .query({
      query: consultaCanastaasignadas,
      variables: {
        folio: folio,
        order: "idDespliegue",
        dir: "ASC",
        aplicaVigencia:true
      },
      errorPolicy: "all",
    }).then(result => {
      let datos = result.data.getCanastasasignadas.canastas
      let prestadores = []

      datos.forEach((element) => {

        client
          .query({
            query: activaCanasta,
            variables: {
              usuario: usuario,
              sucursal: sucursal,
              idCasoCanasta: element.idBeneficios,
              numeroCaso: folio,
              canasta: element.canasta,
              fechaTermino: element.fechaFin ? fechaKinetic(element.fechaFin) : null,
              prestadores: prestadores,
              fAperturaCanasta: fechaKinetic(element.fechaInicio)
            },
            errorPolicy: "all",
          }).then(result => {
            activarPrestador(element.id,element.idBeneficios,usuario,sucursal,folio)
            console.log(result.data)
          })
      })
    })
}

export function manejaDirecciones(prestadores) {
  let array = []
  prestadores.forEach(element => {
    array.push({ 
      label: element.nombreCalle + " " + element.numeroCalle + ", " + element.region, 
      value: element.luat, 
      razonSocial: element.razonSocial,
      rut: element.rut
    });
  });
  return array
}


export function asignarol(roles){
  if(hasJGESCAEC(roles)){
    return 'Jefe Ges Caec'
  }
  if(hasGEP(roles)){
    return 'Ejecutivo GEP'
  }
  if(hasAGE(roles)){
    return 'Ejecutivo Agencia'
  }
  if(isContralor(roles)){
    return 'Ejecutivo Contralor'
  }
  if(isMantenedor(roles)){
    return 'Mantenedor'
  }
}
