import "../../../../assets/App.css";
import "../../../../assets/Repositorio.css";
import {
    Grid,
    Stack
} from "@mui/material";
import SkeletonBar from "../../../../components/Skeleton/SkeletonBar";

export const SkeletonHeaderPS = () => (
    <Grid item xs={12}>
        <div className="container">
            <Grid container p="24px">
                <Grid item xs={8} pb="10px">
                    <Stack direction={"column"} spacing={"10px"}>
                        <SkeletonBar
                            width={180}
                            height={18}
                        />
                        <SkeletonBar
                            width={371}
                            height={16}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={4} pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }} textAlign={"right"}>
                    <Stack direction={"row"}>

                        <Stack direction={"column"} pr="16px" spacing="10px">
                            <SkeletonBar
                                width={200}
                                height={14}
                            />
                            <SkeletonBar
                                width={200}
                                height={14}
                            />
                        </Stack>
                        <SkeletonBar
                            width={180}
                            height={40}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </div>
    </Grid>
)