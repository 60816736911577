import { useState } from "react";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/App.css";
import { AsignarHito } from "../../../../utils/queries";
import { format } from "date-fns";
import { useMsal } from "@azure/msal-react";
import { ModalPrestador } from "./ModalPrestador";
import { ModalAsignaHito } from "./ModalAsignaHito";
import PrimaryButton from "../../../../components/Buttons/Botones";
import { useMutation } from "@apollo/client";

export const AsignaHitoTermino = ({
  deshabilitaInputs,
  estado,
  folio,
  problemaSalud,
  hitoInicio,
  setSnackData
}) => {
  const { accounts } = useMsal();
  const [modalSelectHito, setModalSelectHito] = useState(false);
  const [modalPrestador, setModalPrestador] = useState(false);
  const [ dataTermino, setDataTermino] = useState({});


  const [guardarHitoTermino, { loading }] = useMutation(AsignarHito, {
    onCompleted: () => {
      setModalSelectHito(false);
      setModalPrestador(false);
      setSnackData({
        abrir: true,
        mensaje: "El hito de término fue asignado con éxito",
        severidad: "success",
      });
      clearAll();
      const btnRTabla = document.getElementById('recargaTablaHitos');
      if(btnRTabla){
        btnRTabla.click();
      }
    },
    onError: () => {
      setSnackData({
        abrir: true,
        mensaje: "El hito de término no ha podido ser asignado, intente nuevamente",
        severidad: "error",
      });
    }
  });

  const GuardaHito = () => {
    guardarHitoTermino({
      variables: {
        folio: folio,
        idHito: parseInt(dataTermino.hitoT),
        idHitoInicio: parseInt(hitoInicio.id),
        fechaEvento: dataTermino.fechaEvento,
        horaEvento: format(dataTermino.fechaEvento, "HH:mm"),
        prestador: dataTermino.prestador || null,
        razonSocial: dataTermino.razonSocial || null,
        creador: accounts[0].name,
        region: dataTermino.region || null,
        comuna: dataTermino.comuna || null,
        motivo: dataTermino.motivo || null,
        exceptuar: dataTermino.exceptuar,
        observacion: dataTermino.comentario || null,
        luatPrestador: dataTermino.luatPrestador || null,
        direccionPrestador: dataTermino.direccionPrestador || null
      },
    });
  }

  const verificaCambioModal = () => {
    if(modalPrestador || (dataTermino.descripcion === 'Cierre de caso' || dataTermino.descripcion === 'Cierre de hito con excepción')){
      GuardaHito();
    } else {
      setModalSelectHito(false);
      setModalPrestador(true)
    }
  }

  const cerrarModal = () => {
    setModalSelectHito(false);
    clearAll();
  };

  const volverSelectHito = () => {
    setModalPrestador(false);
    setModalSelectHito(true);
  };

  const abrirModal = () => {
    setModalSelectHito(true);
  };

  const clearAll = () => {
    setDataTermino({});
  };

  return (
    <div>
      <PrimaryButton
        data-testid="btnAsignar"
        disabled={estado === 5 || estado === 6 || estado === 7 || deshabilitaInputs ? true : false}
        onClick={abrirModal}
      >
        Asignar
      </PrimaryButton>
      <ModalAsignaHito
        modalSelectHito={modalSelectHito}
        cerrarModal={cerrarModal}
        verificaCambioModal={verificaCambioModal}
        deshabilitaInputs={estado === 5 || estado === 6 || estado === 7 || deshabilitaInputs ? true : false}
        hitoInicio={hitoInicio}
        problemaSalud={problemaSalud}
        dataTermino={dataTermino}
        setDataTermino={setDataTermino}
        loading={loading}
      />
      <ModalPrestador
        modalPrestador={modalPrestador}
        verificaCambioModal={verificaCambioModal}
        volverSelectHito={volverSelectHito}
        dataTermino={dataTermino}
        setDataTermino={setDataTermino}
        loading={loading}
      />
    </div>
  );
}
