import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

const BuscarC = ({
    onOpen, onClose, filtros, setFiltros
}) => {
    const [configFiltros, setConfigFiltros] = useState(filtros);
    const estadosPS = [{ label: "Vigente", value: 2 }, { label: "No vigente", value: 1 }]
    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Buscar Canasta
                <br></br>
                <label className="subtitleModal">
                    Seleccione los criterios de búsqueda para encontrar Canastas específicas.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">Código canasta</label>
                        <FormControl >
                            <TextField
                                placeholder="Ingresar Código canasta"
                                name="numero"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Problema de Salud</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar Problema de Salud"
                                id="glosa"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Descripción</label>
                        <TextField
                            placeholder="Ingresar Descripción"
                            id="arancel"
                            name="numero"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Arancel</label>
                        <TextField
                            value="2022"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Estado</label>
                        <SelectPersonalizado
                            placeholder={"Seleccionar un estado"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="24px"
                    pl="25px"
                    pr="10px"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="btnCheckDatos"
                        variant=" text"
                        color="default"
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default BuscarC;
