import { Fragment } from "react";
import { Grid, Chip } from "@mui/material";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { Cruz } from "../../../../components/icons/iconosEsencial";

export function ChipsPS({ filtros, setFiltros }) {
    const handleDelete = (cod) => {
        setFiltros({ ...filtros, [cod]: null, pag: 0 });
    };

    const clearFiltros = () => {
        setFiltros({
            ...filtros,
            Codigo: null,
            glosa: null,
            arancel: null,
            estado: null,
            pag: 0
        })
    };

    return (
        <Fragment>
            {filtros && (
                (filtros.Codigo && filtros.Codigo !== null) ||
                (filtros.glosa && filtros.glosa !== null) ||
                (filtros.arancel && filtros.arancel !== null) ||
                (filtros.estado && filtros.estado !== null)) ?
                <Fragment>
                    <Grid item xs={12}>
                        <label className="filtroFont" >Resultados filtrados por:</label>{" "}
                    </Grid>
                    <Grid item xs={12} pt="12px">
                        {filtros.Codigo && filtros.Codigo !== null ?
                            <Chip sx={{ mr: "16px" }} label="CódigoPS" variant="outlined" onDelete={() => handleDelete("Codigo")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.glosa && filtros.glosa !== null ?
                            <Chip sx={{ mr: "16px" }} label="GlosaPS" variant="outlined" onDelete={() => handleDelete("glosa")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.arancel && filtros.arancel !== null ?
                            <Chip sx={{ mr: "16px" }} label="Arancel" variant="outlined" onDelete={() => handleDelete("arancel")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.estado && filtros.estado !== null ?
                            <Chip sx={{ mr: "16px" }} label="EstadoPS" variant="outlined" onDelete={() => handleDelete("estado")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        <OutlinedButton2 sx={{ textDecoration: "underline", textDecorationThickness: "from-font", pl: "12px" }} onClick={clearFiltros}>
                            Limpiar filtros
                        </OutlinedButton2>
                    </Grid>
                </Fragment> : null}
        </Fragment>
    )
}