import "../../../assets/Table.css"
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { filtrado } from "../../../utils/queries";
import { formatRut } from "chilean-rutify";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import { CabeceraTabla } from "../../../components/TableHeader/TableHeader";
import {
  defineEstado,
  defineOrigen,
  EstadoHito,
  ExepcionTag
} from "../../../utils/CamposUtils";
import { MenuArchivos } from "./MenuArchivos";
import SkeletonTable from "../../../components/Skeleton/SkeletonTable";
import { getComparator, stableSort } from "../../../components/Table/utils";
import { MediumButton } from "../../../components/Buttons/Botones";
import { Paginador } from "../../../components/TablePagination/Paginador";
import { EnvioFkFun } from "../../../utils/RedirigeCaso";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { NoResult } from "../../../components/NoData/NoResults";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import { fecha } from "../../../utils/fechaUtils";
import { isContralor } from "../../../utils/hasPermisions";

export function FiltroCasos({ useQueryHook = useQuery, correo, sucursal, rol, ...datos }) {
  const campo = datos.data;
  const [ordenBandeja, setOrdenBandeja] = useState("desc");
  const [ordenarPorFiltroBandeja, setOrdenarPorFiltroBandeja] = useState("fechaSolicitudIsapre");
  const [paginaFiltroBandeja, setPaginaFiltroBandeja] = useState(0);
  const [lineasPorPagFiltroBandeja, setLineasPorPagFiltroBandeja] = useState(5);
  const [resetPag, setNoReset] = useState(false);
  const navigate = useNavigate();
  const [mensajero, setMensajero] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });

  const { data, loading, error } = useQueryHook(filtrado, {
    variables: {
      offset: lineasPorPagFiltroBandeja * paginaFiltroBandeja,
      limit: lineasPorPagFiltroBandeja,
      order: ordenarPorFiltroBandeja,
      dir: ordenBandeja.toUpperCase(),
      fkFun: parseInt(campo.numeroCaso) || null,
      tipoCaso: campo.tipoCaso || null,
      rutPaciente: campo.rutBeneficiario?.replace(/[^0-9kK-]/g, "") || null,
      nombrePaciente: campo.nombres || null,
      fkEstadoSolicitudIsapre: parseInt(campo.estado) || null,
      fechaInicio: campo.fechaSolicitudDesde || null,
      fechaFin: campo.fechaSolicitudHasta || null,
      fkProblemaSalud: campo.patologia || null,
      origen: campo.origen || null,
      numSolicitudOrigen: campo.nroSolicitudSV || null,
    },

    fetchPolicy: "network-only"
  });

  if (loading) {
    if (paginaFiltroBandeja !== 0 && !resetPag) {
      setPaginaFiltroBandeja(0);
    }
    datos.setContador(null);
    return <SkeletonTable test="loader" />;
  }

  if (error) return <pre data-testid="errorMessage">{error.message}</pre>;

  if (data.getSolicitudesGCbyparam.total === 0) {
    datos.setContador(0);
    return (
      <NoResult
        titulo={"No existen coincidencias"}
        subtitulo={"Asegúrate de ingresar y seleccionar datos válidos e intentalo nuevamente"}
      />);
  }

  if (data.getSolicitudesGCbyparam.total !== datos.contador) {
    datos.setContador(data.getSolicitudesGCbyparam.total);
  }
  if (resetPag) {
    setNoReset(false);
  }

  const cabeceraBandejaCasos = [
    {
      id: "fkFun",
      sorted: true,
      sticky: true,
      label: "N° Caso",
    },
    {
      id: "rutPaciente",
      sorted: true,
      label: "Beneficiario",
    },
    {
      id: "fkProblemaSalud",
      sorted: true,
      label: "Diagnóstico",
    },
    {
      id: "fechaSolicitudIsapre",
      sorted: true,
      label: "Fecha solicitud",
    },
    {
      id: "fechaRecepcionSolicitud",
      sorted: true,
      label: "Fecha Recepcion",
    },
    {
      id: "fkEstadoSolicitudIsapre",
      sorted: true,
      label: "Estado",
    },
    {
      id: "declaraHospitalizacion",
      sorted: true,
      label: "Hospitalización",
    },
    {
      id: "origen",
      sorted: true,
      label: "Via Ingreso",
    },
    {
      id: "estadoHito",
      sorted: false,
      label: "Hito",
    },
    {
      id: "createdUser",
      sorted: true,
      label: "Creado por",
    },
    {
      id: "file",
      label: "Archivos",
      sticky: true,
      stickyRight: true
    },
  ];

  const nextPage = () => {
    setNoReset(true);
  }

  return (
    <div data-testid="container" className="">
      <Mensajero data={mensajero} setState={setMensajero} />
      <Box sx={{ width: "100%" }} data-testid="tablaCanastaContainer">
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table className="bandejaCasos">
              <CabeceraTabla
                order={ordenBandeja}
                setOrder={setOrdenBandeja}
                orderBy={ordenarPorFiltroBandeja}
                setOrderBy={setOrdenarPorFiltroBandeja}
                cabecera={cabeceraBandejaCasos}
              />
              <TableBody>
                {stableSort(
                  data.getSolicitudesGCbyparam.solicitudes,
                  getComparator(ordenBandeja, ordenarPorFiltroBandeja)
                )
                  .map((info, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        className="headTable bandejaCasos"
                        key={info.fkFun}
                      >
                        <TableCell
                          component="th"
                          className="tableBodyIzquierda"
                          id={labelId}
                          scope="row"
                        >
                          <label className="labelnroCaso">{info.fkFun}</label>
                          <label className="labeltipoCaso">{info.tipoCaso}</label>
                        </TableCell>
                        <TableCell align="left" className="fila">
                          <label className="labelnroCaso">{formatRut(info.rutPaciente)}</label>
                          <label className="labeltipoCaso ColumnaBeneficiario">
                            {verificaLargo(info.nombrePaciente +
                              " " +
                              info.apellido1Paciente +
                              " " +
                              info.apellido2Paciente, 23)}
                          </label>
                        </TableCell>
                        <TableCell align="left" className="fila">
                          <label className="labelnroCaso">{info.fkProblemaSalud}</label>
                          <label className="labeltipoCaso ColumnaDiagnostico">
                            {info.tipoCaso === "GES" ? info.nombrepat ?
                              verificaLargo(
                                info.nombrepat.GlosaSis, 30
                              ) : "" : verificaLargo(info.diagnosticoCaec, 30)}
                          </label>
                        </TableCell>
                        <TableCell align="right" className="fila">
                          <label className="labelnroCaso">
                            {info.fechaSolicitudIsapre ? fecha(info.fechaSolicitudIsapre) : null}
                          </label>
                        </TableCell>
                        <TableCell align="right" className="fila">
                          <label className="labelnroCaso">
                            {info.fechaRecepcionSolicitud ? fecha(info.fechaRecepcionSolicitud) : null}
                          </label>
                        </TableCell>
                        <TableCell align="center" className="fila">
                          {defineEstado(info.fkEstadoSolicitudIsapre)}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          <label className="labelnroCaso">
                            {info.declaraHospitalizacion === true ? "sí" : "no"}
                          </label>
                          <label className="labeltipoCaso">{info.razonSocialInstitucionHopsitalizacion}</label>
                        </TableCell>
                        <TableCell align="center" className="fila">
                          {defineOrigen(info.origen)}
                        </TableCell>
                        <TableCell align="center" className="fila">
                          <EstadoHito estado={info.estadoHito ? info.estadoHito.id : null} />
                          {info.exeptuar ? <ExepcionTag value={info.exeptuar}/> : null}
                        </TableCell>
                        <TableCell>
                          <label
                            style={{ minWidth: "137px" }}
                            className="labelnroCaso"
                          >
                            {info.createdUser}
                          </label>
                        </TableCell>
                        <TableCell align="center" className="tableBodyAsignar">
                          <MenuArchivos
                            data={info}
                            mensajero={mensajero}
                            setMensajero={setMensajero}
                            correo={correo}
                            sucursal={sucursal}
                            rol={rol}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className="BodyAcciones"
                        >
                          <MediumButton
                            data-testid="enviarFormAForm1"
                            id="buttonAccion"
                            disabled={isContralor(rol) && info.fkEstadoSolicitudIsapre === 8}
                            onClick={() => {
                              EnvioFkFun(info, navigate)
                            }}
                          >
                            Ir al Caso
                          </MediumButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.getSolicitudesGCbyparam.total}
              servPag={true}
              loadNextPage={nextPage}
              itemPorPagina={lineasPorPagFiltroBandeja}
              pagina={paginaFiltroBandeja}
              setPagina={setPaginaFiltroBandeja}
              setLineasPagina={setLineasPorPagFiltroBandeja}
            />
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}