import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import ModalPrestadores from "./components/ModalPrestadores.jsx";
import { EliminarCanasta } from "./components/EliminarCanasta.jsx";
import { EditarCanasta } from "./components/EditarCanasta.jsx";
import { useLazyQuery } from "@apollo/client";
import { validacanastaconbonos } from "../../../../utils/queries.js";
import { hasJGESCAEC } from "../../../../utils/hasPermisions.js";

export const MenuCanasta = ({
  accounts,
  canasta,
  deshabilitaInputs,
  fechaAutorizacion,
  estado,
  paciente,
  setMensajeroData,
  sucursal,
  rol
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [openPrestadores, setOpenPrestadores] = useState(false);
  const [estadoCanasta, setEstadoCanasta] = useState({});
  const [verificaEstadoBonos, { loading }] = useLazyQuery(validacanastaconbonos, {
    onCompleted: (data) => setEstadoCanasta(data.validaCanastaconBonos)
  });

  const cargarBotonEliminar = (event) => {
    setAnchorEl(event.currentTarget);
    verificaEstadoBonos({
      variables: {
        idCanastaAsignada: canasta.idBeneficios,
        rut: paciente
      }
    });
  };

  const abrirModal = () => {
    setModalEliminar(true);
  };

  const abrirModalEditar = () => {
    setModalEditar(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <IconButton
        data-testid="openMenuItem"
        id="basic-button"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={(accion) => {
          cargarBotonEliminar(accion);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid="menuItems"
        className="menuForm"
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          className="menuForm"
          data-testid="verPrestador"
          onClick={() => {
            handleClose();
            setOpenPrestadores(true);
          }}
        >
          Ver prestador
        </MenuItem>
        <MenuItem
        className="menuForm"
          disabled={
            estado === 5 ||
              estado === 6 ||
              estado === 7 ||
              estado === 10 ||
              deshabilitaInputs ||
              (canasta.vigencia === false && estado !== 1 && !hasJGESCAEC(rol))
              ? true
              : false
          }
          data-testid="menuItemEditar"
          onClick={() => {
            handleClose();
            abrirModalEditar();
          }}
        >
          Editar fecha
        </MenuItem>
        <MenuItem
        className="menuForm"
          disabled={
            estado === 5 ||
              estado === 6 ||
              estado === 7 ||
              estado === 10 ||
              deshabilitaInputs
              ? true
              : false
          }
          data-testid="menuItemEliminar"
          onClick={() => {
            handleClose();
            abrirModal();
          }}
        >
          Eliminar
        </MenuItem>

      </Menu>
      <ModalPrestadores
        canasta={canasta}
        onOpen={openPrestadores}
        setOpen={setOpenPrestadores}
        setMensajeroData={setMensajeroData}
        disabled={estado === 4 ||
          estado === 5 ||
          estado === 6 ||
          estado === 7 ||
          deshabilitaInputs
          ? true
          : false}
      />
      <EliminarCanasta
        canasta={canasta}
        open={modalEliminar}
        onClose={() => setModalEliminar(false)}
        handleClose={handleClose}
        setMensajeroData={setMensajeroData}
        cargaBono={loading}
        estadoCanasta={estadoCanasta.respuesta}
        estado={estado}
        usuario={accounts[0].name}
        sucursal={sucursal}
      />
      <EditarCanasta
        modalEditar={modalEditar}
        setModalEditar={setModalEditar}
        fechaInicio={canasta.fechaInicio}
        fechaFin={canasta.fechaFin !== "" ? canasta.fechaFin : null}
        setMensajeroData={setMensajeroData}
        estado={estado}
        cargaBono={loading}
        estadoCanasta={estadoCanasta}
        accounts={accounts}
        id={canasta.id}
        fechaAutorizacion={fechaAutorizacion}
        sucursal={sucursal}
      />
    </Grid>
  );
};
