import { SecondaryButton } from "../../../../../../components/Buttons/Botones";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";

export function BotonAsignar ({useAsignaPrestadorHook = useAsignaPrestadorContext,
    ...props}){
    const { abrirModalInicio } = useAsignaPrestadorHook();

    const click = () => {
        props.cuenta();
        abrirModalInicio();
    };

    return(
        <SecondaryButton
            disabled={
                props.estadoSolicitud === 5 || 
                props.estadoSolicitud === 6 || 
                props.estadoSolicitud === 7 ||
                props.canasta.vigencia === false ||
                props.deshabilitaInputs
                ? 1 : 0}
            onClick={click}
            data-testid="botonAsignarPrestador"
        >
            Establecer
        </SecondaryButton>
    );
}