import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    TextField
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../components/Buttons/Botones";
import {
    formatRut,
    validRut
  } from "chilean-rutify";

const BuscarMedicamentos = ({
    onOpen, onClose, filtros, setFiltros
}) => {
    const [configFiltros, setConfigFiltros] = useState(filtros);
    const [errorrut, setErrorRut] = useState(null);
    const onChangeInput = (event) => {
        let id = event.target.id;
        let value = id === "codigoME" ? parseInt(event.target.value.replace(/[^0-9]/g, "")) : event.target.value.replace(/[']+/g, "").trim();
        if (id === "rut") {
            value = value.replace(/[^0-9kK-]/g, "").trim();
            value = value.length > 2 ? formatRut(value).toUpperCase() : value;
            if (value === "" || validRut(value)) {
                setErrorRut(null);
            } else {
                setErrorRut("Rut inválido");
            }
        }
        setConfigFiltros({
            ...configFiltros,
            [id]: value
        });
    };

    if (onOpen === false && configFiltros !== filtros) {
        setConfigFiltros(filtros);
        if (filtros.rut && validRut(filtros.rut)) {
            setErrorRut(null);
        }
    }
    
    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Buscar Medicamentos Asignados
                <br></br>
                <label className="subtitleModal">
                    Seleccione los criterios de búsqueda para encontrar medicamentos asignados específicos
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">RUT beneficiario </label>
                        <FormControl error={errorrut ? true : undefined}>
                            <TextField
                                placeholder="Ingresar RUT beneficiario"
                                id="rut"
                                data-testid="rut"
                                error={errorrut ? true : undefined}
                                value={configFiltros.rut || ""}
                                inputProps={{ maxLength: 12 }}
                                onChange={onChangeInput}
                            />
                            <FormHelperText>{errorrut || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Canasta</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingrese cod canasta"
                                id="canasta"
                                data-testid="canasta"
                                value={configFiltros.canasta || ""}
                                onChange={onChangeInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">SKU</label>
                        <TextField
                            placeholder="Ingresar SKU "
                            id="codigoME"
                            data-testid="codigoME"
                            value={configFiltros.codigoME || ""}
                            onChange={onChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Nombre medicamento</label>
                        <TextField
                            placeholder="Ingresar nombre medicamento"
                            id="nombreMed"
                            data-testid="nombreMed"
                            value={configFiltros.nombreMed || ""}
                            onChange={onChangeInput}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="24px"
                    pl="25px"
                    pr="10px"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        onClick={()=>{
                            onClose();
                            setErrorRut(null)
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="btnCheckDatos"
                        variant=" text"
                        color="default"
                        disabled={!!errorrut || (!configFiltros.rut && !configFiltros.canasta && !configFiltros.codigoME && !configFiltros.nombreMed)}
                        onClick={() => {
                            setFiltros({ ...configFiltros, pag: 0 });
                            onClose();
                        }}
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default BuscarMedicamentos;
