import { useState } from "react";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid
} from "@mui/material";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { DateTimePicker } from "../../../../components/DatePicker/DateTimePicker";
import { useMutation } from "@apollo/client";
import { editahitosasignados } from "../../../../utils/queries";

const ModalEditarHito = ({
    onOpen,
    setOpen,
    datos,
    fechaAutorizacion,
    setSnackData,
    usuario,
    sucursal
}) => {
    const [fechaInicioP, setFechaInicioP] = useState(datos.hitoInicio.fechaEvento);
    const [fechaTerminoP, setFechaTerminoP] = useState(datos.hitoTermino ? datos.hitoTermino.fechaEvento : null);

    const limpiarFechas = () => {
        setFechaInicioP(datos.hitoInicio.fechaEvento);
        setFechaTerminoP(datos.hitoTermino ? datos.hitoTermino.fechaEvento : null);
    }

    const [guardaFechas, { loading }] = useMutation(editahitosasignados, {
        onCompleted: () => {
            setOpen(false);
            setSnackData({
                abrir: true,
                mensaje: "Fecha de hito editada con éxito",
                severidad: "success",
            });
            const refetchTabla = document.getElementById('recargaTablaHitos');
            if (refetchTabla) {
                refetchTabla.click();
            }
        },
        onError: () => {
            setSnackData({
                abrir: true,
                mensaje: "Error al guardar la fecha, intente nuevamente.",
                severidad: "error",
            });
        }
    });

    return (
        <Grid>
            <Dialog
                maxWidth="md"
                open={onOpen}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <DialogTitle
                    sx={{ padding: "20px 13px 0px 13px" }}
                >
                    <Grid
                        container
                        justifyContent="space-around"
                        width="810px"
                    >
                        <label className="titulo2">
                            Editar fechas de evento
                        </label>
                    </Grid>
                </DialogTitle>
                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center", width: "100%" }}
                >
                    <label className="labelForm">
                        Aquí puedes editar la fecha de inicio y/o término del evento.
                    </label>
                </DialogContentText>
                <Grid
                    container
                    justifyContent="space-around"
                    alignItems="center"
                    width="810px"
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                    }}
                >
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <DialogContent sx={{ padding: "5px 20px 10px!important" }}>
                        <Grid sx={{ margin: "0", width: "100%", padding: "0" }} container>
                            <Grid item xs={12}>
                                <label className="titulo2">Hito de inicio {datos.hitoTermino ? "y término" : null}</label>
                                <p>
                                    Confirmación diagnóstico
                                </p>
                            </Grid>
                            <Grid item xs={datos.hitoTermino ? 6 : 12}>
                                <label className="labelForm">Fecha de inicio</label>
                                <DateTimePicker
                                    noClear
                                    value={fechaInicioP}
                                    minDate={fechaAutorizacion}
                                    testid="fechaInicio"
                                    onChange={(newValue) => {
                                        setFechaInicioP(newValue);
                                    }}
                                />
                            </Grid>
                            {datos.hitoTermino ?
                                <Grid item xs={6}>
                                    <label className="labelForm">Fecha de término</label>
                                    <DateTimePicker
                                        noClear
                                        value={fechaTerminoP}
                                        minDate={fechaInicioP}
                                        testid="fechaTermino"
                                        onChange={(newValue) => {
                                            setFechaTerminoP(newValue);
                                        }}
                                    />
                                </Grid>
                                : null
                            }
                        </Grid>
                    </DialogContent>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <DialogActions sx={{
                        margin: "0",
                        width: "100%",
                        padding: "20px 27px 18px 20px!important",
                    }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <SecondaryButton
                                data-testid="cerrarModal"
                                onClick={() => {
                                    setOpen(false);
                                    limpiarFechas();
                                }}
                            >
                                Cancelar
                            </SecondaryButton>
                            <BotonCarga
                                label="Guardar fecha"
                                testid={"guardaHito"}
                                disabled={(datos.hitoInicio.fechaEvento === fechaInicioP && !datos.hitoTermino) || (datos.hitoInicio.fechaEvento === fechaInicioP && (datos.hitoTermino && datos.hitoTermino.fechaEvento === fechaTerminoP))}
                                loading={loading}
                                toDo={() =>
                                    guardaFechas({
                                        variables: {
                                            idHito: datos.hitoInicio.id,
                                            usuario: usuario,
                                            sucursal: sucursal,
                                            FHitoInicio: fechaInicioP,
                                            FHitoTermino: fechaTerminoP
                                        }
                                    })
                                }
                            />
                        </Grid>
                    </DialogActions>
                </Grid>
            </Dialog >
        </Grid >
    );
}

export default ModalEditarHito;