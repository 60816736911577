import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DownloadGray, DownloadGreen, DownloadWhite } from "../icons/iconosEsencial";

const secondaryAlemana = {
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: '1px solid #00837A',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: '1px solid #00837A',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: '1px solid #909AA1',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "#FFFFFF"
  }
}
const primaryAlemana = {
  color: "#FFFFFF",
  fontStyle: 'normal',
  fontWeight: 'bold',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: ' 0px 2px 2px rgba(121, 133, 142, 0.25)',
  backgroundColor: "#00837A",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",
  border: "none",

  '&:hover': {
    color: "#FFFFFF",
    backgroundColor: '#4DC0B8',
    boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
    border: "none",
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
    boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
  },
  "&:disabled": {
    color: "#E9EBEC",
    boxShadow: 'none',
    backgroundColor: "#E9EBEC"
  }
};

const NoBorder = {
  color: "#00837A",
  fontStyle: 'normal',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  border: 'none',
  left: '0%',
  right: '0%',
  top: '0%',
  bottom: '0%',
  letterSpacing: '0.0021em',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '8px',
  backgroundColor: "#FFFFFF",
  lineHeight: '20px',
  textTransform: 'none',
  fontFamily: 'interstate',
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  '&:hover': {
    border: 'none',
    boxSizing: 'border-box',
    color: "#00837A",
    backgroundColor: '#E6F6F5',
  },
  '&:active': {
    color: "#FFFFFF",
    backgroundColor: "#00736B"
  },
  "&:disabled": {
    boxSizing: 'border-box',
    border: 'none',
    color: "#909AA1",
    boxShadow: 'none',
    backgroundColor: "unset",
  }

}

const switchVariant = (value) => {
  switch(value){
    case 'secondary': return secondaryAlemana;
    case 'noBorder': return NoBorder;
    default: return primaryAlemana;
  }
}

export const BotonCarga = ({
  boxSX,
  disabled,
  label,
  loading,
  name,
  variant,
  setLoading,
  sx,
  testid,
  toDo,
  download,
  children
}) => {

  function handleClick() {
    if (setLoading) {
      setLoading(true);
    }
    toDo();
  }

  const ButtonLoading = styled(LoadingButton)(switchVariant(variant));

  return (
    <Box sx={boxSX}>
      <ButtonLoading
        data-testid={testid}
        onClick={handleClick}
        loading={loading}
        name={name}
        variant="outlined"
        sx={sx}
        disabled={disabled}
      >
        {loading ? "" : <>{download ?
          disabled ?
            <DownloadGray sx={{ pt: "2px" }} />
            : variant ? <DownloadGreen sx={{ pt: "2px" }} />
              : <DownloadWhite sx={{ pt: "2px" }} />
          : ""}
          {label}{children}</>}
      </ButtonLoading>
    </Box>
  );
};

export default BotonCarga;

