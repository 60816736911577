import { Grid } from "@mui/material";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

function Arancel({value, setValue, estado, deshabilitaInputs}) {
  const valores = [
    { label: 2019, value: 2019 },
    { label: 2022, value: 2022 },
  ];
  return (
    <Grid item xs={4}>
      <label className="labelForm">Arancel</label>
        <SelectPersonalizado
          id={"arancel"}
          inputTest={"testArancel"}
          value={value}
          setValue={setValue}
          options={valores}
          disabled={estado === 5 || estado === 6 || estado === 7 || deshabilitaInputs ? true : false}
          placeholder={"Seleccione Arancel"}
        />
    </Grid>
  );
}

export default Arancel;
