import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import { useState } from "react";
import { capitalize, soloNumeros } from "../../../utils/CamposUtils";
import { Divider, FormControl, FormHelperText, Grid } from "@mui/material";
import ThemeTextField from "../../../components/Textfield/ThemeTextField";
import PrimaryButton, {
  SecondaryButton,
} from "../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";
import { Patologias } from "../../../utils/patologias";
import { GetSGCEstados } from "../../../utils/SGCEstados";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  IconArrowClose,
  IconArrowOpen,
} from "../../../components/icons/iconosEsencial";
import FiltroChip from "./FiltroChip";
import {
  formatRut,
  validRut
} from "chilean-rutify";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import Origenes from "./Origenes";

export function BuscadorCasos(props) {
  const [chip, setChip] = useState([]);
  const [filtros, setFiltros] = useState({});
  function mostrarBusquedaAvanzada() {
    props.setFiltro(!props.filtro);
  }

  function LimpiaFiltros() {
    setFiltros({});
    props.setError({});
    props.setCampos({});
    setChip([]);
    props.setFiltro(false);
  }

  const LimpiaFiltro = (nombre) => {
    var campos = filtros;
    const result = chip.filter((data) => data !== nombre);
    delete campos[nombre];
    setFiltros(campos);
    props.setCampos(campos);
    props.setError({});
    setChip(result);
  };

  function detectaCambio(e) {
    const field = e.target.id;
    let errores = props.error;
    let value = e.target.value;
    if (field === "rutBeneficiario") {
      value = value.replace(/[^0-9kK-]/g, "").trim();
      value = value && value.length > 2 ? formatRut(value).toUpperCase() : value;
      if (value && !validRut(value)) {
        errores["rutBeneficiario"] = "El Rut ingresado no es válido";
      } else {
        delete errores["rutBeneficiario"];
      }
    }

    if (value && field === "numeroCaso" && !/^-?\d+$/.test(value)) {
      errores["numeroCaso"] = "Solo se permiten números";
    } else {
      delete errores["numeroCaso"];
    }
    if (value) {
      ActualizaChips(field);
    } else {
      ActualizaChips(field, true)
    }
    setFiltros({ ...filtros, [field]: value });
    props.setError({ ...props.error, ...errores });
  }

  function ActualizaChips(valor, borrar) {
    var chips = chip;
    if (borrar) {
      chips = chips.filter(item => item !== valor)
      setChip(chips);
    } else if (chip.indexOf(valor) === -1) {
      chips.push(valor);
      setChip(chips);
    }
  }

  function detectaCambiosNombre(e) {
    const field = e.target.id;
    let value = capitalize(e.target.value);
    ActualizaChips(field);
    setFiltros({ ...filtros, [field]: value });
  }

  const tipoCasos = [
    { label: "Ambos", value: null },
    { label: "GES", value: "GES" },
    { label: "CAEC", value: "CAEC" },
  ];

  function cambioEstado(value) {
    setFiltros({ ...filtros, estado: value });
    ActualizaChips("estado");
  }

  function CambioTipoCaso(value) {
    setFiltros({ ...filtros, tipoCaso: value });
    ActualizaChips("tipoCaso");
  }

  function cambioPatologia(valor) {
    ActualizaChips("patologia");
    setFiltros({
      ...filtros,
      patologia: valor ? valor.Codigo : null,
    });
  }

  function cambioOrigen(valor) {
    ActualizaChips("origen");
    setFiltros({
      ...filtros,
      origen: valor,
    });
  }
  const buscarCasos = () => {
    props.setCampos(filtros);
    props.setNumeroCasos(null);
    props.setFiltro(false);
  };
  return (
    <div className="containerAccordion container" data-testid="buscaContainer">
      <Accordion expanded={props.filtro} sx={{ borderRadius: "8px!important" }}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Grid
            sx={{ padding: "0 0 10px 0", margin: "0", width: "100%" }}
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs={12}>
              <label className="title">Buscador de casos</label>
            </Grid>

            <Grid item xs={5} sx={{ marginTop: "30px" }}>
              <label className="labelForm">
                Busque por Nº de caso o seleccione{" "}
                <strong className="GreenText">Ver más filtros </strong>para
                <br></br>
                consultar resultados más específicos.
              </label>
            </Grid>
            <Grid item xs={3}>
              <label className="labelForm">N° de casos</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={props.error.numeroCaso ? true : undefined}>
                <ThemeTextField
                  data-testid="formInputNumberCaso"
                  id="numeroCaso"
                  fullWidth
                  placeholder="Ej: 000001001"
                  onChange={detectaCambio}
                  value={filtros.numeroCaso || ""}
                  error={props.error.numeroCaso ? true : undefined}
                  onKeyPress={soloNumeros}
                  maxLength={12}
                />
                <FormHelperText>{props.error.numeroCaso || ""}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ marginTop: "30px" }}>
              <Grid container direction="row" justifyContent="end">
                <PrimaryButton
                  sx={{
                    marginRight: "10px",
                    width: "127px",
                    height: "55px",
                    minWidth: "80px",
                    marginBottom: "10px",
                  }}
                  disabled={props.filtro || !filtros.numeroCaso || Object.keys(props.error).length > 0}
                  onClick={buscarCasos}
                >
                  Buscar casos
                </PrimaryButton>
                <SecondaryButton
                  data-testid="toggleFiltros"
                  onClick={mostrarBusquedaAvanzada}
                  sx={{ width: "163px", height: "55px", marginRight: "8px" }}
                >
                  {props.filtro === false ? (
                    <IconArrowOpen />
                  ) : (
                    <IconArrowClose />
                  )}
                  {props.filtro === false ? "Ver filtros" : "Cerrar filtros"}
                </SecondaryButton>
              </Grid>
            </Grid>
            {chip.length === 0 ? null : (
              <>
                <Grid item xs={12} sx={{ padding: "16px 0px 0px!important" }}>
                  <Divider sx={{ color: "#E9EBEC" }} />
                </Grid>
                <Grid item xs={12}>
                  <label className="labelForm">Resultados filtrados por:</label>
                </Grid>
                <FiltroChip
                  chip={chip}
                  cleanAll={LimpiaFiltros}
                  cleanOne={LimpiaFiltro}
                />
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ color: "#E9EBEC" }} />
          <Grid
            sx={{ padding: "0", margin: "0", width: "100%" }}
            container
            spacing={2}
          >
            <Grid item xs={4}>
              <label className="labelForm">Tipo de caso (opcional)</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <SelectPersonalizado
                  inputTest="formSelectTypeCaso"
                  id="tipoCaso"
                  value={filtros.tipoCaso || ""}
                  error={props.error["tipoCaso"]}
                  setValue={CambioTipoCaso}
                  options={tipoCasos}
                  placeholder={"Selecciona"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <label className="labelForm">N° Solicitud (Sucursal Virtual)</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  data-testid="nroSolicitudSVTest"
                  id="nroSolicitudSV"
                  fullWidth
                  placeholder="Ej: 0000000001"
                  onChange={detectaCambio}
                  value={filtros.nroSolicitudSV || ""}
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>
            <Origenes
              value={filtros.origen || ""}
              setValue={cambioOrigen}
            />
            <Grid item xs={6}>
              <label className="labelForm">Estado del caso</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <GetSGCEstados
                  error={props.error["estado"]}
                  value={filtros.estado || ""}
                  setValue={cambioEstado}
                />
                <span>{props.error["estado"]}</span>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <label className="labelForm">Problema de salud</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <Patologias
                  value={filtros.patologia || ""}
                  setValue={cambioPatologia}
                  filter
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <label className="labelForm">Fecha de solicitud desde</label>
              <DatePicker
                maxDate={filtros.fechaSolicitudHasta || null}
                value={filtros.fechaSolicitudDesde || null}
                noClear
                id="fechaSolicitudDesde"
                testid="formInputDateDesde"
                error={props.error.fechaSolicitudDesde}
                onChange={(fecha) => {
                  ActualizaChips("fechaSolicitudDesde");
                  setFiltros({
                    ...filtros,
                    fechaSolicitudDesde: fecha,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <label className="labelForm">Fecha de solicitud hasta</label>
              <DatePicker
                minDate={filtros.fechaSolicitudDesde || null}
                value={filtros.fechaSolicitudHasta || null}
                noClear
                id="fechaSolicitudHasta"
                testid="formInputDateHasta"
                error={props.error.fechaSolicitudHasta}
                onChange={(fecha) => {
                  ActualizaChips("fechaSolicitudHasta");
                  setFiltros({
                    ...filtros,
                    fechaSolicitudHasta: fecha,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <label className="labelForm">Nombre Beneficiario</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                <ThemeTextField
                  data-testid="formInputNombre"
                  id="nombres"
                  fullWidth
                  placeholder="Ej:Javiera Belén Herrera"
                  onChange={detectaCambiosNombre}
                  value={filtros.nombres || ""}
                />
                <span>{props.error.nombres || ""}</span>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <label className="labelForm">RUT Beneficiario</label>
              <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={props.error.rutBeneficiario ? true : undefined}>
                <ThemeTextField
                  data-testid="formInputRut"
                  id="rutBeneficiario"
                  fullWidth
                  placeholder="Ej: 12345678-9"
                  onChange={detectaCambio}
                  value={filtros.rutBeneficiario || ""}
                  error={props.error.rutBeneficiario ? true : undefined}
                  inputProps={{ maxLength: 12 }}
                />
                <FormHelperText className="errorSpan">
                  {props.error.rutBeneficiario || ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
                spacing={2}
              >
                <SecondaryButton
                  sx={{
                    width: "177px",
                    height: "55px",
                    minWidth: "80px",
                    margin: "10px 15px 0 0",
                  }}
                  data-testid="mostrarResultados"
                  disabled={(!props.filtro || Object.keys(filtros).length === 0) || Object.keys(props.error).length > 0}
                  onClick={buscarCasos}
                >
                  Mostrar resultados
                </SecondaryButton>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
