import { Fragment, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { buildExcel } from "../../../utils/buildExcel";
import { exportaBandeja } from "../../../utils/queries";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import Mensajero from "../../../components/Snackbar/Mensajero";

export function ExportarBandeja(props) {
    const [cargando, setCargando] = useState(false);
    const [getCasos, { fetchMore }] = useLazyQuery(exportaBandeja, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onCompleted: (result) => {
            procesaDatosResp(result)
        },
        onError: (error) => {
            CierraMensajero();
            MuestraMensaje("Archivo no generado, intente nuevamente.", "error");
            setCargando(false)
        }
    });
    const [snackData, setSnackData] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });

    function MuestraMensaje(mensaje, severidad) {
        setSnackData({
            abrir: true,
            mensaje: mensaje,
            severidad: severidad,
        });
    }

    function CierraMensajero() {
        setSnackData({
            abrir: false,
            mensaje: "",
            severidad: "info",
        });
    }

    async function procesaDatosResp(result) {
        let temp = result;
        if (temp && temp.exportSGC) {
            while (temp.exportSGC.solicitudes.length < temp.exportSGC.total) {
                const { data } = await fetchMore({
                    variables: {
                        offset: temp.exportSGC.solicitudes.length,
                        limit: 200,
                    }
                });
                const mergeSol = [...temp.exportSGC.solicitudes, ...data.exportSGC.solicitudes];
                temp = {
                    exportSGC: {
                        ...temp.exportSGC,
                        solicitudes: mergeSol
                    }
                };
            }

            const reporte = temp.exportSGC.solicitudes;
            await buildExcel('Casos', reporte);
            setCargando(false)
            CierraMensajero();
            MuestraMensaje("Archivo generado, revise descargas.", "success");
        } else {
            CierraMensajero();
            MuestraMensaje("Datos no son válidos, favor reintente.", "error");
            setCargando(false)
        }
    }

    function getCasosFunc() {
        setCargando(true)
        MuestraMensaje("Exportando bandeja, por favor espere", "info");
        getCasos({
            variables: {
                offset: 0,
                limit: 200,
                order: "fkFun",
                dir: "ASC",
                fkFun: parseInt(props.campos.numeroCaso),
                tipoCaso: props.campos.tipoCaso,
                rutPaciente: props.campos.rutBeneficiario ? props.campos.rutBeneficiario.replace(/[^0-9kK-]/g, "") : null,
                nombrePaciente: props.campos.nombres,
                fkEstadoSolicitudIsapre: parseInt(props.campos.estado),
                fechaInicio: props.campos.fechaSolicitudDesde,
                fechaFin: props.campos.fechaSolicitudHasta,
                fkProblemaSalud: props.campos.patologia,
                origen: props.campos.origen,
                numSolicitudOrigen: props.campos.nroSolicitudSV
            }
        })
    }
    return (
        <Fragment>
            <Mensajero data={snackData} setState={setSnackData} />
            <BotonCarga
                testid="exportaBandeja"
                sx={{ marginRight: "8px!important" }}
                boxSX={{ "& > button": { m: 1 } }}
                label="Exportar Casos"
                toDo={getCasosFunc}
                loading={cargando}
                variant='secondary'
                disabled={props.casos === null || props.casos === 0 ? true : false}
            />
        </Fragment>
    )
}