import {
    Box,
    Dialog,
    DialogContent,
    Grid,
} from "@mui/material";
import '../../../../components/Skeleton/Loader.css'
export const ModalLoader = ({
    openLoader
}) => {

    return (
        <Dialog
            maxWidth="md"
            open={openLoader}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px",
                    }}
                >
                    <Box className='contenedor-loader' pt="28px" pb="28px">
                        <div className="custom-loader-visa"></div>
                        <div className="LoaderText" htmlFor="archivo">
                            cargando archivo...
                        </div>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}