import { useState } from "react";
import {
    IconButton,
    Grid,
    Menu,
    MenuItem
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DialogEliminarBit } from "./DialogEliminarBit";


export const MenuBitacora = ({ idBit, setSnackData, refetch, sucursal, isContralor }) => {
    const [dialogEliminar, setDialogEliminar] = useState(false);
    const [menuBita, setMenuBita] = useState(null);
    const estadoMenu = Boolean(menuBita);

    const cerrarMenu = () => {
        setMenuBita(null);
    }
    
    const cerrarModal = () => {
        setDialogEliminar(false);
    };

    const abrirModal = () => {
        setDialogEliminar(true);
    };

    return (
        <Grid>
            <IconButton
                id="basic-button"
                data-testid="btnMenu"
                aria-controls={estadoMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={estadoMenu ? "true" : undefined}
                onClick={(event) => {
                    setMenuBita(event.currentTarget);
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
            className="menuForm"
                id="basic-menu"
                anchorEl={menuBita}
                open={estadoMenu}
                onClose={cerrarMenu}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    data-testid="eliminarBitMenu"
                    disabled={isContralor}
                    onClick={() => {
                        abrirModal();
                        cerrarMenu();
                    }}
                >
                    Eliminar Registro
                </MenuItem>
            </Menu>
            <DialogEliminarBit
                dialogEliminar={dialogEliminar}
                cerrarModal={cerrarModal}
                idBit={idBit} 
                setSnackData={setSnackData}
                refetch={refetch}
                sucursal={sucursal}
            />
        </Grid>
    );
}