import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { SecondaryButton } from "../../../components/Buttons/Botones";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import icon_warningOrange from "../../../components/icons/icon_warningOrange.svg";
import { useMutation } from "@apollo/client";
import { eliminaME } from "../../../utils/queries";

const ModalEliminarMed = ({
    data, onOpen, onClose, usuario, sucursal, setSnackData, refetch
}) => {
    const [deleteMed, { loading }] = useMutation(eliminaME,{
        onCompleted: () => {
            setSnackData({
                abrir: true,
                mensaje: "Se eliminó el registro con éxito.",
                severidad: "success",
            });
            onClose();
            refetch();
        },
        onError: ()=> setSnackData({
                abrir: true,
                mensaje: "Error al eliminar registro, intente nuevamente",
                severidad: "error",
            })
    });

    return (
        <Dialog
            maxWidth="md"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px",
                    }}
                >
                    <img alt="img" src={icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2" htmlFor="archivo">
                    Confirmar eliminación del medicamento
                </div>
            </DialogTitle>
            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
                ¿Estás seguro de que deseas eliminar el medicamento {data.sku} 
                <br/> del usuario {data.nombrePaciente}? 
                <br/> Esta acción  no se puede deshacer. Por favor, confirma si
                <br/> deseas continuar con la eliminación del registro.
            </DialogContentText>
            <DialogActions
                container
                direction="row"
                alignitems="center"
                sx={{ justifyContent: "center!important", margin: "5px 0 15px 0" }}
            >
                <BotonCarga
                    sx={{ marginRight: "8px!important" }}
                    label="Eliminar"
                    testid={"eliminarMed"}
                    loading={loading}
                    toDo={()=>deleteMed({
                        variables: {
                            id: parseInt(data.id),
                            usuario: usuario,
                            sucursal: sucursal
                        }
                    })}
                />
                <SecondaryButton disabled={loading} onClick={onClose}>
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalEliminarMed;
