import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import "../../../../assets/Table.css";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { historialBonos } from "../../../../utils/queries";
import { throwServerError, useQuery } from "@apollo/client";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { NoResult } from "../../../../components/NoData/NoResults";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { useState } from "react";
import { parseFechaString } from "../../../../utils/fechaUtils";

export function Bonificaciones({
  rut,
  setSinBonos
}) {
  let rutprueba = rut
  const [Tit, setTit] = useState("Este paciente no tiene bonificaciones asociadas")
  const order = "desc";
  const orderBy = "idCasoCanasta";
  const [linearPorPagBonos, setLineasPorPagBonos] = useState(5);
  const [paginaBonos, setPaginaBonos] = useState(0);
  if (rut === '16123315-3') {
    rutprueba = null
  }
  const { data, loading } = useQuery(historialBonos, {
    fetchPolicy: "cache-and-network",
    variables: {
      rut: rutprueba,
      orden: "ASC",
      limit: 500,
      offset: 0
    },
    onCompleted: (result => setSinBonos(false)),
    onError: (err) => {
      setTit('Servicio no se encuentra disponible')
      setSinBonos(true)
      throwServerError(err.message)
    }
  });

  const cabeceraBonif = [
    { label: "N° Caso", sticky: true, sorted: false },
    { label: "Canasta", sorted: false },
    { label: "Tipo de documento", sorted: false},
    { label: "Fecha gasto", sorted: false },
    { label: "Código prestación", sorted: false },
    { label: "Folio", sorted: false },
    { label: "Monto total venta", sorted: false },
    { label: "Monto total prestación", sorted: false },
    { label: "Monto bonificado", sorted: false },
    { label: "Copago", sorted: false },
    { label: "Periodicidad", sorted: false },
    { label: "Fecha de inicio", sorted: false },
    { label: "Fecha de término", sorted: false, noActions: true },
    { label: "Estado", sorted: false, sticky: true, stickyRight: true, firstSticky: true },
  ];

  if (loading) {
    return (
      <Grid item xs={12}>
        <SkeletonTable test="loader" />
      </Grid>);
  }

  const TagBono = ({ estado }) => {
    switch (true) {
      case estado === "ACTIVE":
        return <label className="tagEstadoCasos tagIngresado">Activo</label>
      case estado === "NULLIFIED":
        return <label className="tagEstadoCasos tagRevision">Anulado</label>
      case estado === "INACTIVE":
        return <label className="tagEstadoCasos tagRechazado">Cerrado</label>
      default:
        return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
  }

  if (data && (data.getBonos).length === 0) { setSinBonos(true) }
  return (
    <Grid item xs={12}>
      {data && data.getBonos.length > 0 ?
        <>
          <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
            <Table data-testid="tablaBonif"  >
              <CabeceraTabla
                order={order}
                setOrder={null}
                orderBy={orderBy}
                setOrderBy={null}
                cabecera={cabeceraBonif}
              />
              <TableBody>
                {data.getBonos
                  .slice(
                    paginaBonos * linearPorPagBonos,
                    (paginaBonos *
                      linearPorPagBonos) +
                    linearPorPagBonos
                  )
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="tableBodyIzquierda"
                          align="left"
                          padding="normal"
                          component="th"
                        >
                          <label className="labelnroCaso">{row.folio}</label>
                          <label className="labeltipoCaso">GES</label>
                        </TableCell>
                        <TableCell
                          className="fila"
                          align="left"
                          padding="normal"
                          sx={{ width: "210px" }}
                        >
                          <label className="labelnroCaso">{row.codigoCanasta}</label>
                          <label className="labeltipoCaso">{verificaLargo(row.descripcionCanasta || "", 18)}</label>

                        </TableCell>
                        <TableCell
                          className="fila"
                          align="left"
                          padding="normal"
                        >
                          {row.tipoDocumento ?? ""}
                        </TableCell>
                        <TableCell
                          className="fila"
                          align="left"
                          padding="normal"
                        >
                          {row.fechaGasto ? parseFechaString(row.fechaGasto) : ""}
                        </TableCell>
                        <TableCell
                          className="fila"
                          align="left"
                          padding="normal"
                          sx={{ width: "210px" }}
                        >
                          <label className="labelnroCaso">{row.prestacion}</label>
                          <label className="labeltipoCaso">{verificaLargo(row.descripcionPrestacion || "", 15)}</label>
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          {row.folioBono}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          ${row.montoTotalBoleta}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          ${row.montoTotalPrestacion}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          ${row.montoBonificado}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          ${row.copago}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          <label className="labelnroCaso">{row.periodicidad || "-"}</label>
                          <label className="labeltipoCaso">{row.tPeriodicidad !== null && row.tPeriodicidad > 0 ? row.tPeriodicidad + " días" : "-"}</label>
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          {row.fechaInicio ? parseFechaString(row.fechaInicio) : ""}
                        </TableCell>
                        <TableCell className="" align="left" padding="normal">
                          {row.fechaFin ? parseFechaString(row.fechaFin) : ""}
                        </TableCell>
                        <TableCell className="tableBodyAcciones" padding="normal">
                          <TagBono estado={row.estado} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.getBonos.length}
              servPag={false}
              pagina={paginaBonos}
              setPagina={setPaginaBonos}
              itemPorPagina={linearPorPagBonos}
              setLineasPagina={setLineasPorPagBonos}
            />
          </Grid>
        </>
        :
        <NoResult
          titulo={Tit}
          subtitulo={""}
        />
      }
    </Grid>
  );
}