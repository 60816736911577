import { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Breadcrumbs,
    Typography,
    Grid,
    Stack,
    Tabs,
    Tab,
} from "@mui/material";
import { Link } from "react-router-dom";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { BotonCargaArchivo } from "../../../components/DragDrop/BotonCargaAchivo";
import TablaProblemasSalud from "./components/TablaProblemasSalud";
import { SkeletonHeaderPS } from "./components/LoaderPS";
import { TabPanel } from "../../../components/TabPanel/TabPanel";
import { HistorialCargaPS } from "./components/HistorialCarga";
import { isMantenedor, hasJGESCAEC } from "../../../utils/hasPermisions";
import { useMutation, useQuery } from "@apollo/client";
import { cargaPS, cargaexcelPS, getPSbyfilter } from "../../../utils/queries";
import { ExcelPS } from "./utils/excelUtilsPS";
import { SkeletonTableWithExport } from "../../../components/Skeleton/SkeletonTableWithExport";
import { fechaHoraGuion } from "../../../utils/fechaUtils";

export default function ProblemasSalud({ rol, usuario, sucursal }) {
    const [mensaje, setMensaje] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });
    const [tabValue, setTabValue] = useState(0);
    const [cargandoExcel, setCargandoExcel] = useState(false);
    const [filtroPS, setFiltroPS] = useState({
        pag: 0, limit: 10, dir: "DESC", order: "Codigo", arancel: -1, eliminado: false
    });

    const [primeraCargaSP, setPrimeraCargaSP] = useState(false);
    const { data, loading, refetch } = useQuery(getPSbyfilter, {
        fetchPolicy: "cache-and-network",
        variables: {
            codigo: filtroPS.Codigo || null,
            glosa: filtroPS.glosa || null,
            arancel: filtroPS.arancel || null,
            estado: filtroPS.estado || null,
            order: filtroPS.order,
            dir: filtroPS.dir,
            offset: filtroPS.pag * filtroPS.limit,
            limit: filtroPS.limit,
            eliminado: filtroPS.eliminado
        }
    });

    const [subidaExcelPS] = useMutation(cargaPS);
    const [cargaExcelGD] = useMutation(cargaexcelPS, {
        onCompleted: () => {
            setCargandoExcel(false);
            setMensaje({
                abrir: true,
                mensaje: "Excel cargado en Gestor Documental.",
                severidad: "success",
            });
        },
        onError: () => mensajeErrorPS("Error al subir a GD.")
    });


    if (!loading && !primeraCargaSP) {
        setPrimeraCargaSP(true);
    }


    const handleChange = (event, newValue) => {
        if(newValue === 0){
            setFiltroPS({pag: 0, limit: 10, dir: "DESC", order: "Codigo", arancel: -1, eliminado: false})
        }
        if(newValue === 2){
            setFiltroPS({pag: 0, limit: 10, dir: "DESC", order: "Codigo", eliminado: true})
        }
        setTabValue(newValue);
    };

    const mensajeErrorPS = (msg) => {
        setCargandoExcel(false);
        setMensaje({
            abrir: true,
            mensaje: "Error al cargar excel: " + msg,
            severidad: "error",
        });
    }

    const cargaExcelPS = (dataZip, largoData, b64Zip, name) => {
        subidaExcelPS({
            variables: {
                dataPS: dataZip,
                creados: largoData,
                usuario: usuario,
                sucursal: sucursal
            },
            onCompleted: (data) => {
                setCargandoExcel(false);
                setMensaje({
                    abrir: true,
                    mensaje: "Excel cargado correctamente, subiendo al gestor...",
                    severidad: "success",
                });
                setTimeout(() => refetch(), 500);
                cargaExcelGD({
                    variables: {
                        id_log: parseInt(data.registraPS),
                        base64: b64Zip,
                        nombreArchivo: name
                    }
                })
            },
            onError: (error) => mensajeErrorPS(error.networkError?.result?.errors[0]?.message !== 'Error en la carga masiva' ?
                error.networkError.result.errors[0].message :
                "Error al cargar Problemas de Salud, revise el documento")
        })
    }
    return (
        <div data-testid="ProblemaSaludMant">
            <Mensajero data={mensaje} setState={setMensaje} />
            <div className="rowInformacion">
                <div className="container Main">
                    <label className="titulo1">Problemas de Salud</label>
                    <Breadcrumbs
                        sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                        aria-label="breadcrumb"
                        className="breadcrum"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Link
                            underline="always"
                            color="inherit"
                            to="/"
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "400",
                                margin: "0px,8px",
                                cursor: "pointer",
                            }}
                        >
                            Inicio
                        </Link>
                        <Link
                            underline="always"
                            color="inherit"
                            to="/Mantenedor"
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "400",
                                margin: "0px,8px",
                                cursor: "pointer",
                            }}
                        >
                            Mantenedor
                        </Link>
                        <Typography
                            sx={{
                                color: "#364855",
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "-1px",
                                lineHeight: "24px",
                            }}
                        >
                            Problemas de Salud
                        </Typography>
                    </Breadcrumbs>
                    <Grid container>
                        {loading && !primeraCargaSP ?
                            <SkeletonHeaderPS /> :
                            <Grid item xs={12} className="container">
                                <Grid container p="24px">
                                    <Grid item xs={8}>
                                        <label className="titulo2">Carga masiva</label>{" "}
                                        <br></br>
                                        <label className="labelFormVademecum">
                                            Carga múltiples problemas de salud desde un archivo.
                                        </label>
                                        <label className="subUltActualizacion">
                                            Última actualización: {data && data.getProblemasSaludXfilter && data.getProblemasSaludXfilter.FultimaCarga ? fechaHoraGuion(data.getProblemasSaludXfilter.FultimaCarga)+" hrs" : "NO DISPONIBLE"}
                                        </label>
                                    </Grid>
                                    <Grid item xs={4} pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                        <Stack direction={"row"} spacing={"16px"}>
                                            <label className="tipoFormatoMin">
                                                Formato aceptado: XLSX. <br></br>
                                                Peso máximo de 2MB
                                            </label>
                                            <BotonCargaArchivo
                                                disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}
                                                cargando={cargandoExcel}
                                                maxSize={2000}
                                                toDo={ExcelPS}
                                                mutation={cargaExcelPS}
                                                msgError={mensajeErrorPS}
                                                setUploading={setCargandoExcel}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        <Grid item xs={12} mt="36px">
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                TabIndicatorProps={{ style: { background: '#00837A' } }}
                            >
                                <Tab label="Problemas de salud" />
                                <Tab label="Historial de Carga" />
                                <Tab label="Registros eliminados" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={tabValue} index={0}>
                                {loading && !primeraCargaSP ?
                                    <SkeletonTableWithExport rows={filtroPS.limit} /> :
                                    <TablaProblemasSalud
                                        data={data}
                                        loading={loading}
                                        filtros={filtroPS}
                                        setFiltros={setFiltroPS}
                                        setSnackData={setMensaje}
                                        refetch={refetch}
                                        rol={rol}
                                        usuario={usuario}
                                        sucursal={sucursal}
                                    />}
                            </TabPanel>
                            <TabPanel value={tabValue} index={1} className="container">
                                <HistorialCargaPS
                                    MensajeSnack={setMensaje}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2} >
                                {loading && !primeraCargaSP ?
                                    <SkeletonTableWithExport rows={filtroPS.limit} /> :
                                    <TablaProblemasSalud
                                        data={data}
                                        loading={loading}
                                        filtros={filtroPS}
                                        setFiltros={setFiltroPS}
                                        setSnackData={setMensaje}
                                        refetch={refetch}
                                        rol={rol}
                                        usuario={usuario}
                                        sucursal={sucursal}
                                        isEliminado
                                    />}
                            </TabPanel>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}


