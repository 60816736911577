import { Grid, Typography } from "@mui/material";
import {
  //IconCancelar,
  IconCancelarRed,
  IconCheckGreen,
} from "../../icons/iconosEsencial";
import "./MultipleFileUpload.css";
import { MediumButton } from "../../Buttons/Botones";
import { SwitchIcon } from "./SwitchIcon";
import React from "react";
import bytesToSize from "../../../utils/bytesToSize";
import ErrorText from "./ErrorText";

export function FileHeader(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {props.error === false ? (
        <Grid item className="groupDrag">
          <Typography className="nameFileAdjunto">
            <SwitchIcon type={props.object.file.type} estado={true} />
            {props.object.file.name.slice(0, 20)}
            <Typography className="sizeFile">
              {bytesToSize(props.object.file.size)}
            </Typography>
          </Typography>
          <Typography className="tagAdjuntado">
            <IconCheckGreen />
            Adjuntado
          </Typography>
        </Grid>
      ) : (
        <Grid item className="groupDrag"> 
            <Typography className="nameFileNoAdjunto">
              <SwitchIcon type={props.object.file.type} estado={false} />
              {props.object.file.name}
              <Typography className="sizeFile">
                {bytesToSize(props.object.file.size)}
              </Typography>
              <Typography className="errorFileNoAdjunto">
                {ErrorText(props.object.errors, false)}
              </Typography>
            </Typography>
            <Typography className="tagNoAdjuntado">
              <IconCancelarRed />
              Contiene errores
            </Typography>
        </Grid>
      )}
      <MediumButton data-testid="botonQuitar" onClick={() => props.onDelete(props.object)}>
        Quitar
      </MediumButton>
    </Grid>
  );
}
