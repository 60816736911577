import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format, isValid } from "date-fns";
import { stringify } from "zipson/lib";
import { ajustaFecha, fechaConvertida } from "../../../../utils/fechaUtils";

export const generaExcelLEP = async (datosLEP, mensajero, eliminado) => {
    const textoEliminado = eliminado ? "_Eliminados" : "";
    try {
        const LEP = [];
        LEP.push(["Código GES", "Código Intervención Sanitaria", "Código Canasta Esencial", "Tipo de Prestación", "Código Prestacion",
            "Pabellón", "A/H", "Arancel MINSAL", "Copago GES", "Periodicidad", "Tiempo Periodicidad (días)", "Observación Glosa", "Código prestación Minsal",
        "Prestación Adicional Esencial","Fecha Inicio", "Fecha Termino", "Arancel"]);
        datosLEP.LEP.forEach((prestacionLEP) => {
            const InicioVigencia = fechaConvertida(prestacionLEP.fechaInicio);
            const TerminoVigencia = prestacionLEP.fechaTermino ? fechaConvertida(prestacionLEP.fechaTermino) : null;
            LEP.push([
                prestacionLEP.problemaSalud,
                prestacionLEP.intervencion,
                prestacionLEP.canasta,
                prestacionLEP.tipoPrestacion,
                prestacionLEP.prestacion,
                prestacionLEP.der_Pab,
                prestacionLEP.AH,
                prestacionLEP.arancelMinsal,
                prestacionLEP.copagoGes,
                prestacionLEP.periodicidad,
                prestacionLEP.tPeriodicidad,
                prestacionLEP.obsGlosa,
                prestacionLEP.codPrestacionMinsal,
                prestacionLEP.presAdicionalEsencial,
                InicioVigencia,
                TerminoVigencia,
                prestacionLEP.decreto
            ])
        });
        const excelLEP = new Workbook();
        excelLEP.creator = "GES";
        excelLEP.addWorksheet("DatosLEP");
        const libroPS = excelLEP.getWorksheet(1);
        libroPS.addRows(LEP);
        excelLEP.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${"LEP"+textoEliminado}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            mensajero({
                abrir: true,
                mensaje: "Descarga generada, revise sus archivos descargados",
                severidad: "success",
            });
            return;
        });
        return;
    } catch (err) {
        mensajero({
            abrir: true,
            mensaje: "No se generó la descarga, intente nuevamente",
            severidad: "error",
        });
    }
}

export const ExcelLep = async (excel, mutation, errorMsg) => {
    try {
        const libroPS = new Workbook();
        const lectorArchivo = new FileReader();
        lectorArchivo.readAsArrayBuffer(excel);
        lectorArchivo.onload = async ({ target }) => {
            await libroPS.xlsx.load(target.result);
            const hoja1 = libroPS.worksheets[0];
            preCargaLep(excel, hoja1._rows, mutation, errorMsg);
        }
    } catch (err) {
        errorMsg("No se logró procesar el archivo, intente nuevamente.");
    }

}

export const preCargaLep = async (excel, data, mutation, errorMsg) => {
    const lectorArchivo = new FileReader();
    lectorArchivo.readAsDataURL(excel);
    lectorArchivo.onload = async ({ target }) => {
        procesaExcelLep(target.result.substr(`data:${excel.type};base64,`.length), data, mutation, errorMsg, excel.name);
    }
}

export const procesaExcelLep = (b64, arrayLep, mutation, errorMsg, name) => {
    try {
        const dataLEP = [];
        if (arrayLep.length <= 1) {
            throw new Error("Archivo sin datos para procesar.");
        }
        if (arrayLep.length > 15001) {
            throw new Error("Excede máximo de registros a procesar. (máx 15.000)");
        }
        arrayLep.forEach((row, index) => {
            if (index === 0) {
                row._cells.forEach((cell) => {
                    const checkTitulosLep = (a, b) => {
                        if (a !== b) {
                            throw new Error("Verificación de títulos fallida.");
                        }
                    }
                    switch (cell._address) {
                        case "A1": checkTitulosLep(cell._value.model.value, "Código GES")
                            break;
                        case "B1": checkTitulosLep(cell._value.model.value, "Código Intervención Sanitaria")
                            break;
                        case "C1": checkTitulosLep(cell._value.model.value, "Código Canasta Esencial")
                            break;
                        case "D1": checkTitulosLep(cell._value.model.value, "Tipo de Prestación")
                            break;
                        case "E1": checkTitulosLep(cell._value.model.value, "Código Prestacion")
                            break;
                        case "F1": checkTitulosLep(cell._value.model.value, "Pabellón")
                            break;
                        case "G1": checkTitulosLep(cell._value.model.value, "Glosa de Prestación")
                            break;
                        case "H1": checkTitulosLep(cell._value.model.value, "A/H")
                            break;
                        case "I1": checkTitulosLep(cell._value.model.value, "Arancel MINSAL")
                            break;
                        case "J1": checkTitulosLep(cell._value.model.value, "Copago GES")
                            break;
                        case "K1": checkTitulosLep(cell._value.model.value, "Periodicidad")
                            break;
                        case "L1": checkTitulosLep(cell._value.model.value, "Tiempo Periodicidad (días)")
                            break;
                        case "M1": checkTitulosLep(cell._value.model.value, "Observación Glosa")
                            break;
                        case "N1": checkTitulosLep(cell._value.model.value, "Código prestación Minsal")
                            break;
                        case "O1": checkTitulosLep(cell._value.model.value, "Prestación Adicional Esencial")
                            break;
                        case "P1": checkTitulosLep(cell._value.model.value, "Fecha Inicio")
                            break;
                        case "Q1": checkTitulosLep(cell._value.model.value, "Fecha Termino")
                            break;
                        case "R1": checkTitulosLep(cell._value.model.value, "Arancel")
                            break;
                        default: break;
                    }
                });
            }

            if (index > 0) {
                const celda = [];

                row._cells.forEach((cell) => {
                    celda.push({ key: cell._address, value: cell._value.model.value });
                });
                const problemaSalud = celda.find((key) => key.key.includes("A"));
                const intervencion = celda.find((key) => key.key.includes("B"));
                const canasta = celda.find((key) => key.key.includes("C"));
                const tipoPrestacion = celda.find((key) => key.key.includes("D"));
                const prestacion = celda.find((key) => key.key.includes("E"));
                const der_Pab = celda.find((key) => key.key.includes("F"));
                const descripcion = celda.find((key) => key.key.includes("G"));
                const AH = celda.find((key) => key.key.includes("H"));
                const arancelMinsal = celda.find((key) => key.key.includes("I"));
                const copagoGes = celda.find((key) => key.key.includes("J"));
                const periodicidad = celda.find((key) => key.key.includes("K"));
                const tPeriodicidad = celda.find((key) => key.key.includes("L"));
                const obsGlosa = celda.find((key) => key.key.includes("M"));
                const codPrestacionMinsal = celda.find((key) => key.key.includes("N"));
                const presAdicionalEsencial = celda.find((key) => key.key.includes("O"));
                const fechaInicio = celda.find((key) => key.key.includes("P"));
                const fechaTermino = celda.find((key) => key.key.includes("Q"));
                const decreto = celda.find((key) => key.key.includes("R"));
                var fechaInicioLep = null;
                var fechaTerminoLEP = null;

                if (!(problemaSalud && problemaSalud.value && Number.isInteger(parseInt(problemaSalud.value)))) {
                    throw new Error("Problema de Salud no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(intervencion && intervencion.value)) {
                    throw new Error("Debe ingresar una intervención (línea " + (index + 1) + "), favor revisar.");
                }
                if (intervencion.value.length > 255) {
                    throw new Error("Intervención sobre el límite (" + intervencion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(canasta && canasta.value)) {
                    throw new Error("Debe ingresar una canasta (línea " + (index + 1) + "), favor revisar.");
                }
                if (canasta.value.length > 255) {
                    throw new Error("Canasta sobre el límite (" + canasta.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(tipoPrestacion && tipoPrestacion.value)) {
                    throw new Error("Debe ingresar un tipo de Prestacion (línea " + (index + 1) + "), favor revisar.");
                }
                if (tipoPrestacion.value.length > 255) {
                    throw new Error("Tipo Prestacion sobre el límite (" + tipoPrestacion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(prestacion && prestacion.value)) {
                    throw new Error("Debe ingresar una prestación (línea " + (index + 1) + "), favor revisar.");
                }
                if (prestacion.value.length > 255) {
                    throw new Error("Prestación sobre el límite (" + prestacion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(der_Pab && (!!der_Pab.value || der_Pab.value === 0) && /^\d+$/.test(der_Pab.value))) {
                    throw new Error("Pabellón no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(descripcion && descripcion.value)) {
                    throw new Error("Debe ingresar una Descripción (línea " + (index + 1) + "), favor revisar.");
                }
                if (descripcion.value.length > 255) {
                    throw new Error("Descripción sobre el límite (" + descripcion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(AH && AH.value)) {
                    throw new Error("Debe ingresar un A/H (línea " + (index + 1) + "), favor revisar.");
                }
                if (AH.value.length > 255) {
                    throw new Error("A/H sobre el límite (" + AH.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(arancelMinsal && (!!arancelMinsal.value || arancelMinsal.value === 0) && /^\d+$/.test(arancelMinsal.value))) {
                    throw new Error("Arancel Minsal no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(copagoGes && (!!copagoGes.value || copagoGes.value === 0) && /^\d+$/.test(copagoGes.value))) {
                    throw new Error("Copago Ges no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(periodicidad && periodicidad.value)) {
                    throw new Error("Debe ingresar un periodicidad (línea " + (index + 1) + "), favor revisar.");
                }
                if (periodicidad.value.length > 255) {
                    throw new Error("Periodicidad sobre el límite (" + periodicidad.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(tPeriodicidad && (!!tPeriodicidad.value || tPeriodicidad.value === 0) && /^\d+$/.test(tPeriodicidad.value))) {
                    throw new Error("Tiempo Periodicidad no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (obsGlosa && obsGlosa.value) {
                    if (obsGlosa.value.length > 255) {
                        throw new Error("Observación Glosa sobre el límite (" + obsGlosa.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (!(codPrestacionMinsal && codPrestacionMinsal.value)) {
                    throw new Error("Debe ingresar un Código Prestación Minsal (línea " + (index + 1) + "), favor revisar.");
                }
                if (codPrestacionMinsal.value.length > 255) {
                    throw new Error("Código Prestación Minsal sobre el límite (" + codPrestacionMinsal.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (presAdicionalEsencial && presAdicionalEsencial.value) {
                    if (presAdicionalEsencial.value.length > 255) {
                        throw new Error("prestación Adicional Esencial sobre el límite (" + presAdicionalEsencial.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (fechaInicio && fechaInicio.value) {
                    if (typeof fechaInicio.value === 'string' || typeof fechaInicio.value === 'object') {
                        if (isValid(fechaInicio.value)) {
                            fechaInicioLep = ajustaFecha(fechaInicio.value);
                        } else {
                            var InicioVigenciaParts = fechaInicio.value.split('/');
                            var mydate = new Date(InicioVigenciaParts[2], InicioVigenciaParts[1] - 1, InicioVigenciaParts[0]);
                            if (isValid(mydate)) {
                                fechaInicioLep = mydate;
                            }
                        }
                    }
                } else {
                    throw new Error("Debe ingresar Fecha Inicio (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaInicioLep === null) {
                    throw new Error("Fecha Inicio no es fecha válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaTermino && fechaTermino.value) {
                    if (isValid(fechaTermino.value)) {
                        fechaTerminoLEP = ajustaFecha(fechaTermino.value);
                    } else {
                        var termVig = fechaTermino.value.split('/');
                        var testdate = new Date(termVig[2], termVig[1] - 1, termVig[0]);
                        if (isValid(testdate)) {
                            fechaTerminoLEP = testdate;
                        } else {
                            throw new Error("Fecha Término no válida (línea " + (index + 1) + "), favor revisar.");
                        }
                    }
                }
                if (!(decreto && decreto.value && Number.isInteger(parseInt(decreto.value)))) {
                    throw new Error("Arancel no válido (línea " + (index + 1) + "), favor revisar.");
                }
                dataLEP.push({
                    problemaSalud: parseInt(problemaSalud.value),
                    intervencion: intervencion.value,
                    canasta: canasta.value,
                    tipoPrestacion: tipoPrestacion.value,
                    prestacion: prestacion.value,
                    der_Pab: der_Pab.value === "0" ? 0 : parseInt(der_Pab.value),
                    descripcion: descripcion.value,
                    AH: AH.value,
                    arancelMinsal: parseInt(arancelMinsal.value),
                    copagoGes: parseInt(copagoGes.value),
                    periodicidad: periodicidad.value,
                    tPeriodicidad: parseInt(tPeriodicidad.value),
                    obsGlosa: (obsGlosa&&obsGlosa.value) || null,
                    codPrestacionMinsal: codPrestacionMinsal.value,
                    presAdicionalEsencial: (presAdicionalEsencial&&presAdicionalEsencial.value) || null,
                    fechaInicio: fechaInicioLep,
                    fechaTermino: fechaTerminoLEP,
                    decreto: parseInt(decreto.value),
                });

            }
        });
        if (dataLEP.some((data, index) =>
            dataLEP.find((b, indexb) =>
                b.canasta === data.canasta &&
                b.prestacion === data.prestacion &&
                b.der_Pab === data.der_Pab &&
                b.problemaSalud === data.problemaSalud &&
                b.AH === data.AH &&
                b.periodicidad === data.periodicidad &&
                b.tPeriodicidad === data.tPeriodicidad &&
                b.decreto === data.decreto &&
                b.canasta === data.canasta &&
                b.descripcion === data.descripcion &&
                index !== indexb))) {
            errorMsg("Existen registros duplicados, favor revisar líneas.");
        } else {
            mutation(stringify(dataLEP), dataLEP.length, stringify(b64), name);
        }
    } catch (err) {
        errorMsg(err.message);
    }
}