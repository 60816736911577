import { useState } from "react";
import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import {
    Breadcrumbs,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { TabPanel } from "../../CasoGesCaec/Header/components/tabs";
import { ModalLoader } from "./components/modalLoader";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { importVdmc, historialVdmc, actualizacachevdmn, tablaVDMC, cargaexcelVDMC } from "../../../utils/queries"
import { parseExcel } from "./utils/generateExcel";
import { BotonCargaArchivo } from "../../../components/DragDrop/BotonCargaAchivo";
import { isMantenedor, hasJGESCAEC } from "../../../utils/hasPermisions";
import { HistorialCargaVDMC } from "./components/HistorialCargaVDMC";
import { fechaHoraGuionP } from "../../../utils/fechaUtils";
import TablaRegistrosV from "./components/TablaRegistrosV";
import { LoaderHistorial, SkeletonHeader } from "../utils/Loader";
import { SkeletonTableWithExport } from "../../../components/Skeleton/SkeletonTableWithExport";

export default function Vademecum({ rol, sucursal, usuario }) {
    const [tabValue, setTabValue] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [resumenVMDC, setResumenVDMC] = useState({});
    const [loadedVDMC, setLoadedVDMC] = useState(false);
    const [filtrosVDMC, setFiltrosVDMC] = useState({
        arancel: -1, eliminado: false, limit: 10,
        offset: 0, pag: 0, order: "patologia", dir: "DESC"
    });
    const [snackData, setSnackData] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });
    const { data, loading, error, refetch: refetchTabla } = useQuery(tablaVDMC, {
        variables: {
            patologia: filtrosVDMC.patologia || null,
            codcanasta: filtrosVDMC.codcanasta || null,
            formulaActiva: filtrosVDMC.formulaActiva || null,
            codigoSB: filtrosVDMC.codigoSB || null,
            descripcion: filtrosVDMC.descripcion || null,
            estado: filtrosVDMC.estado || null,
            arancel: filtrosVDMC.arancel || null,
            eliminado: filtrosVDMC.eliminado,
            limit: filtrosVDMC.limit,
            offset: filtrosVDMC.pag * filtrosVDMC.limit,
            order: filtrosVDMC.order,
            dir: filtrosVDMC.dir
        },
    });

    const { data: historialVDMC, loading: CargaHistorial, refetch, fetchMore, error: errorHistorial } = useQuery(historialVdmc, {
        variables: {
            limit: 15,
            offset: 0
        },
        onCompleted: (res) => procesaHistorial(res.historialVademecum),
        onError: (error) => setSnackData({
            abrir: true,
            mensaje: "Error al cargar historial: " + error.message,
            severidad: "error",
        })
    });

    const [cargabaseVDMC] = useMutation(cargaexcelVDMC, {
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al cargar excel a Gestor documental.",
            severidad: "error",
        })
    });

    const [updCacheVDMC] = useLazyQuery(actualizacachevdmn, {
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al actualizar cacheVDMC.",
            severidad: "error",
        })
    });

    const [putDataVDMC, { loading: cargandoVDMC }] = useMutation(importVdmc);

    const procesaHistorial = (historial) => {
        const datosResumen = historial.logs[0];
        const text = datosResumen.descripcion.split("/");

        setResumenVDMC({
            usuario: datosResumen.LoginAutorizacion,
            fecha: fechaHoraGuionP(datosResumen.FechaIngreso) + "hrs",
            cambios: text,
            totalVDMN: historial.totalVDMN
        });
    }

    const handleChange = (event, newValue) => {
        if (newValue === 2) {
            setFiltrosVDMC({
                eliminado: true, limit: 10, offset: 0, pag: 0, order: "patologia", dir: "DESC"
            });
        }
        if (newValue === 0) {
            setFiltrosVDMC({
                arancel: -1, eliminado: false, limit: 10, offset: 0, pag: 0, order: "patologia", dir: "DESC"
            });
        }
        setTabValue(newValue);
    };

    const cargaDatosVDMC = (data,dataDel, add, upd, del, b64, name) => {
        putDataVDMC({
            variables: {
                data: data,
                dataDel: dataDel,
                creados: add,
                actualizados: upd,
                eliminados: del,
                usuario: usuario,
                sucursal: sucursal
            },
            onCompleted: (data) => {
                setUploading(false);
                setSnackData({
                    abrir: true,
                    mensaje: "Carga completada",
                    severidad: "success",
                });
                cargabaseVDMC({
                    variables: {
                        id_log: parseInt(data.registravademecum),
                        base64: b64,
                        nombreArchivo: name
                    }
                });
                setTimeout(() => {
                    refetch();
                    refetchTabla();
                    updCacheVDMC();
                }, 500);
            },
            onError: () => msgError("No se ha logrado cargar la información, intente nuevamente.")
        });
    }

    const msgError = (msg) => {
        setUploading(false);
        setSnackData({
            abrir: true,
            mensaje: "Error al cargar excel: " + msg,
            severidad: "error",
        });
    }

    if (!loading && !CargaHistorial && !loadedVDMC) {
        setLoadedVDMC(true);
    }

    return (
        <div>
            <Mensajero data={snackData} setState={setSnackData} />
            <ModalLoader
                openLoader={cargandoVDMC}
            />
            <div className="container Main" data-testid="Vademecum">
                <label className="titulo1"> Vademecum</label>
                <Breadcrumbs
                    sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                    aria-label="breadcrumb"
                    className="breadcrum"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        underline="always"
                        color="inherit"
                        to="/"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Inicio
                    </Link>
                    <Link
                        underline="always"
                        color="inherit"
                        to="/Mantenedor"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Mantenedor
                    </Link>
                    <Typography
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "700",
                            marginBottom: "-1px",
                            lineHeight: "24px",
                        }}
                    >
                        Vademecum
                    </Typography>
                </Breadcrumbs>
                <Grid container>
                    {!loadedVDMC && (loading || CargaHistorial) ?
                        <SkeletonHeader /> :
                        <Grid item xs={12} className="container">
                            <Grid container p="14px 24px 24px 24px">
                                <Grid item xs={8} >
                                    <label className="titulo2">Carga masiva</label>{" "}
                                    <br></br>
                                    <label className="labelFormVademecum">
                                        Carga múltiples registros vademecum desde un archivo.
                                    </label>
                                    <label className="subUltActualizacion">
                                        Última actualización: {errorHistorial ? "NO DISPONIBLE" : resumenVMDC.fecha}.
                                    </label>
                                </Grid>
                                <Grid item xs={4} pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                    <Stack direction={"row"} spacing={"16px"} textAlign="right" justifyContent="flex-end">
                                        <label className="tipoFormatoMin">
                                            Formato aceptado: .xlsx <br></br>
                                            Peso máximo de 2MB
                                        </label>
                                        <BotonCargaArchivo
                                            disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}
                                            cargando={uploading}
                                            maxSize={2000}
                                            toDo={parseExcel}
                                            mutation={cargaDatosVDMC}
                                            msgError={msgError}
                                            setUploading={setUploading}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>}
                    <Grid item xs={12} mt="24px">
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: '#00837A' } }}
                        >
                            <Tab label="Registros vademecum" />
                            <Tab label="Historial de Carga" />
                            <Tab label="Registros eliminados" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={tabValue} index={0}>
                            {!loadedVDMC && (loading || CargaHistorial) ?
                                <SkeletonTableWithExport rows={filtrosVDMC.limit} /> :
                                <TablaRegistrosV
                                    data={data}
                                    error={error}
                                    loading={loading}
                                    filtros={filtrosVDMC}
                                    setFiltros={setFiltrosVDMC}
                                    setSnackData={setSnackData}
                                    refetch={refetchTabla}
                                    rol={rol}
                                    usuario={usuario}
                                    sucursal={sucursal}
                                />}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className="container">
                            {!loadedVDMC && (loading || CargaHistorial) ? <LoaderHistorial /> :
                                <HistorialCargaVDMC
                                    historial={(historialVDMC && historialVDMC.historialVademecum) || undefined}
                                    fetchMore={fetchMore}
                                    CargaHistorial={CargaHistorial}
                                    setSnackData={setSnackData}
                                />}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} >
                            {!loadedVDMC && (loading || CargaHistorial) ?
                                <SkeletonTableWithExport rows={filtrosVDMC.limit} /> :
                                <TablaRegistrosV
                                    data={data}
                                    error={error}
                                    loading={loading}
                                    filtros={filtrosVDMC}
                                    setFiltros={setFiltrosVDMC}
                                    setSnackData={setSnackData}
                                    refetch={refetchTabla}
                                    isDeleted={true}
                                    rol={rol}
                                    usuario={usuario}
                                    sucursal={sucursal}
                                />}
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}