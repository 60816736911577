import login_illus from "../../components/icons/login_illus.svg";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export default function Bienvenida({ isAuthorized }) {
    return (
        <div data-testid="bienvenida">
            <AuthenticatedTemplate>
                {isAuthorized ? null : (
                    <div className="container marginContainer">
                        <div className="row">
                            <div className="col-7">
                                <label className="Bienvenida">No estás autorizado</label>
                                <label className="alSistema">para visualizar esta página</label>
                                <label className="BienvenidaTexto">
                                    La cuenta seleccionada no posee el permiso correspondiente.
                                </label>
                            </div>
                            <div className="col-5">
                                <img alt="img2" src={login_illus}></img>
                            </div>
                        </div>
                    </div>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="container marginContainer" style={{ paddingBottom: "12px" }}>
                    <div className="row">
                        <div className="col-7">
                            <label className="Bienvenida">Te damos la bienvenida</label>
                            <label className="alSistema">
                                al sistema de Gestión de casos
                            </label>
                            <label className="BienvenidaTexto">
                                Aquí podrás ingresar y gestionar las solicitudes GES/CAEC de
                                nuestros beneficiarios.
                            </label>
                        </div>
                        <div className="col-5">
                            <img alt="img2" src={login_illus}></img>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </div>)
}
