import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import icon_errorModal from "../../../../components/icons/icon_errorModal.svg";
import { useNavigate } from "react-router-dom";

export function VerificaCaso({folio, setFkFun, ...props}) {
    const navigate = useNavigate();
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.modalCaso}
            onClose={() => props.setModalCaso(false)}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <Grid sx={{ m: 1, textAlign: "center", padding: "0 0" }}>
                <img alt="img2" src={icon_errorModal}></img>
            </Grid>
            <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
                {props.reabrir ?'No puedes reabrir el caso':'No puedes seleccionar este Problema de Salud'}
            </DialogTitle>
            <DialogContent
                sx={{ padding: "10px 0px!important" }}
            >
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    sx={{ padding: "0 20px" }}
                >
                    <Grid item xs={12}>
                        <Typography sx={{ padding: "0 15px", textAlign: "center" }}>
                            Ya existe un caso para el problema de Salud {props.ps} - {props.psNombre}, 
                        </Typography>
                        <Typography sx={{ padding: "0 15px", textAlign: "center" }}>
                            {props.preing ? "N° Solicitud " : "folio N° "} {folio}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ margin: "10px 0" }} />
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SecondaryButton
                            data-testid="cerrarModalDuplicado"
                            sx={{ marginRight: "30px" }}
                            onClick={() => props.setModalCaso(false)}
                        >
                            Aceptar
                        </SecondaryButton>
                        <PrimaryButton
                            data-testid="irCasoModalDuplicado"
                            onClick={() => {
                                sessionStorage.setItem("fkFun", folio);
                                if (!props.preing) {
                                    navigate("/GesCaeInformacion");
                                } else {
                                    setFkFun(folio);
                                    window.history.replaceState({}, document.title)
                                    navigate("/FormularioIngreso");
                                }
                                props.setModalCaso(false)
                            }}
                        >
                            Ir al caso existente
                        </PrimaryButton>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}