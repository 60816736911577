import icon_warningOrange from "../../../../../components/icons/icon_warningOrange.svg";
import icon_error from "../../../../../components/icons/icon_errorModal.svg";
import { eliminaCanasta } from "../../../../../utils/queries.js";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import BotonCarga from "../../../../../components/Buttons/BotonCarga";
import { SecondaryButton } from "../../../../../components/Buttons/Botones";
import { MensajeCanasta } from "./MensajeCanasta";

export const EliminarCanasta = ({
    open,
    onClose,
    handleClose,
    canasta,
    setMensajeroData,
    cargaBono,
    estadoCanasta,
    estado,
    usuario,
    sucursal
}) => {
    const [eliminarCanasta, { loading: eliminandoCanasta }] = useMutation(eliminaCanasta, {
        onCompleted: () => {
            setMensajeroData({
                abrir: true,
                mensaje: "Canasta eliminada correctamente.",
                severidad: "success",
            });
            onClose();
            const recargaPrest = document.getElementById("recargaPrest");
            if (recargaPrest) {
                recargaPrest.click();
            }
            const recargaCanasta = document.getElementById("recargaCanastas");
            if (recargaCanasta) {
                recargaCanasta.click();
            }
        },
        onError: () => {
            setMensajeroData({
                abrir: true,
                mensaje: "No se eliminó la canasta, intente nuevamente.",
                severidad: "error",
            });
            onClose();
        }
    });

    const menuEliminarCanasta = (event) => {
        handleEliminar();
        handleClose();
    };

    const handleEliminar = () => {
        eliminarCanasta({
            variables: {
                idCanastaAsignada: parseInt(canasta.id),
                estado: estado,
                usuario: usuario,
                sucursal: sucursal,
            },
        });
    };

    return (
        <Dialog
            justifyContent="center"
            data-testid="EliminarCanastaDialog"
            maxWidth="md"
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ paddingBottom: "0" }}>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px"
                    }}
                >
                    <img alt="img" src={estadoCanasta === 1 ? icon_error : icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2">
                    {estadoCanasta === 1 || estadoCanasta === -1 ? "No es posible eliminar" : "¿Seguro que quieres eliminar la canasta?"}
                </div>
            </DialogTitle>
            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
                <MensajeCanasta estadoCanasta={estadoCanasta} idCanasta={canasta.canasta} />
            </DialogContentText>
            <DialogActions
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ justifyContent: "center!important", margin: "5px 5px 15px 5px" }}
            >
                <BotonCarga
                    testid="btnEliminarCanasta"
                    loading={cargaBono || eliminandoCanasta}
                    disabled={estadoCanasta === -1 || estadoCanasta === 1}
                    toDo={menuEliminarCanasta}
                    label="Eliminar"
                    sx={{ marginRight: "10px" }}
                />
                <SecondaryButton
                    data-testid="closeModal"
                    disabled={eliminandoCanasta}
                    onClick={onClose}
                >
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};