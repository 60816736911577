// import { parseFecha } from './utils';

function parseaDatos(codigo, fecha, data){

    var datos = "";
    data.forEach((dato) => {

        datos += codigo 
            + "|" + fecha
            + "|" + dato.idGES
            + "|" + dato.rutPaciente
            + "|" + dato.DV
            + "|" + dato.nombrePaciente
            + "|" + dato.problemaSalud
            + "|" + dato.nombreGarantiaOportunidad
            + "|" + dato.tipoIntervencionSanitaria
            + "|" + dato.fechaInicioGOP
            + "|" + dato.fechaLimiteGOP
            + "\n"
    });

    return datos;

}

function parseaXlsx(codigo, fecha,general){
    
    var datos = [];
    datos.push(["Código Aseguradora","Periodo de Información","Identificación Única GES","RUT Beneficiario",
    "DV del RUT del Beneficiario","Nombre del Beneficiario","Problema de Salud","Nombre de la Garantia de Oportunidad",
    "Tipo de Intervención Sanitaria","Fecha de Inicio de la Garantia de Oportunidad","Fecha Límite de la Garantia de Oportunidad"])
    general.forEach((dato) => {
        
        datos.push([ 
            codigo,
            fecha,
            dato.idGES,
            dato.rutPaciente,
            dato.DV,
            dato.nombrePaciente,
            dato.problemaSalud,
            dato.nombreGarantiaOportunidad,
            dato.tipoIntervencionSanitaria,
            dato.fechaInicioGOP,
            dato.fechaLimiteGOP
        ]);
    })

    return datos
}

export function buildInfo105(cod, fecha, data) {
    return {
        "txt": parseaDatos(cod, fecha, data),
        "xlsx": parseaXlsx(cod, fecha, data)
    }
}