import { useState } from "react";
import {
  consultaCanastaasignadas,
} from "../../../../utils/queries.js";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/Table.css";
import { getComparator, stableSort } from "../../../../components/Table/utils";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { useQuery, NetworkStatus } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import AsignaPrestador from "../Prestadores/AsignaPrestador/AsignaPrestador";
import { useMsal } from "@azure/msal-react";
import { MenuCanasta } from "./MenuCanasta.jsx";
import { NoResult } from "../../../../components/NoData/NoResults";
import { IconHospital } from "../../../../components/icons/iconosEsencial.jsx";
import { fecha, fechaHora } from "../../../../utils/fechaUtils.js";

export function CanastaAsignadas({
  setMensajeroData,
  sucursal,
  paciente,
  deshabilitaInputs,
  folio,
  estado,
  fechaAutorizacion,
  rol
}) {
  const [ordenCanasta, setOrdenCanasta] = useState("desc");
  const [ordenaCanasta, setOrdenaCanasta] = useState("idDespliegue");
  const [pagCanasta, setPagCanasta] = useState(0);
  const [lineasPagCanasta, setLineasPagCanasta] = useState(5);
  const [resetPagCanasta, setResetPagCanasta] = useState(false);
  const { accounts } = useMsal();

  const { data, loading, error, refetch, networkStatus } = useQuery(
    consultaCanastaasignadas,
    {
      variables: {
        folio: folio,
        offset: lineasPagCanasta * pagCanasta,
        limit: lineasPagCanasta,
        order: ordenaCanasta,
        dir: ordenCanasta.toUpperCase(),
        aplicaVigencia:false
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onError: (err)=>console.log(err.message)
    }
  );

  if (loading && (networkStatus !== NetworkStatus.refetch)) {
    if (pagCanasta !== 0 && !resetPagCanasta) {
      setPagCanasta(0);
      setResetPagCanasta(false);
    }
    return <SkeletonTable test={"loading-canasta"} />;
  }

  if (error) {
    return <pre data-testid="errorCanasta">Error cargando datos: {error.message}</pre>;
  }

  const cambioPagCanasta = () => {
    setResetPagCanasta(true);
  }

  const canastaAsignada = [
    {
      id: "idDespliegue",
      sorted: true,
      sticky: true,
      label: "N°",
    },
    {
      id: "canasta",
      sorted: true,
      label: "Canasta",
    },
    {
      id: "descripcion",
      sorted: true,
      label: "Descripción",
    },
    {
      id: "fechaInicio",
      sorted: true,
      label: "Fecha inicio",
    },
    {
      id: "fechaFin",
      sorted: true,
      label: "Fecha Término",
    },
    {
      id: "prestadores",
      sorted: true,
      label: "Prestadores",
    },
    {
      id: "ciclo",
      sorted: true,
      label: "Ciclo",
    },
    {
      id: "estado",
      sorted: true,
      label: "Estado",
    },
    {
      id: "userCreated",
      sorted: true,
      label: "Creado por",
    },
    {
      id: "updatedUser",
      sorted: true,
      label: "Actualizado por",
    },
    {
      id: "",
      label: "Establecer prestador",
      sticky: true,
      stickyRight: true
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>

      <Button 
        id="recargaCanastas" 
        data-testid="recargaCanastas" 
        hidden={true} 
        onClick={() => refetch()} 
      />
      {!data || data.getCanastasasignadas.total === 0 ?
        <NoResult
          titulo={"Sin Canastas Asignadas"}
          subtitulo={"Aún no se han asignado Canastas"}
        /> :
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table>
              <CabeceraTabla
                order={ordenCanasta}
                orderBy={ordenaCanasta}
                setOrder={setOrdenCanasta}
                setOrderBy={setOrdenaCanasta}
                cabecera={canastaAsignada}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(data.getCanastasasignadas.canastas, getComparator(ordenCanasta, ordenaCanasta))
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const contDR = row.prestadoresAsignados.filter(pres => pres.tipoPrestador === "DR").length;
                    return (
                      <TableRow
                        hover
                        className="headTable"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          className="tableBodyIzquierda"
                        >
                          {row.idDespliegue}
                        </TableCell>
                        <TableCell align="left" className="fila">
                        <label className="labelnroCaso">{row.canasta}</label>
                        <label className="labeltipoCaso">({row.idBeneficios})</label>
                        </TableCell>
                        <TableCell align="left" className="fila" sx={{ minWidth: '400px' }}>
                          {row.descripcion}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {fecha(row.fechaInicio)}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.fechaFin ? fecha(row.fechaFin) : null}
                        </TableCell>
                        <TableCell align="center" className="fila">
                          <IconHospital /> {contDR}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.ciclo}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.vigencia === true ? "Vigente" : "No Vigente"}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.userCreated} {fechaHora(row.createdAt)}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.updatedUser} {fechaHora(row.updatedAt)}
                        </TableCell>
                        <TableCell
                          data-testid="openModal"
                          align="center"
                          className="tableBodyAsignar"
                        >
                          <AsignaPrestador
                            estadoSolicitud={estado}
                            folio={folio}
                            fechaAu={fechaAutorizacion}
                            data={row}
                            deshabilitaInputs={deshabilitaInputs}
                            setMensajeroData={setMensajeroData}
                            refetch={refetch}
                            sucursal={sucursal}
                          />
                        </TableCell>
                        <TableCell className="BodyAcciones" align="left">
                          <MenuCanasta
                            estado={estado}
                            canasta={row}
                            accounts={accounts}
                            paciente={paciente}
                            deshabilitaInputs={deshabilitaInputs}
                            setMensajeroData={setMensajeroData}
                            fechaAutorizacion={fechaAutorizacion}
                            sucursal={sucursal}
                            rol={rol}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.getCanastasasignadas.total}
              servPag={true}
              loadNextPage={cambioPagCanasta}
              itemPorPagina={lineasPagCanasta}
              pagina={pagCanasta}
              setPagina={setPagCanasta}
              setLineasPagina={setLineasPagCanasta}
            />
          </Grid>
        </Paper>}
    </Box>
  );
}