import { Fragment, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalEdicionMedicamento from "./ModalEdicionMedicamento";
import ModalEliminarMed from "./ModalEliminarMed";
import HistorialCambios from "./HistorialCambiosME";

export const MenuME = ({ row, disabled, eliminado, setSnackData, refetch, sucursal, usuario }) => {
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalHistorial, setModalHistorial] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirEditar = () => {
        handleClose();
        setModalEditar(true);
    };

    const abrirEliminar = () => {
        handleClose();
        setModalEliminar(true);
    };

    const abrirHistorialME = () => {
        handleClose();
        setModalHistorial(true);
    };

    return (
        <Fragment>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className="menuForm"
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                <MenuItem onClick={abrirHistorialME}>
                    Historial de cambios
                </MenuItem>
                <MenuItem disabled={disabled} hidden={eliminado} onClick={abrirEditar}>
                    Editar
                </MenuItem>
                <MenuItem disabled={disabled} hidden={eliminado} onClick={abrirEliminar}>
                    Eliminar
                </MenuItem>
            </Menu>
            <HistorialCambios
                onOpen={modalHistorial}
                onClose={() => setModalHistorial(false)}
                me={row}
            />
            <ModalEdicionMedicamento
                data={row}
                onOpen={modalEditar}
                onClose={() => setModalEditar(false)}
                refetch={refetch}
                setSnackData={setSnackData}
                sucursal={sucursal}
                usuario={usuario}
            />
            <ModalEliminarMed
                data={row}
                onOpen={modalEliminar}
                onClose={() => setModalEliminar(false)}
                refetch={refetch}
                setSnackData={setSnackData}
                sucursal={sucursal}
                usuario={usuario}
            />
        </Fragment>
    )
}