import { Fragment } from "react";
import { Divider, Grid } from "@mui/material"
import { IconIdea } from "../icons/iconosEsencial";
import  ErrorCircle  from "../icons/icon_circleError.svg";

export function NoResult({ titulo, subtitulo, noDivider, error }) {
    return (
        <Fragment>
            {noDivider ? null :
                <Grid item xs={12}>
                    <Divider
                        width="1px"
                        orientation="horizontal"
                        sx={{ width: "100%" }}
                    ></Divider>
                </Grid>}
            <div className="box-resultados">

                <Grid
                    sx={{ margin: "35px" }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {error ? <img src={ErrorCircle} alt="error"/>
                        : <IconIdea className="icon" />}
                </Grid>
                <Grid
                    sx={{ margin: "auto 0" }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <label className="TituloResultadoTabla">{titulo}</label>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <label className="subtituloResultadoTabla">{subtitulo}</label>
                </Grid>
            </div>
        </Fragment>
    );
}