import { FormControlLabel, Radio } from "@mui/material";
import { motivosGeneral } from "../../../../utils/queries";
import { useQuery } from "@apollo/client";

export  function Opciones({ disabled, ...props}) {
    const { data, loading, error } = useQuery(motivosGeneral, {
      variables: { tipocaso: props.tipoCaso === "GES" ? 1 : 2, accion: props.tipo},
    });
    if(props.tipo === 2){
      return null;
    }
    if (loading) {
      return "Cargando opciones...";
    }
    if (error){
      console.log(error.message)
      return "Error cargando opciones";
    }

    return data.getmotivos.map((dato, index) => (
        <FormControlLabel
          key={index}
          value={parseInt(dato.id)}
          control={<Radio />}
          disabled={disabled}
          label={dato.descripcion}
          data-testid="OpcionesCerrado"
        />
      ));
  }