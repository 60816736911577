import { useState } from "react";
import { getps } from "./queries";
import { useQuery } from "@apollo/client";
import { SelectPersonalizado } from "../components/select/SelectPersonalizado";

export function Patologias({ asignaNombre, disabled, error, form1, psNombre, setValue, value, filter }) {
    const [patologias, setPatologias] = useState([{label:"Cargando...", value:""}]);
    const { data } = useQuery(getps, {
        fetchPolicy: "cache-first",
        onCompleted: (data) => { 
            let array = filter ? [{label: "Todos", value: ""}] :[];
            data.getProblemasSalud.forEach((element) => {
                array.push({label:element.Codigo+" - "+element.GlosaSis, value: element.Codigo})
            });
            setPatologias(array);
        },
        onError: (err)=> {
            console.log("ERROR PATOLOGIAS: ", err.message);
            setPatologias([{label:"ERROR", value:""}])
        }
    })


    function returnValue(valor) {
        const selectedValue = data.getProblemasSalud.find((element) => element.Codigo === valor);
        setValue(selectedValue);
    }
    if (data && form1) {
        if (!psNombre && value !== "") {
            const selectedValue = data.getProblemasSalud.find((element) => element.Codigo === value);
            asignaNombre(selectedValue.GlosaSis);
        }
    }

    return (
        <SelectPersonalizado
            inputTest="problemaSaludSelect"
            id="diagnostico"
            error={error}
            value={value || ""}
            disabled={disabled}
            setValue={returnValue}
            options={patologias}
            placeholder={"Seleccione Patología"}
            placeholderHidden={filter}
        />
    );
}