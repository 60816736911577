export default function TextDialogSeguimiento(value){
    switch(value){
        case 1: return "Caso Ingresado";
        case 2: return "Caso Autorizado";
        case 3: return "Caso Rechazado";
        case 4: return "Caso Cerrado";
        case 5: return "Caso Derivado a GEP";
        case 6: return "Asignación de canasta";
        case 7: return "Edición de Fecha Canasta";
        case 8: return "Asignación de prestador";
        case 9: return "Edicion del prestador";
        case 15: return "Caso Anulado";
        case 23: return "Caso Pre-Ingresado";
        case 26: return "Caso Re-abierto";
        case 29: return "Eliminacion hito asignado";
        case 30: return "Edición de Fecha de Hito";
        case 31: return "Eliminación Canasta";
        default: return "ACCIÓN " + value + " NO DEFINIDA";
    }
}