import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { styled } from "@mui/material";
import "./appbar.css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { isContralor, isMantenedor } from "../../utils/hasPermisions";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4D5D68",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4D5D68",
    fontSize: "14px",
    fontFamily: "Interstate",
    padding: "8px 12px 8px 12px",
  },
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? 240 : 64,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": {
      paddingTop: "16px",
      width: 240,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      width: 64,
      paddingTop: "16px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
  }),
}));

export const DrawerLeft = ({ open, handleDrawerClose, handleDrawerOpen, rol }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ width: 310 }} aria-label="mailbox folders">
      <CustomDrawer
        variant="permanent"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: open ? 300 : 65,
            background: "#FFFFFF",
            color: "#67737C",
            fontFamily: "Interstate",
            fontSize: "16px",
            borderRight: "none",
            top: "65px",
            // [theme.breakpoints.up('md')]: {
            //     top: '64px'
            // }
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box display="flex" justifyContent={open ? "flex-end" : "center"}>
          <div className="titleContenedor">
            {open ? (
              <Typography className="drawerTitle">Gestión de casos</Typography>
            ) : null}
          </div>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ArrowBackRoundedIcon />
              ) : (
                <ArrowForwardRoundedIcon />
              )}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <ArrowForwardRoundedIcon />
            </IconButton>
          )}
        </Box>

        <List className="listDrawer">
          {/* <BootstrapTooltip title="Inicio" arrow placement="right">
            <ListItem button onClick={()=>cambioRuta("")}>
              <ListItemIcon className="Inicio">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill=""
                >
                  <path
                    d="M19.8832 7.70202L17.5 5.75529V2.73149C17.5 2.66932 17.4753 2.6097 17.4314 2.56574C17.3874 2.52178 17.3278 2.49708 17.2656 2.49708H16.4843C16.4221 2.49708 16.3625 2.52178 16.3185 2.56574C16.2746 2.6097 16.2499 2.66932 16.2499 2.73149V4.73681L10.7879 0.278449C10.5645 0.0982654 10.2861 0 9.99912 0C9.71211 0 9.43376 0.0982654 9.21035 0.278449L0.115096 7.70202C0.0509452 7.7543 0.0101478 7.82989 0.00165221 7.91221C-0.00684336 7.99453 0.0176565 8.07685 0.0697781 8.14113L0.464358 8.62596C0.516431 8.68983 0.591622 8.73055 0.673567 8.73925C0.755512 8.74796 0.83758 8.72395 0.901912 8.67245L2.4982 7.36838V16.8617C2.4982 17.0275 2.56406 17.1865 2.68128 17.3037C2.79851 17.421 2.9575 17.4868 3.12328 17.4868H8.12389C8.28967 17.4868 8.44866 17.421 8.56589 17.3037C8.68311 17.1865 8.74897 17.0275 8.74897 16.8617V11.8611L11.2493 11.8728V16.8766C11.2493 17.0424 11.3151 17.2014 11.4324 17.3186C11.5496 17.4358 11.7086 17.5017 11.8744 17.5017L16.875 17.4888C17.0407 17.4888 17.1997 17.4229 17.317 17.3057C17.4342 17.1885 17.5 17.0295 17.5 16.8637V7.36838L19.0963 8.67245C19.1606 8.72469 19.2431 8.74927 19.3255 8.74077C19.4079 8.73227 19.4836 8.6914 19.5358 8.62713L19.9304 8.14231C19.9563 8.11032 19.9756 8.07355 19.9872 8.03408C19.9989 7.99462 20.0026 7.95325 19.9982 7.91235C19.9938 7.87144 19.9814 7.8318 19.9617 7.79571C19.9419 7.75962 19.9152 7.72778 19.8832 7.70202V7.70202ZM16.2456 16.2417H16.2495L12.499 16.2535V11.2462C12.4985 11.0809 12.4328 10.9225 12.3161 10.8055C12.1994 10.6884 12.0412 10.6222 11.8759 10.6211L8.12546 10.6106C8.04321 10.6103 7.96171 10.6263 7.88565 10.6576C7.80959 10.6889 7.74045 10.7349 7.6822 10.793C7.62395 10.851 7.57773 10.92 7.54619 10.996C7.51466 11.072 7.49843 11.1534 7.49843 11.2357V16.2417H3.74836V6.34834L9.99912 1.24732L16.2499 6.34834L16.2456 16.2417Z"
                    fill=""
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
          </BootstrapTooltip> */}
          <BootstrapTooltip title="Beneficiario" arrow placement="right">
            <ListItem 
            className="leftMenu"
              hidden={isMantenedor(rol)}
              selected={
                window.location.pathname === "/" ? true : false
              }
              button
              onClick={() => navigate("/")}
            >
              <ListItemIcon className="leftMenu" >
                
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 17 22"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5313 3.125H10.8973C10.9137 3.01836 10.9219 2.90762 10.9219 2.79688C10.9219 1.52949 9.89238 0.5 8.625 0.5C7.35762 0.5 6.32813 1.52949 6.32813 2.79688C6.32813 2.90762 6.33633 3.01836 6.35274 3.125H2.71875C1.63184 3.125 0.75 4.00684 0.75 5.09375V19.5312C0.75 20.6182 1.63184 21.5 2.71875 21.5H14.5313C15.6182 21.5 16.5 20.6182 16.5 19.5312V5.09375C16.5 4.00684 15.6182 3.125 14.5313 3.125ZM8.625 1.8125C9.17051 1.8125 9.60938 2.25137 9.60938 2.79688C9.60938 3.34238 9.17051 3.78125 8.625 3.78125C8.07949 3.78125 7.64063 3.34238 7.64063 2.79688C7.64063 2.25137 8.07949 1.8125 8.625 1.8125ZM15.1875 19.5312C15.1875 19.8922 14.8922 20.1875 14.5313 20.1875H2.71875C2.35781 20.1875 2.0625 19.8922 2.0625 19.5312V5.09375C2.0625 4.73281 2.35781 4.4375 2.71875 4.4375H4.6875V5.25781C4.6875 5.52852 4.90898 5.75 5.17969 5.75H12.0703C12.341 5.75 12.5625 5.52852 12.5625 5.25781V4.4375H14.5313C14.8922 4.4375 15.1875 4.73281 15.1875 5.09375V19.5312ZM12.2344 12.3125H9.9375V10.0156C9.9375 9.83516 9.78984 9.6875 9.60938 9.6875H7.64063C7.46016 9.6875 7.3125 9.83516 7.3125 10.0156V12.3125H5.01563C4.83516 12.3125 4.6875 12.4602 4.6875 12.6406V14.6094C4.6875 14.7898 4.83516 14.9375 5.01563 14.9375H7.3125V17.2344C7.3125 17.4148 7.46016 17.5625 7.64063 17.5625H9.60938C9.78984 17.5625 9.9375 17.4148 9.9375 17.2344V14.9375H12.2344C12.4148 14.9375 12.5625 14.7898 12.5625 14.6094V12.6406C12.5625 12.4602 12.4148 12.3125 12.2344 12.3125Z"
                    fill=""
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Beneficiario" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip title="Bandeja de Casos" arrow placement="right">
            <ListItem
            className="leftMenu"
              hidden={isMantenedor(rol)}
              selected={
                window.location.pathname === "/TodosLosCasos" ? true : false
              } button onClick={() => navigate("/TodosLosCasos")}>
              <ListItemIcon className="leftMenu">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 19 17"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 8.59443C7.5 8.26613 7.76613 8 8.09443 8H10.6556C10.9839 8 11.25 8.26613 11.25 8.59443V8.90557C11.25 9.23387 10.9839 9.5 10.6556 9.5H8.09443C7.76613 9.5 7.5 9.23387 7.5 8.90557V8.59443Z"
                    fill=""
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1.09443C0 0.766134 0.266134 0.5 0.594427 0.5H18.1556C18.4839 0.5 18.75 0.766134 18.75 1.09443V5.15557C18.75 5.48387 18.4839 5.75 18.1556 5.75H17.25V15.6556C17.25 15.9839 16.9839 16.25 16.6556 16.25H2.09443C1.76613 16.25 1.5 15.9839 1.5 15.6556V5.75H0.594427C0.266134 5.75 0 5.48387 0 5.15557V1.09443ZM17.25 4.25V2H1.5V4.25H17.25ZM3 14.75H15.75V5.75H3V14.75Z"
                    fill=""
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Bandeja de Casos" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip
            hidden={isContralor(rol) || isMantenedor(rol)}
            title="Reportería"
            arrow
            placement="right"
          >
            <ListItem
            className="leftMenu"
             selected={
              window.location.pathname === "/Reporteria" ? true : false
            } button onClick={() => navigate("/Reporteria")}>
              <ListItemIcon
               className="leftMenu">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 12 16"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 7.75V8.625C9 8.83125 8.83125 9 8.625 9H3.375C3.16875 9 3 8.83125 3 8.625V7.75C3 7.54375 3.16875 7.375 3.375 7.375H8.625C8.83125 7.375 9 7.54375 9 7.75ZM8.625 10H3.375C3.16875 10 3 10.1687 3 10.375V11.25C3 11.4563 3.16875 11.625 3.375 11.625H8.625C8.83125 11.625 9 11.4563 9 11.25V10.375C9 10.1687 8.83125 10 8.625 10ZM12 4.12187V14.5C12 15.3281 11.3281 16 10.5 16H1.5C0.671875 16 0 15.3281 0 14.5V1.5C0 0.671875 0.671875 0 1.5 0H7.87813C8.275 0 8.65625 0.159375 8.9375 0.440625L11.5594 3.0625C11.8406 3.34063 12 3.725 12 4.12187ZM8 1.62188V4H10.3781L8 1.62188ZM10.5 14.5V5.5H7.25C6.83437 5.5 6.5 5.16563 6.5 4.75V1.5H1.5V14.5H10.5Z"
                    fill=""
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Reportería" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip
            hidden={isContralor(rol) || isMantenedor(rol)}
            title="Formularios"
            arrow
            placement="right"
            className="leftMenu"
          >
            <ListItem
            className="leftMenu"
             selected={
              window.location.pathname === "/Formularios" ? true : false
            } button onClick={() => navigate("/Formularios")}>
              <ListItemIcon>
                <svg width="20" height="20" viewBox="0 0 12 16" fill="" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" fill="" />
                </svg>

              </ListItemIcon>
              <ListItemText primary="Formularios" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip title="Mantenedor" arrow placement="right">
            <ListItem
            className="leftMenu"
              button
              selected={
                window.location.pathname === "/Mantenedor" ? true : false
              } onClick={() => navigate("/Mantenedor")}
            >
              <ListItemIcon
              className="leftMenu">
                <svg width="20" height="20" viewBox="0 0 16 11" fill="" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.6644 4.58333H13.3338V3.20833C13.3338 2.44922 12.7365 1.83333 12.0004 1.83333H7.5558L5.77797 0H1.33338C0.597242 0 0 0.615885 0 1.375V9.625C0 10.3841 0.597242 11 1.33338 11H12.4449C12.9032 11 13.331 10.7565 13.5754 10.3526L15.795 6.68594C16.3505 5.77214 15.7116 4.58333 14.6644 4.58333ZM1.33338 1.54688C1.33338 1.45234 1.40838 1.375 1.50005 1.375H5.22517L7.00301 3.20833H11.8337C11.9254 3.20833 12.0004 3.28568 12.0004 3.38021V4.58333H4.22236C3.75568 4.58333 3.32233 4.83542 3.08066 5.24792L1.33338 8.23281V1.54688ZM12.4449 9.625H2.00007L4.14458 5.95833H14.6671L12.4449 9.625Z" fill="" />
                </svg>

              </ListItemIcon>
              <ListItemText primary="Mantenedor" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip title="Medi. de Excepción" arrow placement="right">
            <ListItem
            className="leftMenu"
              button
              selected={
                window.location.pathname === "/Medicamento" ? true : false
              } onClick={() => navigate("/Medicamento")}
            >
              <ListItemIcon
              className="leftMenu">
                <svg width="20" height="20" viewBox="0 0 12 16" fill="" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 2H8C8 0.896875 7.10313 0 6 0C4.89687 0 4 0.896875 4 2H1.5C0.671875 2 0 2.67188 0 3.5V14.5C0 15.3281 0.671875 16 1.5 16H10.5C11.3281 16 12 15.3281 12 14.5V3.5C12 2.67188 11.3281 2 10.5 2ZM6 1.25C6.41563 1.25 6.75 1.58438 6.75 2C6.75 2.41563 6.41563 2.75 6 2.75C5.58437 2.75 5.25 2.41563 5.25 2C5.25 1.58438 5.58437 1.25 6 1.25ZM10.5 14.3125C10.5 14.4156 10.4156 14.5 10.3125 14.5H1.6875C1.58438 14.5 1.5 14.4156 1.5 14.3125V3.6875C1.5 3.58437 1.58438 3.5 1.6875 3.5H3V4.625C3 4.83125 3.16875 5 3.375 5H8.625C8.83125 5 9 4.83125 9 4.625V3.5H10.3125C10.4156 3.5 10.5 3.58437 10.5 3.6875V14.3125ZM8.75 9H7V7.25C7 7.1125 6.8875 7 6.75 7H5.25C5.1125 7 5 7.1125 5 7.25V9H3.25C3.1125 9 3 9.1125 3 9.25V10.75C3 10.8875 3.1125 11 3.25 11H5V12.75C5 12.8875 5.1125 13 5.25 13H6.75C6.8875 13 7 12.8875 7 12.75V11H8.75C8.8875 11 9 10.8875 9 10.75V9.25C9 9.1125 8.8875 9 8.75 9Z" fill="" />
                </svg>
              </ListItemIcon>
              <ListItemText primary="Medi. de Excepción" />
            </ListItem>
          </BootstrapTooltip>
          <BootstrapTooltip title="Ayuda" arrow placement="right">
            <ListItem
            className="leftMenu"
              button
            //onClick={()=>cambioRuta("ayuda")}
            >
              <ListItemIcon
              className="leftMenu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.75 15.4437C10.75 15.0295 10.4142 14.6937 9.99998 14.6937C9.58576 14.6937 9.24998 15.0295 9.24998 15.4437H10.75ZM9.24998 15.4546C9.24998 15.8688 9.58576 16.2046 9.99998 16.2046C10.4142 16.2046 10.75 15.8688 10.75 15.4546H9.24998ZM9.24998 12.1818C9.24998 12.596 9.58576 12.9318 9.99998 12.9318C10.4142 12.9318 10.75 12.596 10.75 12.1818H9.24998ZM6.00312 6.73034C5.89527 7.13027 6.13204 7.5419 6.53197 7.64975C6.9319 7.7576 7.34353 7.52083 7.45138 7.1209L6.00312 6.73034ZM9.24998 15.4437V15.4546H10.75V15.4437H9.24998ZM12.5227 7.71901C12.5227 8.41202 12.0825 8.77569 11.2138 9.36793C10.4694 9.87551 9.24998 10.6328 9.24998 12.1818H10.75C10.75 11.549 11.1669 11.2154 12.0588 10.6073C12.8266 10.0838 14.0227 9.30699 14.0227 7.71901H12.5227ZM9.99998 5.29545C11.4397 5.29545 12.5227 6.39784 12.5227 7.71901H14.0227C14.0227 5.53477 12.2331 3.79545 9.99998 3.79545V5.29545ZM7.45138 7.1209C7.72539 6.10482 8.76259 5.29545 9.99998 5.29545V3.79545C8.13863 3.79545 6.46735 5.00887 6.00312 6.73034L7.45138 7.1209ZM5.63634 2.02273H14.3636V0.522728H5.63634V2.02273ZM17.9772 5.63636V14.3636H19.4772V5.63636H17.9772ZM14.3636 17.9773H5.63634V19.4773H14.3636V17.9773ZM2.02271 14.3636V5.63636H0.522705V14.3636H2.02271ZM5.63634 17.9773C3.64059 17.9773 2.02271 16.3594 2.02271 14.3636H0.522705C0.522705 17.1878 2.81216 19.4773 5.63634 19.4773V17.9773ZM17.9772 14.3636C17.9772 16.3594 16.3594 17.9773 14.3636 17.9773V19.4773C17.1878 19.4773 19.4772 17.1878 19.4772 14.3636H17.9772ZM14.3636 2.02273C16.3594 2.02273 17.9772 3.64061 17.9772 5.63636H19.4772C19.4772 2.81218 17.1878 0.522728 14.3636 0.522728V2.02273ZM5.63634 0.522728C2.81216 0.522728 0.522705 2.81218 0.522705 5.63636H2.02271C2.02271 3.64061 3.64059 2.02273 5.63634 2.02273V0.522728Z"
                    fill="#BCC2C6"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText sx={{ color: "#BCC2C6" }} primary="Ayuda" />
            </ListItem>
          </BootstrapTooltip>

        </List>
      </CustomDrawer>
    </Box>
  );
};
