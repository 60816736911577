import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import { ErrorModalIcon } from "../../../../components/icons/iconosEsencial";
import PrimaryButton from "../../../../components/Buttons/Botones";

export const ModalErrorCarga = ({ open, onClose, fkFun }) => {
    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ padding: "20px" }}>
                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center" }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        data-testid="dialogModal"
                        sx={{ m: 1, textAlign: "center", padding: "0" }}
                    ></Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        data-testid="dialogModal"
                        sx={{ m: 1, textAlign: "center", padding: "0" }}
                    >
                        <ErrorModalIcon
                            sx={{ width: "42px", height: "42px" }}
                        ></ErrorModalIcon>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DialogTitle className="tituloModal" sx={{ padding: "0 0 6px" }}>
                            Error en la carga de archivos
                        </DialogTitle>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <Typography>
                            No se cargó el archivo:<strong>Formulario2_SF_{fkFun}.pdf</strong>
                        </Typography>
                        <Typography>
                            Puedes intentar cargar el formulario nuevamente desde bandeja de casos.
                        </Typography>
                    </Grid>
                </DialogContentText>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        onClick={onClose}
                    >
                        <PrimaryButton>Continuar</PrimaryButton>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}