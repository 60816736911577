import {
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import { DragAndDrop } from "../../../../components/DragDrop/DragAndDrop";
import { Patologias } from "../../../../utils/patologias";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";
import { SelectOpciones } from "./SelectOpciones"
import ListaAntecedentes from "./ListaAntecedentes";

export default function DefinicionCaso({
    campos,
    setCampos,
    casoInvalido,
    error,
    setError,
    deshabilita,
    setDesahilita,
    detectaCambio,
    verificaCaso,
    antecedentesSV
}) {
    function detectaCambioComboTipoConst(valor) {
        let dataInsti = {
            rutInsti: "",
            direccion: "",
            luat: "",
            razonSocial: "",
            rutMedico: "",
            medico: [],
            nombreMedico: "",
            primerApellidoMedico: "",
            segundoApellidoMedico: "",
            especialidad: "",
            tratamientoActual: ""
        };
        if (valor === "GES") {
            dataInsti = {};
            setDesahilita({
                ...deshabilita,
                eventoAdm: false
            });
        } else if (valor === "CAEC") {
            setDesahilita({
                ...deshabilita,
                eventoAdm: true
            });
        }
        setCampos({
            ...campos,
            tipoCaso: valor,
            fkProblemaSalud: null,
            diagnosticoCaec: null,
            eventoAdmin: null,
            fechaEventoAdm: null,
            ...dataInsti
        });
        setError({
            ...error,
            fkProblemaSalud: null,
            diagnosticoCaec: null,
            eventoAdmin: null,
            fechaEventoAdm: null,
            diagnosticoNombre: null,
            tipoCaso: null
        });
    }

    function ingresoDiagostico(valor) {
        setCampos({
            ...campos,
            fkProblemaSalud: valor.Codigo,
            diagnosticoNombre: valor.GlosaSis,
            verificado: null
        });
        setError({
            ...error,
            fkProblemaSalud: null
        });
        verificaCaso({
            variables:
            {
                problemaSalud: valor.Codigo,
                rut: campos.rutPaciente.replace(/[^0-9kK-]/g, "")
            }
        });
    }

    const AsignaNombrePS = (nombre) => {
        setCampos({
            ...campos,
            diagnosticoNombre: nombre
        });
    }

    function cambioEventoAdm(valor) {
        setCampos({
            ...campos,
            "eventoAdmin": valor,
            fechaEventoAdm: null,
        });
        setError({
            ...error,
            "eventoAdmin": null,
            fechaEventoAdm: null,
        });
    }

    function cambioAntecedente(valor) {
        setCampos({ ...campos, "fkTipoAntecedente": valor });
        setError({ ...error, "fkTipoAntecedente": null });
    }

    function DetectaCambioArchivo(file) {
        setCampos({
            ...campos,
            files: file,
        })
        setError({
            ...error,
            archivo: null
        })
    }

    function DetectaCambioArchivoOpt(file) {
        setCampos({
            ...campos,
            filesOpt: file,
        });
    }

    const tipoCasoSel = [
        { label: "GES", value: "GES" },
        { label: "CAEC", value: "CAEC" }
    ];

    return (
        <div className="card-box">
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <label className="title">Tipo de caso</label>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Tipo de constancia</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <SelectPersonalizado
                            inputTest="tipoCasoFormSelectTipoCaso"
                            id="tipoCaso"
                            error={error.tipoCaso}
                            value={campos.tipoCaso || ""}
                            disabled={casoInvalido || (campos.fkFun ? true : false)}
                            setValue={detectaCambioComboTipoConst}
                            options={tipoCasoSel}
                            placeholder={"Seleccione"}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{ display: campos.tipoCaso !== "CAEC" ? "block" : "none" }}
                >
                    <label className="labelForm">Problema de salud</label>
                    <FormControl
                        sx={{ width: "98%!important", margin: "0 1% 0 0" }}
                    >
                        <Patologias
                            error={error.fkProblemaSalud ? true : undefined}
                            value={campos.fkProblemaSalud || ""}
                            disabled={casoInvalido || !campos.tipoCaso || (campos.fkFun ? true : false)}
                            setValue={ingresoDiagostico}
                            form1={true}
                            psNombre={campos.diagnosticoNombre}
                            asignaNombre={AsignaNombrePS}
                        />
                        <span className="errorSpan">{error.fkProblemaSalud}</span>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{ display: campos.tipoCaso !== "CAEC" ? "none" : "block" }}
                >
                    <label className="labelForm">Diagnóstico CAEC</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="diagnosticoCaecTest"
                            id="diagnosticoCaec"
                            placeholder="Ej: Cirugía Vascular"
                            error={error.diagnosticoCaec ? true : undefined}
                            disabled={casoInvalido || (campos.fkFun ? true : false)}
                            onChange={detectaCambio}
                            value={campos.diagnosticoCaec || ""}
                            inputProps={{ maxLength: 100 }}
                        />
                        <span className="errorSpan">{error.diagnosticoCaec}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Evento Adm.</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <SelectOpciones
                            placeholder={"Selecciona"}
                            disabled={casoInvalido || !campos.tipoCaso || (campos.tipoCaso === "CAEC" ? true : false)}
                            setValue={cambioEventoAdm}
                            name="eventoAdmin"
                            id="eventoAdmin"
                            value={campos.eventoAdmin || ""}
                            tipo="EA"
                        />
                        <span className="errorSpan">{error.eventoAdmin}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Fecha evento Adm.</label>
                    <DatePicker
                        id="fechaEventoAdm"
                        testid="fechaEventoAdm"
                        error={error.fechaEventoAdm}
                        disabled={casoInvalido || !campos.tipoCaso || campos.eventoAdmin === 14 || (campos.tipoCaso === "CAEC" ? true : false)}
                        value={campos.fechaEventoAdm || null}
                        noClear
                        onChange={(newValue) => {
                            setCampos({
                                ...campos,
                                fechaEventoAdm: newValue
                            });
                            setError({
                                ...error,
                                fechaEventoAdm: null
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <label className="labelForm">Hora evento Adm.</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                        <DesktopTimePicker
                            disabled={casoInvalido || !campos.tipoCaso || campos.eventoAdmin === 14 || (campos.tipoCaso === "CAEC" ? true : false)}
                            type="time"
                            label=""
                            inputProps={{ "data-testid": "horaEvento", "id": "horaEvento" }}
                            value={campos.fechaEventoAdm || null}
                            onChange={(newValue) => {
                                setCampos({
                                    ...campos,
                                    fechaEventoAdm: newValue
                                });
                                setError({
                                    ...error,
                                    fechaEventoAdm: null
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} onKeyDown={(e) => { e.preventDefault() }} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Antecedentes presentados</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <SelectOpciones
                            placeholder={"Selecciona"}
                            setValue={cambioAntecedente}
                            value={campos.fkTipoAntecedente || ""}
                            disabled={casoInvalido || (campos.fkFun && campos.origen === 3)}
                            id="fkTipoAntecedente"
                            tipo="AP"
                            tipoCaso={campos.tipoCaso}
                        />
                        <span className="errorSpan">{error.fkTipoAntecedente}</span>
                    </FormControl>
                </Grid>
                {campos.fkFun && antecedentesSV.length > 0 ?
                    <Grid item xs={12}>
                        <ListaAntecedentes antecedentes={antecedentesSV} />
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Grid item sx={{ width: "99.4%!important", margin: "0 0 24px" }}>
                            <label className="labelForm">Cargar antecedentes <label className="formatoLabel">Formatos válidos: GIF, JPEG, JPG, PNG, PDF (5 MB Máximo)</label></label>
                            <DragAndDrop
                                files={campos.files}
                                disabled={casoInvalido || (campos.fkFun && antecedentesSV.length > 0) || (campos.files ? true : false)}
                                setFiles={DetectaCambioArchivo}
                                maxFiles={1}
                            />
                            <span className="errorSpan">{error.archivo}</span>
                        </Grid>
                    </Grid>}
                <Grid item xs={12} hidden={(campos.files === undefined || campos.files === null) && antecedentesSV.length === 0 ? true : false}>
                    <Grid item sx={{ width: "99.4%!important", margin: "0 0 24px" }}>
                        <label className="labelForm">Cargar antecedentes adicionales (opcional) <label className="formatoLabel">Formatos válidos: GIF, JPEG, JPG, PNG, PDF (5 MB Máximo)</label></label>
                        <DragAndDrop
                            disabled={casoInvalido}
                            files={campos.filesOpt}
                            setFiles={DetectaCambioArchivoOpt}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}