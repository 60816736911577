import { OutlinedButton } from "../../../components/Buttons/Botones";
import {
    Chip,
    Grid,
    Stack,
} from "@mui/material";
import { Cruz } from "../../../components/icons/iconosEsencial";

export default function FiltroChip({ chip, cleanOne, cleanAll }) {
    function NombreTag(valor) {
        switch (valor) {
            case "numeroCaso": return "N° Caso";
            case "tipoCaso": return "Tipo Caso";
            case "rutBeneficiario": return "RUT";
            case "nombres": return "Nombres";
            case "estado": return "Estado";
            case "fechaSolicitudDesde": return "Fecha Desde";
            case "fechaSolicitudHasta": return "Fecha Hasta";
            case "patologia": return "PS";
            case "origen": return "Origen";
            case "nroSolicitudSV": return "N° SV";
            default: return "UNDEFINED";
        }
    }
    return (
        <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
                {chip.map((data, index) => (
                    <Chip
                        className="tagLila"
                        data-testid="chipBandeja"
                        key={index}
                        label={NombreTag(data)}
                        onDelete={() => cleanOne(data)}
                        deleteIcon={<Cruz sx={{ fontSize: "10px" }}/>}
                    />
                ))}
                <OutlinedButton
                    data-testid="limpiaFiltros"
                    onClick={cleanAll}
                >
                    Limpiar filtros
                </OutlinedButton>
            </Stack>
        </Grid>
    );
}