import React from "react";
import "../../../assets/App.css";
import "../../../assets/Modales.css";
import "../../../assets/Repositorio.css";
import Grid from "@mui/material/Grid";
import { AsignaHitoInicio } from "./components/AsignaHitoInicio";
import TablaHitos from "./components/TablaHitos";

function GesCaeHitos({ deshabilitaInputs, info, rol,sucursal }) {
  return (
    <div className="AppForm">
      <AsignaHitoInicio
        folio={info.fkFun}
        estado={info.fkEstadoSolicitudIsapre}
        salud={info.fkProblemaSalud}
        fechaAutorizacion={
          info.fechaAutorizacionSoliciut
            ? info.fechaAutorizacionSoliciut
            : info.fechaRecepcionSolicitud
        }
        deshabilitaInputs={deshabilitaInputs}
      />
      <div className="container">
        <Grid
          sx={{ padding: "15px 24px 20px 14px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <label className="titulo2 p-4">Hitos Asignados</label>
        </Grid>
        <TablaHitos
          folio={info.fkFun}
          estado={info.fkEstadoSolicitudIsapre}
          problemaSalud={info.fkProblemaSalud}
          fechaAutorizacion={
            info.fechaAutorizacionSoliciut
              ? info.fechaAutorizacionSoliciut
              : info.fechaRecepcionSolicitud
          }
          deshabilitaInputs={deshabilitaInputs}
          rol={rol}
          sucursal={sucursal}
        />
      </div>
    </div>
  );
}

export default GesCaeHitos;
