import { useState } from "react";
import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import {
    Breadcrumbs,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { BotonCargaArchivo } from "../../../components/DragDrop/BotonCargaAchivo";
import PrimaryButton from "../../../components/Buttons/Botones";
import TablaRegistroCanastas from "./components/TablaRegistroCanastas";
import { TabPanel } from "../../../components/TabPanel/TabPanel";
import { HistorialCargasCanastas } from "./components/HistorialCargasCanastas";
import { cargaCanastas, cargaexcelCanastas } from "../../../utils/queries";
import { useMutation } from "@apollo/client";
import { hasJGESCAEC, isMantenedor } from "../../../utils/hasPermisions";
import { ExcelCanasta } from "./utils/excelCanastas";
import Mensajero from "../../../components/Snackbar/Mensajero";

export default function MantenedorCanastas({ rol, sucursal, usuario }) {
    const [tabValue, setTabValue] = useState(0);
    const [msgSnack, setMsgSnack] = useState({ abrir: false, mensaje: "", severidad: "info" });
    const [cargandoCanasta, setCargandoCanasta] = useState(false);
    const [CargaMasivaCanastas] = useMutation(cargaCanastas);
    const [CargaArchExcel] = useMutation(cargaexcelCanastas, {
        onCompleted: () => {
            setMsgSnack({
                abrir: true,
                mensaje: "Excel cargado en GD",
                severidad: "success",
            });
            //refetch();
        },
        onError: () => {
            showError("Error al cargar excel Canastas a GD");
            //refetch();
        }
    });

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const cargaCanasta = (canastasStr, len, base64Can, name) => {
        CargaMasivaCanastas({
            variables: {
                canastas: canastasStr,
                creados: len,
                usuario: usuario,
                sucursal: sucursal
            },
            onCompleted: (data) => {
                setCargandoCanasta(false);
                setMsgSnack({
                    abrir: true,
                    mensaje: "Carga exitosa, cargando a GD",
                    severidad: "success",
                });
                CargaArchExcel({
                    variables: {
                        id_log: parseInt(data.registraCanastas),
                        base64: base64Can,
                        nombreArchivo: name
                    }
                });
            },
            onError: (error) => showError(error && error.networkError && error.networkError.result && error.networkError.result.errors[0]?.message !== 'Error en la carga masiva' ?
                error.networkError.result.errors[0].message :
                "Error al cargar Canastas, revise el documento")
        })
    }

    const showError = (msg) => {
        setCargandoCanasta(false);
        setMsgSnack({
            abrir: true,
            mensaje: "Error al cargar excel: " + msg,
            severidad: "error",
        });
    }
    return (
        <div>
            <Mensajero data={msgSnack} setState={setMsgSnack} />
            <div className="container Main" data-testid="MedicamentosMain">
                <label className="titulo1"> Canastas</label>
                <Breadcrumbs
                    sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                    aria-label="breadcrumb"
                    className="breadcrum"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        underline="always"
                        color="inherit"
                        to="/"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Inicio
                    </Link>
                    <Link
                        underline="always"
                        color="inherit"
                        to="/Mantenedor"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Mantenedor
                    </Link>
                    <Typography
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "700",
                            marginBottom: "-1px",
                            lineHeight: "24px",
                        }}
                    >
                        Canastas
                    </Typography>
                </Breadcrumbs>
                <Grid container>

                    <Grid item xs={6} pr="24px">
                        <div className="container">
                            <Grid container p="24px">
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <label className="titulo2">Carga individual</label>{" "}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="labelFormVademecum">
                                            Carga información específica de una canasta
                                        </label>
                                    </Grid>
                                    <Grid item pt="16px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                        <PrimaryButton

                                        >
                                            Cargar información
                                        </PrimaryButton>
                                    </Grid>
                                    <Grid item xs={12} pt="16px" >
                                        <label className="tipoFormato">
                                            Última carga: 20/12/2023 - 09:00hrs
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="container">
                            <Grid container p="24px">
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <label className="titulo2">Carga masiva</label>{" "}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="labelFormVademecum">
                                            Carga múltiples canastas desde un archivo.
                                        </label>
                                    </Grid>
                                    <Grid item pt="16px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                        <Stack direction={"row"}>
                                            <BotonCargaArchivo
                                                disabled={!(hasJGESCAEC(rol) || isMantenedor(rol)) || cargandoCanasta}
                                                cargando={cargandoCanasta}
                                                maxSize={2000}
                                                toDo={ExcelCanasta}
                                                mutation={cargaCanasta}
                                                msgError={showError}
                                                setUploading={setCargandoCanasta}
                                            />
                                            <label className="tipoFormatoMin" style={{ paddingLeft: "16px" }}>
                                                Formato aceptados: .xlsx <br></br>
                                                Peso máximo de 2MB
                                            </label>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} pt="16px" >
                                        <label className="tipoFormato">
                                            Última carga: 20/12/2023 - 09:00hrs
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} mt="36px">
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: '#00837A' } }}
                        >
                            <Tab label="Registro de Canastas" />
                            <Tab label="Historial de Carga" />
                            <Tab label="Registros eliminados" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={tabValue} index={0}>
                            {/* <LoaderTableC /> */}
                            <TablaRegistroCanastas
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className="container">
                            {/* <LoaderC /> */}
                            <HistorialCargasCanastas setMsgSnack={setMsgSnack}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} >
                            <TablaRegistroCanastas
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
