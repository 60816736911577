import React from "react";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import ContadorRed from "./ContadorRed";

const FueraDeRed = ({
  useAsignaPrestadorHook = useAsignaPrestadorContext,
  estado,
  data,
  fechaAutorizacionSolicitud,
  tipoCaso,
  fkFun,
  deshabilitaInputs
}) => {
  const { abrirFueraRed } = useAsignaPrestadorHook();

  return (
    <div className="border text-center box-red" data-testid="containerFDR">
      <label className="titulo2 d-block">Fuera de la RED</label>

      {/* <ContadorFueraRed /> */}
      <ContadorRed amount={data.conFR} />
      
      <button
        id = "btnAgregarP"
        data-testid="btnOpenModal"
        className="btn btn-secondary btn-lg"
        type="button"
        disabled={deshabilitaInputs || estado === 5 || estado === 6 || estado === 7 ? 
          1 : (data.contDR > 0 ? false : true) }
        onClick={()=>abrirFueraRed(tipoCaso)} 
      >
        Agregar prestador
      </button>
    </div>
  );
};

export default FueraDeRed;
