import { useState } from "react";
import { useQuery } from "@apollo/client";
import { obtenhitosasignados } from "../../../../utils/queries";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import Mensajero from "../../../../components/Snackbar/Mensajero";
import { AsignaHitoTermino } from "./AsignarHitoTerminoModal";
import { NoResult } from "../../../../components/NoData/NoResults";
import { MenuHito } from "./MenuHito";
import { EstadoHito, ExepcionTag } from "../../../../utils/CamposUtils.js";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import PrimaryButton from "../../../../components/Buttons/Botones";
import { fecha } from "../../../../utils/fechaUtils";
import { useMsal } from "@azure/msal-react";

export default function TablaHitos({
  deshabilitaInputs,
  estado,
  folio,
  problemaSalud,
  fechaAutorizacion,
  rol,
  sucursal,
  useMsalHook = useMsal
}) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("idDespliegue");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //Snack
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const { accounts } = useMsalHook();
  const { data, loading, error, refetch } = useQuery(obtenhitosasignados, {
    variables: { folio: folio, offset: page * rowsPerPage, limit: rowsPerPage },
    fetchPolicy: "cache-and-network",
  });
  if (loading) return <SkeletonTable test={"loading-hitos"} />;
  if (error) return <pre data-testid="error-hitos">Error: {error.message}</pre>;

  const headCellsHitoInicio = [
    {
      id: "id",
      sorted: true,
      sticky: true,
      label: "N°",
    },
    {
      id: "descripcion",
      sorted: true,
      label: "Descripción de Hito de Inicio",
    },
    {
      id: "fechaEvento",
      sorted: true,
      label: "Fecha Inicio",
    },
    {
      id: "plazo",
      sorted: true,
      label: "Plazo Máximo",
    },
    {
      id: "descripcionTermino",
      sorted: true,
      label: "Descripción de Hito de Término",
    },
    {
      id: "fechaTermino",
      sorted: true,
      label: "Fecha Término",
    },
    {
      id: "HitoTermino",
      sorted: true,
      label: "Estado",
    },
    {
      id: "creador",
      sorted: true,
      label: "Creado por",
    },
    {
      id: "",
      label: "Asignar Hito de Término",
      sticky: true,
      stickyRight: true,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <button id="recargaTablaHitos" data-testid="recargaTablaHitos" hidden onClick={()=>refetch()}/>
      {!data || data.gethitosAsignados.total === 0 ? (
        <NoResult
          titulo={"Sin Hitos"}
          subtitulo={"Aún no se han asignado Hitos"}
        />
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Mensajero data={snackData} setState={setSnackData} />
          <TableContainer>
            <Table
              data-testid="tablaHitos"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <CabeceraTabla
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                cabecera={headCellsHitoInicio}
              />
              <TableBody>
                {data.gethitosAsignados.hitos.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.hitoInicio.id}>
                        <TableCell
                          className="tableBodyIzquierda"
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.hitoInicio.idDespliegue}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.hitoInicio.datosHito.nombreHito.descripcion}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {fecha(row.hitoInicio.fechaEvento)}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.hitoInicio.datosHito.plazo} {row.hitoInicio.datosHito.unidad}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.hitoTermino
                            ? row.hitoTermino.datosHito.nombreHito.descripcion
                            : null}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.hitoTermino
                            ? fecha(row.hitoTermino.fechaEvento)
                            : null}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          <EstadoHito
                            estado={parseInt(row.hitoInicio.descripcionEstado)}
                          />
                          {row.hitoTermino&&row.hitoTermino.exceptuar ? <ExepcionTag/> : null}
                        </TableCell>
                        <TableCell align="left" className="fila">
                          {row.hitoInicio.creador}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableBodyAsignar"
                        >
                          {row.hitoTermino === null ? (
                            <AsignaHitoTermino
                              hitoInicio={row.hitoInicio}
                              folio={folio}
                              estado={estado}
                              problemaSalud={problemaSalud}
                              setSnackData={setSnackData}
                              deshabilitaInputs={deshabilitaInputs}
                            />
                          ) : (
                            <PrimaryButton disabled={true}>
                              Asignado
                            </PrimaryButton>
                          )}
                        </TableCell>
                        <TableCell className="BodyAcciones" align="center">
                          <MenuHito
                            datos={row.hitoInicio}
                            estado={estado}
                            setSnackData={setSnackData}
                            fechaAutorizacion={fechaAutorizacion}
                            rol={rol}
                            usuario={accounts[0].name}
                            sucursal={sucursal}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[5, 10, 15]}
              data={data.gethitosAsignados.total}
              itemPorPagina={rowsPerPage}
              pagina={page}
              setPagina={setPage}
              setLineasPagina={setRowsPerPage}
            />
          </Grid>
        </Paper>
      )}
    </Box>
  );
}