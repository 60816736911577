import { useQuery } from "@apollo/client";
import { useState } from "react";
import { consultaHitoTermino } from "../../../../utils/queries";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

export default function GetHitoTermino({
    problemaSalud,
    dataHito,
    Hito,
    setHito,
    deshabilitaInputs
}) {
    const [hitos, setHitos] = useState([{ label: "Cargando...", value: "" }]);
    const { data } = useQuery(consultaHitoTermino, {
        variables: {
            problemaSalud: problemaSalud,
            etapa: dataHito.datosHito.etapa,
        },
        onCompleted: (res) => {
            let tempArray = [];
            res.getGopTermino.forEach((hito) => {
                tempArray.push({ label: hito.nombreHito.descripcion, value: hito.id })
            });
            setHitos(tempArray);
        }, 
        onError: () => {
            setHitos([{ label: "Error cargando opciones, favor recargar", value: "" }]);
        }
    });

    function cambiaHitoTermino(value) {
        setHito(data.getGopTermino.find((hitoT) => hitoT.id === value))
    }
    return (
        <SelectPersonalizado
            inputName={"descripcionHitoTermino"}
            inputTest="selectHitoTerm"
            value={Hito}
            setValue={cambiaHitoTermino}
            options={hitos}
            placeholder={"Selecciona una descripción del hito"}
            disabled={deshabilitaInputs}
        />
    )
}