import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#4D5D68",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#4D5D68",
        fontSize: "14px",
        fontFamily: "Interstate",
        padding: "8px 12px 8px 12px"
    },
}));

export const verificaLargo = (texto, largo) => {
    if (texto.length > largo) {
        var textoCorto = texto.slice(0, largo);
        return (
            <Grid>
                <CustomTooltip title={texto} arrow placement="right">
                    <label>{textoCorto}...</label>
                </CustomTooltip>
            </Grid>
        );
    } else {
        return texto;
    }
};
