import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { fecha } from "../../../../../../utils/fechaUtils";

const Titulo = ({ isCaec, canasta }) => {
  const fechatermino = isCaec ? null : canasta.fechaFin ?(" al " + fecha(canasta.fechaFin) ): " sin fecha Termino";
  return (
    <Fragment>
      <Grid item xs={12} sx={{ padding: "20px 0 0", width: "890px" }}>
        <label className={isCaec ? "title" : "titulo2"} data-testid="containerTitulo">Establecer prestadores</label>
      </Grid>
      <Grid item xs={12} sx={{ fontSize:"16px",fontWeight: "400",paddingBottom: "20px" }}>
        <label className="labelForm">
          {isCaec ? "En esta sección podrás establecer prestadores tanto fuera como dentro de la RED." 
          : "Esta canasta tiene un periodo desde: "
          + fecha(canasta.fechaInicio)
          + fechatermino
          + "."}
        </label>
      </Grid>
      {isCaec ? "" :<Divider/>}
    </Fragment>
  );
};

export default Titulo;
