import { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { NoResult } from "../../../../components/NoData/NoResults";
import { DiaFechaHoraWParse } from "../../../../utils/fechaUtils";
import InfiniteScroll from 'react-infinite-scroll-component';
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { DownloadGreen } from "../../../../components/icons/iconosEsencial";
import { useLazyQuery } from "@apollo/client";
import { obtenerarchivo } from "../../../../utils/queries";
import { saveAs } from "file-saver";
import base64toBlob from "../../../../utils/Base64";

export function HistorialCargaVDMC({ historial = { logs: [] }, fetchMore, setSnackData }) {
    const [dercargaVDMC, setDescargaVDMC] = useState({});
    const [getArchivo] = useLazyQuery(obtenerarchivo);

    function descargaArchivo(docId, i) {
        setSnackData({
            abrir: true,
            mensaje: "Generando descarga...",
            severidad: "info",
        });
        const valor = docId;
        getArchivo({
            variables: {
                documentId: valor,
                tipoDoc: "OP",
            },
            onCompleted: (res) => {
                let b64 = res.obtenerArchivo.base64.replace(/['"]+/g, "");
                let name = res.obtenerArchivo.nombExtArchivoSPO.replace(/['"]+/g, "");
                const blob = base64toBlob(b64, name);

                saveAs(blob, name);
                setDescargaVDMC(oldState => ({ ...oldState, [i]: false }));

                setSnackData({
                    abrir: true,
                    mensaje: "Descarga generada, revise sus archivos descargados",
                    severidad: "success",
                });
            },
            onError: (error) => {
                console.log("error resp: " + error);
                setSnackData({
                    abrir: true,
                    mensaje: "No se generó la descarga, intente nuevamente",
                    severidad: "error",
                });
                setDescargaVDMC(oldState => ({ ...oldState, [i]: false }));
            }
        });
    }
    return (
        <Grid container p="24px">
            <Grid item xs={12}>
                <label className="titulo2">Historial Vademecum</label>{" "}
            </Grid>
            <Grid item xs={12}>
                <label className="LabelVademecumDetail">
                    Aquí podrás ver las cargas que se han realizado en este módulo.
                </label>
            </Grid>
            {
                historial && historial.logs.length > 0 ?
                    <Grid item xs={12} pt="24px">
                        <Grid>
                            <label className="LabelVademecumRegisters">
                                Mostrando {historial.logs.length} registros
                            </label>
                        </Grid>
                        <Grid>
                            <Grid
                                container
                                id="ScrollGrid"
                                data-testid="scrollVDMC"
                                className="scrollBar"
                                border="1px solid #D2D6D9"
                                borderRadius="8px"
                                padding="16px"
                                style={{
                                    maxHeight: "450px",
                                    overflowY: "scroll",
                                    paddingRight: "8px"
                                }}
                            >
                                <InfiniteScroll
                                    scrollableTarget={"ScrollGrid"}
                                    dataLength={historial.logs.length}
                                    style={{ width: "800px" }}
                                    next={() => fetchMore({
                                        variables: {
                                            offset: historial.logs.length,
                                            limit: 5,
                                        },
                                        updateQuery: (previousResult, { fetchMoreResult }) => {
                                            const newLogs = [...previousResult.historialVademecum.logs, ...fetchMoreResult.historialVademecum.logs]
                                            return {
                                                historialVademecum: {
                                                    ...previousResult.historialVademecum,
                                                    logs: newLogs
                                                }
                                            };
                                        },
                                    })}
                                    hasMore={historial.logs.length !== historial.totallogVDMN}
                                    loader={<CircularProgress className="spinnerGreen" />}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>No existen más registros.</b>
                                        </p>
                                    }
                                >
                                    {historial.logs.map((vdmc, index) => {
                                        const text = vdmc.descripcion.split("/");
                                        const fecha = DiaFechaHoraWParse(vdmc.FechaIngreso) + "hrs";
                                        return (
                                            <Grid container key={index} sx={{ marginBottom: "20px" }}>
                                                <Grid item xs={1} textAlign="right" pr='16px' style={{ flexBasis: "5%", maxWidth: "5%" }}>
                                                    <span className={index === 0 ? "purpleDot" : "grayDot"}></span>
                                                </Grid>
                                                <Grid item xs={11} textAlign="left" style={{ flexBasis: "95%", maxWidth: "95%" }}>
                                                    <label className="titleList">{vdmc.LoginAutorizacion}</label>
                                                    <label className="dateList"  >{fecha}</label>
                                                    <label className="changeText">Cambios: </label>
                                                    <label className="filesList"> {text[0] + " / " + text[1] + " / " + text[2]}</label>
                                                    {text[4] ?
                                                        <OutlinedButton2
                                                            sx={{ ml: "19px", whiteSpace: "pre" }}
                                                            disabled={dercargaVDMC[index]}
                                                            data-testid={"testid" + index}
                                                            onClick={() => {
                                                                setDescargaVDMC(oldState => ({ ...oldState, [index]: true }));
                                                                descargaArchivo(text[4], index)
                                                            }}
                                                        >
                                                            {dercargaVDMC[index] ? <CircularProgress className="spinnerGreen" /> :
                                                                <>
                                                                    <DownloadGreen />
                                                                    Archivo original
                                                                </>
                                                            }
                                                        </OutlinedButton2>
                                                        : null}
                                                </Grid>
                                            </Grid>)
                                    })}
                                </InfiniteScroll>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container border="1px solid #D2D6D9" borderRadius="8px" padding="16px" style={{ maxHeight: "450px", paddingRight: "8px" }}>
                        <NoResult
                            titulo={"Aún no hay registros"}
                            subtitulo={"No se han realizado cargas de Vademecum."}
                            noDivider
                        />
                    </Grid>

            }
        </Grid>
    )
}