import { useState } from "react";
import "../../assets/App.css";
import "../../assets/Repositorio.css";
import {
    Breadcrumbs,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Botones";
import Mensajero from "../../components/Snackbar/Mensajero";
import { BotonCargaArchivo } from "../../components/DragDrop/BotonCargaAchivo";
import ModalIndividual from "./components/ModalIndividual";
import LoaderMedicamentos from "./components/LoaderMedicamentos";
import TablaMedicamentos from "./components/TablaMedicamentos";
import { tablaquery, cargaMasivaME, guardaExcelMEGD, actualizaCacheME } from "../../utils/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { hasJGESCAEC, isMantenedor } from "../../utils/hasPermisions";
import { parseExcelMed } from "./utils/procesaExcelME";
import { Subida } from "../../components/Dialog/Subida";
import { TabPanel } from "../../components/TabPanel/TabPanel";
import HistorialCargasME from "./components/HistorialME";

export default function Medicamentos({ rol, sucursal, usuario }) {
    const [modalAsigna, setModalAsigna] = useState(false);
    const [tabMed, setTabMed] = useState(0);
    const [filtros, setFiltros] = useState({ pag: 0, limit: 5, activo: true, dir: "DESC", order: 'me."createdAt"' });
    const [firstLoad, setFirstLoad] = useState(false);
    const [cargaMasivaActiva, setCargaMasivaActiva] = useState(false);
    const [snackData, setSnackData] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });
    const { data, loading, refetch } = useQuery(tablaquery, {
        fetchPolicy: "cache-and-network",
        variables: {
            rut: filtros.rut?.replace(/[^0-9kK-]/g, "") || null,
            canasta: filtros.canasta || null,
            codigoME: filtros.codigoME || null,
            nombreMed: filtros.nombreMed || null,
            activo: filtros.activo,
            limit: filtros.limit,
            offset: filtros.pag * filtros.limit,
            order: filtros.order,
            dir: filtros.dir
        }
    });

    const [uploadMassiveME] = useMutation(cargaMasivaME);
    const [actCacheME] = useLazyQuery(actualizaCacheME);

    const [uploadMEGD] = useMutation(guardaExcelMEGD, {
        onCompleted: () => setSnackData({
            abrir: true,
            mensaje: "Carga realizada correctamente en GD",
            severidad: "success",
        }),
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Excel no se cargó en GD",
            severidad: "warning",
        })
    })

    if (loading && !firstLoad) {

        return (
            <div className="container Main" data-testid="loaderMed">
                <LoaderMedicamentos />
            </div>
        );
    } else {
        if (!firstLoad) {
            setFirstLoad(true);
        }
    }

    const subirMasiva = (med, reg, cont, b64, nArch) => {
        uploadMassiveME({
            variables: {
                dataME: reg,
                datamedicamento: med,
                creados: cont,
                usuario: usuario,
                sucursal: sucursal
            },
            onCompleted: (data) => {
                setTimeout(() => {
                    refetch();
                    actCacheME();
                }, 500)
                setCargaMasivaActiva(false);
                setSnackData({
                    abrir: true,
                    mensaje: "Carga realizada correctamente, cargando a GD",
                    severidad: "success",
                });
                uploadMEGD({
                    variables: {
                        id_log: parseInt(data.registraME),
                        base64: b64,
                        nombreArchivo: nArch
                    }
                });
            },
            onError: (error) =>
                errorMedSnack(
                    error.networkError?.result?.errors[0]?.message !== 'Error en la carga masiva' ?
                        error.networkError.result.errors[0].message :
                        "Error al cargar ExcelME, revise el documento")
        });
    }

    const errorMedSnack = (msg) => {
        setCargaMasivaActiva(false);
        setSnackData({
            abrir: true,
            mensaje: msg,
            severidad: "error",
        })
    }

    const cambiaTab = (event, newValue) => {
        if (newValue === 0) {
            setFiltros({ pag: 0, limit: 5, activo: true, dir: "DESC", order: 'me."createdAt"' });
        }
        if (newValue === 2) {
            setFiltros({ pag: 0, limit: 5, activo: false, dir: "DESC", order: 'me."createdAt"' });
        }
        setTabMed(newValue);
    };

    const refreshData = async () => {
        await refetch();
        await actCacheME();
    }

    return (
        <div>
            <ModalIndividual
                onOpen={modalAsigna}
                onClose={() => setModalAsigna(false)}
                setSnackData={setSnackData}
                refetch={refreshData}
                sucursal={sucursal}
                usuario={usuario}
            />
            <Subida modal={cargaMasivaActiva} />
            <Mensajero data={snackData} setState={setSnackData} />
            <div className="container Main" data-testid="MedicamentosMain">
                <label className="titulo1"> Medicamentos de Excepción</label>
                <Breadcrumbs
                    sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                    aria-label="breadcrumb"
                    className="breadcrum"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        underline="always"
                        color="inherit"
                        to="/"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Inicio
                    </Link>
                    <Typography
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "700",
                            marginBottom: "-1px",
                            lineHeight: "24px",
                        }}
                    >
                        Medicamentos de Excepción
                    </Typography>
                </Breadcrumbs>
                <Grid container>
                    <Grid item xs={6} pr="24px">
                        <div className="container">
                            <Grid container p="24px">
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <label className="titulo2">Asignación individual</label>{" "}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="labelFormVademecum">
                                            Asigna medicamento excepción a usuario específico.
                                        </label>
                                    </Grid>
                                    <Grid item pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                        <PrimaryButton
                                            disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}
                                            onClick={() => setModalAsigna(true)}
                                        >
                                            Agregar asignación
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="container">
                            <Grid container p="24px">
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <label className="titulo2">Carga masiva</label>{" "}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="labelFormVademecum">
                                            Carga múltiples medicamentos desde un archivo.
                                        </label>
                                    </Grid>
                                    <Grid item  pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                        <Stack direction={"row"}>
                                            <BotonCargaArchivo
                                                disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}
                                                toDo={parseExcelMed}
                                                mutation={subirMasiva}
                                                msgError={errorMedSnack}
                                                setUploading={setCargaMasivaActiva}
                                                maxSize={2000}
                                                usuario={usuario}
                                                sucursal={sucursal}
                                            />
                                            <label className="tipoFormatoMinQ" style={{ paddingLeft: "16px" }}>
                                                Formato aceptado: XLSX. <br></br>
                                                Peso máximo de 2MB
                                            </label>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} mt="24px">
                        <Tabs
                            value={tabMed}
                            onChange={cambiaTab}
                            TabIndicatorProps={{ style: { background: '#00837A' } }}
                        >
                            <Tab label="Medicamentos" />
                            <Tab label="Historial de Carga" />
                            <Tab label="Medicamentos eliminados" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={tabMed} index={0}>
                            <TablaMedicamentos
                                data={data}
                                loading={loading}
                                filtros={filtros}
                                setFiltros={setFiltros}
                                setSnackData={setSnackData}
                                actCacheME={actCacheME}
                                refetch={refreshData}
                                rol={rol}
                                sucursal={sucursal}
                                usuario={usuario}
                            />
                        </TabPanel>
                        <TabPanel value={tabMed} index={1} className="container">
                            <HistorialCargasME setSnackData={setSnackData} />
                        </TabPanel>
                        <TabPanel value={tabMed} index={2}>
                            <TablaMedicamentos
                                eliminado
                                data={data}
                                loading={loading}
                                filtros={filtros}
                                setFiltros={setFiltros}
                                setSnackData={setSnackData}
                                refetch={refreshData}
                                rol={rol}
                                sucursal={sucursal}
                                usuario={usuario}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}