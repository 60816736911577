import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonBar from "./SkeletonBar";

const Rows = ({ index }) => {
    return (
        <TableRow key={index}>
            <TableCell
            >
                <Stack spacing={"6px"} direction="column">
                    <SkeletonBar width={100} height={14} />
                    <SkeletonBar width={100} height={14} />
                </Stack>

            </TableCell>
            <TableCell
            >
                <Stack spacing={"6px"} direction="column">
                    <SkeletonBar width={100} height={14} />
                    <SkeletonBar width={100} height={14} />
                </Stack>

            </TableCell>
            <TableCell
            >
                <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell
            >
                <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell
            >

                <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell
            >

                <SkeletonBar width={100} height={14} />
            </TableCell>
            <TableCell
            >
                <Stack spacing={"6px"} direction="column">
                    <SkeletonBar width={100} height={14} />
                    <SkeletonBar width={100} height={14} />
                </Stack>
            </TableCell>
            <TableCell
            >
                <SkeletonBar width={50} height={14} />
            </TableCell>
        </TableRow>
    );
}

const FilasPS = ({ index, ancho = 3, sticky }) => {
    return (
        <TableRow key={index}>
            <TableCell>
                <Stack spacing={"6px"} direction="column">
                    <SkeletonBar width={100} height={14} />
                    <SkeletonBar width={100} height={14} />
                </Stack>

            </TableCell>
            {Array.from({ length: ancho }, () =>
                <TableCell>
                    <SkeletonBar width={100} height={14} />
                </TableCell>)}
            {sticky ?
                <>
                    <TableCell
                        className="tableBodyAsignar"
                        sx={{ width: "15%" }}
                        align="left"
                    >
                        <SkeletonBar width={50} height={14} />
                    </TableCell>
                    <TableCell
                        className="BodyAcciones"
                        sx={{ width: "5%" }}
                        align="left"
                    >
                        <SkeletonBar width={50} height={14} />
                    </TableCell>
                </> :
                <TableCell>
                    <SkeletonBar width={25} height={14} />
                </TableCell>}
        </TableRow>
    );
}
export const TableBodyLoader = ({ largo, isPS, ancho, sticky }) => {
    return (<TableBody data-testid="LoaderTablaMed">
        {isPS ? Array.from({ length: largo }, (v, i) => <FilasPS index={i} ancho={ancho} sticky={sticky}/>)
            : Array.from({ length: largo }, (v, i) => <Rows index={i} />)}
    </TableBody>);
}

export const SkeletonTableWithExport = ({ rows }) => (
    <Grid item xs={12}>
        <div className="container">
            <Grid container p="24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">

                        <SkeletonBar
                            width={218}
                            height={18}
                        />
                        <Stack direction={"row"} spacing="16px">

                            <SkeletonBar
                                width={120}
                                height={16}
                            />

                            <SkeletonBar
                                width={140}
                                height={40}
                            />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                    <TableHead>
                        <TableRow>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={80} height={14} /></TableCell>
                            <TableCell><SkeletonBar width={50} height={14} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBodyLoader largo={rows} />
                </Table>
            </TableContainer>
        </div>
    </Grid>
)