function parseaDatos(codigo, fecha, data){

    var datos = "";
    data.forEach((dato) => {

        datos += codigo 
            + "|" + fecha
            + "|" + dato.folio
            + "|" + dato.problemaSalud
            + "|" + dato.tipoIntervencion
            + "|" + dato.codPrestacion
            + "|" + dato.prestacionUnitaria
            + "|" + dato.eventoAdmin
            + "|" + dato.fechaEvento
            + "|" + dato.horaEvento
            + "|" + dato.rutPrestador
            + "|" + dato.DV
            + "|" + dato.nombrePrestador
            + "|" + dato.comuna
            + "|" + dato.region
            + "\n"
    });

    return datos;

}

function parseaXlsx(codigo, fecha,general){
    
    var datos = [];
    datos.push(["Código Aseguradora","Periodo de Información","Identificación Única GES","Problema de Salud",
    "Tipo de Intervención Sanitaria","Código Grupo de Prestaciones GES","Código de Prestación Unitaria","Evento Administrativo",
    "Fecha otorgamiento de la prestación o realización del evento administrativo",
    "Hora otorgamiento de la prestación o realización del evento administrativo","RUT Prestador",
    "DV del RUT del Prestador","Nombre del Prestador","Comuna del Prestador","Región del Prestador"])
    general.forEach((dato) => {
        
        datos.push([ 
            codigo,
            fecha,
            dato.folio,
            dato.problemaSalud,
            dato.tipoIntervencion,
            dato.codPrestacion,
            dato.prestacionUnitaria,
            dato.eventoAdmin,
            dato.fechaEvento,
            dato.horaEvento,
            dato.rutPrestador,
            dato.DV,
            dato.nombrePrestador,
            dato.comuna,
            dato.region
        ]);
    })

    return datos
}

export function buildInfo038(cod, fecha, data) {
    return {
        "txt": parseaDatos(cod, fecha, data),
        "xlsx": parseaXlsx(cod, fecha, data)
    }
}