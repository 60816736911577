import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { SelectPersonalizado } from "../select/SelectPersonalizado";
import { getcomunasxregion } from "../../utils/queries";

export const Localidad = ({
    disabled,
    region,
    regionError,
    setRegion,
    comuna,
    comunaError,
    setComuna,
    requerido,
    form1set,
    regionOnly
}) => {
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);

    const { loading } = useQuery(getcomunasxregion, {
        fetchPolicy: "cache-first",
        onCompleted: (data) => {
            procesaDatos(data)
        }
    })

    function procesaDatos(data) {
        let temp = [];

        data.getLocalidades.forEach((regionItem) => {
            temp.push({ ...regionItem, label: regionItem.idReg + " - " + regionItem.nomReg, value: regionItem.idReg });
        });

        setRegiones(temp);
        if (region) {
            const regPreselect = data.getLocalidades.find((reg) => reg.idReg === region);

            let tempArray = [];

            regPreselect.comunas.forEach((comu) => {
                tempArray.push({ ...comu, label: comu.idCom + " - " + comu.nomCom, value: comu.idCom })
            });

            setComunas(tempArray);
            if (comuna && form1set) {
                form1set(tempArray.find((com)=>com.idCom === comuna).nomCom);
            }
        }
    }

    function seteaRegion(valor) {
        const reggionData = regiones.find((reg) => reg.idReg === valor);
        if (!regionOnly) {
            let tempArray = [];
            reggionData.comunas.forEach((comu) => {
                tempArray.push({ ...comu, label: comu.idCom + " - " + comu.nomCom, value: comu.idCom })
            });
            setComunas(tempArray);
        }
        setRegion(reggionData);
    }

    function seteaComuna(valor) {
        setComuna(comunas.find((comu) => comu.idCom === valor));
    }

    return (
        <Fragment>
            <Grid item xs={regionOnly ? 9 : 6}>
                <label htmlFor="region">Región {requerido ? "(Requerido)" : ""}</label>
                <SelectPersonalizado
                    id="Region"
                    inputTest="regionSel"
                    error={regionError}
                    value={region}
                    setValue={seteaRegion}
                    options={regiones}
                    disabled={disabled}
                    placeholder={loading ? "Cargando..." : "Seleccione Región"}
                />
            </Grid>
            {regionOnly ? null :
                <Grid item xs={6}>
                    <label htmlFor="region">Comuna {requerido ? "(Requerido)" : ""}</label>
                    <SelectPersonalizado
                        id="Comuna"
                        inputTest="comunaSel"
                        error={comunaError}
                        value={comuna}
                        setValue={seteaComuna}
                        options={comunas}
                        disabled={disabled || !region}
                        placeholder={"Seleccione Comuna"}
                    />
                </Grid>}
        </Fragment>
    )
}