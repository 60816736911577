import {
  Box,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom
} from "@mui/material";
import { Paginador } from "../../../../../../components/TablePagination/Paginador";
import infoPurple from "../../../../../../components/icons/infoPurple.svg";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import { stableSort, getComparator } from "../../../../../../components/Table/utils";
import { Fragment } from "react";
import Aviso from "../../../../../../components/Alert/Aviso";
import { CustomTooltip } from "../../../../../../components/Tooltip/Tooltip";
import { fecha } from "../../../../../../utils/fechaUtils";
import { formatRut } from "chilean-rutify";

const OpcionesPrestador = ({
  useAsignaPrestadorHook = useAsignaPrestadorContext,
  prestadores,
  loading,
  prestadoresAsignados,
  isEditaPres
}) => {
  const {
    regionBusqueda,
    handleDatosPrestador,
    page,
    rowsPerPage,
    setPagBusqueda,
    setLineasBusqueda,
    region,
    setDataCorrecta,
    selRegion, 
    setSelRegion,
  } = useAsignaPrestadorHook();
  const ordenBitacora = ("asc");
  const ordenPorBitacora = ("");

  return (
    <Fragment>
      <Grid item xs={12}>
        <Collapse in={prestadores.length > 0 && regionBusqueda === region && selRegion}>
          <Aviso
            open={isEditaPres}
            mensaje={"Hay prestadores ya establecidos en tu búsqueda (para saber el periodo actual posiciónate sobre el prestador). \n Si quieres modificar el periodo hazlo en la sección de prestadores."}
            severidad={"warning"}
            noClose
          />
          <label className="tituloTable">
            Resultados de la búsqueda
          </label>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="headTable">
                <TableRow className="headerTable">
                  <TableCell className="headerTable">RUT Prestador</TableCell>
                  <TableCell className="headerTable">Nombre prestador</TableCell>
                  <TableCell className="headerTable">
                    Dirección prestador
                  </TableCell>
                  <TableCell className="headerTable">Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="bodyPrestadores">
                {stableSort(prestadores, getComparator(ordenBitacora, ordenPorBitacora))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((dato) => {
                    const existente = prestadoresAsignados.find((prest) => prest.prestador === dato.rut && prest.vigencia === true);
                    const fechaTermino = existente&&existente.fechaTermino ? fecha(existente.fechaTermino) : "Sin fecha termino.";
                    const tooltiptext = existente ?
                      (
                        "Fecha Inicio: "
                        + fecha(existente.fechaInicio)
                        + " Fecha Termino: "
                        + fechaTermino
                      )
                      : null;

                    return (
                      <CustomTooltip
                        arrow
                        disableFocusListener
                        disableTouchListener
                        placement="right"
                        TransitionComponent={Zoom}
                        title={tooltiptext}
                        sx={{maxWidth: 220}}
                      >
                        <TableRow
                          key={dato.nombre}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>{formatRut(dato.rut)}</TableCell>
                          <TableCell>{dato.nombre}</TableCell>
                          <TableCell>{dato.direccion}</TableCell>
                          <TableCell>
                            <button
                              data-testid="btnHandleDatos"
                              className="btn btn-secondary btn-lg"
                              type="button"
                              disabled={!!existente}
                              onClick={() => {
                                handleDatosPrestador(
                                  dato.rut,
                                  dato.nombre,
                                  dato.direccion,
                                  dato.luat,
                                );
                                setSelRegion(false);
                                setDataCorrecta(false);
                              }}
                            >
                              {existente ? "Establecido" : "Establecer"}
                            </button>
                          </TableCell>
                        </TableRow>
                      </CustomTooltip>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid alignItems="center">
            <Paginador
              opciones={[3, 5]}
              data={prestadores.length}
              itemPorPagina={rowsPerPage}
              pagina={page}
              setPagina={setPagBusqueda}
              setLineasPagina={setLineasBusqueda}
            />
          </Grid>
        </Collapse>
        <Collapse in={!loading && regionBusqueda !== "0" && prestadores.length === 0 && regionBusqueda === region}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow align="center">
                  <TableCell colSpan={10} className="" align="center" padding="normal">
                    <Box
                      sx={{
                        padding: "20px",
                      }}
                    >
                      <img sx={{ padding: "9px 0" }} alt="img2" src={infoPurple}></img>
                    </Box>
                    <label className="titulo2">Sin Prestadores</label>
                    <br />
                    <label>No se ha encontrado Prestadores.</label>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Grid>
    </Fragment>
  );
};

export default OpcionesPrestador;
