import { Fragment, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalEliminar } from "./ModalEliminar";
import EdicionPS from "./EdicionPS";
import HistorialCambios from "./HistorialCambios";
import { isMantenedor, hasJGESCAEC } from "../../../../utils/hasPermisions";

export const MenuPS = ({ dataPS, rol, setSnackData, refetch, isEliminado, sucursal, usuario }) => {
    const [modalEditarPS, setModalEditarPS] = useState(false);
    const [modalEliminarPS, setModalEliminarPS] = useState(false);
    const [modalHistorialPS, setModalHistorialPS] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirEditar = () => {
        handleClose();
        setModalEditarPS(true);
    };

    const abrirEliminar = () => {
        handleClose();
        setModalEliminarPS(true);
    };

    const abrirHistorial = () => {
        handleClose();
        setModalHistorialPS(true);
    };
    return (
        <Fragment>
            <ModalEliminar
                onOpen={modalEliminarPS}
                onClose={() => setModalEliminarPS(false)}
                dataPS={dataPS}
                setSnackData={setSnackData}
                refetch={refetch}
                sucursal={sucursal}
                usuario={usuario}
            />
            <EdicionPS
                onOpen={modalEditarPS}
                onClose={() => setModalEditarPS(false)}
                dataPS={dataPS}
                setSnackData={setSnackData}
                refetch={refetch}
                sucursal={sucursal}
                usuario={usuario}
            />
            <HistorialCambios
                onOpen={modalHistorialPS}
                onClose={() => setModalHistorialPS(false)}
                dataPS={dataPS}
            />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className="menuForm"
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                <MenuItem onClick={abrirHistorial}>
                    Historial de cambios
                </MenuItem>
                <MenuItem hidden={isEliminado} disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))} onClick={abrirEditar}>
                    Editar
                </MenuItem>
                <MenuItem hidden={isEliminado} disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))} onClick={abrirEliminar}>
                    Eliminar
                </MenuItem>
            </Menu>
        </Fragment>
    )
}