import { useState } from "react";
import { getEstados } from "../utils/queries";
import { useQuery } from "@apollo/client";
import { SelectPersonalizado } from "../components/select/SelectPersonalizado";

export function GetSGCEstados({ disabled, error, setValue, value }) {
    const [estados, setEstados] = useState([{label:"Cargando...", value:""}]);
    
    useQuery(getEstados, {
        fetchPolicy: "cache-first",
        onCompleted: (data) => {
            let array = [{label:"Todos", value:""}];
            data.getSGCEstados.forEach((element) => {
                array.push({ label: element.label, value: parseInt(element.value) });
            });
            setEstados(array);
        },
        onError: (error) => {
            console.log("ERROR ESTADOS", error.message);
        }
    })

    function returnValue(valor) {
        const selectedValue = estados.find((element) => element.value === valor);
        setValue(selectedValue.value);
    }
    
    return (
        <SelectPersonalizado
            inputTest="estadoSelect"
            id="estado"
            error={error}
            value={value || ""}
            disabled={disabled}
            setValue={returnValue}
            options={estados}
            placeholderHidden
        />
    );
}