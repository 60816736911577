import { fechaConvertida } from "../../../../../utils/fechaUtils";

export const MensajeCanasta = ({ estadoCanasta, idCanasta }) => {
    switch (estadoCanasta) {
        case 0: return <label>Vas a eliminar la canasta {idCanasta} <br /> Se eliminarán los prestadores asociados a la misma</label>;
        case 1: return <label>No es posible eliminar la canasta porque <br />ya sea han emitido bonos o gastos asociados.</label>;
        case -1: return <label>No es posible realizar esta acción debido a que hay <br />problemas con el servicio de validación de bonos</label>;
        default: return "";
    }
}

export const MensajeEditaCanasta = (estadoCanasta) => {
    switch (estadoCanasta.respuesta) {
        case 1: return "La canasta seleccionada tiene bonos activos, se han acotado las fechas seleccionables. El primer gasto se realizó el "
            + fechaConvertida(estadoCanasta.FInicio) + (estadoCanasta.FTermino ? ", y el último gasto se realizó el " + fechaConvertida(estadoCanasta.FTermino) + ".": ".");
        case -1: return "No es posible realizar esta acción debido a que hay problemas con el servicio de validación de bonos";
        default: return "";
    }
}