import { Fragment } from "react";
import {
  Divider,
  FormControl,
  Grid,
  Typography
} from "@mui/material";
import { parseISO } from "date-fns";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import { MediumButton } from "../../../../../../components/Buttons/Botones";
import { DatePicker } from "../../../../../../components/DatePicker/DatePicker";
import { formatRut } from "chilean-rutify";

const AsignaFechas = ({
  useAsignaPrestadorHook = useAsignaPrestadorContext,
  fechaAutorizacionSolicitud,
  canasta,
  isEditaPres,
}) => {
  const {
    dataPrestador,
    fechaInicio,
    handleFechaInicio,
    fechaTermino,
    handleFechaTermino,
    handleDatosPrestador,
    setDataCorrecta,
    setSelRegion,
  } = useAsignaPrestadorHook();

  const { rutPrestador, razonSocial, direccion } = dataPrestador;

  const fechaFin = canasta && canasta.fechaFin ? parseISO(canasta.fechaFin) : undefined;
  const fechaCanasta = canasta && canasta.fechaInicio ? parseISO(canasta.fechaInicio) : parseISO(fechaAutorizacionSolicitud);

  return (
    <Fragment>
      <Grid item xs={12} pt="16px">
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          sx={{ width: "100%!important", margin: "19px 0 0 0" }}
        >
          <Grid item xs={12} className="boxprestadorEstablecido">
            <Grid container>
              <Grid item xs={1}>
                <label className="tagGreen">
                  RED
                </label>
              </Grid>
              <Grid item xs={11} textAlign="right">
                <label className="run2">RUT: {formatRut(rutPrestador)}</label>
              </Grid>
              <Grid item xs={9} pt="16px">
                <Typography className="namePrestador">{razonSocial}</Typography>
                <Typography className="subPrestador">{direccion}</Typography>
              </Grid>
              <Grid item xs={3} pt="24px" pl="32px">
                <MediumButton
                  sx={{ width: "auto" }}
                  onClick={() => {
                    handleDatosPrestador(
                      null,
                      null,
                      null,
                      null,
                    );
                    setSelRegion(true);
                    setDataCorrecta(true);
                  }}
                >
                  Eliminar prestador
                </MediumButton>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} pt="16px">
          <label>Fecha de inicio</label>
          <DatePicker
            minDate={fechaCanasta}
            maxDate={fechaTermino || fechaFin}
            disabled={isEditaPres}
            value={fechaInicio}
            noClear
            onChange={(f) => {
              handleFechaInicio(f);
            }}
          />
        </Grid>
        <Grid item xs={6} pt="16px">
          <label>Fecha de término</label>
          <DatePicker
            minDate={fechaInicio}
            maxDate={fechaFin}
            value={fechaTermino}
            onChange={(f) => {
              handleFechaTermino(f);
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AsignaFechas;
