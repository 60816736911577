import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { stringify } from "zipson/lib";
import { isValid } from "date-fns";
import { ajustaFecha } from "../../../../utils/fechaUtils";

export async function generateExcel(data = [], setSnackData) {
    try {
        const vdmc = [];
        vdmc.push(["PATOLOGIA", "COD. CANASTA", "FORMULA O PRINCIPIO ACTIVO", "CODIGO PRODUCTO SB", "DESCRIPCION",
            "DIVISION", "FABRICANTE", "FORMA FARMACEUTICA", "UNIDAD DE MEDIDA", "UNIDAD DE CONCENTRACION", "CANTIDAD",
            "BIOEQUIVALENCIA", "ARANCEL", "INICIO VIGENCIA", "FIN VIGENCIA", "DESCRIPCION PROBLEMA SALUD", "DESCRIPCION CANASTA"]);
        data.forEach((result) => {
            const fechaInicio = result.inicioVigencia ? new Date(result.inicioVigencia) : null;
            const fechaTermino = result.finVigencia ? new Date(result.finVigencia) : null;
            vdmc.push([
                result.patologia,
                result.canasta,
                result.formulaActiva,
                result.codigoSB,
                result.descripcion,
                result.division,
                result.fabricante,
                result.formaFarmaceutica,
                result.uniMed,
                result.uniCon,
                result.cantidad,
                result.bioequivalencia,
                result.arancel,
                fechaInicio,
                fechaTermino,
                result.descripcionps,
                result.descripcioncanasta
            ])
        });
        const xlsVDMC = new Workbook();

        xlsVDMC.creator = "Vademecum";

        xlsVDMC.addWorksheet("Datos");

        const worksheet = xlsVDMC.getWorksheet(1);

        worksheet.addRows(vdmc);

        xlsVDMC.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${"Vademecum"}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            setSnackData({
                abrir: true,
                mensaje: "Descarga generada, revise sus archivos descargados",
                severidad: "success",
            });
        });
    } catch (err) {
        console.log(err);
        setSnackData({
            abrir: true,
            mensaje: "No se generó la descarga, intente nuevamente",
            severidad: "error",
        });
    }
}

export const parseExcel = async (excel, mutation, msgError) => {
    try {
        const workbook = new Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(excel);
        reader.onload = async ({ target }) => {
            await workbook.xlsx.load(target.result);
            const hoja1 = workbook.worksheets[0];
            createb64(hoja1._rows, mutation, msgError, excel);
        }
    } catch (err) {
        msgError("No se logró procesar el archivo, intente nuevamente.");
    }
}
export const createb64 = async (rows, mutation, msgError, excel) => {
    const reader = new FileReader();
    reader.readAsDataURL(excel);
    reader.onload = async ({ target }) => {
        procesaDatos(rows, mutation, msgError, target.result.substr(`data:${excel.type};base64,`.length), excel.name);
    }
}
export const procesaDatos = (hoja, mutation, msgError, b64, name) => {
    try {
        const datos = [];
        const datosDel = [];
        var agregar = 0;
        var actualizar = 0;
        var eliminar = 0;
        if (hoja.length <= 1) {
            throw new Error("Archivo sin datos para procesar.");
        }
        if (hoja.length > 30001) {
            throw new Error("Excede máximo de registros a procesar. (máx 30.000)");
        }
        hoja.forEach((row, index) => {
            if (index === 0) {
                row._cells.forEach((cell) => {
                    const verifyEqual = (a, b) => {
                        if (a !== b) {
                            throw new Error("Columnas Inválidas.");
                        }
                    }
                    switch (cell._address) {
                        case "A1": verifyEqual(cell._value.model.value, "PATOLOGIA")
                            break;
                        case "B1": verifyEqual(cell._value.model.value, "COD. CANASTA")
                            break;
                        case "C1": verifyEqual(cell._value.model.value, "FORMULA O PRINCIPIO ACTIVO")
                            break;
                        case "D1": verifyEqual(cell._value.model.value, "CODIGO PRODUCTO SB")
                            break;
                        case "E1": verifyEqual(cell._value.model.value, "DESCRIPCION")
                            break;
                        case "F1": verifyEqual(cell._value.model.value, "DIVISION")
                            break;
                        case "G1": verifyEqual(cell._value.model.value, "FABRICANTE")
                            break;
                        case "H1": verifyEqual(cell._value.model.value, "FORMA FARMACEUTICA")
                            break;
                        case "I1": verifyEqual(cell._value.model.value, "UNIDAD DE MEDIDA")
                            break;
                        case "J1": verifyEqual(cell._value.model.value, "UNIDAD DE CONCENTRACION")
                            break;
                        case "K1": verifyEqual(cell._value.model.value, "CANTIDAD")
                            break;
                        case "L1": verifyEqual(cell._value.model.value, "BIOEQUIVALENCIA")
                            break;
                        case "M1": verifyEqual(cell._value.model.value, "ARANCEL")
                            break;
                        case "N1": verifyEqual(cell._value.model.value, "INICIO VIGENCIA")
                            break;
                        case "O1": verifyEqual(cell._value.model.value, "FIN VIGENCIA")
                            break;
                        case "P1": verifyEqual(cell._value.model.value, "ACCION")
                            break;
                        default: break;
                    }
                });
            }

            if (index > 0) {
                const celda = [];

                row._cells.forEach((cell) => {
                    celda.push({ key: cell._address, value: cell._value.model.value });
                });
                const patologia = celda.find((key) => key.key.includes("A"));
                const canasta = celda.find((key) => key.key.includes("B"));
                const formulaActiva = celda.find((key) => key.key.includes("C"));
                const codigoSB = celda.find((key) => key.key.includes("D"));
                const descripcion = celda.find((key) => key.key.includes("E"));
                const division = celda.find((key) => key.key.includes("F"));
                const fabricante = celda.find((key) => key.key.includes("G"));
                const formaFarmaceutica = celda.find((key) => key.key.includes("H"));
                const uniMed = celda.find((key) => key.key.includes("I"));
                const uniCon = celda.find((key) => key.key.includes("J"));
                const cantidad = celda.find((key) => key.key.includes("K"));
                const bioequivalencia = celda.find((key) => key.key.includes("L"));
                const arancel = celda.find((key) => key.key.includes("M"));
                const inicioVigencia = celda.find((key) => key.key.includes("N"));
                const finVigencia = celda.find((key) => key.key.includes("O"));
                const valorAccion = celda.find((key) => key.key.includes("P"));

                var inicioVigenciaVDMC = null;
                var finVigenciaVDMC = null;

                if (!(patologia && patologia.value && Number.isInteger(parseInt(patologia.value)))) {
                    throw new Error("Patología no válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(canasta && canasta.value)) {
                    throw new Error("Debe ingresar un Código canasta (línea " + (index + 1) + "), favor revisar.");
                }
                if (canasta.value.length > 255) {
                    throw new Error("Código canasta sobre el límite (" + canasta.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(formulaActiva && formulaActiva.value)) {
                    throw new Error("Debe ingresar una Fórmula Activa (línea " + (index + 1) + "), favor revisar.");
                }
                if (formulaActiva.value.length > 255) {
                    throw new Error("Fórmula Activa sobre el límite (" + formulaActiva.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(codigoSB && codigoSB.value && Number.isInteger(parseInt(codigoSB.value)))) {
                    throw new Error("Código SB no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(descripcion && descripcion.value)) {
                    throw new Error("Debe ingresar una descripción (línea " + (index + 1) + "), favor revisar.");
                }
                if (descripcion.value.length > 255) {
                    throw new Error("Descripción sobre el límite (" + descripcion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (division && division.value) {
                    if (division.value.length > 255) {
                        throw new Error("Division sobre el límite (" + division.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (fabricante && fabricante.value) {
                    if (fabricante.value.length > 255) {
                        throw new Error("Fabricante sobre el límite (" + fabricante.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }

                if (formaFarmaceutica && formaFarmaceutica.value) {
                    if (formaFarmaceutica.value.length > 255) {
                        throw new Error("Forma Farmaceutica sobre el límite (" + formaFarmaceutica.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }

                if (uniMed && uniMed.value) {
                    if (uniMed.value.length > 255) {
                        throw new Error("Unidad Médica sobre el límite (" + uniMed.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (uniCon && uniCon.value) {
                    if (uniCon.value.length > 255) {
                        throw new Error("Unidad de concentración sobre el límite (" + uniCon.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (cantidad && cantidad.value) {
                    if (!Number.isInteger(parseInt(cantidad.value))) {
                        throw new Error("Cantidad no es número (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (bioequivalencia && bioequivalencia.value) {
                    if (bioequivalencia.value.length > 255) {
                        throw new Error("Bioequivalencia sobre el límite (" + bioequivalencia.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                    }
                }
                if (!(arancel && arancel.value && Number.isInteger(parseInt(arancel.value)))) {
                    throw new Error("Arancel no válido (línea " + (index + 1) + "), favor revisar.");
                }

                if (inicioVigencia && inicioVigencia.value) {
                    if (typeof inicioVigencia.value === 'string' || typeof inicioVigencia.value === 'object') {
                        if (isValid(inicioVigencia.value)) {
                            inicioVigenciaVDMC = ajustaFecha(inicioVigencia.value);
                        } else {
                            var tempInicio = inicioVigencia.value.split('/');
                            var fecha = new Date(tempInicio[2], tempInicio[1] - 1, tempInicio[0]);
                            if (isValid(fecha)) {
                                inicioVigenciaVDMC = fecha;
                            }
                        }
                    }
                } else {
                    throw new Error("Debe ingresar InicioVigencia (línea " + (index + 1) + "), favor revisar.");
                }
                if (inicioVigenciaVDMC === null) {
                    throw new Error("Inicio Vigencia no es fecha válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (finVigencia && finVigencia.value) {
                    if (isValid(finVigencia.value)) {
                        finVigenciaVDMC = ajustaFecha(finVigencia.value);
                    } else {
                        var finVig = finVigencia.value.split('/');
                        var tempFin = new Date(finVig[2], finVig[1] - 1, finVig[0]);
                        if (isValid(tempFin)) {
                            finVigenciaVDMC = tempFin;
                        } else {
                            throw new Error("Término de Vigencia no válido (línea " + (index + 1) + "), favor revisar.");
                        }
                    }
                }
                switch (valorAccion.value) {
                    case "A": agregar++;
                        break;
                    case "M": actualizar++;
                        break;
                    case "E": eliminar++;
                        break;
                    default: throw new Error("Valor de acción no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (valorAccion.value === "E") {
                    datosDel.push({
                        patologia: patologia.value,
                        canasta: canasta.value,
                        formulaActiva: formulaActiva.value,
                        codigoSB: codigoSB.value,
                        descripcion: descripcion.value.replace(/[']+/g, ""),
                        division: (division && division.value) || null,
                        fabricante: (fabricante && fabricante.value) || null,
                        formaFarmaceutica: (formaFarmaceutica && formaFarmaceutica.value) || null,
                        uniMed: (uniMed && uniMed.value) || null,
                        uniCon: (uniCon && uniCon.value) || null,
                        cantidad: (cantidad && cantidad.value) || null,
                        bioequivalencia: (bioequivalencia && bioequivalencia.value) || null,
                        arancel: arancel.value,
                        inicioVigencia: inicioVigenciaVDMC,
                        finVigencia: finVigenciaVDMC || new Date(),
                        eliminado: true,
                        f_eliminado: new Date(),
                        accion: valorAccion.value
                    });
                } else {
                    datos.push({
                        patologia: patologia.value,
                        canasta: canasta.value,
                        formulaActiva: formulaActiva.value,
                        codigoSB: codigoSB.value,
                        descripcion: descripcion.value.replace(/[']+/g, ""),
                        division: (division && division.value) || null,
                        fabricante: (fabricante && fabricante.value) || null,
                        formaFarmaceutica: (formaFarmaceutica && formaFarmaceutica.value) || null,
                        uniMed: (uniMed && uniMed.value) || null,
                        uniCon: (uniCon && uniCon.value) || null,
                        cantidad: (cantidad && cantidad.value) || null,
                        bioequivalencia: (bioequivalencia && bioequivalencia.value) || null,
                        arancel: arancel.value,
                        inicioVigencia: inicioVigenciaVDMC,
                        finVigencia: finVigenciaVDMC || (valorAccion.value === "E" && new Date()) || null,
                        eliminado: false,
                        f_eliminado: new Date('1970-01-01T04:00:00.000Z'),
                        accion: valorAccion.value
                    });
                }

            }
        });
        if (datos.some((data, index) =>
            datos.find((b, indexb) =>
                b.codigoSB === data.codigoSB &&
                b.canasta === data.canasta &&
                b.arancel === data.arancel &&
                index !== indexb))) {
            msgError("existen registros duplicados, favor revisar líneas.");
        } else {
            if (datosDel.some((data, index) =>
                datosDel.find((b, indexb) =>
                    b.codigoSB === data.codigoSB &&
                    b.canasta === data.canasta &&
                    b.arancel === data.arancel &&
                    index !== indexb))) {
                msgError("existen registros duplicados, favor revisar líneas.");
            } else {
                mutation(stringify(datos), stringify(datosDel), agregar, actualizar, eliminar, stringify(b64), name);
            }
        }
        agregar = 0;
        actualizar = 0;
        eliminar = 0;
    } catch (err) {
        msgError(err.message);
    }
}