

import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import { SecondaryButton } from "../../../components/Buttons/Botones";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { useMutation } from "@apollo/client";
import { editaME } from "../../../utils/queries";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import { parseFecha } from "../../../utils/fechaUtils";
import { isEqual } from "date-fns";

const ModalEdicionMedicamento = ({
    data, onOpen, onClose, usuario, sucursal, setSnackData, refetch
}) => {
    const [fechaInicio, setFechaInicio] = useState(parseFecha(data.fechaInicio));
    const [fechaTermino, setFechaTermino] = useState(data.fechaTermino);
    const [editarFechaME, { loading }] = useMutation(editaME, {
        onCompleted: () => {
            setFechaInicio(null);
            setFechaTermino(null);
            setSnackData({
                abrir: true,
                mensaje: "La fecha fué editada con éxito.",
                severidad: "success",
            });
            onClose();
            refetch();
        },
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al editar fecha, intente nuevamente",
            severidad: "error",
        })
    });

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle sx={{ width: "100%" }} className="titulo2" textAlign="center" p="24px">
                Edición de fechas de medicamento de excepción
                <br></br>
                <label className="subtitleModal">
                    Estás editando el caso {data.caso} con el medicamento {data.sku}.
                </label>
            </DialogTitle>
            <DialogContent dividers
                sx={{ padding: "0 21px 0px 3px!important" }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid
                        sx={{ margin: "0", width: "100%" }}
                        container
                        spacing={2}
                        pb="24px"
                    >
                        <Grid item xs={6}>
                            <label className="textCaso">Beneficiario</label>
                            <label className="textLabel2">{data.nombrePaciente}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="textCaso">RUT</label>
                            <label className="textLabel2">{data.rut}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="textCaso">Canasta</label>
                            <label className="textLabel2">{data.canasta}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="textCaso">Descripción</label>
                            <label className="textLabel2">{data.descripcionCanasta}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="textCaso">SKU</label>
                            <label className="textLabel2">{data.sku}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="textCaso">Descripción</label>
                            <label className="textLabel2">{data.descripcionmed}</label>
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha inicio</label>
                            <DatePicker
                                disabled={loading}
                                minDate={data.fechaAutorizacion}
                                value={fechaInicio}
                                noClear
                                id="fechaInicioME"
                                testid="fechaInicioME"
                                onChange={(fecha) => setFechaInicio(fecha)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha término</label>
                            <DatePicker
                                disabled={loading}
                                minDate={fechaInicio}
                                value={fechaTermino}
                                id="fechaTerminoME"
                                testid="fechaTerminoME"
                                onChange={(fecha) => setFechaTermino(fecha)}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SecondaryButton
                        disabled={loading}
                        onClick={onClose}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        sx={{
                            minWidth: "179px"
                        }}
                        boxSX={{ paddingRight: "7px" }}
                        label={"Editar fecha"}
                        testid={"cambiaFechaME"}
                        loading={loading}
                        disabled={isEqual(fechaInicio, parseFecha(data.fechaInicio)) && fechaTermino === data.fechaTermino}
                        toDo={() => {
                            editarFechaME({
                                variables: {
                                    id: parseInt(data.id),
                                    fechaInicio: fechaInicio,
                                    fechaTermino: fechaTermino,
                                    usuario: usuario,
                                    sucursal: sucursal
                                }
                            })
                        }}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ModalEdicionMedicamento;
