import React from "react";
import "../../components/Skeleton/Skeleton.scss";

const SkeletonCaso = () => {
  const LabelsCol = () => {
    return Array.from({length: 4}, ()=>(<li><div className="col-sk"></div></li>))
  }

  const LabelsRow = () => {
    return(
      <>
        <ul className="ul-label">
          <LabelsCol/>
        </ul>
        <ul className="ul-input">
          <LabelsCol/>
        </ul>
      </>
    )
  }
  return (  
    <div>  
      <div className="rowInformacion">
        <div className="container Main">
          <label className="titulo1"> Caso N°: </label>
        </div>
      </div>
      {/* <div className="bloque p-4"> */}
      <div>
        <div className="container">
          <div className="skeleton">
            <div className="post-sk">
              <div className="title-sk2"></div>
                {Array.from({length: 3}, ()=>(<LabelsRow/>))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bloque p-4">
          {Array.from({length: 3}, ()=>(<LabelsRow/>))}
        </div>
      </div>
    </div>   
  );
};

export default SkeletonCaso;
