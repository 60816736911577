import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableBody,
    TableRow,
} from "@mui/material";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import { getComparator, stableSort } from "../../../../components/Table/utils";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";

const ModalPrexistencias = ({ prexistencias }) => {
    const [modalPrexistencias, setModalPrexistencias] = useState(false);
    const cerrarModal = () => setModalPrexistencias(false);
    const [orderPrex] = useState('desc');
    const [orderByPrex] = useState(null);
    const [pagPrex, setPagPrex] = useState(0);
    const [linesPagPrex, setLinesPagPrex] = useState(3);
    const cabeceraPrex = [
        { id: "typeIll", label: "Causal" },
        { id: "startDateTimeIas", label: "Fecha inicio exclusión" },
        { id: "endDateTimeIas", label: "Fecha fin exclusión" },
        { id: "durationValueIas", label: "Plazo exclusión", noActions: true }
    ]
    return (
        <Grid item xs={3}>
            <SecondaryButton
                sx={{ height: "40px", width: "200px" }}
                data-testid="prexistenciasBTN"
                onClick={() => setModalPrexistencias(true)}
            >
                Ver
            </SecondaryButton>
            <Dialog
                fullWidth
                className="dialogModalBig"
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalPrexistencias}
                onClose={cerrarModal}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <DialogTitle className="titulo2" sx={{ pb: "0" }} xs={12} textAlign="center" mt="26px" mb="16px">
                    Prexistencias
                    <Grid
                        container
                    >
                        <Grid item xs={12}>
                            <label className="subtitleModal">
                                Estas son las preexistencias declaradas del paciente
                            </label>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers className="seguimiento">
                    <Grid
                        sx={{ padding: "5px", margin: "0", width: "100%" }}
                        container
                        spacing={2}
                    >
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                >
                                    <CabeceraTabla
                                        order={orderPrex}
                                        orderBy={orderByPrex}
                                        cabecera={cabeceraPrex}
                                        nonsticky
                                    />
                                    <TableBody>
                                        {stableSort(prexistencias, getComparator(orderPrex, orderByPrex))
                                            .slice(
                                                pagPrex * linesPagPrex,
                                                pagPrex * linesPagPrex + linesPagPrex
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        className="headTable"
                                                        key={index}
                                                    >
                                                        <TableCell align="left" className="fila">
                                                            {row.typeIll}
                                                        </TableCell>
                                                        <TableCell align="left" className="fila">
                                                            {row.startDateTimeIas}
                                                        </TableCell>
                                                        <TableCell align="left" className="fila">
                                                            {row.endDateTimeIas}
                                                        </TableCell>
                                                        <TableCell align="left" className="fila">
                                                            {row.durationValueIas}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid alignItems="center">
                                <Paginador
                                    opciones={[3, 5, 7]}
                                    data={prexistencias.length}
                                    itemPorPagina={linesPagPrex}
                                    pagina={pagPrex}
                                    setPagina={setPagPrex}
                                    setLineasPagina={setLinesPagPrex}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <SecondaryButton
                            variant=" text"
                            color="default"
                            data-testid="cerrarBtn"
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </SecondaryButton>
                    </Grid>
                </DialogActions>
            </Dialog >
        </Grid >

    );
};

export default ModalPrexistencias;
