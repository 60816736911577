import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { useMutation } from "@apollo/client";
import { editaPS } from "../../../../utils/queries";

const EdicionPS = ({
    onOpen,
    onClose,
    dataPS,
    setSnackData,
    refetch,
    sucursal,
    usuario
}) => {
    const [editableData, setEditableData] = useState(dataPS);
    const [editaDataPS, { loading }] = useMutation(editaPS, {
        onCompleted: () => {
            setSnackData({
                abrir: true,
                mensaje: "El registro se ha modificado con éxito.",
                severidad: "success",
            });
            onClose();
            refetch();
        },
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Ha ocurrido un error al intentar modificar el registro. Por favor, inténtelo de nuevo más tarde.",
            severidad: "error",
        })
    });

    const onInputChange = (event) => {
        setEditableData({
            ...editableData,
            [event.target.id]: event.target.value
        });
    };

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Edición para el problemas de salud
                <br></br>
                <label className="subtitleModal">
                    Modifica los campos necesarios para actualizar la información.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="textCaso">Código</label>
                        <label className="textLabel2">{editableData.Codigo}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="textCaso">Arancel</label>
                        <label className="textLabel2">{editableData.Arancel}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="textCaso">Tipo de Rango Edad</label>
                        <label className="textLabel2">{editableData.TipoRangoEdad || "-"}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="textCaso">Edad de Inicio</label>
                        <label className="textLabel2">{editableData.InicioEdad || "-"}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="textCaso">Edad de Término</label>
                        <label className="textLabel2">{editableData.TerminoEdad || "-"}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="textCaso">Género</label>
                        <label className="textLabel2">{editableData.Genero || "-"}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Glosa </label>
                        <FormControl >
                            <TextField
                                id="GlosaSis"
                                disabled={loading}
                                value={editableData.GlosaSis || ""}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Descripción </label>
                        <FormControl >
                            <TextField
                                id="Descripcion"
                                data-testid="descripcionInput"
                                disabled={loading}
                                value={editableData.Descripcion || ""}
                                onChange={onInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Inicio Vigencia</label>
                        <DatePicker
                            testid="inicioVigencia"
                            value={editableData.InicioVigencia || null}
                            disabled={loading}
                            maxDate={editableData.TerminoVigencia || null}
                            onChange={(fecha) => setEditableData({
                                ...editableData,
                                InicioVigencia: fecha
                            })}
                            noClear
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Termino Vigencia</label>
                        <DatePicker
                            testid="terminoVigencia"
                            value={editableData.TerminoVigencia || null}
                            disabled={loading}
                            minDate={editableData.InicioVigencia}
                            onChange={(fecha) => setEditableData({
                                ...editableData,
                                TerminoVigencia: fecha
                            })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingLeft: "20px", paddingRight: "11px" }}
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        disabled={loading}
                        onClick={() => {
                            setEditableData(dataPS);
                            onClose();
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        testid="editarPS"
                        label="Editar"
                        disabled={dataPS === editableData}
                        loading={loading}
                        toDo={() => editaDataPS({
                            variables: {
                                id: parseInt(dataPS.id),
                                glosa: editableData.GlosaSis,
                                descripcion: editableData.Descripcion,
                                fechaInicio: editableData.InicioVigencia,
                                fechaTermino: editableData.TerminoVigencia,
                                usuario: usuario,
                                sucursal: sucursal
                            }
                        })}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default EdicionPS;
