import Aviso from "../../../../../../components/Alert/Aviso";

const AvisoPrestadoresFR = ({ contador, isapre }) => {
  return (
    <Aviso
      open={contador === 0 && isapre === 1}
      noClose={true}
      severidad={"info"}
      mensaje={"Solo puedes establecer prestadores fuera de la RED cuando hayas establecido un prestador dentro de la RED."}
    />
  );
};

export default AvisoPrestadoresFR;
