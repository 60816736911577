import { Grid } from '@mui/material';
import { Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileHeader } from './components/FileHeader';
import "./components/MultipleFileUpload.css"
import { IconSubirDisabled } from '../icons/iconosEsencial';
import { useState } from 'react';

export function DragAndDrop({ files, maxFiles, setFiles, disabled }) {
  const [errorDocs, setErrorDocs] = useState(null);
  const onDrop = (accFiles, rejFiles) => {
    var temp = files;
    accFiles.forEach((file) => {
      if (!temp) {
        temp = [];
        temp.push({ file })
      } else if (maxFiles === undefined || temp.length < maxFiles) {
        temp.push({ file })
      }
    });
    setFiles(temp);
    var tempError = errorDocs;
    rejFiles.forEach((rechazado) => {
      if (!tempError) {
        tempError = [];
        tempError.push({ ...rechazado })
      } else {
        tempError.push({ ...rechazado })
      }
    });
    setErrorDocs(tempError);
  };

  function Delete(file) {
    const listArchivos = files.filter((fw) => fw !== file);
    if (listArchivos.length === 0) {
      setFiles(null)
    } else {
      setFiles(listArchivos);
    }
  }

  function Drop(item) {
    const listErrores = errorDocs.filter((fw) => fw !== item);
    setErrorDocs(listErrores);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    disabled: disabled,
    accept: {
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf']
    },
    maxSize: 5000 * 1024, // 5MB
  });

  return (
    <Fragment>
      <Grid item>
        <div {...getRootProps({ className: (maxFiles && files?.length === maxFiles) || disabled ? "DragDropBackgroundDisabled" : "DragDropBackground" })}>
          <input data-testid="inputdragdrop" {...getInputProps()} />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            data-testid="TESTING"
          >
            <Grid item>
              <IconSubirDisabled />
            </Grid>
            <Grid item>
              <label className={(maxFiles && files?.length === maxFiles) || disabled ? "seleccionaLabelDisabled" : "seleccionaLabel"} data-testid="dropTest">Selecciona</label>
            </Grid>
            <Grid item>
              <label className={(maxFiles && files?.length === maxFiles) || disabled ? "arrastraLabelDisabled" : "arrastraLabel"}>ó arrastra tu archivo aquí</label>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {files && files.map((fileWrapper, index) => (
        <Grid item key={index}>
          <FileHeader
            onDelete={Delete}
            object={fileWrapper}
            error={false}
          />
        </Grid>
      ))}
      {errorDocs && errorDocs.map((fileWrapper, index) => (
        <Grid item key={index}>
          <FileHeader
            onDelete={Drop}
            object={fileWrapper}
            error={true}
          />
        </Grid>
      ))}
    </Fragment>
  );
}
