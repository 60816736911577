import { useState } from "react";
import { client } from "../../../../../../utils/apollo";
import { validRut } from "chilean-rutify";
import { guardaPrestador } from "../../../../../../utils/queries";
import { useMsal } from "@azure/msal-react";
import { activarPrestador } from "../../../../../../utils/Utils";

export const useAsignaPrestador = () => {
  // State modal
  const [modalInicio, setModalInicio] = useState(false);
  const [modalFueraRed, setModalFueraRed] = useState(false);
  // States region
  const [region, setRegion] = useState(0);
  const [regionBusqueda, setRegionBusqueda] = useState("0");
  const [selRegion, setSelRegion] = useState(false);

  //disabled
  const [guardando, setGuardando] = useState(false);
  const [deshabilitaRS, setDeshabilitaRS] = useState(true);

  const { accounts } = useMsal();
  // Fechas
  const [fechaInicio, setFechainicio] = useState(new Date());
  const [fechaTermino, setFechatermino] = useState(null);

  // State prestador
  const [dataPrestador, setDataPrestador] = useState({
    rutPrestador: null,
    razonSocial: null,
    direccion: null,
    luat: null
  });
  const [tipoPrestador, setTipoPrestador] = useState(null);
  const [dataCorrecta, setDataCorrecta] = useState(true);
  const [motivoFueraRed, setMotivoRed] = useState(0);
  const [transitorio, setTransitorio] = useState(null);
  const [observacion, setObervacion] = useState("");
  const [deshabilitaDirecciones, setDeshabilitaDirecciones] = useState(true);

  //Errores
  const [rutError, setRutError] = useState(null);
  const [motivoError, setMotivoError] = useState(false);
  const [transitorioError, setTransError] = useState(false);
  const [observacionError, setObsError] = useState(false);
  const [inicioError, setInicioError] = useState(false);
  const [terminoError, setTerminoError] = useState(false);

  // Paginacion
  const [page, setPagBusqueda] = useState(0);
  const [rowsPerPage, setLineasBusqueda] = useState(3);
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = ['Elegir tipo', 'Buscar prestador y asignar fechas'];

  const totalSteps = () => {
    return steps.length;
  };

  const handleStep = (step) => () => {
    if (step === 0) {
      handleDatosPrestador(
        null,
        null,
        null,
        null,
      );
      setSelRegion(false);
      setDataCorrecta(true);
      setRegionBusqueda("0");
      setRegion(0);
    }
    setActiveStep(step);
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleBack = (isCaec) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    clearAll();
    if (isCaec) {
      setModalInicio(false);
    }
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const abrirModalInicio = () => {
    setModalInicio(true);
  }

  const cerrarModalInicio = () => {
    setModalInicio(false);
    clearAll();
  }

  const cambiaRegion = (object) => {
    setRegion(object.idReg);
  };

  const activarTablaPrestadores = () => {
    setRegionBusqueda(region);
  };

  const handleDatosPrestador = (rut, nombre, direccion, luat = null) => {
    setDataPrestador({
      rutPrestador: rut,
      razonSocial: nombre,
      direccion: direccion,
      luat: luat
    });
  };

  const handleFechaInicio = (fecha) => {
    setFechainicio(fecha);
  };

  const handleFechaTermino = (fecha) => {
    setFechatermino(fecha);
  };

  const abrirFueraRed = (tipo) => {
    setModalInicio(false);
    setTipoPrestador("FR");
    setModalFueraRed(true);
  };

  const cerrarFueraRed = () => {
    setModalFueraRed(false);
    clearAll();
  };

  const handleMotivoRed = (event) => {
    setMotivoRed(event.target.value);
  };

  const estadoTransitorio = (event) => {
    setTransitorio(JSON.parse(event.target.value));
    setTransError(false);
  };

  const observacionIngresada = (event) => {
    if (observacion.length <= 300) {
      setObervacion(event.target.value);
    }
  };

  const checkDatos = (
    tipoCaso,
    fkFun,
    canasta,
    estado,
    sucursal,
    postguardar,
    setMensajeroData) => {
    let lock = 0;
    if (dataPrestador.rutPrestador && dataPrestador.razonSocial && dataPrestador.direccion) {
      if (validRut(dataPrestador.rutPrestador)) {
        setRutError(null);
      } else {
        setRutError("Debe verificar el rut del prestador.");
        lock = 1;
      }
    } else {
      setRutError("Debe completar los datos faltantes del prestador.");
      lock = 1;
    }

    if (transitorio && observacion.length === 0) {
      setObsError(true);
      lock = 1;
    } else {
      setObsError(false);
    }
    if (transitorio === null) {
      setTransError(true);
      lock = 1;
    } else {
      setTransError(false);
    }
    if (lock === 0) {
      guardarPrestador(fkFun, tipoCaso, canasta, estado, sucursal, postguardar, setMensajeroData);
    }
  };

  const guardarPrestador = (
    folio,
    tipoCaso,
    canasta,
    estado,
    sucursal,
    postGuardar,
    setMensajeroData) => {
    setGuardando(true);

    const canastaid = canasta && canasta.id ? parseInt(canasta.id) : null;
    client
      .mutate({
        mutation: guardaPrestador,
        variables: {
          folio: folio,
          idcanastaAsignada: canastaid,
          canasta: (canasta && canasta.canasta) || null,
          prestador: dataPrestador.rutPrestador.replace(/[^0-9kK-]/g, ""),
          luat: dataPrestador.luat ? dataPrestador.luat : '888',
          nombrePrestador: dataPrestador.razonSocial,
          direccionPrestador: dataPrestador.direccion,
          motivoFueraRed: parseInt(motivoFueraRed),
          fechaInicio: fechaInicio,
          fechaTermino: fechaTermino,
          tipoPrestador: tipoPrestador,
          transitorio: transitorio ? transitorio : false,
          createdby: accounts[0].name,
          comentario: observacion,
          sucursal: sucursal,
          tipoCaso: tipoCaso,
        },
      })
      .then((result) => {
        setModalInicio(false);
        setModalFueraRed(false);
        setGuardando(false);
        postGuardar();

        if (tipoCaso === "GES" && estado === 4) {
          activarPrestador(parseInt(canasta.id), canasta.idBeneficios, accounts[0].name, sucursal, parseInt(folio));
        }
        if (tipoPrestador === "FR") {
          clearAll();
        } else {
          handleBack();
        }
      })
      .catch((error) => {
        setMensajeroData({
          abrir: true,
          mensaje: "No se pudo guardar el prestador en la BD, Favor reintente",
          severidad: "error",
        });
        setGuardando(false);
      });
  };

  const clearAll = () => {
    setGuardando(false);
    setActiveStep(0);
    setRegion(0);
    setRegionBusqueda("0");
    setDataPrestador({
      rutPrestador: null,
      razonSocial: null,
      direccion: null,
      luat: null
    });
    setPagBusqueda(0);
    setLineasBusqueda(3);
    setFechainicio(new Date());
    setFechatermino(null);
    setDataCorrecta(true);
    setMotivoRed(null);
    setTipoPrestador(null);
    setTransitorio(null);
    setObervacion("");
    setRutError(false);
    setMotivoError(false);
    setTransError(false);
    setObsError(false);
    setInicioError(false);
    setTerminoError(false);
    setDeshabilitaRS(true);
    setDeshabilitaDirecciones(true);
  };

  return {
    selRegion, 
    setSelRegion,
    steps,
    activeStep,
    completed,
    handleStep,
    handleBack,
    handleNext,
    region,
    cambiaRegion,
    regionBusqueda,
    deshabilitaDirecciones,
    activarTablaPrestadores,
    handleDatosPrestador,
    setDataPrestador,
    // Modal detalles
    dataPrestador,
    fechaInicio,
    handleFechaInicio,
    fechaTermino,
    handleFechaTermino,
    dataCorrecta,
    setDataCorrecta,
    guardarPrestador,
    setTipoPrestador,
    // Modal fuera de red
    modalFueraRed,
    abrirFueraRed,
    cerrarFueraRed,
    setRutError,
    handleMotivoRed,
    estadoTransitorio,
    observacion,
    observacionIngresada,
    checkDatos,
    setFechainicio,
    setFechatermino,
    // Errores
    rutError,
    motivoError,
    transitorioError,
    observacionError,
    inicioError,
    terminoError,
    // page
    page,
    rowsPerPage,
    setPagBusqueda,
    setLineasBusqueda,
    deshabilitaRS,
    modalInicio,
    setModalInicio,
    abrirModalInicio,
    cerrarModalInicio,
    //disabled
    guardando,
    clearAll,
  };
};
