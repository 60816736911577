import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import { SecondaryButton } from "../../../../../../components/Buttons/Botones";
import { addDays } from "date-fns";

export const BotonEditar = ({ useAsignaPrestadorHook = useAsignaPrestadorContext,
    cuenta, fechaInicio, fechaTermino, EditaPresTrans }) => {
    const { abrirModalInicio, setFechainicio, setFechatermino } = useAsignaPrestadorHook();

    const cargaData = () => {
        EditaPresTrans();
        setFechainicio(addDays(fechaInicio, 1));
        setFechatermino(fechaTermino);
        cuenta();
        abrirModalInicio();
    }

    return (
        <SecondaryButton
            sx={{ marginLeft: "25px", width: "165px" }}
            data-testid="cargaEditar"
            onClick={cargaData}
        >
            Nuevo Prestador
        </SecondaryButton>
    );
}