import {
    Card,
    CardContent,
    Divider,
    Grid,
} from "@mui/material";
import { useState } from "react";
import { ButtonWithoutBorder } from "../../../../../components/Buttons/Botones";
import {
    IconDate,
    IconDateDisabled,
    IconHospital,
    IconHospitalDisabled,
    IconUser,
    IconUserDisabled
} from "../../../../../components/icons/iconosEsencial";
import { verificaLargo } from "../../../../../components/Tooltip/Tooltip";
import { EliminarPrestador } from "../../Prestadores/components/EliminarPrestador";
import { formatRut } from "chilean-rutify";

const CardPrestadores = ({
    type,
    estado,
    rut,
    nombre,
    direccion,
    luat,
    fechaInicio,
    fechaFin,
    transitorio,
    usuarioCreacion,
    fechaCreacion,
    id,
    setMensajeroData,
    setHidePres,
    disabled
}) => {
    const [eliminaPrestador, setEliminaPrestador] = useState(false);

    const cierraElimina = (valor) => {
        setEliminaPrestador(valor);
        setHidePres(valor);
    };

    return (
        <Card data-testid="cardTest" variant="outlined" className={type ? "cardDisabled" : ""}>
            <CardContent>
                <Grid container>
                    <Grid xs={6}>
                        {estado === 'red' ?
                            <label className="tag1">
                                RED
                            </label> :
                            <label className="tagFueraRed">
                                Fuera de RED
                            </label>}
                    </Grid>
                    <Grid xs={6} className="text-end">
                        <label className="run1">
                            RUT
                            <br />
                            {formatRut(rut)}
                        </label>
                    </Grid>
                    <Grid xs={12} className="text-end">
                        <label className="run1">
                            LUAT
                            <br />
                            {luat}
                        </label>
                    </Grid>
                    <Grid xs={12}>
                        <label className="textLabel4">
                            {verificaLargo(nombre, 23)}
                        </label>
                    </Grid>
                    <Grid xs={12} sx={{ height: "50px" }} mb="10px">
                        <label className="subTextDir">
                            {verificaLargo(direccion, 50)}
                        </label>
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={6} mt="10px">
                        <label className="textLabel4">
                            Fecha de inicio
                        </label>
                    </Grid>
                    <Grid xs={6} mt="10px">
                        <label className="textLabel4">
                            Fecha de termino
                        </label>
                    </Grid>
                    <Grid xs={6} mb="10px">
                        <label className="subText">
                            {fechaInicio}
                        </label>
                    </Grid>
                    <Grid xs={6} mb="10px">
                        <label className="subText">
                            {fechaFin}
                        </label>
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={6} mt="10px" mb="10px">
                        <label className="textCard">
                            Transitorio
                        </label>
                        <label className="textLabel4">
                            {transitorio}
                        </label>
                    </Grid>
                    <Grid xs={6} textAlign="right" mt="20px">
                        {type ?
                            <IconHospitalDisabled sx={{ width: "40px", height: "40px" }} /> :
                            <IconHospital sx={{ width: "40px", height: "40px" }} />
                        }
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={2} mt="20px" textAlign="center">
                        {type ?
                            <IconUserDisabled /> :
                            <IconUser />
                        }
                    </Grid>
                    <Grid xs={10} mt="10px">
                        <label className="textLabel5">
                            Usuario creación
                        </label>
                        <label className="subText">
                            {usuarioCreacion}
                        </label>
                    </Grid>
                    <Grid xs={2} mt="20px" textAlign="center">
                        {type ?
                            <IconDateDisabled /> :
                            <IconDate />
                        }
                    </Grid>
                    <Grid xs={10} mt="10px">
                        <label className="textLabel5">
                            Fecha de creación
                        </label>
                        <label className="subText">
                            {fechaCreacion}
                        </label>
                    </Grid>
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid xs={12} textAlign="center" mt="16px">
                        <ButtonWithoutBorder
                            data-testid="eliminaPresTest"
                            disabled={type || disabled}
                            onClick={() => {
                                setHidePres(true)
                                setEliminaPrestador(true);
                            }}
                        >
                            Eliminar
                        </ButtonWithoutBorder>
                    </Grid>
                </Grid>
                <EliminarPrestador
                    open={eliminaPrestador}
                    setOpen={cierraElimina}
                    prestador={{ nombrePrestador: nombre, id: id }}
                    setAlertData={setMensajeroData}
                />
            </CardContent>
        </Card>
    );
};

export default CardPrestadores;
