import { useState } from "react";
import "../../../assets/App.css";
import "../../../assets/Repositorio.css";
import {
    Breadcrumbs,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { BotonCargaArchivo } from "../../../components/DragDrop/BotonCargaAchivo";
import TablaRegistrosLEP from "./components/TablaRegistrosLEP";
import { TabPanel } from "../../../components/TabPanel/TabPanel";
import { ExcelLep } from "./utils/excelLep";
import { hasJGESCAEC, isMantenedor } from "../../../utils/hasPermisions";
import Mensajero from "../../../components/Snackbar/Mensajero";
import { useMutation, useQuery } from "@apollo/client";
import { cargaLEP, cargaexcelLEP, tablaLep } from "../../../utils/queries";
import { SkeletonHeader } from "../utils/Loader";
import { SkeletonTableWithExport } from "../../../components/Skeleton/SkeletonTableWithExport";
import { fechaHoraGuionP } from "../../../utils/fechaUtils";
import HistorialCargasLEP from "./components/HistorialCargasLEP";

export default function MantenedorLEP({ rol, sucursal, usuario }) {
    const [tabValue, setTabValue] = useState(0);
    const [cargaLep, setCargaLep] = useState(false);
    const [firstLoadLEP, setFirstLoadLEP] = useState(false);
    const [fechaLEP, setFechaLEP] = useState(null);
    const [mensaje, setMensaje] = useState({ abrir: false, mensaje: "", severidad: "info" });
    const [filtroLEP, setFiltroLEP] = useState({ order: "id", dir: "DESC", limit: 10, offset: 0, decreto: -1, eliminado: false, pag: 0 });
    const [CargaMasivaLep] = useMutation(cargaLEP);
    const [CargaArchExcel] = useMutation(cargaexcelLEP, {
        onCompleted: () => {
            setMensaje({
                abrir: true,
                mensaje: "Excel cargado en GD",
                severidad: "success",
            });
            refetch();
        },
        onError: () => {
            muestraError("Error al cargar excel LEP a GD");
            refetch();
        }
    });

    const { data, loading, error, refetch } = useQuery(tablaLep, {
        variables: {
            problemaSalud: filtroLEP.problemaSalud || null,
            nombreProblemaSalud: filtroLEP.nombreProblemaSalud || null,
            tipoPrestacion: filtroLEP.tipoPrestacion || null,
            prestacion: filtroLEP.prestacion || null,
            descripcion: filtroLEP.descripcion || null,
            AH: filtroLEP.AH || null,
            decreto: filtroLEP.decreto || null,
            estado: filtroLEP.estado || null,
            order: filtroLEP.order,
            dir: filtroLEP.dir,
            limit: filtroLEP.limit,
            offset: filtroLEP.limit * filtroLEP.pag,
            eliminado: filtroLEP.eliminado
        },
        onCompleted: (res) => setFechaLEP(res?.getLepByFilter?.lastUpdate?.FechaIngreso || null)
    });

    if (!loading && data && !firstLoadLEP) {
        setFirstLoadLEP(true);
    }
    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            setFiltroLEP({ order: "id", dir: "DESC", limit: 10, offset: 0, decreto: -1, eliminado: false, pag: 0 });
        }
        if (newValue === 2) {
            setFiltroLEP({ order: "id", dir: "DESC", limit: 10, offset: 0, eliminado: true, pag: 0 });
        }
        setTabValue(newValue);
    };

    const cargaDatosLep = (lepCompress, len, baseLep, nom) => {
        CargaMasivaLep({
            variables: {
                prestxcan: lepCompress,
                creados: len,
                usuario: usuario,
                sucursal: sucursal
            },
            onCompleted: (data) => {
                setCargaLep(false);
                setMensaje({
                    abrir: true,
                    mensaje: "Carga exitosa, cargando a GD",
                    severidad: "success",
                });
                CargaArchExcel({
                    variables: {
                        id_log: parseInt(data.registraPrestaciones),
                        base64: baseLep,
                        nombreArchivo: nom
                    }
                });
            },
            onError: (error) => muestraError(error && error.networkError && error.networkError.result && error.networkError.result.errors[0]?.message !== 'Error en la carga masiva' ?
                error.networkError.result.errors[0].message :
                "Error al cargar LEP, revise el documento")
        })
    }

    const muestraError = (msg) => {
        setCargaLep(false);
        setMensaje({
            abrir: true,
            mensaje: "Error al cargar excel: " + msg,
            severidad: "error",
        });
    }
    return (
        <div>
            <Mensajero data={mensaje} setState={setMensaje} />
            <div className="container Main" data-testid="MedicamentosMain">
                <label className="titulo1">LEP</label>
                <Breadcrumbs
                    sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
                    aria-label="breadcrumb"
                    className="breadcrum"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        underline="always"
                        color="inherit"
                        to="/"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Inicio
                    </Link>
                    <Link
                        underline="always"
                        color="inherit"
                        to="/Mantenedor"
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "0px,8px",
                            cursor: "pointer",
                        }}
                    >
                        Mantenedor
                    </Link>
                    <Typography
                        sx={{
                            color: "#364855",
                            fontSize: "14px",
                            fontWeight: "700",
                            marginBottom: "-1px",
                            lineHeight: "24px",
                        }}
                    >
                        LEP
                    </Typography>
                </Breadcrumbs>
                <Grid container>
                    {loading && !firstLoadLEP ? <SkeletonHeader /> :
                        <Grid item xs={12} className="container">
                            <Grid container p="14px 24px 24px 24px">
                                <Grid item xs={8} >
                                    <label className="titulo2">Carga masiva</label>{" "}
                                    <br></br>
                                    <label className="labelFormVademecum">
                                        Carga múltiples registros LEP desde un archivo.
                                    </label>
                                    <label className="subUltActualizacion">
                                        Última actualización: {fechaLEP ? (fechaHoraGuionP(fechaLEP) + "hrs") : "NO DISPONIBLE"}
                                    </label>
                                </Grid>
                                <Grid item xs={4} pt="24px" direction={"row"} sx={{ minWidth: "190px !important" }}>
                                    <Stack direction={"row"} spacing={"16px"} textAlign="right">
                                        <label className="tipoFormatoMin">
                                            Formato aceptado: .xlsx <br></br>
                                            Peso máximo de 2MB
                                        </label>
                                        <BotonCargaArchivo
                                            disabled={!(hasJGESCAEC(rol) || isMantenedor(rol)) || cargaLep}
                                            cargando={cargaLep}
                                            maxSize={2000}
                                            toDo={ExcelLep}
                                            mutation={cargaDatosLep}
                                            msgError={muestraError}
                                            setUploading={setCargaLep}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>}

                    <Grid item xs={12} mt="24px">
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { background: '#00837A' } }}
                        >
                            <Tab label="Registros LEP" />
                            <Tab label="Historial de Carga" />
                            <Tab label="Registros eliminados" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={tabValue} index={0}>
                            {loading && !firstLoadLEP ? <SkeletonTableWithExport rows={5} /> :
                                <TablaRegistrosLEP
                                    data={data}
                                    error={error}
                                    filtros={filtroLEP}
                                    setFiltros={setFiltroLEP}
                                    loading={loading}
                                    setSnackData={setMensaje}
                                    refetch={refetch}
                                    rol={rol}
                                    usuario={usuario}
                                    sucursal={sucursal}
                                />}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className="container">
                            <HistorialCargasLEP setSnackData={setMensaje}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} >
                            {loading && !firstLoadLEP ? <SkeletonTableWithExport rows={5} /> :
                                <TablaRegistrosLEP
                                    isEliminado
                                    data={data}
                                    error={error}
                                    filtros={filtroLEP}
                                    setFiltros={setFiltroLEP}
                                    loading={loading}
                                    setSnackData={setMensaje}
                                    refetch={refetch}
                                    rol={rol}
                                    usuario={usuario}
                                    sucursal={sucursal}
                                />}
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
