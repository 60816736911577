import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import { Patologias } from "../../../../utils/patologias";
import { Arancel } from "../../ProblemasSalud/components/Arancel";

const BuscarV = ({
    onOpen, onClose, filtros, setFiltros, isDeleted
}) => {
    const [filtrosModal, setFiltrosModal] = useState(filtros);
    const estadoVDMC = [{ label: "Vigente", value: 2 }, { label: "No vigente", value: 1 }]
    if (!onOpen && filtrosModal !== filtros) {
        setFiltrosModal(filtros);
    }

    const detectaCambioFiltro = (event) => {
        let name = event.target.name;
        let value = name === "numero" ? parseInt(event.target.value.replace(/[^0-9]/g, "")) : event.target.value.replace(/[']+/g, "").trim();
        setFiltrosModal({
            ...filtrosModal,
            [event.target.id]: value
        });
    };

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Buscar registro Vademecum
                <br></br>
                <label className="subtitleModal">
                    Seleccione los criterios de búsqueda para encontrar los registros Vademecum específicos.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">Patología</label>
                        <Patologias
                            value={filtrosModal.patologia || null}
                            setValue={(val) =>
                                setFiltrosModal({ ...filtrosModal, patologia: val.Codigo })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Código de Canasta</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar Código de Canasta"
                                id="codcanasta"
                                data-testid="codcanasta"
                                value={filtrosModal.codcanasta || ""}
                                onChange={detectaCambioFiltro}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Fórmula o Principio Activo </label>
                        <FormControl >
                            <TextField
                                placeholder="Ingresar Fórmula o Principio Activo"
                                id="formulaActiva"
                                data-testid="formulaActiva"
                                value={filtrosModal.formulaActiva || ""}
                                onChange={detectaCambioFiltro}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Código SB</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar Código SB"
                                id="codigoSB"
                                name="numero"
                                data-testid="codigoSB"
                                value={filtrosModal.codigoSB || ""}
                                onChange={detectaCambioFiltro}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Descripción</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar Descripción"
                                id="descripcion"
                                data-testid="descripcion"
                                value={filtrosModal.descripcion || ""}
                                onChange={detectaCambioFiltro}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Arancel</label>
                        <Arancel
                            id="arancel"
                            data-testid="arancelVDMC"
                            value={filtrosModal.arancel}
                            setValue={(val) => {
                                setFiltrosModal({
                                    ...filtrosModal,
                                    arancel: val
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Estado</label>
                        <SelectPersonalizado
                            placeholder={isDeleted ? "ELIMINADO" : "Seleccionar un estado"}
                            placeholderHidden
                            disabled={isDeleted}
                            value={filtrosModal.estado}
                            setValue={(value) =>
                                setFiltrosModal({
                                    ...filtrosModal,
                                    estado: value
                                })}
                            options={estadoVDMC}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="24px"
                    pl="25px"
                    pr="10px"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        onClick={()=>{
                            onClose();
                            setFiltrosModal(filtros);
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="btnCheckDatos"
                        variant=" text"
                        color="default"
                        onClick={()=>{
                            onClose();
                            setFiltros(filtrosModal);
                        }}
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default BuscarV;
