import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { cie10 } from "../../../../utils/queries";
import { Autocomplete, Box, TextField } from "@mui/material";

export default function Cie10({ tipoCaso, info, informacionPaciente, setInformacionPaciente, deshabilitaInputs }) {
    const [cies10, setCies10] = useState([]);
    const [called, setCalled] = useState(false);
    const [getAllCie10,{ loading }] = useLazyQuery(cie10, {
        onCompleted: (res) => {
            setCies10(Array.from(new Set(res.getCie10)));
            res.getCie10.forEach((elemento) => {
                if (elemento.codigo === informacionPaciente.cie10) {
                    setInformacionPaciente({
                        ...informacionPaciente,
                        autoComplete: elemento,
                    });
                }
            });
        },
        onError: (err)=>{
            console.log(err.message)
        }
    });
    

    if (tipoCaso === "GES" && cies10.length === 0 && informacionPaciente.cies10.length > 0) {
        informacionPaciente.cies10.forEach((cie, index) => {
            if(cie.codigo === informacionPaciente.cie10){
                setInformacionPaciente({
                    ...informacionPaciente,
                    autoComplete: cie,
                });
            }
        });
        setCies10(informacionPaciente.cies10);
    }
    if (tipoCaso === "CAEC" && !called){
        setCalled(true);
        getAllCie10({});
    }

    return (
        <div id="divCaec">
            <label className="labelForm" htmlFor="DiagCAEC">
                {tipoCaso === "GES" ? "Diagnóstico CIE10" : "CAEC"}
            </label>
            <Autocomplete
                id="cie10"
                data-testid="cie10"
                loading={loading}
                disabled={
                    info.fkEstadoSolicitudIsapre === 4 ||
                        info.fkEstadoSolicitudIsapre === 5 ||
                        info.fkEstadoSolicitudIsapre === 6 ||
                        info.fkEstadoSolicitudIsapre === 7 ||
                        info.fkEstadoSolicitudIsapre === 9 ||
                        deshabilitaInputs
                        ? true
                        : false
                }
                sx={{ width: 300 }}
                options={cies10}
                autoHighlight
                isOptionEqualToValue={(option, value) => option === value}
                value={informacionPaciente.autoComplete || null}
                clearText={"Limpiar"}
                onChange={(event, option) => {
                    setInformacionPaciente({
                        ...informacionPaciente,
                        autoComplete: option,
                    });
                }}
                getOptionLabel={(option) => {
                    return option.codigo + " - " + option.Descripcion;
                }}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.codigo}
                        value={option.codigo}
                    >
                        {option.codigo} - {option.Descripcion}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Escribe un CIE10"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    );
}