import { useState } from "react";
import {
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { TableBodyLoader } from "../../../../components/Skeleton/SkeletonTableWithExport";
import { CabeceraTabla } from "../../../../components/TableHeader/TableHeader";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { NoResult } from "../../../../components/NoData/NoResults";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";
import BuscarV from "./BuscarV";
import ExportarV from "./ExportarV";
import { ChipsV } from "./ChipsV";
import { MenuV } from "./MenuV";

const TagsV = ({ estado }) => {
    switch (true) {
        case estado === true:
            return <label className="tagEstadoCasos tagIngresado">Vigente</label>
        case estado === false:
            return <label className="tagEstadoCasos tagRevision">No Vigente</label>
        default:
            return <label className="tagEstadoCasos tagRechazado">{estado}</label>
    }
}

export default function TablaRegistrosV({ data, error, filtros, setFiltros, loading, setSnackData, refetch, isDeleted, rol, usuario, sucursal }) {
    const [modalBuscar, setModalBuscar] = useState(false);
    const cabeceraV = [
        { id: "codigoCanasta", label: "Código Canasta" },
        { id: "formula", label: "Fórmula o Principio Activo" },
        { id: "sb", label: "Código SB" },
        { id: "descripcion", label: "Descripción" },
        { id: "arancel", label: "Arancel" },
        { id: "estado", label: "Estado" },
        { id: "acciones", label: "Acciones", noActions: true },
    ];

    return (
        <div className="container">
            <BuscarV onOpen={modalBuscar} onClose={() => setModalBuscar(false)} filtros={filtros} setFiltros={setFiltros} isDeleted={isDeleted}/>
            <Grid container p="14px 24px 24px 24px">
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent="space-between">
                        <label className="titulo2" style={{ paddingTop: "10px" }}>Registros vademecum</label>{" "}
                        <Stack direction={"row"} >
                            <div style={{ paddingTop: "10px" }}>
                                <OutlinedButton2
                                    className="noStyle"
                                    style={{ border: "none" }}
                                    onClick={() => setModalBuscar(true)}
                                >
                                    Ver filtros
                                </OutlinedButton2>
                            </div>
                            <ExportarV filtros={filtros} setSnackData={setSnackData} loadingTabla={loading}/>
                        </Stack>
                    </Stack>
                </Grid>
                <ChipsV filtros={filtros} setFiltros={setFiltros} />
            </Grid>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderRadius: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <CabeceraTabla
                        cabecera={cabeceraV}
                    />
                    {loading ? <TableBodyLoader largo={filtros.limit} isPS ancho={5} /> :
                        <TableBody>

                            {data && data.getVDMCbyFilter && data.getVDMCbyFilter.total > 0 ?
                                data.getVDMCbyFilter.VDMC.map((item, index) =>
                                    <TableRow
                                        key={item.id+index}
                                        data-testid="withData"
                                    >
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {item.canasta}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {verificaLargo(item.formulaActiva, 20)}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {item.codigoSB}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {verificaLargo(item.descripcion, 20)}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            {item.arancel}
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "15%" }}
                                            align="left"
                                        >
                                            <TagsV estado={isDeleted ? "Eliminado" : item.vigencia} />
                                        </TableCell>
                                        <TableCell
                                            className="fila"
                                            sx={{ width: "5%" }}
                                            align="left"
                                        >
                                            <MenuV 
                                                dataV={item}
                                                setSnackData={setSnackData}
                                                refetch={refetch}
                                                rol={rol}
                                                isDeleted={isDeleted}
                                                sucursal={sucursal}
                                                usuario={usuario}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                                : null}
                        </TableBody>}
                </Table>
            </TableContainer>
            {!loading && (!data || data.getVDMCbyFilter.total === 0) ?
                <NoResult titulo={error ? "Error" : "Sin resultados"} subtitulo={error ? "Error en la carga de datos" : "No se encontraron resultados para esta tabla"} noDivider />
                : null}
            <Grid alignItems="center" pb="15px" pt="24px">
                {loading ? null :
                    <Paginador
                        opciones={[10, 20, 30]}
                        data={(data && parseInt(data.getVDMCbyFilter.total)) || 0}
                        pagina={filtros.pag}
                        setPagina={(v) => setFiltros({ ...filtros, pag: v })}
                        itemPorPagina={filtros.limit}
                        original={filtros}
                        cambioLineasPag={setFiltros}
                    />}
            </Grid>
        </div>
    );
}