import { useState } from "react";
import { editaFechaCanasta } from "../../../../../utils/queries.js";
import { SecondaryButton } from "../../../../../components/Buttons/Botones";
import { parseISO } from "date-fns";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import BotonCarga from "../../../../../components/Buttons/BotonCarga";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import Aviso from "../../../../../components/Alert/Aviso.jsx";
import { MensajeEditaCanasta } from "./MensajeCanasta.js";
import { parseFecha } from "../../../../../utils/fechaUtils.js";

export const EditarCanasta = ({
    accounts,
    estado,
    fechaAutorizacion,
    fechaInicio,
    fechaFin,
    id,
    modalEditar,
    setModalEditar,
    setMensajeroData,
    sucursal,
    cargaBono,
    estadoCanasta
}) => {
    const [fechainicio, setFechainicioE] = useState(fechaInicio);
    const [fechaTermino, setFechaterminoE] = useState(fechaFin);
    const [editarCanasta, { loading: editandoCanasta }] = useMutation(editaFechaCanasta, {
        onCompleted: () => {
            setMensajeroData({
                abrir: true,
                mensaje: "Canasta editada correctamente.",
                severidad: "success",
            });
            handleCloseEditar();
            const recargaPrest = document.getElementById("recargaPrest");
            if (recargaPrest) {
                recargaPrest.click();
            }
            const recargaCanasta = document.getElementById("recargaCanastas");
            if (recargaCanasta) {
                recargaCanasta.click();
            }
        },
        onError: (err) => {
            //console.log(err.message);
            setMensajeroData({
                abrir: true,
                mensaje: "No se editó la canasta, intente nuevamente.",
                severidad: "error",
            });
        }
    });

    const editaCanasta = () => {
        editarCanasta({
            variables: {
                id: parseInt(id),
                fechaInicio: fechainicio,
                fechaTermino: fechaTermino,
                estadoCaso: estado,
                usuario: accounts[0].name,
                sucursal: sucursal,
            },
        });
    };

    const handleCloseEditar = () => {
        setModalEditar(false);
    };

    return (
        <Dialog
            maxWidth="md"
            open={modalEditar}
            data-testid="dialogEditar"
            onClose={handleCloseEditar}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                width="810px"
                sx={{
                    "& .MuiTextField-root": { m: 1 },
                }}
            >
                <DialogTitle sx={{ padding: "20px 13px 0px 13px" }}>
                    <label className="titulo2">Editar Fechas Canastas </label>
                </DialogTitle>
                <DialogContentText
                    alignContent="center"
                    sx={{ m: 1, textAlign: "center", width: "100%" }}
                >
                    <label className="labelForm">
                        Aquí puedes editar la fecha de inicio y/o término de la canasta.
                    </label>
                </DialogContentText>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <DialogContent sx={{ padding: "5px 20px 10px!important" }}>
                    <Grid
                        sx={{ margin: "0", width: "100%", padding: "10px 0 0" }}
                        container
                    >
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha de inicio</label>
                            <DatePicker
                                id="fechaInicioCan"
                                testid="fechaInicioCan"
                                minDate={parseISO(fechaAutorizacion)}
                                maxDate={(estadoCanasta.FInicio ? parseFecha(estadoCanasta.FInicio) : fechaTermino) || null}
                                value={fechainicio}
                                disabled={editandoCanasta || cargaBono || estadoCanasta.respuesta === -1}
                                noClear
                                onChange={(newValue) => {
                                    setFechainicioE(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label className="labelForm">Fecha de término</label>
                            <DatePicker
                                id="fechaTerminoCan"
                                testid="fechaTerminoCan"
                                minDate={estadoCanasta.FTermino ? parseFecha(estadoCanasta.FTermino) : fechainicio}
                                value={fechaTermino}
                                disabled={editandoCanasta || cargaBono || estadoCanasta.respuesta === -1}
                                onChange={(newValue) => {
                                    setFechaterminoE(newValue);
                                }}
                            />
                        </Grid>
                        <Aviso
                            mensaje={MensajeEditaCanasta(estadoCanasta)}
                            open={estadoCanasta && (estadoCanasta.respuesta === -1 || estadoCanasta.respuesta === 1)}
                            severidad={estadoCanasta.respuesta === -1 ? "error" : "warning"}
                            noClose
                        />
                    </Grid>
                </DialogContent>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <DialogActions
                    sx={{
                        margin: "0",
                        width: "100%",
                        padding: "20px 27px 18px 20px!important",
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <SecondaryButton
                            onClick={handleCloseEditar}
                            disabled={editandoCanasta}
                            data-testid="cancelarEdicion"
                        >
                            Cancelar
                        </SecondaryButton>
                        <BotonCarga
                            testid="btnEditarCanasta"
                            loading={editandoCanasta || cargaBono}
                            disabled={estadoCanasta.respuesta === -1 || (fechaInicio === fechainicio && fechaFin === fechaTermino)}
                            toDo={editaCanasta}
                            label="Continuar"
                        />
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}