import { useState } from "react";
import AsignaPrestador from "./Prestadores/AsignaPrestador/AsignaPrestador"
import TablaPrestadoresCaec from "./Prestadores/TablaPrestadoresCaec";
import Mensajero from "../../../components/Snackbar/Mensajero";

export default function CAEC({ data, deshabilitaInputs, sucursal }) {
    const [snackData, setSnackData] = useState({
        abrir: false,
        mensaje: "",
        severidad: "info",
    });
    const [dataPrestadores, setDataPrestadores] = useState(null);

    return (
        <div data-testid="CAEC">
            <Mensajero data={snackData} setState={setSnackData} />
            <AsignaPrestador
                data={data}
                deshabilitaInputs={deshabilitaInputs}
                sucursal={sucursal}
                estadoSolicitud={data.fkEstadoSolicitudIsapre}
                fechaAu={data.fechaAutorizacionSoliciut}
                folio={data.fkFun}
                setMensajeroData={setSnackData}
                isCaec={true}
                dataPrestadores={dataPrestadores}
            />
            <TablaPrestadoresCaec
                data={data}
                deshabilitaInputs={deshabilitaInputs}
                sucursal={sucursal}
                setDataPrestadores={setDataPrestadores}
            />
        </div>
    );
}