import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { stringify } from "zipson/lib";
import { fechaConvertida, ajustaFecha } from "../../../utils/fechaUtils";
import { validRut } from "chilean-rutify";
import { isValid } from "date-fns";

export const generaExcelMed = async (data = [], setSnackData) => {
    try {
        const vdmc = [];
        vdmc.push(["RUT", "Caso", "Canastas", "Fecha de inicio", "Fecha de término",
            "SKU", "Descripción ME", "Fecha creación", "Usuario creación"]);
        data.forEach((med) => {
            const fechaInicio = fechaConvertida(med.fechaInicio);
            const fechaTermino = med.fechaTermino ? fechaConvertida(med.fechaTermino) : null;
            vdmc.push([
                med.rut,
                med.caso,
                med.canasta,
                fechaInicio,
                fechaTermino,
                med.sku,
                med.descripcionmed,
                med.fechaCreacion,
                med.usuarioCreador
            ])
        });
        const xlsVDMC = new Workbook();

        xlsVDMC.creator = "GES";

        xlsVDMC.addWorksheet("DatosME");

        const worksheet = xlsVDMC.getWorksheet(1);

        worksheet.addRows(vdmc);

        xlsVDMC.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${"MedicamentosExcepción"}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            setSnackData({
                abrir: true,
                mensaje: "Descarga generada, revise sus archivos descargados",
                severidad: "success",
            });
            return;
        });
        return;
    } catch (err) {
        setSnackData({
            abrir: true,
            mensaje: "No se generó la descarga, intente nuevamente",
            severidad: "error",
        });
    }
}

export const parseExcelMed = async (archivo, mutation, errorCargaMed, usuario, sucursal) => {
    try {
        const libroExcel = new Workbook();
        const ReaderME = new FileReader();
        ReaderME.readAsArrayBuffer(archivo);
        ReaderME.onload = async ({ target }) => {
            await libroExcel.xlsx.load(target.result);
            const hoja1 = libroExcel.worksheets[0];
            preCargaME(hoja1._rows, mutation, errorCargaMed, usuario, sucursal, archivo);
        }
    } catch (err) {
        errorCargaMed("No se logró procesar el archivo, intente nuevamente.");
    }

}

export const preCargaME = async (data, mutation, errorMsg, usuario, sucursal, excel) => {
    const ReaderME = new FileReader();
    ReaderME.readAsDataURL(excel);
    ReaderME.onload = async ({ target }) => {
        preparaDatosME(data, mutation, errorMsg, usuario, sucursal, target.result.substr(`data:${excel.type};base64,`.length), excel.name);
    }
}

export const preparaDatosME = (excelArray, mutation, errorCargaMed, usuario, sucursal, b64, name) => {
    try {
        const meds = [];
        const registros = [];
        if (excelArray.length <= 1) {
            throw new Error("Archivo sin datos para procesar.");
        }
        if (excelArray.length > 30001) {
            throw new Error("Excede máximo de registros a procesar. (máx 30.000)");
        }
        excelArray.forEach((row, index) => {
            if (index === 0) {
                row._cells.forEach((cell) => {
                    const checkTitulos = (a, b) => {
                        if (a !== b) {
                            throw new Error("Columnas Inválidas.");
                        }
                    }
                    switch (cell._address) {
                        case "A1": checkTitulos(cell._value.model.value, "RUT")
                            break;
                        case "B1": checkTitulos(cell._value.model.value, "Caso")
                            break;
                        case "C1": checkTitulos(cell._value.model.value, "Canastas")
                            break;
                        case "D1": checkTitulos(cell._value.model.value, "Fecha de inicio")
                            break;
                        case "E1": checkTitulos(cell._value.model.value, "Fecha de término")
                            break;
                        case "F1": checkTitulos(cell._value.model.value, "SKU")
                            break;
                        case "G1": checkTitulos(cell._value.model.value, "Descripción ME")
                            break;
                        default: break;
                    }
                });
            }

            if (index > 0) {
                const camposExcel = [];

                row._cells.forEach((cell) => {
                    camposExcel.push({ posX: cell._address, value: cell._value.model.value });
                });
                const rut = camposExcel.find((fila) => fila.posX.includes("A"));
                const caso = camposExcel.find((fila) => fila.posX.includes("B"));
                const canasta = camposExcel.find((fila) => fila.posX.includes("C"));
                const fechaInicio = camposExcel.find((fila) => fila.posX.includes("D"));
                const fechaTermino = camposExcel.find((fila) => fila.posX.includes("E"));
                const sku = camposExcel.find((fila) => fila.posX.includes("F"));
                const descripcionmed = camposExcel.find((fila) => fila.posX.includes("G"));
                var fechaInicioME = null;
                var fechaTerminoME = null;

                if (!rut || !validRut(rut.value)) {
                    throw new Error("Rut inválido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(caso && caso.value && Number.isInteger(caso.value))) {
                    throw new Error("Caso no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(canasta && canasta.value)) {
                    throw new Error("Debe ingresar una canasta (línea " + (index + 1) + "), favor revisar.");
                }
                if (canasta.value.length > 255) {
                    throw new Error("canasta sobre el límite (" + canasta.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaInicio && fechaInicio.value) {
                    if (typeof fechaInicio.value === 'string' || typeof fechaInicio.value === 'object') {
                        if (isValid(fechaInicio.value)) {
                            fechaInicioME = ajustaFecha(fechaInicio.value);
                        } else {
                            var InicioVigenciaParts = fechaInicio.value.split('/');
                            var mydate = new Date(InicioVigenciaParts[2], InicioVigenciaParts[1] - 1, InicioVigenciaParts[0]);
                            if (isValid(mydate)) {
                                fechaInicioME = mydate;
                            }
                        }
                    }
                } else {
                    throw new Error("Debe ingresar Fecha Inicio (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaInicioME === null) {
                    throw new Error("Fecha Inicio no es fecha válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaTermino && fechaTermino.value) {
                    if (isValid(fechaTermino.value)) {
                        fechaTerminoME = ajustaFecha(fechaTermino.value);
                    } else {
                        var termVig = fechaTermino.value.split('/');
                        var testdate = new Date(termVig[2], termVig[1] - 1, termVig[0]);
                        if (isValid(testdate)) {
                            fechaTerminoME = testdate;
                        } else {
                            throw new Error("Fecha Término no válida (línea " + (index + 1) + "), favor revisar.");
                        }
                    }
                }
                if (!(sku && sku.value && Number.isInteger(sku.value))) {
                    throw new Error("SKU no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(descripcionmed && descripcionmed.value)) {
                    throw new Error("Debe ingresar una descripción (línea " + (index + 1) + "), favor revisar.");
                }
                if (descripcionmed.value.length > 255) {
                    throw new Error("Descripción sobre el límite (" + descripcionmed.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                registros.push({
                    rut: rut.value.toUpperCase(),
                    caso: caso.value,
                    canasta: canasta.value,
                    fecha_inicio: fechaInicioME,
                    fecha_fin: fechaTerminoME,
                    sku: sku.value,
                    descripcionmed: descripcionmed.value.replace(/[']+/g, "").trim(),
                    activo: true,
                    creador: usuario,
                    sucursal: sucursal
                });
                meds.push({
                    sku: sku.value,
                    descripcion: descripcionmed.value,
                    creador: usuario,
                    sucursal: sucursal
                })
            }
        });
        if (registros.some((arrayA, indexA) =>
            registros.find((arrayB, indexB) =>
                arrayB.caso === arrayA.caso &&
                arrayB.canasta === arrayA.canasta &&
                arrayB.sku === arrayA.sku &&
                indexA !== indexB))) {
            throw new Error("existen registros duplicados, favor revisar el excel de carga.");
        } else {
            mutation(stringify(meds), stringify(registros), registros.length, stringify(b64), name);
        }
    } catch (err) {
        console.log(err.message)
        errorCargaMed(err.message);
    }
}