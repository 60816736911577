import React from "react";
import "../../components/Skeleton/Skeleton.scss";

const SkeletonIngreso = () => {
  const colsk = (
  <li>
    <div className="col-sk"></div>
  </li>);

  const columns = Array.from({length: 4}, ()=>(colsk));

  const labels = (<ul className="ul-label2">
    {Array.from({length: 3}, ()=>(colsk))}
  </ul>);

  const inputs = (<ul className="ul-input2">
    {Array.from({length: 3}, ()=>(colsk))}
  </ul>);

  const combo = Array.from({length: 3}, ()=>(<>{labels}{inputs}</>));
  const container = (      
    <div className="container">
      <div className="bloque p-4">
        <div className="skeleton ">
          <div className="skeleton">
            <div className="post-sk">
              <div className="title-sk2"/>
              {combo}
            </div>
          </div>
        </div>
      </div>
  </div>);
  return (
    <>
      <div className="container" data-testid="contenedorSkeleton">
        <div className="bloque p-4">
          <div className="skeleton ">
            <div className="skeleton">
              <div className="post-sk">
                <div className="title-sk2"/>
                <ul className="ul-label">
                  {columns}
                </ul>
                <ul className="ul-input">
                  {columns}
                </ul>
                {combo}
              </div>
            </div>
          </div>
        </div>
      </div>
      {Array.from({length: 3}, ()=>(container))}
    </>
  );
};
export default SkeletonIngreso;
