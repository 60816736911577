import { getDate, getMonth, getYear, parseISO, isValid } from "date-fns";

export function parseFecha(valor){
    var fecha = parseISO(valor);
    if(isValid(fecha)){
        let dia = "" + getDate(fecha);
        let mes = "" + (getMonth(fecha)+1);
        let anio = "" + getYear(fecha);
        if(dia.length < 2){
            dia = "0" + dia;
        }
        if(mes.length < 2){
            mes = "0" + mes;
        }
        return dia + mes + anio;
    }
    return "01013000";
}

export function parseRut(rut, nodv){
  if(rut === null) return "";
  let tmp = rut.split('-');
  let dvRep = tmp[1];
  let rutRepSinDV = tmp[0];
  if(nodv){
      return rutRepSinDV
  }
  return dvRep;      
}

export function matchCie10(cie10){
    switch(cie10){
        case "Q005.2": return 1;
        case "Q005.1": return 2;
        case "C095.1": return 3;
        case "C095.2": return 4;
        case "C95.0": return 5;
        case "C95.1": return 6;
        default: return 0;
    }
  }

export function parseEstado(estado){
  switch(estado){
      case 1:
          return "E";
      case 2:
          return "E";
      case 3:
          return "E";
      case 4:
          return "A";
      case 5:
          return "R";
      case 6:
          return "N"
      case 7:
          return "A";
      case 8:
          return "E";
      case 10:
          return "A";  
      default:
          return "";
  }
}