import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { exportaME } from "../../../../utils/queries";
import { generaExcelMed } from "../../../Medicamentos/utils/procesaExcelME";
import { parse } from "zipson";

export default function ExportarC({ filtros, setSnackData, loadingTabla }) {
    const [exportarMed, { loading }] = useLazyQuery(exportaME,{
        fetchPolicy: "network-only",
        onCompleted: (data) => generaExcelMed(parse(data.exportaME), setSnackData),
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al consultar al servidor, intente nuevamente",
            severidad: "error",
        })
    });

    return (
        <BotonCarga
            label={"Exportar"}
            testid={"exportarME"}
            download
            secondary={true}
            loading={loading}
            disabled={loadingTabla}
            
        />
    );
}