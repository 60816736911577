import { Fragment } from "react";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import {
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Step,
    StepButton,
    Stepper,
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../../../components/Buttons/Botones";
import AvisoPrestadoresFR from "./AvisoPrestadoresFR";
import Titulo from "./Titulo";
import DentroDeRed from "./DentroDeRed";
import FueraDeRed from "./FueraDeRed";
import ModalRegion from "./ModalRegion";
import Aviso from "../../../../../../components/Alert/Aviso";

export function DialogInicio({ useAsignaPrestadorHook = useAsignaPrestadorContext,
    data,
    estadoSolicitud,
    folio,
    fechaAutorizacionSolicitud,
    sucursal,
    tipoCaso,
    isEditaPres,
    setMensajeroData,
    canasta,
    volver,
    postguardar,
    isCaec,
    prestadoresAsignados
}) {
    const {
        steps,
        activeStep,
        completed,
        handleStep,
        handleBack,
        handleNext,
        modalInicio,
        cerrarModalInicio,
        dataCorrecta,
        guardarPrestador,
        guardando,
        setTipoPrestador
    } = useAsignaPrestadorHook();

    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={modalInicio}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="tituloModal" sx={{ padding: "10px 0" }}>
                <Titulo canasta={canasta} isCaec={isCaec} />
            </DialogTitle>
            <Grid item xs={12} justifyContent="center" padding="16px 180px 4px 180px">
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <DialogContent sx={{ padding: "0 20px" }}>
                <Fragment>
                    {activeStep === 0 ?
                        <Slide direction="right" in={activeStep === 0} mountOnEnter unmountOnExit>
                            <Grid container>
                                <Grid item xs={12} padding="0px 20px 16px 20px">
                                    <Aviso
                                        open={isEditaPres}
                                        mensaje={"La fecha de termino del prestador será modificada cuando establezcas un nuevo prestador que complete el periodo de la canasta."}
                                        severidad={"warning"}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    sx={{ padding: "0 20px" }}
                                >
                                    <Grid item xs={isEditaPres ? 12 : 6}>
                                        <DentroDeRed
                                            estado={estadoSolicitud}
                                            tipoCaso={tipoCaso}
                                            fkFun={folio}
                                            data={(data && data.cuentaprestadoresxcanasta && data.cuentaprestadoresxcanasta.contDR) || 0}
                                            fechaAutorizacionSolicitud={fechaAutorizacionSolicitud}
                                            handleNext={() => {
                                                handleNext();
                                                setTipoPrestador("DR");
                                            }}
                                        />
                                    </Grid>
                                    {isEditaPres ? null :
                                        <Grid item xs={6}>
                                            <FueraDeRed
                                                estado={estadoSolicitud}
                                                fechaAutorizacionSolicitud={fechaAutorizacionSolicitud}
                                                tipoCaso={tipoCaso}
                                                fkFun={folio}
                                                data={(data && data.cuentaprestadoresxcanasta) || { contDR: 0, conFR: 0 }}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    sx={{ padding: "0 20px" }}
                                >
                                    <Grid item xs={12}>
                                        <AvisoPrestadoresFR contador={(data && data.cuentaprestadoresxcanasta && data.cuentaprestadoresxcanasta.contDR) || 0} isapre={estadoSolicitud} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Slide> : null}
                    {activeStep === 1 ?
                        <Slide direction="left" in={activeStep === 1} mountOnEnter unmountOnExit>
                            <Grid container >
                                <ModalRegion
                                    canasta={canasta}
                                    tipoCaso={tipoCaso}
                                    folio={folio}
                                    isEditaPres={isEditaPres}
                                    fechaAutorizacionSolicitud={fechaAutorizacionSolicitud}
                                    estadoSolicitud={estadoSolicitud}
                                    isCaec={isCaec}
                                    prestadoresAsignados={prestadoresAsignados}
                                    sucursal={sucursal}
                                />
                            </Grid>
                        </Slide>
                        : null}
                </Fragment>
            </DialogContent>
            <DialogActions dividers>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "0 20px 0 20px" }}
                >
                    {activeStep > 0 ?
                        <SecondaryButton
                            onClick={() => handleBack(isCaec)}
                        >
                            Volver
                        </SecondaryButton>
                        :
                        <SecondaryButton
                            onClick={
                                isEditaPres ?
                                    () => { cerrarModalInicio(); volver(true); }
                                    : cerrarModalInicio
                            }
                        >
                            Cerrar
                        </SecondaryButton>}
                    <PrimaryButton
                        data-testid="btnCloseModal"
                        hidden={activeStep === 0}
                        variant=" text"
                        color="default"
                        disabled={dataCorrecta || guardando}
                        onClick={() => {
                            guardarPrestador(
                                folio,
                                tipoCaso,
                                canasta,
                                estadoSolicitud,
                                sucursal,
                                postguardar,
                                setMensajeroData
                            );
                        }}
                    >
                        Establecer prestador
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}