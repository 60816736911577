import { useState } from "react";
import { Grid } from "@mui/material";
import NuevoRegistroBitacora from "./components/NuevoRegistroBitacora";
import TablaBitacora from "./components/TablaBitacora";
import Mensajero from "../../../components/Snackbar/Mensajero";

export function GesCaeBitacora({ data, deshabilitaInputs, sucursal, isContralor }) {
  const [snackData, setSnackData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  return (
    <Grid>
      <Mensajero data={snackData} setState={setSnackData} />
      <NuevoRegistroBitacora
        data={data}
        deshabilitaInputs={deshabilitaInputs}
        setSnackData={setSnackData}
        sucursal={sucursal}
        isContralor={isContralor}
      />
      <div className="container">
        <Grid
          sx={{ padding: "15px 24px 20px 14px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <label className="titulo2 p-4">Historial Bitácora</label>
        </Grid>
        <TablaBitacora
          folio={data.fkFun}
          estado={data.fkEstadoSolicitudIsapre}
          snackData={snackData}
          setSnackData={setSnackData}
          sucursal={sucursal}
          isContralor={isContralor}
        />
      </div>
    </Grid>
  );
}

export default GesCaeBitacora;
