
const ErrorText = (errors, mantenedor) => {
    var errores = [];
    const textError = mantenedor ? "" : "Error: ";
    errors && errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
            errores.push(1);
        }
        if (error.code === "file-too-large") {
            errores.push(2);
        }
        if (error.code === "too-many-files") {
            errores.push(3);
        }
    })
    if (errores.indexOf(3) !== -1) {
        return textError + "Demasiados archivos adjuntados, eliminar sobrantes.";
    }
    if (errores.indexOf(1) !== -1 && errores.indexOf(2) !== -1) {
        return textError + "Formato de archivo inválido y " + (mantenedor ? "peso sobre el límite" : "pesa más de 5MB") + ", Por favor, Inténtelo nuevamente."
    }
    if (errores.indexOf(1) !== -1) {
        return textError + "Formato de archivo inválido, Por favor, Inténtelo nuevamente.";
    }
    if (errores.indexOf(2) !== -1) {
        return textError + "El archivo " + (mantenedor ? "esta sobre el límite de peso" : "pesa más de 5MB") + ", Por favor, Inténtelo nuevamente.";
    }
    return "";
}

export default ErrorText;