import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import icon_warningOrange from "../../../../components/icons/icon_warningOrange.svg";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useMutation } from "@apollo/client";
import { eliminabitacora } from "../../../../utils/queries";
import { useMsal } from "@azure/msal-react";

export const DialogEliminarBit = ({
    dialogEliminar,
    cerrarModal,
    idBit,
    setSnackData,
    refetch,
    sucursal
}) => {

    const { accounts } = useMsal();
    const [eliminaRegistro, { loading }] = useMutation(eliminabitacora, {
        onCompleted: () => {
            cerrarModal();
            setSnackData({
                abrir: true,
                mensaje: "Registro eliminado correctamente.",
                severidad: "success",
            });
            refetch();
        },
        onError: (err) => {
            console.log("Error Tabla bitacora: ", err.message);
            setSnackData({
                abrir: true,
                mensaje: "No fue posible eliminar el registro, Favor reintente.",
                severidad: "error",
            });
        }
    });

    const handleEliminar = () => {
        eliminaRegistro({
            variables: {
                id: parseInt(idBit),
                usuario: accounts[0].name,
                sucursal: sucursal,
            },
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={dialogEliminar}
            onClose={cerrarModal}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px",
                    }}
                >
                    <img alt="img" src={icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2" htmlFor="archivo">
                    ¿Seguro que quieres eliminar?
                </div>
            </DialogTitle>

            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
                Vas a eliminar un registro de la bitácora
            </DialogContentText>

            <DialogActions
                container
                direction="row"
                alignitems="center"
                sx={{ justifyContent: "center!important", margin: "5px 0 15px 0" }}
            >
                <BotonCarga
                    id="guardarHI"
                    testid="eliminarRegBit"
                    sx={{ marginRight: "8px!important" }}
                    boxSX={{ "& > button": { m: 1 } }} campos
                    label="Eliminar"
                    toDo={handleEliminar}
                    loading={loading}
                />
                <SecondaryButton onClick={cerrarModal}>
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
}