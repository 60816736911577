import React, { useState } from "react";
import {
    Autocomplete,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import { SecondaryButton } from "../../../components/Buttons/Botones";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import {
    formatRut,
    validRut
  } from "chilean-rutify";
import { dataUsuarioMe, guardaME, listamed } from "../../../utils/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SelectPersonalizado } from "../../../components/select/SelectPersonalizado";
import BotonCarga from "../../../components/Buttons/BotonCarga";
import { parseFecha } from "../../../utils/fechaUtils";

const ModalIndividual = ({
    onOpen,
    onClose,
    setSnackData,
    refetch,
    usuario,
    sucursal
}) => {
    const [dataMEIndividual, setDataMEIndividual] = useState({});
    const [errores, setErrores] = useState({});
    const [medicamentos, setMedicamentos] = useState([]);
    const [lockMed, setLockMed] = useState(false);

    const { loading: loadingSKU, refetch: recargaListaME } = useQuery(listamed, {
        onCompleted: (data) => {
            const meds = [];
            data.getmedicamentos.forEach((med) => {
                meds.push({ codigo: med.sku.toString(), descripcion: med.descripcion })
            });
            setMedicamentos(meds);
        }
    });

    const [getCasosPaciente, { loading }] = useLazyQuery(dataUsuarioMe, {
        onCompleted: (data) => {
            if (data.dataUsuarioME && data.dataUsuarioME.message && data.dataUsuarioME.message.length > 0) {
                if (data.dataUsuarioME && data.dataUsuarioME.casos.length > 0) {
                    const casosAbiertos = [];
                    data.dataUsuarioME.casos.forEach((caso) => {
                        casosAbiertos.push({
                            label:`${caso.fkProblemaSalud} - ${caso.GlosaSis}`,
                            value: caso.fkFun,
                            fechaAu: caso.fechaAutorizacion,
                            canastas: caso.canastas
                        });
                    });
                    setDataMEIndividual({
                        ...dataMEIndividual,
                        nombre: data.dataUsuarioME.nombre,
                        casos: casosAbiertos
                    });
                } else {
                    setErrores({ ...errores, rut: "Este RUT no tiene casos asociados" });
                }
            } else if (data.dataUsuarioME && data.dataUsuarioME.message && data.dataUsuarioME.message.length === 0) {
                setErrores({ ...errores, rut: "Rut ingresado no existe en el servicio" });
            } else if (data.dataUsuarioME && !data.dataUsuarioME.message){
                setErrores({ ...errores, rut: "Rut ingresado con poliza no vigente" });
            }

        },
        onError: () => setErrores({ ...errores, rut: "Error en consulta, reintentar" })
    });

    const [saveME, { loading: loadingGuardaME }] = useMutation(guardaME, {
        onCompleted: () => {
            setSnackData({
                abrir: true,
                mensaje: "Medicamento asignado correctamente",
                severidad: "success",
            });
            close();
            recargaListaME();
            refetch();
        },
        onError: () => {
            setSnackData({
                abrir: true,
                mensaje: "No fue posible asignar el medicamento, intente nuevamente",
                severidad: "error",
            });
        }
    });

    const verificaRut = (e) => {
        let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
        rut = rut.length > 2  ? formatRut(rut).toUpperCase() : rut;
        let err = errores;
        delete err.rut;
        delete err.caso;
        delete err.canasta;
        setDataMEIndividual({
            ...dataMEIndividual,
            rut: rut,
            caso: null,
            casos: null,
            canasta: null,
            canastas: null,
            nombre: null
        });
        if (rut) {
            if (validRut(rut)) {
                setErrores({ ...err });
                getCasosPaciente({
                    variables: {
                        rut: rut.replace(/[^0-9kK-]/g, "")
                    }
                });
            } else {
                setErrores({
                    ...err,
                    rut: "Este RUT no es válido"
                });
            }
        } else {
            setErrores({ ...err });
        }

    };

    const cambioSKU = (value, event) => {
        if (event === null || event.type === "click" || event.type === "keydown") return;
        let sku = value.replace(/[^0-9]/g, "");
        if (sku.length > 9) return
        let err = errores;
        delete err.sku;
        delete err.nombreMed;
        setLockMed(false);
        setDataMEIndividual({
            ...dataMEIndividual,
            sku: sku,
            nombreMed: null
        });
        setErrores({ ...err });
    }

    const asignaSKU = (event, option) => {
        let err = errores;
        delete err.sku;
        delete err.nombreMed;
        setErrores({ ...err });
        setLockMed(true);
        if (option) {
            setDataMEIndividual({
                ...dataMEIndividual,
                sku: option.codigo,
                nombreMed: option.descripcion
            })
        } else {
            setDataMEIndividual({
                ...dataMEIndividual,
                sku: null,
                nombreMed: null
            })
        }
    }

    const cambioInputs = (e) => {
        const valor = e.target.value.replace(/[']+/g, "").trim();
        const id = e.target.id;
        let err = errores;
        delete err[id];
        setDataMEIndividual({
            ...dataMEIndividual,
            [id]: valor
        })
        setErrores({ ...err });
    }

    const buscaCanasta = (valCaso) => {
        let err = errores;
        delete err.caso;
        const canastas = [];
        const casoActivo = dataMEIndividual.casos.find((caso) => parseInt(caso.value) === parseInt(valCaso));
        casoActivo.canastas.forEach((canasta) => {
            canastas.push({ label: canasta, value: canasta });
        });
        setDataMEIndividual({
            ...dataMEIndividual,
            caso: valCaso,
            canasta: null,
            canastas: canastas,
            fechaAu: casoActivo.fechaAu,
            fechaInicio: casoActivo.fechaAu,
            fechaTermino: null
        });
        setErrores({ ...err });
    }

    const checkDatosME = () => {
        var error = errores;
        if (!validRut(dataMEIndividual.rut)) {
            error = { ...error, rut: "Ingrese un RUT válido" }
        }
        if (!dataMEIndividual.caso) {
            error = { ...error, caso: "Debe seleccionar un caso" }
        }
        if (!dataMEIndividual.canasta) {
            error = { ...error, canasta: "Debe seleccionar una canasta" }
        }
        if (!dataMEIndividual.sku) {
            error = { ...error, sku: "Debe ingresar un SKU" }
        }
        if (!dataMEIndividual.nombreMed) {
            error = { ...error, nombreMed: "Debe ingresar una descripción" }
        }
        if (!dataMEIndividual.fechaInicio) {
            error = { ...error, fechaInicio: "Debe ingresar una fecha de inicio" }
        }

        if (Object.keys(error).length === 0) {
            saveME({
                variables: {
                    rut: dataMEIndividual.rut.replace(/[^0-9kK-]/g, "").toUpperCase(),
                    canasta: dataMEIndividual.canasta,
                    codigoME: parseInt(dataMEIndividual.sku),
                    caso: parseInt(dataMEIndividual.caso),
                    fechaInicio: dataMEIndividual.fechaInicio,
                    fechaTermino: dataMEIndividual.fechaTermino || null,
                    nombreMed: dataMEIndividual.nombreMed,
                    usuario: usuario,
                    sucursal: sucursal
                }
            })
        } else {
            setErrores({ ...error });
        }
    }

    const close = () => {
        setDataMEIndividual({});
        setErrores({});
        onClose();
    }

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Asignación individual
                <br></br>
                <label className="subtitleModal">
                    Asigna medicamento excepción a usuario específico.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">RUT beneficiario </label>
                        <FormControl error={errores.rut ? true : undefined}>
                            <TextField
                                data-testid="inputRutBenef"
                                error={errores.rut ? true : undefined}
                                placeholder="Ingresar RUT beneficiario"
                                value={dataMEIndividual.rut || ""}
                                onChange={verificaRut}
                                disabled={loadingGuardaME}
                                inputProps={{ maxLength: 12 }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton position="start">
                                            {loading ?
                                                <CircularProgress data-testid="buscandoRutBenef" size={20} />
                                                : null
                                            }
                                        </IconButton>
                                    )
                                }}
                            />
                            <FormHelperText>{errores.rut || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Nombre beneficiario</label>
                        <TextField
                            placeholder="Nombre beneficiario"
                            value={dataMEIndividual.nombre || ""}
                            disabled
                            data-testid="nombreBenef"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Problema de salud</label>
                        <SelectPersonalizado
                            inputTest="selectCasos"
                            id="selectCasos"
                            disabled={!dataMEIndividual.casos || loadingGuardaME}
                            value={dataMEIndividual.caso || ""}
                            error={errores.caso}
                            setValue={buscaCanasta}
                            options={dataMEIndividual.casos || []}
                            placeholder={"Seleccionar Problema de salud"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Canasta (código)</label>
                        <SelectPersonalizado
                            inputTest="selectCanasta"
                            id="selectCanasta"
                            disabled={!dataMEIndividual.caso || loadingGuardaME}
                            error={errores.canasta}
                            value={dataMEIndividual.canasta || ""}
                            setValue={((value) => cambioInputs({ target: { value: value, id: "canasta" } }))}
                            options={dataMEIndividual.canastas || []}
                            placeholder={"Seleccionar canasta"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">SKU medicamento</label>
                        <FormControl error={errores.sku ? true : undefined}>
                            <Autocomplete
                                id="autocomSKU"
                                data-testid="autocomSKU"
                                disabled={loadingGuardaME}
                                sx={{ width: 300 }}
                                options={medicamentos}
                                loading={loadingSKU}
                                loadingText={"Cargando..."}
                                noOptionsText={"Sin coincidencias"}
                                autoHighlight
                                clearText={"Limpiar"}
                                clearOnBlur={false}
                                value={medicamentos.find((med) => med.codigo === dataMEIndividual.sku) || null}
                                onChange={asignaSKU}
                                inputValue={dataMEIndividual.sku || ""}
                                onInputChange={(event, valor) => cambioSKU(valor, event)}
                                getOptionLabel={(option) => option.codigo.toString()}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                        key={option.codigo}
                                        value={option.codigo}
                                    >
                                        {option.codigo.toString()}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Ej: 123456789"
                                        data-testid="inputSKUAutocomplete"
                                    />
                                )}
                            />
                            <FormHelperText>{errores.sku || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Nombre medicamento</label>
                        <FormControl error={errores.nombreMed ? true : undefined}>
                            <TextField
                                placeholder="Ingresar nombre medicamento"
                                id="nombreMed"
                                data-testid="nombreMed"
                                value={dataMEIndividual.nombreMed || ""}
                                disabled={lockMed || loadingGuardaME}
                                error={errores.nombreMed ? true : undefined}
                                onChange={cambioInputs}
                            />
                            <FormHelperText>{errores.nombreMed || ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Fecha inicio</label>
                        <DatePicker
                            minDate={parseFecha(dataMEIndividual.fechaAu)}
                            value={dataMEIndividual.fechaInicio || parseFecha(dataMEIndividual.fechaAu) || null}
                            noClear
                            id="fechaInicio"
                            testid="fechaInicio"
                            error={errores.fechaInicio}
                            disabled={!dataMEIndividual.caso || loadingGuardaME}
                            onChange={(fecha) => {
                                let err = errores;
                                delete err.fechaInicio;
                                setDataMEIndividual({
                                    ...dataMEIndividual,
                                    fechaInicio: fecha,
                                });
                                setErrores({ ...err });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Fecha término</label> <label className="labeloptional">(opcional)</label>
                        <DatePicker
                            minDate={dataMEIndividual.fechaInicio || null}
                            value={dataMEIndividual.fechaTermino || null}
                            disabled={!dataMEIndividual.caso || loadingGuardaME}
                            id="fechaTermino"
                            testid="fechaTermino"
                            error={errores.fechaTermino}
                            onChange={(fecha) => {
                                setDataMEIndividual({
                                    ...dataMEIndividual,
                                    fechaTermino: fecha,
                                });
                            }}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingLeft: "20px", paddingRight: "11px" }}
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        disabled={loadingGuardaME}
                        onClick={close}
                    >
                        Cancelar
                    </SecondaryButton>
                    <BotonCarga
                        testid="formButtonSubmit"
                        label="Asignar"
                        toDo={checkDatosME}
                        loading={loadingGuardaME}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ModalIndividual;
