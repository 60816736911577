import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { exportaLEP } from "../../../../utils/queries";
import { generaExcelLEP } from "../utils/excelLep";
import { parse } from "zipson";

export default function ExportarLEP({ filtros, setSnackData, loadingTabla }) {
    const [exportarLEP, { loading }] = useLazyQuery(exportaLEP, {
        fetchPolicy: "network-only",
        onCompleted: (data) => generaExcelLEP(parse(data.exportaLEP), setSnackData, filtros?.eliminado),
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al consultar al servidor, intente nuevamente",
            severidad: "error",
        })
    });

    return (
        <BotonCarga
            label={"Exportar"}
            testid={"ExportarLEP"}
            download
            variant='secondary'
            loading={loading}
            disabled={loadingTabla}
            toDo={() => exportarLEP({
                variables: {
                    problemaSalud: filtros.problemaSalud || null,
                    nombreProblemaSalud: filtros.nombreProblemaSalud || null,
                    tipoPrestacion: filtros.tipoPrestacion || null,
                    prestacion: filtros.prestacion || null,
                    descripcion: filtros.descripcion || null,
                    AH: filtros.AH || null,
                    decreto: filtros.decreto || null,
                    estado: filtros.estado || null,
                    order: filtros.order,
                    dir: filtros.dir,
                    eliminado: filtros.eliminado
                }
            })}
        />
    );
}
