function findPerm(list, rol){
    if(list.indexOf(rol) !== -1){
        return true;
    } else {
        return false;
    }
}

export function hasGEP(list){
    return findPerm(list, "ROL_GEP");
}

export function hasAGE(list){
    return findPerm(list, "ROL_AGENCIA");
}

export function hasJGESCAEC(list){
    return findPerm(list, "ROL_JGESCAEC");
}

export function isMantenedor(list){
    return findPerm(list, "ROL_ADMIN_MANTENEDORES");
}
export function isContralor(list){
    return findPerm(list, "ROL_CONTRALOR");
}