import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
    cargararchivo2,
    prestadoresAsignados,
    getsolicitudxfk,
    getDescripcion,
    guardaform,
    generarFormulario2
} from "../../utils/queries";
import { Grid } from "@mui/material";
import { format } from 'date-fns';
import { IconOutlined } from "../Buttons/Botones";
import { IconView } from "../icons/iconosEsencial";
import { PreviewFile } from "../Dialog/PreviewFile";
import base64toBlob from "../../utils/Base64";
import { procesaPrestadores } from "./utils/procesaPrestadores";
import { parseFecha, fecha } from "../../utils/fechaUtils";
import { formatRut } from "chilean-rutify";
import { useMsal } from "@azure/msal-react";

export const GenerarF2Bandeja = ({ fkFun, upd }) => {
    const [modalGF2, setModalGF2] = useState(false);
    const [urlSPO, setF2Bandeja] = useState(null);

    const [obtenDataCaso, { data, loading: loadingDB }] = useLazyQuery(getsolicitudxfk, {
        variables: { fkFun: fkFun },
        fetchPolicy: "network-only",
        onCompleted: () => {
            getPrestadores({
                variables: {
                    folio: fkFun,
                    order: "idDespliegue",
                    dir: "ASC"
                }
            });
        }
    });

    const [getPrestadores, { loading: loadingPres }] = useLazyQuery(prestadoresAsignados, {
        fetchPolicy: "network-only",
        onCompleted: (result) => {
            setDataPrestEsta(result.getPrestadoresAsignados.prestadores);
        }
    });

    const [getCieCaec, { loading: loadingCie }] = useLazyQuery(getDescripcion,
        {
            fetchPolicy: "network-only"
        });

    const [generaForm2, { loading: loadingForm }] = useLazyQuery(generarFormulario2, {
        onCompleted: (result) => {
            const file = base64toBlob(result.generarFormulario2.base64, null)
            setF2Bandeja(URL.createObjectURL(file));
            subirForm2(file);
        }
    });

    const [subirDoc, { loading: loadF1G }] = useMutation(cargararchivo2);

    const [guardarFormBD, { loading: loadF1DB }] = useMutation(guardaform);

    const subirForm2 = (file) => {
        const fileRead = new FileReader();
        fileRead.readAsDataURL(file);
        fileRead.onload = ({ target }) => {
            subirDoc({
                variables: {
                    idProceso: "11",
                    idTipoTrans: "2002",
                    idTipoDoc: "2006",
                    folioTrans: fkFun.toString(),
                    fechaTrans: format(new Date(), "MM-dd-yyyy"),
                    rutBenef: data.getSolicitudesGCbyFkfun[0].rutPaciente,
                    folioDoc: fkFun.toString(),
                    nombreDoc: "Formulario2_SF_" + data.getSolicitudesGCbyFkfun[0].rutPaciente + ".pdf",
                    base64: target.result.substr(`data:${file.type};base64,`.length)
                },
                onCompleted: (gestor) => {
                    guardarFormBD({
                        variables: {
                            link: gestor.crearArchivo2.documentId,
                            tipoDoc: "form2",
                            folio: fkFun,
                        }
                    })
                }
            })
        }
    }

    const setDataPrestEsta = (resPrestadores) => {
        const listaPrestadores = procesaPrestadores(resPrestadores, data.getSolicitudesGCbyFkfun[0].fkProblemaSalud);
        if (data.getSolicitudesGCbyFkfun[0].tipoCaso === "GES") {
            data.getSolicitudesGCbyFkfun[0].nombrepat.cie10.forEach((glosa) => {
                if (glosa.codigo === data.getSolicitudesGCbyFkfun[0].fkCie10) {
                    generarForm2(listaPrestadores, data.getSolicitudesGCbyFkfun[0].fkCie10 + " - " + glosa.Descripcion);
                }
            })
        }
        if (data.getSolicitudesGCbyFkfun[0].tipoCaso === "CAEC") {
            getCieCaec({
                variables: {
                    codigo: data.getSolicitudesGCbyFkfun[0].fkCie10
                },
                onCompleted: (des) => {
                    generarForm2(listaPrestadores, data.getSolicitudesGCbyFkfun[0].fkCie10 + " - " + des.getCie10byCodigo.Descripcion);
                }
            })
        }
    }

    function generarForm2(listaPrestadores, cie) {
        const fechaVB = parseFecha(data.getSolicitudesGCbyFkfun[0].vistoBueno);
        const rutRepresentante = data.getSolicitudesGCbyFkfun[0].rutRepresentante.split('-');
        generaForm2({
            variables: {
                p1Establecimiento: listaPrestadores[0].nombrePrestador,
                p1Direccion: listaPrestadores[0].direccionPrestador,
                p1Habitacion: " - ",
                p1Medico: " - ",
                p2Establecimiento: listaPrestadores[1]?.nombrePrestador || " - ",
                p2Direccion: listaPrestadores[1]?.direccionPrestador || " - ",
                p2Habitacion: " - ",
                p2Medico: " - ",
                nombreBeneficiario: data.getSolicitudesGCbyFkfun[0].nombreCotizante,
                rutBeneficiario: formatRut(data.getSolicitudesGCbyFkfun[0].rutCotizante),
                nombrePaciente: data.getSolicitudesGCbyFkfun[0].nombrePaciente + " " + data.getSolicitudesGCbyFkfun[0].apellido1Paciente + " " + data.getSolicitudesGCbyFkfun[0].apellido2Paciente,
                rutPaciente: formatRut(data.getSolicitudesGCbyFkfun[0].rutPaciente),
                coberturaGES: data.getSolicitudesGCbyFkfun[0].tipoCaso === "GES" ? "X" : "",
                coberturaCAEC: data.getSolicitudesGCbyFkfun[0].tipoCaso !== "GES" ? "X" : "",
                cie10: cie,
                diagnostico: data.getSolicitudesGCbyFkfun[0].tipoCaso === "GES" ? data.getSolicitudesGCbyFkfun[0].fkProblemaSalud + " - " + data.getSolicitudesGCbyFkfun[0].nombrepat.GlosaSis : data.getSolicitudesGCbyFkfun[0].diagnosticoCaec,
                tratamiento: data.getSolicitudesGCbyFkfun[0].tratamiento,
                fechaSolicitud: fecha(data.getSolicitudesGCbyFkfun[0].fechaRecepcionSolicitud),
                vb: format(fechaVB, "dd/MM/yyyy"),
                fechaIniBenef: fecha(data.getSolicitudesGCbyFkfun[0].fechaAutorizacionSoliciut),
                montoDeducible: data.getSolicitudesGCbyFkfun[0].deducible ? data.getSolicitudesGCbyFkfun[0].deducible.toString() : "-",
                fecha: format(fechaVB, "dd/MM/yyyy"),
                hora: format(fechaVB, "HH:mm:ss"),
                informe: data.getSolicitudesGCbyFkfun[0].nombreRepresentante + " " + data.getSolicitudesGCbyFkfun[0].apellido1Representante + " " + data.getSolicitudesGCbyFkfun[0].apellido2Representante,
                rut: rutRepresentante[0].toString(),
                dv: rutRepresentante[1].toString(),
            }
        });
    }

    const generarF2 = () => {
        obtenDataCaso();
        setModalGF2(true);
    };

    const cerrarModal = () => {
        setModalGF2(false);
        upd();
    };
    return (
        <Grid>
            <IconOutlined
                onClick={generarF2}
                data-testid="prevFileForm"
            >
                <IconView />
            </IconOutlined>
            <PreviewFile
                openDialog={modalGF2}
                handleClose={cerrarModal}
                urlSPO={urlSPO}
                loading={loadingDB || loadingPres || loadingCie || loadingForm}
                uploadG={loadF1G}
                uploadDB={loadF1DB}
            />
        </Grid>
    )
}