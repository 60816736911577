import { Fragment, useState } from "react";
import {
    Autocomplete,
    Box,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { TabPanel } from "../../pages/CasoGesCaec/Header/components/tabs";
import { HospitalAlt } from "../icons/iconosEsencial";
import ThemeTextField from "../Textfield/ThemeTextField";
import { SelectPersonalizado } from "../select/SelectPersonalizado";
import {
    formatRut,
    validRut
} from "chilean-rutify";
import {
    getAllPrest
} from "../../utils/queries";
import { manejaDirecciones } from "../../utils/Utils";
import Aviso from "../Alert/Aviso";

const BuscaPrestador = ({
    razonSocial,
    rutInsti,
    luat,
    direccion,
    error,
    asignaPrestador,
    casoInvalido,
    isModal,
    disabled,
    resetInputPres,
    setResetInputPres,
    isEditaPres
}) => {
    const [tabValue, setTabValue] = useState(0);
    const [direcciones, setDirecciones] = useState([]);
    const [prestadores, setPrestadores] = useState([]);
    const [inputVal, setInputVal] = useState(razonSocial || "");
    const [aviso, setAviso] = useState(false);
    const [isManual, setIsManual] = useState(false);
    const [dataModif, setDataModif] = useState(false);
    const { data, loading } = useQuery(getAllPrest, {
        fetchPolicy: "cache-first",
        onCompleted: (res) => {
            filtraValores(res.getAllPrestadores);
        },
        onError: (err) => {
            //console.log(err.message)
            setAviso(true);
            setIsManual(true);
        }
    });

    if (resetInputPres && dataModif) {
        setInputVal("");
        setTabValue(0);
        setIsManual(false);
        setAviso(false);
        setResetInputPres(false);
        setDataModif(false);
    }

    function filtraValores(dataPrestadores) {
        var temp = [];
        dataPrestadores.forEach((pres) => {
            if (!temp.find((savedPres) => savedPres.razonSocial === pres.razonSocial)) {
                temp.push(pres);
            }
        })
        temp.sort((a, b) => {
            const nameA = a.razonSocial.toUpperCase();
            const nameB = b.razonSocial.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setPrestadores(temp);
        verificaPreselect(dataPrestadores);
    }

    function verificaPreselect(dataPrestadores) {
        if (rutInsti) {
            const tempDirecciones = manejaDirecciones(dataPrestadores.filter((pres) => pres.rut === rutInsti));
            if (tempDirecciones.length > 0) {
                setDirecciones(tempDirecciones);
            } else {
                setIsManual(true);
            }
        }
    }

    const handleChange = (event, newValue) => {
        setDataModif(true);
        asignaPrestador(null, null, null, null);
        setDirecciones([]);
        setIsManual(false);
        setInputVal("");
        setAviso(false);
        setTabValue(newValue);
    };

    function checkPrestadores(nombrePres) {
        setDataModif(true);
        setInputVal(nombrePres)
        if (nombrePres) {
            if (data) {
                if (!data.getAllPrestadores.find((pres) => pres.razonSocial.toUpperCase().includes(nombrePres.toUpperCase()))) {
                    setIsManual(true);
                    setAviso(true);
                    asignaPrestador(null, null, nombrePres, null);
                } else {
                    setIsManual(false);
                    setAviso(false);
                }
            } else {
                setIsManual(true);
                setAviso(true);
                asignaPrestador(null, null, nombrePres, null);
            }
        } else {
            setIsManual(false);
            setAviso(false);
        }
    }

    function establecePrestador(option) {
        setDataModif(true);
        if (option) {
            const tempDirecciones = manejaDirecciones(data.getAllPrestadores.filter((pres) => pres.razonSocial === option.razonSocial));
            setDirecciones(tempDirecciones);
            setIsManual(false);
            setAviso(false);
            setInputVal(option.razonSocial);

            if (tempDirecciones.length === 1) {
                asignaPrestador(tempDirecciones[0].label, tempDirecciones[0].value, option.razonSocial, formatRut(option.rut));
            } else {
                asignaPrestador(null, null, option.razonSocial, formatRut(option.rut));
            }
        } else {
            asignaPrestador(null, null, null, null);
            setDirecciones([]);
        }
    }

    function procesaRut(e) {
        let rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
        rut = rut.length > 2 ? formatRut(rut).toUpperCase() : rut;
        asignaPrestador(null, null, null, rut);
        setIsManual(false);
        setAviso(false);
        setDirecciones([]);
        if (validRut(rut)) {
            buscarPrestador(rut);
        }
    }
    function buscarPrestador(rut) {
        setDataModif(true);
        const tempDirecciones = manejaDirecciones(data.getAllPrestadores.filter((pres) => pres.rut === rut.replace(/[^0-9kK-]/g, "")));
        if (tempDirecciones.length > 0) {
            setDirecciones(tempDirecciones);
            setIsManual(false);
            setAviso(false);

            if (tempDirecciones.length === 1) {
                asignaPrestador(tempDirecciones[0].label, tempDirecciones[0].value, tempDirecciones[0].razonSocial, rut);
            } else {
                asignaPrestador(null, null, tempDirecciones[0].razonSocial, rut);
            }
        } else {
            setDirecciones([]);
            setIsManual(true);
            setAviso(true);
        }
    }

    function estableceDireccion(value) {
        const prest = direcciones.find((savedPres) => savedPres.value === value);
        asignaPrestador(prest.label, prest.value, razonSocial, rutInsti);
    }

    return (
        <Fragment>
            {isModal && !isEditaPres ?
                <>
                    <Grid item xs={12} >
                        <Typography className="AltTitulo1">Datos del prestador</Typography>
                        <Typography className="AltTitulo2">
                            Busca la institución por RUT o por Razón social.
                        </Typography>

                    </Grid>
                    <Stack direction="row" spacing={1} sx={{ paddingLeft: "16px" }}>
                        <Chip
                            label="Razón social"
                            color="secondary"
                            className={tabValue === 0 ? "chipSelected" : "chipNotSelect"}
                            onClick={() => handleChange(null, 0)}
                        />
                        <Chip
                            label="RUT"
                            color="secondary"
                            className={tabValue === 1 ? "chipSelected" : "chipNotSelect"}
                            disabled={loading}
                            onClick={() => handleChange(null, 1)}
                        />
                    </Stack>
                </> : null}
            {isModal ?
                null
                :
                <Grid item xs={12}>
                    <Box
                        className="nav"
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "start",
                            padding: "0 0 0 0",
                        }}
                    >
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            TabIndicatorProps={{ style: { background: '#00837A' } }}
                        >
                            <Tab label="Razón social" />
                            <Tab label="RUT" disabled={loading} data-testid="rutTabT" />
                        </Tabs>
                    </Box>
                </Grid>}

            <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                    <Grid
                        container
                        spacing={isModal ? undefined : 2}
                    >
                        {isModal ? null :
                            <Grid item xs={12}>
                                <label className="textLabel4"><HospitalAlt /> Datos institución</label>
                            </Grid>}
                        <Grid item xs={isModal ? 12 : 4}>
                            <label className="labelForm">Razón social</label>
                            <FormControl sx={{ width: "100%", margin: "0 1% 0 0" }}>
                                <Autocomplete
                                    id="autocompleteBuscaPres"
                                    data-testid="autocompleteBuscaPres"
                                    disabled={disabled || loading || isEditaPres}
                                    sx={{ width: 300 }}
                                    options={prestadores}
                                    loading={loading}
                                    loadingText={"Cargando..."}
                                    noOptionsText={"Sin coincidencias"}
                                    autoHighlight
                                    clearText={"Limpiar"}
                                    clearOnBlur={false}
                                    value={prestadores.find((savedPres) => savedPres.razonSocial === razonSocial) || null}
                                    onChange={(event, option) => {
                                        establecePrestador(option);
                                    }}
                                    inputValue={inputVal}
                                    onInputChange={(event, valor) => checkPrestadores(valor)}
                                    getOptionLabel={(option) => option.razonSocial}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                            key={option.internalRolId}
                                            value={option.razonSocial}
                                        >
                                            {option.razonSocial}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Ej: Nombre empresa S.A."
                                            data-testid="inputRZAutoCom"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <Fragment>
                                                        {loading ? <CircularProgress data-testid="loadPrest" color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={isModal ? 6 : 4}>
                            <label className="labelForm">RUT institución</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={rutInsti ? !validRut(rutInsti) : undefined}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="rutInstitucion"
                                    name="rut"
                                    id="rutInsti"
                                    placeholder="Ej: 11.111.111-1"
                                    disabled={disabled || !isManual || isEditaPres}
                                    inputProps={{ maxLength: 12 }}
                                    error={rutInsti ? !validRut(rutInsti) : undefined}
                                    onChange={(e) => asignaPrestador(direccion, null, razonSocial, e.target.value && e.target.value.length > 2 ? formatRut(e.target.value.trim()).toUpperCase() : e.target.value)}
                                    value={rutInsti || ""}
                                />
                                <FormHelperText>{rutInsti && !validRut(rutInsti) ? "Rut inválido" : ""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={isModal ? 6 : 4}>
                            <label className="labelForm">Dirección</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                {isManual ?
                                    <ThemeTextField
                                        fullWidth
                                        data-testid="direccion"
                                        id="direccion"
                                        onChange={(e) => asignaPrestador(e.target.value, null, razonSocial, rutInsti)}
                                        placeholder="Ej: Av Las Garmendias"
                                        disabled={disabled || !isManual || luat === "888"}
                                        value={direccion || ""}
                                    />
                                    : <SelectPersonalizado
                                        inputTest="DireccionTest"
                                        id="direccionPrest"
                                        disabled={disabled || casoInvalido || !razonSocial || direcciones.length === 0}
                                        value={luat || ""}
                                        phvalue={luat === "888" ? "888" : ""}
                                        setValue={estableceDireccion}
                                        options={direcciones}
                                        placeholder={luat === "888" ? direccion : "Seleccione"}
                                    />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: aviso ? "5px" : "0px" }}>
                        <Aviso
                            open={aviso}
                            setOpen={setAviso}
                            severidad={"info"}
                            titulo={"No tenemos datos asociados a la Razón Social que ingresaste"}
                            subtitulo={"Prueba buscando por RUT o completa manualmente los datos de la institución."}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid
                        container
                        spacing={2}
                    >
                        {isModal ? null :
                            <Grid item xs={12}>
                                <label className="textLabel4"><HospitalAlt /> Datos institución</label>
                            </Grid>}
                        <Grid item xs={isModal ? 6 : 4}>
                            <label className="labelForm">RUT institución</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }} error={rutInsti ? !validRut(rutInsti) : undefined}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="rutInstitucion"
                                    error={rutInsti ? !validRut(rutInsti) : undefined}
                                    name="rut"
                                    id="rutInsti"
                                    placeholder="Ej: 11.111.111-1"
                                    disabled={disabled || casoInvalido}
                                    onChange={procesaRut}
                                    value={rutInsti || ""}
                                    inputProps={{ maxLength: 12 }}
                                />
                                <FormHelperText>{rutInsti && !validRut(rutInsti) ? "Rut inválido" : ""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={isModal ? 6 : 4}>
                            <label className="labelForm">Dirección</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                {isManual ?
                                    <ThemeTextField
                                        fullWidth
                                        data-testid="direccion"
                                        id="direccion"
                                        onChange={(e) => asignaPrestador(e.target.value, null, razonSocial, rutInsti)}
                                        placeholder="Ej: Av Las Garmendias"
                                        disabled={disabled || !isManual || luat === "888"}
                                        value={direccion || ""}
                                    />
                                    : <SelectPersonalizado
                                        inputTest="DireccionTest"
                                        id="direccionPrest"
                                        disabled={disabled || casoInvalido || !validRut(rutInsti || "") || direcciones.length === 0}
                                        value={luat || ""}
                                        phvalue={luat === "888" ? "888" : ""}
                                        setValue={estableceDireccion}
                                        options={direcciones}
                                        placeholder={luat === "888" ? direccion : "Seleccione"}
                                    />
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={isModal ? 12 : 4}>
                            <label className="labelForm">Razón social</label>
                            <FormControl sx={{ width: "99%!important", margin: "0 1% 0 0" }}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="razonSocial"
                                    id="razonSocial"
                                    onChange={(e) => asignaPrestador(direccion, luat, e.target.value, rutInsti)}
                                    placeholder="Ej: Nombre empresa S.A"
                                    disabled={disabled || !isManual}
                                    value={razonSocial || ""}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Aviso
                        open={aviso}
                        setOpen={setAviso}
                        severidad={"info"}
                        titulo={"No tenemos datos asociados al RUT que ingresaste"}
                        subtitulo={"Prueba buscando por Razón social o completa manualmente los datos de la institución."}
                    />
                </TabPanel>
                <span className="errorSpan">{error.rutInsti || ""}</span>
            </Grid>
        </Fragment>
    );
}

export default BuscaPrestador;