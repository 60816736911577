import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import { SecondaryButton } from "../../../../../components/Buttons/Botones";
import CardPrestadores from "./CardPrestadores";
import { NoResult } from "../../../../../components/NoData/NoResults";
import { fecha, fechaHora } from "../../../../../utils/fechaUtils";

const ModalPrestadores = ({ canasta, onOpen, setOpen, setMensajeroData, disabled }) => {
    const [hidePres, setHidePres] = useState(false);

    const cerrarModal = () => setOpen(false);

    return (
        <Dialog
            fullWidth
            className="dialogModalBig3"
            hidden={hidePres}
            open={onOpen}
            onClose={cerrarModal}
            scroll={'paper'}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <Grid
                className="dialogModalAlternative"
                container
                textAlign="center"
                sx={{
                    "& .MuiTextField-root": { m: 1 },
                }}
            >
                <Grid item xs={12} mb={"16px"}>
                    <DialogTitle>Prestadores de Canasta {canasta.canasta}</DialogTitle>
                    <label>
                        Estos son los prestadores que estan establecidos en esta canasta.
                    </label>
                </Grid>
            </Grid>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    direction={canasta&&canasta.prestadoresAsignados.length > 0 ? "none" : "column"}
                    textAlign="left"
                    spacing={"16px"}
                >
                    {canasta&&canasta.prestadoresAsignados.length > 0 ? 
                        canasta.prestadoresAsignados.map((prestador) =>
                        <Grid item xs={4}>
                            <CardPrestadores
                                type={!prestador.vigencia}
                                estado={prestador.tipoPrestador === "DR" ? "red" : "fuera"}
                                rut={prestador.prestador}
                                nombre={prestador.nombrePrestador}
                                direccion={prestador.direccionPrestador}
                                luat={prestador.luat}
                                fechaInicio={fecha(prestador.fechaInicio)}
                                fechaFin={prestador.fechaTermino ? fecha(prestador.fechaTermino) : ""}
                                transitorio={prestador.transitorio ? "sí" : "no"}
                                usuarioCreacion={prestador.createdby}
                                fechaCreacion={fechaHora(prestador.createdAt)}
                                id={prestador.id}
                                setHidePres={setHidePres}
                                setMensajeroData={setMensajeroData}
                                disabled={disabled}
                            />
                        </Grid>
                    ): <NoResult titulo={"Sin Prestadores"} subtitulo={"Aún no se han asignado Prestadores."} noDivider={true}/>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="cerrarModalPrest"
                        onClick={cerrarModal}
                    >
                        Cerrar
                    </SecondaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ModalPrestadores;
