import { useState } from "react";
import "../../assets/Repositorio.css";
import "../../assets/Main.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { SolicitudReporte } from "./components/SolicitudReporte";
import { TablaReportes } from "./components/TablaReportes";
import { TablaGOP } from "./components/TablaGOP";
import { Link } from "react-router-dom";

export const reportes = [
  { label: "(036) Solicitudes de Acceso a las GES en Isapres", value: "036" },
  {
    label: "(037) DETALLE DE CASOS GES PARA GARANTÍA DE PROTECCIÓN FINANCIERA",
    value: "037",
  },
  {
    label: "(038) DETALLE DE CASOS GES PARA GARANTÍA DE OPORTUNIDAD",
    value: "038",
  },
  { label: "(075) PRESTADORES GES EN CONVENIO", value: "075" },
  {
    label:
      "(105) GARANTIAS DE OPORTUNIDAD SIN PRESTACION OTORGADA (GARANTÍAS DE OPORTUNIDAD SIN HITO)",
    value: "105",
  },
];

export const meses = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

export function Reporteria() {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Typography>{value === index && children}</Typography>
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
      <div data-testid="reporteria">
        <div className="rowInformacion">
          <div className="container Main">
            <label className="titulo1"> Reportería</label>
            <Breadcrumbs
              sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
              aria-label="breadcrumb"
              className="breadcrum"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                underline="always"
                color="inherit"
                to="/"
                sx={{
                  color: "#364855",
                  fontSize: "14px",
                  fontWeight: "400",
                  margin: "0px,8px",
                  cursor: "pointer",
                }}
              >
                Inicio
              </Link>
              <Typography
                sx={{
                  color: "#364855",
                  fontSize: "14px",
                  fontWeight: "700",
                  marginBottom: "-1px",
                  lineHeight: "24px",
                }}
              >
                Reportería
              </Typography>
            </Breadcrumbs>
            <Box
              className="nav"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                padding: "26px 0 0 0",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{ style: { background: "#00837A" } }}
              >
                <Tab label="Superintendencia de salud" {...a11yProps(0)} />
                <Tab label="GOP" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <SolicitudReporte />
          <TablaReportes />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TablaGOP />
        </TabPanel>
      </div>
  );
}

export default Reporteria;
