import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from "@mui/material";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";

export default function DatosRepresentante({
    campos,
    setCampos,
    casoInvalido,
    error,
    setError,
    deshabilita,
    setDesahilita,
    detectaCambio,
    otrosIntegrantes,
    beneficiarios,
    detectaCambiosNombre
}) {
    function detectaCambioComboSolicita(valor) {
        /* swich para cambio de opcion en direccion   */
        switch (valor) {
            case "1":
                setDesahilita({
                    ...deshabilita,
                    beneficiarios: true,
                    beneficiarioSel: true
                });
                setCampos({
                    ...campos,
                    solicita: valor,
                    rutRepresentante: campos.rutPaciente,
                    nombreRepresentante: campos.nombrePaciente,
                    apellido1Representante: campos.apellido1Paciente,
                    apellido2Representante: campos.apellido2Paciente,
                    emailRepresentante: campos.emailPaciente,
                    telefonoRepresentante: campos.telPaciente,
                    celularRepresentante: campos.celPaciente,
                    tipoDocumento: "rut",
                    beneficiario: "",
                    relacion: null
                });
                setError({
                    ...error,
                    solicita: null,
                    rutRepresentante: null,
                    nombreRepresentante: null,
                    apellido1Representante: null,
                    apellido2Representante: null,
                    tipoDocumento: null,
                });
                break;
            case "2":
                setDesahilita({
                    ...deshabilita,
                    beneficiarios: false,
                    beneficiarioSel: true
                })
                setCampos({
                    ...campos,
                    solicita: valor,
                    rutRepresentante: null,
                    nombreRepresentante: null,
                    apellido1Representante: null,
                    apellido2Representante: null,
                    emailRepresentante: null,
                    telefonoRepresentante: null,
                    celularRepresentante: null,
                    tipoDocumento: null,
                    beneficiario: "",
                    relacion: null
                });
                setError({
                    ...error,
                    solicita: null,
                    rutRepresentante: null,
                    nombreRepresentante: null,
                    apellido1Representante: null,
                    apellido2Representante: null,
                    tipoDocumento: null,
                });
                break;

            case "3":
                setDesahilita({
                    ...deshabilita,
                    beneficiarios: true,
                    beneficiarioSel: false
                })
                setCampos({
                    ...campos,
                    solicita: valor,
                    rutRepresentante: null,
                    nombreRepresentante: null,
                    apellido1Representante: null,
                    apellido2Representante: null,
                    emailRepresentante: null,
                    telefonoRepresentante: null,
                    celularRepresentante: null,
                    tipoDocumento: "rut",
                    relacion: null
                });
                setError({
                    ...error,
                    solicita: null,
                    rutRepresentante: null,
                    nombreRepresentante: null,
                    apellido1Representante: null,
                    apellido2Representante: null,
                    tipoDocumento: null,
                });
                break;
        }
    }

    function asignarepplan(valor) {
        let rep = beneficiarios.find(
            (temp) => temp.value === (valor)
        );

        setCampos({
            ...campos,
            beneficiario: valor,
            rutRepresentante: rep.rut,
            nombreRepresentante: rep.nombre,
            apellido1Representante: rep.ap1,
            apellido2Representante: rep.ap2,
            relacion: rep.relacion,
        });
    }

    function detectaCambioRadio(e) {
        let value = e.target.value;

        if (value === "rut") {
            setCampos({
                ...campos,
                tipoDocumento: "rut",
                pasaporteRepresentante: null
            })
            setError({
                ...error,
                tipoDocumento: null,
                rutRepresentante: null,
                pasaporteRepresentante: null
            })
        }
        if (value === "pasaporte") {
            setCampos({
                ...campos,
                tipoDocumento: "pasaporte",
                rutRepresentante: null,
            })
            setError({
                ...error,
                tipoDocumento: null,
                rutRepresentante: null,
                pasaporteRepresentante: null
            })
        }
    }

    const quienSolicita = [
        { label: "Beneficiario", value: "1", disabled: false },
        { label: "Tercero", value: "2", disabled: false },
        { label: "Otro integrante del plan", value: "3", disabled: otrosIntegrantes ? false : true }
    ];

    return (
        <div className="card-box">
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <label className="title">Datos del representante</label>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Quién solicita</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <SelectPersonalizado
                            inputTest="representanteFormSelect"
                            id="solicita"
                            error={error.solicita}
                            disabled={casoInvalido || campos.fkFun}
                            value={campos.solicita || ""}
                            setValue={detectaCambioComboSolicita}
                            options={quienSolicita}
                            placeholder={"Seleccione"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Beneficiario</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <SelectPersonalizado
                            inputTest="beneficiarioTest"
                            id="beneficiario"
                            error={error.beneficiario}
                            value={campos.beneficiario || ""}
                            disabled={casoInvalido || parseInt(campos.solicita) !== 3 || campos.fkFun}
                            setValue={asignarepplan}
                            options={beneficiarios}
                            placeholder={"Seleccione"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                    <label className="labelForm">Selecciona el tipo de documento</label>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        row
                        id="radioDoc"
                        data-testid="radioDocTest"
                        disabled={casoInvalido}
                        error={error.tipoDocumento ? true : undefined}
                        value={campos.tipoDocumento || ""}
                        onChange={detectaCambioRadio}
                    >
                        <FormControlLabel
                            value={"rut"}
                            control={<Radio id="tipoDocumento" disabled={casoInvalido || deshabilita.beneficiarios} data-testid="representanteFormCheckboxRut" size="small" />}
                            label={"Rut"}
                        />
                        <FormControlLabel
                            value={"pasaporte"}
                            control={<Radio id="tipoDocumento" disabled={casoInvalido || deshabilita.beneficiarios} data-testid="representanteFormCheckboxPasaporte" size="small" />}
                            label={"Pasaporte"}
                        />
                    </RadioGroup>
                    <span className="errorSpan">{error.tipoDocumento}</span>{" "}
                </Grid>
                <Grid item xs={4}>
                    <label
                        className="labelForm"
                        style={{ display: campos.tipoDocumento ? "block" : "none" }}
                    >
                        {campos.tipoDocumento ? campos.tipoDocumento === "rut" ? "Rut" : "Pasaporte" : ""}
                    </label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputRut"
                            sx={{ display: campos.tipoDocumento === "rut" ? "block" : "none" }}
                            error={error.rutRepresentante ? true : undefined}
                            name="rut"
                            id="rutRepresentante"
                            placeholder="Ej: 11.111.111-1"
                            disabled={casoInvalido || deshabilita.beneficiarios}
                            inputProps={{ maxLength: 12 }}
                            onChange={detectaCambio}
                            value={campos.rutRepresentante || ""}
                        />
                        <span className="errorSpan">{error.rutRepresentante}</span>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputPasaporte"
                            id="pasaporteRepresentante"
                            placeholder="Pasaporte"
                            inputProps={{ maxLength: 10 }}
                            error={error.pasaporteRepresentante ? true : undefined}
                            disabled={casoInvalido || deshabilita.beneficiarios}
                            sx={{ display: campos.tipoDocumento === "pasaporte" ? "block" : "none" }}
                            onChange={detectaCambio}
                            value={campos.pasaporteRepresentante || ""}
                        />
                        <span className="errorSpan">{error.pasaporteRepresentante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Nombres</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputNombre"
                            id="nombreRepresentante"
                            placeholder="Ej:Javiera Belén"
                            error={error.nombreRepresentante ? true : undefined}
                            disabled={casoInvalido || deshabilita.beneficiarios}
                            onChange={detectaCambiosNombre}
                            value={campos.nombreRepresentante || ""}
                        />
                        <span className="errorSpan">{error.nombreRepresentante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Primer apellido</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputApellidoPaterno"
                            id="apellido1Representante"
                            placeholder="Ej: Gonzales"
                            error={error.apellido1Representante ? true : undefined}
                            disabled={casoInvalido || deshabilita.beneficiarios}
                            onChange={detectaCambiosNombre}
                            value={campos.apellido1Representante || ""}
                        />
                        <span className="errorSpan">{error.apellido1Representante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Segundo apellido</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputApellidoPaterno"
                            id="apellido2Representante"
                            placeholder="Ej: Rojas"
                            error={error.apellido2Representante ? true : undefined}
                            disabled={casoInvalido || deshabilita.beneficiarios}
                            onChange={detectaCambiosNombre}
                            value={campos.apellido2Representante || ""}
                        />
                        <span className="errorSpan">{error.apellido2Representante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Correo electrónico</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputEmail"
                            name="correo"
                            id="emailRepresentante"
                            placeholder="Ej: ejemplo@correo.com"
                            disabled={casoInvalido}
                            error={error.emailRepresentante ? true : undefined}
                            onChange={detectaCambio}
                            value={campos.emailRepresentante || ""}
                        />
                        <span className="errorSpan">{error.emailRepresentante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Teléfono particular</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputTelefono"
                            name="telefono"
                            id="telefonoRepresentante"
                            placeholder="Ej: 2 12345678"
                            inputProps={{ maxLength: 9 }}
                            disabled={casoInvalido}
                            error={error.telefonoRepresentante ? true : undefined}
                            onChange={detectaCambio}
                            value={campos.telefonoRepresentante || ""}
                        />
                        <span className="errorSpan">{error.telefonoRepresentante}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <label className="labelForm">Teléfono celular</label>
                    <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                        <ThemeTextField
                            fullWidth
                            data-testid="representanteFormInputCelular"
                            name="telefono"
                            id="celularRepresentante"
                            placeholder="Ej: 9 12345678"
                            inputProps={{ maxLength: 9 }}
                            disabled={casoInvalido}
                            error={error.celularRepresentante ? true : undefined}
                            onChange={detectaCambio}
                            value={campos.celularRepresentante || ""}
                        />
                        <span className="errorSpan">{error.celularRepresentante}</span>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}