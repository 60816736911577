import {
  calculadeducible,
  prestadoresAsignados,
  generarFormulario2,
  verificarcaso,
  getDescripcion,
} from "../../../../utils/queries";
import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { format } from "date-fns";
import Loader from "../../../../components/Skeleton/Loader";
import { fecha } from "../../../../utils/fechaUtils";
import { procesaPrestadores } from "../../../../components/ArchivosGestor/utils/procesaPrestadores";

export function IframeForm2({ info, setForm2, setDeducible }) {
  const [url, setUrl] = useState(null);
  const [data, setData] = useState(null);
  const [listaPrestadores, setListaPrestadores] = useState([]);
  const [cie, setCie] = useState("");

  const [getPrestadores, { loading: getPres}] = useLazyQuery(prestadoresAsignados,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        var tableData = result.getPrestadoresAsignados.prestadores;
        setListaPrestadores(procesaPrestadores(tableData, info.fkProblemaSalud));
        getDescripcionCie();
      },
      onError: (error) => {
        console.log("ERROR PRESTADORES: ", error);
        setForm2("error");
      }
    });
  const [verificaCaso, { loading: verifCaso}] = useLazyQuery(verificarcaso,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        calculaDeducible({
          variables: {
            rut: data.rutPaciente,
            tipoCaso: data.tipoCaso,
            casosPrevios: result.verCasosActivos,
          }
        })
      },
      onError: (error) => {
        console.log("ERROR VERIFICA CASO: ", error)
        setForm2("error");
      }
    });
  const [getCieCaec, { loading: ciecaec}] = useLazyQuery(getDescripcion,
    {
      fetchPolicy: "network-only",
      onCompleted: (des) => {
        setCie(data.fkCie10 + " - " + des.getCie10byCodigo.Descripcion);
        verificaCaso({
          variables: {
            rut: data.rutCotizante
          }
        })
      },
      onError: (error) => {
        console.log("ERROR CIE CAEC: ", error)
        setForm2("error");
      }
    });

  const [calculaDeducible, { loading: deducible}] = useLazyQuery(calculadeducible,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        setDeducible(result.obtenerDeducible.deducible);
        generaPDF(result.obtenerDeducible.deducible);
      },
      onError: (error) => {
        console.log("ERROR DEDUCIBLE: ", error)
        setForm2("error");
      }
    });
  const [generaForm2, { loading: generaForm}] = useLazyQuery(generarFormulario2, {
    onCompleted: (result) => {
      let base64 = atob(result.generarFormulario2.base64);
      let length = base64.length;
      let out = new Uint8Array(length);
      while (length--) {
        out[length] = base64.charCodeAt(length);
      }
      const file = new Blob([out], { type: "application/pdf" });
      let transFUrl = URL.createObjectURL(file)
      setForm2(file);
      setUrl(transFUrl);
    },
    onError: (error) => {
      console.log("error resp form 1 : " + error);
      setForm2("error");
    }
  });

  if (info && !data) {
    setData(info);
    getPrestadores({
      variables: {
        folio: parseInt(info.fkFun),
        order: "idDespliegue",
        dir: "ASC"
      }
    });
  }

  function getDescripcionCie() {
    if (data.tipoCaso === "GES") {
      data.nombrepat.cie10.forEach((glosa) => {
        if (glosa.codigo === data.fkCie10) {
          setCie(data.fkCie10 + " - " + glosa.Descripcion);
          verificaCaso({
            variables: {
              rut: data.rutCotizante
            }
          });
        }
      })
    }
    if (data.tipoCaso === "CAEC") {
      getCieCaec({
        variables: {
          codigo: data.fkCie10
        }
      })
    }
  }

  function generaPDF(deducible) {
    const date = new Date();
    const rutRepresentante = data.rutRepresentante.split('-');
    generaForm2({
      variables: {
        p1Establecimiento: listaPrestadores[0].nombrePrestador,
        p1Direccion: listaPrestadores[0].direccionPrestador,
        p1Habitacion: " - ",
        p1Medico: " - ",
        p2Establecimiento: listaPrestadores[1]?.nombrePrestador || " - ",
        p2Direccion: listaPrestadores[1]?.direccionPrestador || " - ",
        p2Habitacion: " - ",
        p2Medico: " - ",
        nombreBeneficiario: data.nombreCotizante,
        rutBeneficiario: data.rutCotizante,
        nombrePaciente: data.nombrePaciente + " " + data.apellido1Paciente + " " + data.apellido2Paciente,
        rutPaciente: data.rutPaciente,
        coberturaGES: data.tipoCaso === "GES" ? "X" : "",
        coberturaCAEC: data.tipoCaso !== "GES" ? "X" : "",
        cie10: cie,
        diagnostico: data.tipoCaso === "GES" ? data.fkProblemaSalud + " - " + data.nombrepat.GlosaSis : data.diagnosticoCaec,
        tratamiento: data.tratamiento,
        fechaSolicitud: fecha(data.fechaRecepcionSolicitud),
        vb: format(date, "dd/MM/yyyy"),
        fechaIniBenef: fecha(data.fechaAutorizacionSoliciut),
        montoDeducible: deducible === 0 ? "" : deducible.toString(),
        fecha: format(date, "dd/MM/yyyy"),
        hora: format(date, "HH:mm:ss"),
        informe: data.nombreRepresentante + " " + data.apellido1Representante + " " + data.apellido2Representante,
        rut: rutRepresentante[0].toString(),
        dv: rutRepresentante[1].toString(),

      }
    });
  }

  return (
    <div className="row">
      <div id="divIframe" className="col divIframe" data-testid="iframeForm2">
        {generaForm || deducible || ciecaec || verifCaso || getPres ? <div data-testid="noDocTestForm"><Loader /></div> :
          
          !url ? "" :
          <iframe
            id="iframeSolicitud"
            data-testid="docTestForm"
            title="prueba"
            src={url}
            height="100%"
            width="100%"
          />}
      </div>
    </div>
  );
}