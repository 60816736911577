/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./resultadosTablas.css";
import { Divider, Grid } from "@mui/material";
import { IconIdea } from "../../../../components/icons/iconosEsencial";

const ResultadoTablas = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider
          width="1px"
          orientation="horizontal"
          sx={{ width: "100%" }}
        ></Divider>
      </Grid>
      <div className="box-resultados">
        <Grid
          sx={{ margin: "35px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* Aquí va el icono */}
          <IconIdea className="icon" />
          {/* Aquí va el icono */}
        </Grid>
        <Grid
          sx={{ margin: "auto 0" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* Aquí va el títlulo */}
          <label className="TituloResultadoTabla">Aún no buscas un paciente</label>
          {/* Aquí va el títlulo */}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* Aquí va el subtítulo */}
          <label className="subtituloResultadoTabla">Busca un RUT para comenzar.</label>
          {/* Aquí va el subtítulo */}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {/* Aquí va el botón */}
        </Grid>
      </div>
    </>
  );
};

export default ResultadoTablas;
