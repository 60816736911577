import { useState } from "react";
import {
    Autocomplete,
    Box,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { canastasxPatologia } from "../../../../utils/queries";

function CanastaSelect({
    arancel,
    canasta,
    deshabilitaInputs,
    estado,
    intervencion,
    patologia,
    setCanasta,
}) {
    const [canastasArray, setCanastasArray] = useState([]);
    useQuery(canastasxPatologia, {
        variables: { patologia: patologia, intervencion: intervencion, arancel: arancel },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setCanasta(null);
            actualizaListaCanastas(data);
        },
        onError: (error) => {
            console.log(error.message)
        }
    });

    function actualizaListaCanastas(canastas) {
        let arr = [];
        if (canastas.getCanastasbyPat && canastas.getCanastasbyPat.length > 0) {
            canastas.getCanastasbyPat.forEach((can) => {
                arr.push({ codigo: can.codigo, descripcion: can.descripcion })
            })
            setCanastasArray(arr)
        }
    }

    return (
        <Grid item xs={4}>
            <label htmlFor="Canasta" className="labelForm">
                Canasta
            </label>
            <FormControl
                className="input-group"
                sx={{
                    width: "97.5%!important",
                    margin: "0 15px 0 0",
                    flexDirection: "row",
                }}
            >
                <Autocomplete
                    id="autocompleteCanasta"
                    data-testid="autocompleteCanasta"
                    disabled={estado === 5 || estado === 6 || estado === 7 || deshabilitaInputs ? true : false}
                    sx={{ width: 300 }}
                    options={canastasArray}
                    autoHighlight
                    clearText={"Limpiar"}
                    value={canasta}
                    onChange={(event, option) => {
                        setCanasta(option)
                    }}

                    getOptionLabel={(option) => { return (option.codigo + " - " + option.descripcion) }}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                            key={option.codigo}
                            value={option.codigo}
                        >
                            {option.codigo} - {option.descripcion}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Ingresa Canasta o código Canasta"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
            </FormControl>
        </Grid>
    )
}

export default CanastaSelect;