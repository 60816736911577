import { useState } from "react";
import "../../../assets/App.css";
import { useLazyQuery } from "@apollo/client";
import {
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  formatRut,
  validRut
} from "chilean-rutify";
import {
  getbeneficiarioxrut,
  verificarcasoexistente,
  getsolicitudsv,
  leerRegistros
} from "../../../utils/queries";
import validator from "validator";
import {
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Typography,
  Collapse,
} from "@mui/material";
import icon_infoBig from "../../../components/icons/icon_infoBig.svg";
import PrimaryButton, { SecondaryButton } from "../../../components/Buttons/Botones";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { VerificaCaso } from "./components/VerificaCaso"
import SkeletonIngreso from "../../../components/Skeleton/SkeletonIngreso";
import DatosBeneficiario from "./components/DatosBeneficiario";
import DatosRepresentante from "./components/DatosRepresentante";
import DefinicionCaso from "./components/DefinicionCaso";
import DatosInstitucion from "./components/DatosInstitucion";
import DatosHospitalizacion from "./components/DatosHospitalizacion";
import {
  validaSeccionUno,
  validaSeccionDos,
  validaSeccionTres,
  validaSeccionCuatro,
  validaSeccionCinco
} from "./utils/ValidaSecciones";
import { parseFecha } from "../../../utils/fechaUtils";

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function FormularioIngreso({ blockBack }) {
  const [campos, setCampos] = useState({});
  const [error, setError] = useState({});
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [cargarDatos, setCargaDatos] = useState(false);
  const [modalCaso, setModalCaso] = useState(false);
  const [deshabilita, setDesahilita] = useState({
    direccion: true,
    direccionHospi: true,
    beneficiarios: true,
    beneficiarioSel: true,
    hospitalizacion: true,
    razonSocial: true,
    razonSocialHospi: true,
    traslado: true,
    medico: true,
    especialidadOtro: true
  });
  const [dialogAnterior, setDialogAnterior] = useState(false);
  const [otrosIntegrantes, setOtrosIntegrantes] = useState(false);
  const location = useLocation();
  const [fkFun, setFkFun] = useState(sessionStorage.getItem("fkFun"));
  const [casoInvalido, setCasoInvalido] = useState(false);
  const [isKinetycError, setIsKinetycError] = useState(false);
  const [antecedentesSV, setAntecedentesSV] = useState([]);
  const navigate = useNavigate();
  const [verificaCaso] = useLazyQuery(verificarcasoexistente, {
    onCompleted: (data) => {
      if (data.verificarCasoExistente[0] !== 0 && !campos.verificado) {
        setModalCaso(true);
        setCampos({
          ...campos,
          duplicado: data.verificarCasoExistente[0],
          psFolio: campos.fkProblemaSalud,
          preing: data.verificarCasoExistente[1] === 8 ? true : false,
          verificado: true
        })
      }
    }
  });
  const [getDataSV, { data: dataSV, loading }] = useLazyQuery(getsolicitudsv, {
    fetchPolicy: "network-only",
    onCompleted: (dataCaso) => {
      const datos = dataCaso.getSolicitudesGCbyFkfun[0];
      var region = null;
      var comuna = null;
      var dataInsti = {};
      if (datos.codLocalidadPaciente) {
        region = datos.codLocalidadPaciente.substr(0, 2);
        comuna = datos.codLocalidadPaciente;
      }
      if (datos.tipoCaso === "CAEC") {
        dataInsti = {
          rutInsti: "",
          direccion: "",
          luat: "",
          razonSocial: "",
          rutMedico: "",
          medico: [],
          nombreMedico: "",
          primerApellidoMedico: "",
          segundoApellidoMedico: "",
          especialidad: "",
          tratamientoActual: ""
        };
      }

      const parseDatos = ({
        ...datos,
        ...dataInsti,
        region: region,
        comuna: comuna,
        celPaciente: (datos.celPaciente && datos.celPaciente.toString().replace(/[^0-9]/g, "")) || null,
        telPaciente: (datos.telPaciente && datos.telPaciente.toString().replace(/[^0-9]/g, "")) || null,
        solicita: datos.rutRepresentante !== datos.rutPaciente ? 3 : 1,
        rutRepresentante: (datos.rutRepresentante && formatRut(datos.rutRepresentante)) || formatRut(datos.rutPaciente),
        tipoDocumento: "rut",
        nombreRepresentante: datos.nombreRepresentante || datos.nombrePaciente,
        apellido1Representante: datos.apellido1Representante || datos.apellido1Paciente,
        apellido2Representante: datos.apellido2Representante || datos.apellido2Paciente,
        emailRepresentante: datos.emailRepresentante || datos.emailPaciente,
        telefonoRepresentante: (datos.telRepresentante && datos.telRepresentante.toString().replace(/[^0-9]/g, "")) || (datos.telPaciente && datos.telPaciente.toString().replace(/[^0-9]/g, "")) || null,
        celularRepresentante: (datos.celRepresentante && datos.celRepresentante.toString().replace(/[^0-9]/g, "")) || (datos.celPaciente && datos.celPaciente.toString().replace(/[^0-9]/g, "")) || null,
        chkHospi: datos.declaraHospitalizacion === true ? "si" : "no",
        chkTraslado: datos.declaraTraslado === true ? "si" : "no",
        chkUrgencia: datos.declaraUrgenciaVital === true ? "si" : "no",
        fechaDesde: datos.fechaHospitalizacionDesde ? parseFecha(datos.fechaHospitalizacionDesde) : null,
        fechaHasta: datos.fechaHospitalizacionHasta ? parseFecha(datos.fechaHospitalizacionHasta) : null,
        rutInstiHosp: (datos.rutInstitucionHopsitalizacion && formatRut(datos.rutInstitucionHopsitalizacion)) || "",
        razonSocialHospi: datos.razonSocialInstitucionHopsitalizacion,
        direccionHospi: datos.direccionHospitalizacion,
        luatHospi: datos.luatHospitalizacion,
        trasDesde: datos.origenDestinoTraslado,
        trasHasta: datos.destinoTraslado
      });

      setCampos(parseDatos);
      setCargaDatos(true);

      getDataKinetyc({
        variables: { rut: datos.rutPaciente },
      });
    }
  });
  const [getDataKinetyc, { loading: loadingBenef }] = useLazyQuery(getbeneficiarioxrut, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let pac = [],
        notben,
        beneficiario,
        optBeneficiario = [],
        i = 1,
        valRep = null;

      if (data.getBeneficiariosbyRut === null) {
        setCasoInvalido(true);
      } else {
        pac = data.getBeneficiariosbyRut;
        beneficiario = pac.find((pac) => pac.rut === campos.rutPaciente.replace(/[^0-9kK-]/g, ""));
        const cotizante = pac.find((person) => person.tipoBeneficiario === "PRIMARY");
        const representante = campos.rutRepresentante ? pac.find((person) => person.rut === campos.rutRepresentante.replace(/[^0-9kK-]/g, "")) : {};
        notben = pac.filter((pac) => pac.rut !== campos.rutPaciente.replace(/[^0-9kK-]/g, ""));
        if (notben.length > 0) {
          setOtrosIntegrantes(true);
          notben.forEach((gf) => {
            if (dataSV && dataSV.getSolicitudesGCbyFkfun[0].rutRepresentante === gf.rut) { valRep = i }
            optBeneficiario.push({
              value: i++,
              label:
                formatRut(gf.rut) +
                "-" +
                gf.primerNombre + (gf.segundoNombre ? " " + gf.segundoNombre : "") +
                " " +
                gf.primerApellido +
                " " +
                gf.segundoApellido,
              rut: formatRut(gf.rut),
              nombre: gf.primerNombre + (gf.segundoNombre ? " " + gf.segundoNombre : ""),
              ap1: gf.primerApellido,
              ap2: gf.segundoApellido,
              relacion: gf.relacion,
            });
          });
        } else {
          optBeneficiario = []
        }
        setBeneficiarios(optBeneficiario);
        setCampos({
          ...campos,
          afiliado: cotizante,
          fechaNac: beneficiario.fechaNacimiento,
          genero: beneficiario.genero,
          optBeneficiario: optBeneficiario,
          beneficiario: valRep,
          nombrePaciente: beneficiario.primerNombre + (beneficiario.segundoNombre ? " " + beneficiario.segundoNombre : ""),
          apellido1Paciente: beneficiario.primerApellido,
          apellido2Paciente: beneficiario.segundoApellido,
          nombreRepresentante: campos.rutRepresentante ? representante.primerNombre + (representante.segundoNombre ? " " + representante.segundoNombre : "") : "",
          apellido1Representante: representante.primerApellido || "",
          apellido2Representante: representante.segundoApellido || ""
        });
      }
      if (fkFun) {
        getDocumentos(
          {
            variables: {
              folio: parseInt(fkFun),
              offset: null,
              limit: null,
              order: "idDespliegue",
              dir: "ASC",
              clasificacion: "5,4"
            }
          });
      }

    },
    onError: () => {
      setCasoInvalido(true);
      setIsKinetycError(true);
    }
  });

  const [getDocumentos, { loading: loadingDocsGestor }] = useLazyQuery(leerRegistros, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAntecedentesSV(data.getBitacora.bitacoras);
      setCampos({
        ...campos,
        antecedentesSV: data.getBitacora.bitacoras
      });
    }
  });

  if (!location.state && !fkFun) {
    navigate("/");
  }
  if (fkFun && !dataSV && !loading && !location.state) {
    blockBack(true);
    window.scrollTo(0, 0);
    getDataSV({
      variables:
      {
        fkFun: parseInt(fkFun)
      }
    });
  }

  if (loading || loadingBenef || loadingDocsGestor) {
    return <SkeletonIngreso />;
  }

  if (location.state && !cargarDatos) {
    blockBack(true);
    setCampos(location.state.campos);
    setCargaDatos(true);
    window.scrollTo(0, 0);
    if (fkFun && !location.state.campos.antecedentesSV) {
      getDocumentos(
        {
          variables: {
            folio: parseInt(fkFun),
            offset: null,
            limit: null,
            order: "idDespliegue",
            dir: "ASC",
            clasificacion: "5,4"
          }
        });
    } else {
      setAntecedentesSV(location.state.campos.antecedentesSV || []);
    }
    if (location.state.campos.afiliado) {
      if (location.state.campos.optBeneficiario && location.state.campos.optBeneficiario.length > 0) {
        setOtrosIntegrantes(true);
        setBeneficiarios(location.state.campos.optBeneficiario)
      }
    } else {
      getDataKinetyc({
        variables: { rut: location.state.campos.rutPaciente.replace(/[^0-9kK-]/g, "") },
      });
    }

  }

  function detectaCambio(e) {
    let field = e.target.id;
    let value = e.target.value;
    let type = e.target.name;
    if (type === "correo") {
      ingresaCorreo(field, value);
      return;
    }
    if (type === "telefono") {
      ingresaTelefono(field, value);
      return;
    }
    if (type === "rut") {
      ingresaRut(field, value);
      return;
    }

    setCampos({
      ...campos,
      [field]: value
    });
    setError({
      ...error,
      [field]: null
    })
  }

  function ingresaCorreo(campo, valor) {
    if (!validator.isEmail(valor)) {
      setError({
        ...error,
        [campo]: "Correo inválido",
      });
    } else {
      setError({
        ...error,
        [campo]: "",
      });
    }
    setCampos({
      ...campos,
      [campo]: valor
    });
  }

  function ingresaTelefono(campo, valor) {
    let value = valor.replace(/[^0-9]/g, "");
    if (value.length > 9) {
      return;
    }
    if (value.length !== 9) {
      setError({
        ...error,
        [campo]: "Número de teléfono inválido",
      });
    } else {
      setError({
        ...error,
        [campo]: "",
      });
    }
    setCampos({
      ...campos,
      [campo]: value
    });
  }

  function ingresaRut(campo, valor) {
    let error = null;
    valor = valor.replace(/[^0-9kK-]/g, "").trim();
    valor = valor.length > 2 ? formatRut(valor).toUpperCase() : valor;
    if (!validRut(valor)) {
      error = "Ingrese un rut válido";
    }
    setCampos({
      ...campos,
      [campo]: valor
    });
    setError({
      ...error,
      [campo]: error
    })
  }

  function detectaCambiosNombre(e) {
    let field = e.target.id;
    let value = capitalize(e.target.value);

    setCampos({
      ...campos,
      [field]: value,
    });
    setError({
      ...error,
      [field]: null
    });
  }

  function validaForm() {
    let idFocus = null;

    if (!validaSeccionUno(campos, error, setError)) {
      document.getElementById('datosBenef').focus();
      idFocus = true;
    }

    if (!validaSeccionDos(campos, error, setError)) {
      if (idFocus === null) {
        document.getElementById('datosRep').focus();
        idFocus = true;
      }
    }

    if (!validaSeccionTres(campos, error, setError)) {
      if (idFocus === null) {
        document.getElementById('defCaso').focus();
        idFocus = true;
      }
    }

    if (campos.tipoCaso === "GES" && !validaSeccionCuatro(campos, error, setError)) {
      if (idFocus === null) {
        document.getElementById('datosInsti').focus();
        idFocus = true;
      }
    }

    if (!validaSeccionCinco(campos, error, setError)) {
      if (idFocus === null) {
        document.getElementById('datosHospi').focus();
        idFocus = true;
      }
    }

    if (idFocus === null) {
      blockBack(false);
      setTimeout(() => {
        document.getElementById("enlace2").click();
      }, 500);

    }
  }

  return (
    <div>
      <div className="container Main">
        <label className="titulo1"> Solicitar GES-CAEC</label>
        <Breadcrumbs
          sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
          aria-label="breadcrumb"
          className="breadcrum"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            id="botonInicio"
            underline="always"
            color="inherit"
            to={campos.fkFun ? "/TodosLosCasos" : "/"}
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "400",
              margin: "0px,8px",
            }}
          >
            Inicio
          </Link>
          <Typography
            sx={{
              color: "#364855",
              fontSize: "14px",
              fontWeight: "700",
              marginBottom: "-1px",
              lineHeight: "24px",
            }}
          >
            Solicitar GES-CAEC
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="container" id="datosBenef" tabIndex="0">
        <DatosBeneficiario
          campos={campos}
          setCampos={setCampos}
          casoInvalido={casoInvalido}
          isKinetycError={isKinetycError}
          error={error}
          setError={setError}
          detectaCambio={detectaCambio}
        />
      </div>
      <div className="container" id="datosRep" tabIndex="1">
        <DatosRepresentante
          campos={campos}
          setCampos={setCampos}
          casoInvalido={casoInvalido}
          error={error}
          setError={setError}
          deshabilita={deshabilita}
          setDesahilita={setDesahilita}
          otrosIntegrantes={otrosIntegrantes}
          detectaCambio={detectaCambio}
          beneficiarios={beneficiarios}
          detectaCambiosNombre={detectaCambiosNombre}
        />
      </div>
      <div className="container" id="defCaso" tabIndex="2">
        <DefinicionCaso
          campos={campos}
          setCampos={setCampos}
          casoInvalido={casoInvalido}
          error={error}
          setError={setError}
          deshabilita={deshabilita}
          setDesahilita={setDesahilita}
          detectaCambio={detectaCambio}
          verificaCaso={verificaCaso}
          antecedentesSV={antecedentesSV}
        />
      </div>
      <Collapse in={campos.tipoCaso === "GES"}>
        <div className="container" id="datosInsti" tabIndex="3">
          <DatosInstitucion
            campos={campos}
            setCampos={setCampos}
            casoInvalido={casoInvalido}
            error={error}
            setError={setError}
            deshabilita={deshabilita}
            setDesahilita={setDesahilita}
            detectaCambio={detectaCambio}
            detectaCambiosNombre={detectaCambiosNombre}
          />
        </div>
      </Collapse>
      <div className="container" id="datosHospi" tabIndex="4">
        <DatosHospitalizacion
          campos={campos}
          setCampos={setCampos}
          casoInvalido={casoInvalido}
          error={error}
          setError={setError}
          deshabilita={deshabilita}
          setDesahilita={setDesahilita}
          detectaCambio={detectaCambio}
        />
        <Divider sx={{ margin: "15px 0" }}></Divider>
        <Grid item xs={12} sx={{ padding: "0 0 24px 0" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SecondaryButton
              sx={{ marginLeft: "16px" }}
              onClick={() => setDialogAnterior(true)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              data-testid="enviarFormAForm1"
              disabled={casoInvalido || (campos.psFolio && campos.fkProblemaSalud === campos.psFolio ? true : false)}
              sx={{ marginRight: "16px" }}
              onClick={validaForm}
            >
              Continuar
            </PrimaryButton>
          </Grid>
        </Grid>
        <Link
          id="enlace2"
          key={"enlace2"}
          state={{ campos }}
          to={"/SolicitudGesCae"}
          replace={true}
        />
      </div>
      <div id="dialogs">
        <VerificaCaso
          ps={campos.fkProblemaSalud}
          psNombre={campos.diagnosticoNombre}
          folio={campos.duplicado}
          modalCaso={modalCaso}
          setModalCaso={setModalCaso}
          preing={campos.preing}
          setFkFun={setFkFun}
        />
        {/* Dialog boton  anterior */}
        <Dialog
          className="dialogModalBig"
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={dialogAnterior}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Grid
            className="dialogModal"
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
              <img alt="img2" src={icon_infoBig}></img>
            </Grid>
            <DialogTitle className="tituloModal" sx={{ padding: "9px" }}>
              ¿Seguro que quieres cancelar la solicitud?
            </DialogTitle>
            <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
              <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
              >
                Vas a cancelar la solicitud.
              </DialogContentText>
            </DialogContent>
          </Grid>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              marginBottom="30px"
            >
              <PrimaryButton

                variant=" text"
                color="default"
                onClick={() => {
                  blockBack(false);
                  setTimeout(() => {
                    document.getElementById("botonInicio").click()
                  }, 500);
                }
                }
              >
                Cancelar solicitud
              </PrimaryButton>
              <SecondaryButton
                variant=" text"
                className="btn btn-secondary"
                onClick={() => setDialogAnterior(false)}
              >
                Volver
              </SecondaryButton>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );

}