import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import "../../assets/Table.css";
import "../../assets/App.css";


export function CabeceraTabla(props) {
  const { order, orderBy, setOrder, setOrderBy } = props;

  const onRequestSort = (event, property) => {
    const orCan = orderBy === property && order === "asc";
    setOrder(orCan ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const asignarPresente = props.cabecera.filter((cab) => cab.stickyRight === true);
  const sinAcciones = props.cabecera.filter((cab) => cab.noActions === true);
  return (
    <TableHead>
      <TableRow>
        {props.cabecera.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={headCell.sticky ? headCell.stickyRight ? headCell.firstSticky ? "headerFirstSticky" : "headerTableAsignar" : "headerTableIzquierda" : "headerTable"}
            sx={{...headCell.sx }}
            align="center"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorted ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        {sinAcciones.length > 0 ? null :
          <TableCell
            className={props.nonsticky ? "asignaHeaderNoSticky" : asignarPresente.length > 0 ? "headerAcciones" : "headerTableAcciones"}
            align={"center"}
            padding={"normal"}
          >
            Acciones
          </TableCell>}
      </TableRow>
    </TableHead>
  );
}