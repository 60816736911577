import { Grid } from "@mui/material";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

function Switch({ value, setValue }) {
    const valores = [
        { label: 'Canastas asignadas', value: 1 },
        { label: 'Historial prestadores', value: 2 },
    ];
    return (
        <Grid item xs={12}>
            <SelectPersonalizado
                id={"switch"}
                value={value}
                setValue={setValue}
                options={valores}
            />
        </Grid>
    );
}

export default Switch;
