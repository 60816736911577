import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { exportaPS } from "../../../../utils/queries";
import { generaExcelPS } from "../utils/excelUtilsPS";
import { parse } from "zipson";

export default function ExportarPS({ filtros, setSnackData, loadingTabla }) {
    const [exportarMed, { loading }] = useLazyQuery(exportaPS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => generaExcelPS(parse(data.exportaPS), setSnackData),
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al consultar al servidor, intente nuevamente",
            severidad: "error",
        })
    });

    return (
        <BotonCarga
            label={"Exportar"}
            testid={"exportarPS"}
            download
            variant='secondary'
            loading={loading}
            disabled={loadingTabla}
            toDo={() => exportarMed({
                variables: {
                    codigo: filtros.Codigo || null,
                    glosa: filtros.glosa || null,
                    arancel: filtros.arancel || null,
                    estado: filtros.estado || null,
                    eliminado: filtros.eliminado
                }
            })}

        />
    );
}