import { Fragment, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalEliminarLEP } from "./ModalEliminarLEP";
import HistorialCambios from "./HistorialAccionesLEP";
import { isMantenedor, hasJGESCAEC } from "../../../../utils/hasPermisions";

export const MenuLEP = ({ lep, rol, setSnackData, refetch, isEliminado, sucursal, usuario }) => {
    const [modalEliminarLEP, setModalEliminarLEP] = useState(false);
    const [modalHistorialLEP, setModalHistorialLEP] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirHistorial = () =>{
        setModalHistorialLEP(true);
        handleClose();
    }

    const abrirEliminar = () =>{
        setModalEliminarLEP(true);
        handleClose();
    }

    return (
        <Fragment>
            <ModalEliminarLEP
                lep={lep}
                onOpen={modalEliminarLEP}
                onClose={() => setModalEliminarLEP(false)}
                setSnackData={setSnackData}
                refetch={refetch}
                sucursal={sucursal}
                usuario={usuario}
            />
            <HistorialCambios
                lep={lep}
                onOpen={modalHistorialLEP}
                onClose={() => setModalHistorialLEP(false)}
            />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className="menuForm"
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem hidden={!isEliminado} onClick={abrirHistorial}>
                    Historial de cambios
                </MenuItem>
                <MenuItem hidden={isEliminado} disabled={!(hasJGESCAEC(rol) || isMantenedor(rol))}  onClick={abrirEliminar}>
                    Eliminar
                </MenuItem>
            </Menu>
        </Fragment>
    )
}
