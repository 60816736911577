import {
  Grid
} from "@mui/material";
import { GenerarF1Bandeja } from "../../../components/ArchivosGestor/GenerarF1Bandeja";
import { GenerarF2Bandeja } from "../../../components/ArchivosGestor/GenerarF2Bandeja";
import { SubirDocForm } from "./SubirDocForm";
import { IconView } from "../../../components/icons/iconosEsencial";
import { ObtenerArchivoGestor } from "../../../components/ArchivosGestor/ObtenerArchivoGestor";

export const Opciones = ({
  sinFirmar,
  form,
  estado,
  num,
  disabled,
  fkFun,
  rutPaciente,
  upd,
  mensajero,
  setMensajero,
  correo,
  sucursal
}) => {
  var sinFirma = !sinFirmar ? " Firmado" : "";
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ padding: "5px 0" }}
    >
      <Grid item xs={9}>
        <label>{form ? "Formulario " + num + sinFirma : "Sin Formulario " + num + sinFirma}</label>
      </Grid>
      <Grid item xs={1.5}>
        {form ?
          <ObtenerArchivoGestor tipoDoc={form} isBandeja={true} /> :
          sinFirmar && !form && num === 1 && estado >= 1 && estado <= 7 ?
            <GenerarF1Bandeja fkFun={fkFun} correo={correo} sucursal={sucursal} upd={upd}/> :
            sinFirmar && true && num === 2 && (estado === 4 || estado === 7) ?
              <GenerarF2Bandeja fkFun={fkFun} correo={correo} sucursal={sucursal} upd={upd}/> :
              <Grid item xs={1.5}>
                <IconView sx={{ opacity: ".5" }} />
              </Grid>}
      </Grid>
      <Grid item xs={1.5}>
        {sinFirmar ? null :
          <SubirDocForm
            tipoDoc={form}
            disabled={disabled}
            folio={fkFun}
            rut={rutPaciente}
            doc={"form" + num + "_firmado"}
            upd={upd}
            mensajero={mensajero}
            setMensajero={setMensajero}
          />
        }
      </Grid>
    </Grid>)
};