import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

export const DatePicker = ({
    value,
    onChange,
    minDate,
    maxDate,
    disabled,
    id,
    testid,
    label,
    error,
    noClear
}) => {
    return (
        <FormControl sx={{ width: "98%!important", margin: "0" }} error={error ? true : undefined}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
            >
                <DesktopDatePicker
                    className="form-control inputText"
                    minDate={minDate}
                    maxDate={maxDate}
                    type="date"
                    required
                    disabled={disabled}
                    value={value}
                    label={label || "DD  /  MM  /  AAAA"}
                    allowSameDateSelection={true}
                    showDaysOutsideCurrentMonth={true}
                    onChange={(f) => {
                        onChange(f);
                    }}
                    inputProps={{ "data-testid": testid, "id": id }}
                    renderInput={(params) => (

                        <Grid sx={{ position: "relative", display: "inline-block" }} >
                            <TextField
                                {...params}
                                error={error ? true : undefined}
                                sx={{margin: "0!important"}}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                            {value && !noClear &&
                                <IconButton
                                    style={{ position: "absolute", top: ".5rem", margin: "auto", right: "2rem" }}
                                    sx={{ opacity: ".5" }}
                                    disabled={disabled}
                                    onClick={() => onChange(null)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            }
                        </Grid>
                    )}
                />
            </LocalizationProvider>
            <FormHelperText>{error || ""}</FormHelperText>
        </FormControl>
    );
}