import { motivoFueraRed } from "../../../../../../utils/queries";
import { useQuery } from "@apollo/client";

function OpcionesFuera({useQueryHook = useQuery}){
  const { data, loading, error } = useQueryHook(motivoFueraRed)

  if (loading) {
    return <option disabled data-testid="loadingFuera">Cargando...</option>
  }
  if(error){
    return <option disabled data-testid="errorFuera">Error: {error.message}</option>
  }
  return (
    data.getMotivosFueraRed.map((dato, index) => (
        <option key={index} value={parseInt(dato.id)}>{dato.descripcion}</option>
      )
    )
  )
}

export default OpcionesFuera;
