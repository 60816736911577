import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import PrimaryButton, {
    SecondaryButton,
} from "../../../../../components/Buttons/Botones";
import {
    asignacanastas,
    activaCanasta,
} from "../../../../../utils/queries.js";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import ThemeTextField from "../../../../../components/Textfield/ThemeTextField";

export const AsignaCanasta = ({
    folio,
    accounts,
    estado,
    setAlertData,
    refetch,
    canastasAsignadas,
    setModalCanAsig,
    row,
    autorizacion,
    limpiaFiltros,
    filtros,
    deshabilitaInputs,
    sucursal
}) => {
    const [open, setOpen] = useState(false);
    const [errorfechainicio, setErrorfechainicio] = useState("");
    const [fechainicioCanasta, setFechainicio] = useState(new Date());
    const [fechaTermino, setFechatermino] = useState(null);
    const [ciclo, setCiclo] = useState(null);
    const [idCanasta, setIdCanasta] = useState("");
    const [descripcionCanasta, setDescripcionCanasta] = useState("");
    const [cargandoAsignaCanasta, setCargandoCanasta] = useState(false);
    const [activarCanasta] = useLazyQuery(activaCanasta, {
        errorPolicy: "all",
        onCompleted: (data) => {
            //console.log("ACTIVA CANASTA", data);
            finalizaActivacion();
        },
        onError: (error) => {
            console.log(error.message);
            finalizaActivacion();
        }
    })
    const [asignaCanastaMut] = useMutation(asignacanastas, {
        onCompleted: (data) => {
            if (estado === 4 || estado === 9) {
                activarCanasta({
                    variables: {
                        usuario: accounts[0].name,
                        sucursal: sucursal,
                        idCasoCanasta: data.asignaCanasta.idBeneficios,
                        numeroCaso: folio,
                        canasta: idCanasta,
                        prestadores: [],
                        fAperturaCanasta: format(fechainicioCanasta, "dd-MM-yyyy"),
                        fechaTermino: fechaTermino ? format(fechaTermino, "dd-MM-yyyy") : null
                    },
                });
            } else {
                finalizaActivacion();
            }

        },
        onError: (error) => {
            console.log(error.message);
            handleClose();
            setAlertData({
                abrir: true,
                mensaje: "La canasta no ha podido ser asignada, intente nuevamente",
                severidad: "error",
            });
            setCargandoCanasta(false);
        }
    });

    function finalizaActivacion() {
        handleClose();
        setAlertData({
            abrir: true,
            mensaje: "La canasta fue asignada con éxito!",
            severidad: "success",
        });
        setCargandoCanasta(false);
        const recargaCanastas = document.getElementById("recargaCanastas");
        if (recargaCanastas) {
            recargaCanastas.click();
        }
        refetch();
        limpiaFiltros();
    }

    const handleClickOpen = (data) => {
        setIdCanasta(data.codigo);
        setDescripcionCanasta(data.descripcion);
        setFechainicio(new Date());
        setFechatermino(data.vigenciaHasta);
        checkCanasta(data);
    };

    const asignaCiclo = (event) => {
        setCiclo(event.target.value.replace(/[^0-9]/g, "").slice(0, 3));
    };

    const handleSave = () => {
        setCargandoCanasta(true);
        setErrorfechainicio("");
        if (fechainicioCanasta === null) {
            setErrorfechainicio("Ingrese fecha inicio");
        } else {
            asignaCanastaMut({
                variables: {
                    folio: folio,
                    canasta: idCanasta,
                    descripcion: descripcionCanasta,
                    ciclo: parseInt(ciclo) || null,
                    userCreated: accounts[0].name,
                    updatedUser: accounts[0].name,
                    fechaInicio: fechainicioCanasta,
                    fechaFin: fechaTermino,
                    sucursal: sucursal,
                },
            });
        }
    };

    function checkCanasta(data) {
        let duplicada;
        if (canastasAsignadas) {
            canastasAsignadas.getCanastasasignadas.canastas.forEach((canAsig) => {
                if (canAsig.canasta === data.codigo && canAsig.vigencia === true) {
                    setModalCanAsig(true);
                    duplicada = true
                    return;
                }
            })
        }
        if (!duplicada) {
            setOpen(true);
        }
    }

    const handleClose = () => {
        setIdCanasta("");
        setDescripcionCanasta("");
        setFechatermino(null);
        setCiclo(null);
        setOpen(false);
    };

    return (
        <Grid>
            <PrimaryButton
                data-testid="asignaButton"
                disabled={
                    estado === 5 || estado === 6 || estado === 7 || filtros.arancel === 2019 || deshabilitaInputs
                        ? true
                        : false
                }
                onClick={() => {
                    handleClickOpen(row);
                }}
            >
                Asignar
            </PrimaryButton>
            <Dialog
                maxWidth="md"
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >

                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                >
                    <DialogTitle sx={{ width: "100%" }}>
                        <Typography className="tituloModal">Detalles finales</Typography>
                    </DialogTitle>
                    <DialogContent
                        sx={{ padding: "0 21px 0px 3px!important" }}
                    >
                        <DialogContentText
                            alignContent="center"
                            sx={{ m: 1, textAlign: "center" }}
                        >
                            Para asignar de manera correcta tu canasta
                            completa la siguiente información.
                        </DialogContentText>

                        <Grid
                            sx={{ margin: "0", width: "100%" }}
                            container
                            spacing={2}
                        >
                            <Grid item xs={4}>
                                <DatePicker
                                    id="fechaInicioCanasta"
                                    testid="fechaInicioCanasta"
                                    label="Fecha Inicio"
                                    minDate={autorizacion}
                                    value={fechainicioCanasta}
                                    noClear
                                    error={errorfechainicio}
                                    onChange={(newValue) => {
                                        setFechainicio(newValue);
                                        setErrorfechainicio("");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    id="fechaTerminoCanasta"
                                    testid="fechaTerminoCanasta"
                                    label="Fecha Término"
                                    minDate={fechainicioCanasta}
                                    value={fechaTermino}
                                    onChange={(newValue) => {
                                        setFechatermino(newValue);
                                        setErrorfechainicio("");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <ThemeTextField
                                        id="outlined"
                                        data-testid="inputCiclo"
                                        label="Ciclo"
                                        value={ciclo || ""}
                                        sx={{
                                            width: "280px",
                                            margin: "0!important"
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 999,
                                                maxLength: 3,
                                            },
                                        }}
                                        type="number"
                                        onChange={asignaCiclo}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Grid>

                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <SecondaryButton
                            disabled={cargandoAsignaCanasta}
                            onClick={handleClose}
                        >
                            Volver
                        </SecondaryButton >
                        <PrimaryButton
                            data-testid="asignaCanastaBtn"
                            disabled={cargandoAsignaCanasta}
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Asignar canasta
                        </PrimaryButton >
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}