import { useState } from "react";
import PrimaryButton, {
  SecondaryButton,
} from "../../../components/Buttons/Botones";
import { PrestadoresEstablecidos } from "./Prestadores/PrestadoresEstablecidos";
import { FiltroCanasta } from "./FiltroCanastas/FiltroCanastas";
import { CanastaAsignadas } from "./CanastasAsignadas/CanastasAsignadas";
import { Collapse, Divider, Grid } from "@mui/material";
import Intervencion from "./components/Intervencion";
import Arancel from "./components/Arancel";
import Switch from "./components/Switch";
import CanastaSelect from "./components/CanastaSelect";
import Mensajero from "../../../components/Snackbar/Mensajero";

export default function GES({ deshabilitaInputs, sucursal, data, rol }) {
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [interv, Setinterv] = useState(null);
  const [canasta, setCanasta] = useState(null);
  const [arancel, setArancel] = useState(2022);
  const [switchCP, setSwitchCP] = useState(1);
  const [busqueda, setBusqueda] = useState(null);
  const estado = data.fkEstadoSolicitudIsapre;
  const patologia = data.fkProblemaSalud;
  const folio = data.fkFun;
  const fechaAutorizacion = data.fechaAutorizacionSoliciut;
  const [alertData, setAlertData] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });

  function AplicarFiltro() {
    setMostrarResultados(true);
    setBusqueda({
      codigo: canasta ? canasta.codigo : null,
      intervencion: interv,
      arancel: arancel,
    });
  }
  function SeleccionarFiltro(value) {
    Setinterv(value);
  }

  const limpiaFiltros = () => {
    setCanasta(null);
    Setinterv(null);
    setArancel(2022);
    setMostrarResultados(false);
    setBusqueda({ intervencion: null, arancel: 2022 });
  };

  return (
    <>
      <div className="container" data-testid="container">
        <Grid
          sx={{ padding: "0px 24px 20px 14px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <label className="title">Canastas</label>{" "}
          </Grid>
          <Grid item xs={12}>
            <label className="labelForm">
              En esta sección podrás gestionar tus canastas y prestadores.
            </label>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ color: "#D2D6D9" }} />
          </Grid>
          <Arancel
            estado={estado}
            value={arancel}
            setValue={setArancel}
            deshabilitaInputs={deshabilitaInputs}
          />
          <Intervencion
            estado={estado}
            patologia={patologia}
            arancel={arancel}
            value={interv}
            setValue={SeleccionarFiltro}
            deshabilitaInputs={deshabilitaInputs}
          />
          <CanastaSelect
            estado={estado}
            intervencion={interv}
            patologia={patologia}
            arancel={arancel}
            canasta={canasta}
            setCanasta={setCanasta}
            deshabilitaInputs={deshabilitaInputs}
          />
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            sx={{ margin: "15px 10px 20px" }}
          >
            <SecondaryButton
              data-testid="limpiaFiltros"
              sx={{ width: "179px", marginRight: "13px" }}
              disabled={
                deshabilitaInputs ||
                (canasta === null && interv === null && arancel === null) ||
                  estado === 5 ||
                  estado === 6 ||
                  estado === 7
                  ? true
                  : false
              }
              onClick={limpiaFiltros}
            >
              Limpiar filtros
            </SecondaryButton>

            <PrimaryButton
              data-testid="aplicaFiltros"
              disabled={
                estado === 5 || estado === 6 || estado === 7 || deshabilitaInputs
                  ? true
                  : false
              }
              type="button"
              onClick={AplicarFiltro}
            >
              <img className="icon-findS" alt="" />
              Buscar canasta
            </PrimaryButton>
          </Grid>
        </Grid>
        <Collapse in={mostrarResultados} unmountOnExit>
          <Grid>
            <label className="titulo2 p-4">Resultados de la búsqueda</label>
            <FiltroCanasta
              data={patologia}
              estado={estado}
              filtros={busqueda}
              folio={folio}
              fechaAu={fechaAutorizacion}
              deshabilitaInputs={deshabilitaInputs}
              limpiaFiltros={limpiaFiltros}
              setAlertData={setAlertData}
              sucursal={sucursal}
            />
          </Grid>
        </Collapse>
      </div>
      <div className="container">
        <Mensajero data={alertData} setState={setAlertData} />
        <Grid
          sx={{ padding: "15px 24px 20px 14px", margin: "0", width: "100%" }}
          container
          spacing={2}
        >
          <Grid item xs={8}>
            <label className="titulo2 p-2">Canastas/Prestadores </label>
          </Grid>
          <Grid item xs={4}>
            <Switch
              value={switchCP}
              setValue={setSwitchCP}
            />
          </Grid>
        </Grid>
        {
          switchCP === 1 ?
            <CanastaAsignadas
              folio={folio}
              estado={estado}
              paciente={data.rutPaciente}
              fechaAutorizacion={fechaAutorizacion}
              deshabilitaInputs={deshabilitaInputs}
              setMensajeroData={setAlertData}
              sucursal={sucursal}
              rol={rol}
            />
            :
            <PrestadoresEstablecidos
              data={patologia}
              intervencion={interv}
              folio={folio}
              estado={estado}
              deshabilitaInputs={deshabilitaInputs}
              setAlertData={setAlertData}
              sucursal={sucursal}
            />
        }

      </div>
    </>
  );
}
