import { useState } from "react";
import {
    querymedico,
} from "../../../../utils/queries";
import {
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton
} from "@mui/material";
import { IconDoctor } from "../../../../components/icons/iconosEsencial";
import ThemeTextField from "../../../../components/Textfield/ThemeTextField";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import {
    formatRut,
    validRut
  } from "chilean-rutify";
import BuscaPrestador from "../../../../components/BuscaPrestador/BuscaPrestador.jsx";
import { useLazyQuery } from "@apollo/client";

export default function DatosInstitucion({
    campos,
    setCampos,
    casoInvalido,
    error,
    setError,
    deshabilita,
    setDesahilita,
    detectaCambio,
    detectaCambiosNombre
}) {
    const [especialidad, setEspecialidad] = useState([{ label: "Otro", value: "-1" }]);
    const [GetMedicos, { loading }] = useLazyQuery(querymedico);
    function asignaPrestador(dir, luat, raz, rut) {
        setCampos({
            ...campos,
            rutInsti: rut,
            direccion: dir,
            luat: luat,
            razonSocial: raz
        });
        setError({
            ...error,
            rutInsti: null
        });
    }

    function verificaDoc(e) {
        var rut = e.target.value.replace(/[^0-9kK-]/g, "").trim();
        rut = rut.length > 2 ? formatRut(rut).toUpperCase() : rut;
        setCampos({
            ...campos,
            rutMedico: rut
        });
        if (rut !== "" && validRut(rut)) {
            GetMedicos({
                variables: {
                    rut: rut.replace(/[^0-9kK-]/g, ""),
                    tipo: 1,
                },
                onCompleted: (res) => {
                    procesaMedicos(res, rut);
                },
                onError: (err)=>{
                    console.log(err.message);
                    procesaMedicos(null, rut);
                }
            });
            setError({
                ...error,
                rutMedico: ""
            });
        } else {
            setError({
                ...error,
                rutMedico: "Ingrese rut válido"
            });
        }
    }

    function procesaMedicos(data, rut) {
        let especialidades = [{ label: "Otro", value: "-1" }];

        if (!data || !data.getPrestadorbyRut) {

            setDesahilita({
                ...deshabilita,
                medico: false
            })

            setCampos({
                ...campos,
                rutMedico: rut,
                medico: [],
                nombreMedico: null,
                primerApellidoMedico: null,
                segundoApellidoMedico: null,
            });

        } else {
            setCampos({
                ...campos,
                rutMedico: rut,
                medico: data.getPrestadorbyRut,
                nombreMedico: data.getPrestadorbyRut.NomMed,
                primerApellidoMedico: data.getPrestadorbyRut.ApeMed1,
                segundoApellidoMedico: data.getPrestadorbyRut.ApeMed2,
            });

            data.getPrestadorbyRut.Especialidades.forEach((esp) => {
                especialidades.push({ label: esp.descEsp, value: esp.descEsp })
            });

            setDesahilita({
                ...deshabilita,
                medico: true
            });

            document.getElementById("especialidad").disabled = false;
        }
        setEspecialidad(especialidades);
    }

    function cambioEspecialidad(valor) {
        setCampos({
            ...campos,
            especialidad: valor,
            otro: null
        });
        setError({
            ...error,
            especialidad: null
        });
    }
    return (
        <div className="card-box">
            <Grid
                sx={{ padding: "5px 15px 24px 5px", margin: "0", width: "100%" }}
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <label className="title">Datos institución y médico tratante</label>
                    <br />
                    <label className="subTitle">Busca la institución por RUT o por Razón social.</label>
                </Grid>
                <BuscaPrestador 
                    luat={campos.luat}
                    razonSocial={campos.razonSocial}
                    rutInsti={campos.rutInsti}
                    direccion={campos.direccion}
                    directions={campos.direcciones}
                    error={error}
                    detectaCambio={detectaCambio}
                    asignaPrestador={asignaPrestador}
                />
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <label className="textLabel4"><IconDoctor /> Datos médico tratante</label>
                        </Grid>
                        <Grid item xs={4}>
                            <label className="labelForm">RUT Médico</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="rutInstitucion"
                                    error={error.rutMedico ? true : undefined}
                                    name="rut"
                                    id="rutMedico"
                                    placeholder="Ej: 11.111.111-1"
                                    inputProps={{ maxLength: 12 }}
                                    disabled={casoInvalido}
                                    onChange={verificaDoc}
                                    value={campos.rutMedico || ""}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                position="start"
                                            >
                                                {
                                                    loading
                                                        ?
                                                        <CircularProgress
                                                            data-testid="cargandoDoc"
                                                            size={20}
                                                        />
                                                        :
                                                        null
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                                <span className="errorSpan">{error.rutMedico}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <label className="labelForm">Nombre Completo</label>
                            <FormControl sx={{ width: "99%!important", margin: "0 1% 0 0" }}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="nombreMedico"
                                    name="Nombre"
                                    id="nombreMedico"
                                    placeholder="Ej: Rodrigo Gutierrez Medina"
                                    disabled={casoInvalido || deshabilita.medico}
                                    error={error.nombreMedico ? true : undefined}
                                    onChange={detectaCambiosNombre}
                                    value={campos.nombreMedico}
                                />
                                <span className="errorSpan">{error.nombreMedico}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <label className="labelForm">Especialidad</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                <SelectPersonalizado
                                    inputTest="especialidad"
                                    id="especialidad"
                                    disabled={casoInvalido}
                                    error={error.especialidad}
                                    value={campos.especialidad || ""}
                                    setValue={cambioEspecialidad}
                                    options={especialidad}
                                    placeholder={"Seleccione"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <label className="labelForm">Otro</label>
                            <FormControl sx={{ width: "98%!important", margin: "0 1% 0 0" }}>
                                <ThemeTextField
                                    fullWidth
                                    data-testid="otro"
                                    error={error.otro ? true : undefined}
                                    name="otro"
                                    id="otro"
                                    placeholder="Escribe especialidad del médico"
                                    disabled={casoInvalido || campos.especialidad !== "-1"}
                                    onChange={detectaCambio}
                                    value={campos.otro || ""}
                                />
                                <span className="errorSpan">{error.otro}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <label className="labelForm">Tratamiento actual</label>
                            <FormControl sx={{ width: "99.5%!important", margin: "0 1% 0 0" }}>
                                <textarea
                                    name=""
                                    style={{ borderColor: error.tratamientoActual ? "red" : "#ced4da" }}
                                    className="form-control"
                                    id="tratamientoActual"
                                    cols="30"
                                    rows="4"
                                    disabled={casoInvalido}
                                    onChange={detectaCambio}
                                    value={campos.tratamientoActual || ""}
                                    placeholder="Escribe breve descripción del tratamiento"
                                    maxLength="155"
                                />
                                <label className="text-end">{(campos.tratamientoActual && campos.tratamientoActual.length) || 0}/155</label>
                                <span className="errorSpan">{error.tratamientoActual}</span>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}