import BotonCarga from "../../../components/Buttons/BotonCarga";
import { useLazyQuery } from "@apollo/client";
import { exportaME } from "../../../utils/queries";
import { generaExcelMed } from "../utils/procesaExcelME";
import { parse } from "zipson";

export default function ExportarME({ filtros, setSnackData, loadingTabla }) {
    const [exportarMed, { loading }] = useLazyQuery(exportaME,{
        fetchPolicy: "network-only",
        onCompleted: (data) => generaExcelMed(parse(data.exportaME), setSnackData),
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Error al consultar al servidor, intente nuevamente",
            severidad: "error",
        })
    });

    return (
        <BotonCarga
            label={"Exportar"}
            testid={"exportarME"}
            download
            variant='secondary'
            loading={loading}
            disabled={loadingTabla}
            toDo={()=>{
                exportarMed({
                    variables: {
                        rut: filtros.rut?.replace(/[^0-9kK-]/g, "") || null,
                        canasta: filtros.canasta || null,
                        codigoME: filtros.codigoME || null,
                        nombreMed: filtros.nombreMed || null,
                        activo: filtros.activo,
                    }
                })
            }}
        />
    );
}