import React from "react";

const ContadorRed = ({ amount }) => {
  if (amount > 0) {
    return (
      <label className="tagPurple" data-testid="ConContador">{amount} prestador(es) Establecido(s)</label>
    );
  }

  return null;
};

export default ContadorRed;
