import { useQuery } from "@apollo/client";
import { cuentaprestadores } from "../../../../../../utils/queries";
import { useAsignaPrestadorContext } from "../context/AsignaPrestadorContext";
import Titulo from "./Titulo";
import DentroDeRed from "./DentroDeRed";
import FueraDeRed from "./FueraDeRed";
import AvisoPrestadoresFR from "./AvisoPrestadoresFR";
import { Button, Grid } from "@mui/material";

const InicioCaec = ({
    folio,
    estado,
    deshabilitaInputs
}) => {
    const {
        handleNext,
        abrirModalInicio,
        setTipoPrestador
    } = useAsignaPrestadorContext();
    const { data, refetch } = useQuery(cuentaprestadores, {
        variables: { folio: folio },
        fetchPolicy: "cache-and-network",
    });
    const nextCaec = () => {
        setTipoPrestador("DR");
        handleNext();
        abrirModalInicio();
    }
    return (
        <div className="AppForm container">
            <Grid
                container
                spacing={2}
                direction="row"
                sx={{ padding: "20px" }}
            >
                <Button id="recargaCont" data-testid="refetchPresCaec" hidden={true} onClick={() => { refetch(); }} />
                <Grid item xs={12}>
                    <Titulo isCaec />
                </Grid>
                <Grid item xs={6}>
                    <DentroDeRed
                        estado={estado}
                        data={(data && data.cuentaprestadores.contDR) || 0}
                        handleNext={nextCaec}
                        deshabilitaInputs={deshabilitaInputs}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FueraDeRed
                        estado={estado}
                        data={(data && data.cuentaprestadores) || { contDR: 0, conFR: 0 }}
                        deshabilitaInputs={deshabilitaInputs}
                    />
                </Grid>
            </Grid>
            <AvisoPrestadoresFR contador={(data && data.cuentaprestadores.contDR) || 0} isapre={estado} />
        </div>
    );
}

export default InicioCaec;