import React from "react";
import "../../components/Skeleton/Skeleton.scss";

const SkeletonTable = (props) => {
  var listItems = [];
  var liHTML = [];

  for (let index = 0; index < 7; index++) {
    liHTML.push(<li key={index}><div className="col-sk"></div></li>);
  }

  for (let index = 0; index < 8; index++) {
    listItems.push(
      <ul key={index} className="ul-sk">
        {liHTML}
      </ul>
    );
  }

  return (
    // <div className="skeleton hide-sk">
    <div className="skeleton" data-testid={props.test}>
      <div className="post-sk">
        {/*<div className="title-sk"></div>*/}
        {listItems}
      </div>
    </div>
  );
};

export default SkeletonTable;
