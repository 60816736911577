import { isAfter } from "date-fns";
import validator from "validator";
import { validRut } from "chilean-rutify";

//Valida secciones del formulario
export function validaSeccionUno(campos, error, setError) {
    let seccion1 = true;
    let errores = error;
    if (!campos.region) {
        seccion1 = false;
        errores["region"] = "Debe elegir una región";
    } else {
        errores["region"] = null;
    }
    if (!campos.comuna) {
        seccion1 = false;
        errores["comuna"] = "Debe elegir una comuna";
    } else {
        errores["comuna"] = "";
    }
    if (!campos.dirPaciente) {
        seccion1 = false;
        errores["dirPaciente"] = "Debe escribir un domicilio";
    } else {
        errores["dirPaciente"] = "";
    }
    if (!campos.emailPaciente || !validator.isEmail(campos.emailPaciente)) {
        seccion1 = false;
        errores["emailPaciente"] = "Debe escribir un correo válido";
    } else {
        errores["emailPaciente"] = "";
    }
    if (!campos.telPaciente || campos.telPaciente.length !== 9) {
        seccion1 = false;
        errores["telPaciente"] = "Debe escribir un teléfono válido";
    } else {
        errores["telPaciente"] = "";
    }
    if (!campos.celPaciente || campos.celPaciente.length !== 9) {
        seccion1 = false;
        errores["celPaciente"] = "Debe escribir un teléfono válido";
    } else {
        errores["celPaciente"] = "";
    }
    setError(prevState => {
  return {...prevState, ...errores};
});
    return seccion1;
}
export function validaSeccionDos(campos, error, setError) {
    let seccion2 = true;
    let errores = error;

    if (!campos.solicita) {
        seccion2 = false;
        errores["solicita"] = "Debe seleccionar tipo representante";
    } else {
        errores["solicita"] = null;
        if (campos.solicita === "2") {
            if (!campos.tipoDocumento) {
                seccion2 = false;
                errores["documento"] = "Debe elegir un tipo de documento";
            } else {
                if (campos.tipoDocumento === "rut") {
                    if (!campos.rutRepresentante || !validRut(campos.rutRepresentante)) {
                        seccion2 = false;
                        errores["rutRepresentante"] = "Debe ingresar un RUT válido";
                    } else {
                        errores["rutRepresentante"] = "";
                    }
                } else if (campos.tipoDocumento === "pasaporte") {
                    if (!campos.pasaporteRepresentante) {
                        seccion2 = false;
                        errores["pasaporteRepresentante"] = "Debe ingresar un pasaporte";
                    } else {
                        errores["pasaporteRepresentante"] = "";
                    }
                }
                if (!campos.nombreRepresentante) {
                    seccion2 = false;
                    errores["nombreRepresentante"] = "Debe ingresar un nombre";
                } else {
                    errores["nombreRepresentante"] = "";
                }
                if (!campos.apellido1Representante) {
                    seccion2 = false;
                    errores["apellido1Representante"] = "Debe ingresar un apellido paterno";
                } else {
                    errores["apellido1Representante"] = "";
                }
                if (!campos.apellido2Representante) {
                    seccion2 = false;
                    errores["apellido2Representante"] = "Debe ingresar un apellido materno";
                } else {
                    errores["apellido2Representante"] = "";
                }
            }
        }
        if (campos.solicita === "3") {
            if (!campos.beneficiario) {
                seccion2 = false;
                errores["beneficiario"] = "Debe seleccionar un beneficiario";
            } else {
                errores["beneficiario"] = "";
            }
        }
        if (!campos.emailRepresentante || !validator.isEmail(campos.emailRepresentante)) {
            seccion2 = false;
            errores["emailRepresentante"] = "Debe Ingresar un correo válido";
        } else {
            errores["emailRepresentante"] = "";
        }
        if (!campos.telefonoRepresentante || campos.telefonoRepresentante.length !== 9) {
            seccion2 = false;
            errores["telefonoRepresentante"] = "Debe Ingresar un teléfono válido";
        } else {
            errores["telefonoRepresentante"] = "";
        }
        if (!campos.celularRepresentante || campos.celularRepresentante.length !== 9) {
            seccion2 = false;
            errores["celularRepresentante"] = "Debe Ingresar un teléfono válido";
        } else {
            errores["celularRepresentante"] = "";
        }
    }

    setError(prevState => {
  return {...prevState, ...errores};
});

    return seccion2;
}
export function validaSeccionTres(campos, error, setError) {
    let seccion3 = true;
    let errores = error;
    if (!campos.tipoCaso) {
        seccion3 = false;
        errores["tipoCaso"] = "Debe seleccionar un tipo de caso";
    } else {
        errores["tipoCaso"] = "";
    }
    if (campos.tipoCaso === "GES") {
        if (!campos.fkProblemaSalud) {
            seccion3 = false;
            errores["fkProblemaSalud"] = "Debe seleccionar un problema de salud";
        } else {
            errores["fkProblemaSalud"] = "";
        }
        if (!campos.eventoAdmin) {
            seccion3 = false;
            errores["eventoAdmin"] = "Debe seleccionar un evento Administrativo";
        } else {
            errores["eventoAdmin"] = "";
            if (campos.eventoAdmin !== 14 && !campos.fechaEventoAdm) {
                seccion3 = false;
                errores["fechaEventoAdm"] = "Debe ingresar una fecha/hora válida";
            } else {
                errores["fechaEventoAdm"] = "";
            }
        }
    }
    if (campos.tipoCaso === "CAEC") {
        if (!campos.diagnosticoCaec) {
            seccion3 = false;
            errores["diagnosticoCaec"] = "Debe ingresar un diagnóstico CAEC";
        } else {
            errores["diagnosticoCaec"] = "";
        }
    }
    if (!campos.fkTipoAntecedente) {
        seccion3 = false;
        errores["fkTipoAntecedente"] = "Debe seleccionar un tipo de antecedente";
    } else {
        errores["fkTipoAntecedente"] = "";
    }
    if ((campos.files && campos.files.length > 0 ) || (campos.antecedentesSV && campos.antecedentesSV.length > 0) || campos.origen===3) {
        errores["archivo"] = "";
    } else {
        seccion3 = false;
        errores["archivo"] = "Debe subir un antecedente";
    }

    setError(prevState => {
  return {...prevState, ...errores};
});

    return seccion3;
}
export function validaSeccionCuatro(campos, error, setError) {
    let seccion4 = true;
    let errores = error;
    if (campos.rutInsti && validRut(campos.rutInsti) && campos.razonSocial && campos.direccion) {
        errores["rutInsti"] = "";
    } else {
        seccion4 = false;
        errores["rutInsti"] = "Debe completar los datos faltantes del prestador.";
    }

    if (!campos.rutMedico || !validRut(campos.rutMedico)) {
        seccion4 = false;
        errores["rutMedico"] = "Debe ingresar un RUT válido";
    } else {
        errores["rutMedico"] = "";
        if (!campos.nombreMedico) {
            seccion4 = false;
            errores["nombreMedico"] = "Debe ingresar un nombre";
        } else {
            errores["nombreMedico"] = "";
        }
    }
    if (campos.rutMedico && !campos.especialidad) {
        seccion4 = false;
        errores["especialidad"] = "Debe seleccionar una especialidad";
    } else {
        errores["especialidad"] = "";
        if (campos.especialidad === "-1" && !campos.otro) {
            seccion4 = false;
            errores["otro"] = "Debe ingresar una descripción";
        } else {
            errores["otro"] = "";
        }
    }
    if (!campos.tratamientoActual) {
        seccion4 = false;
        errores["tratamientoActual"] = "Debe ingresar un tratamiento";
    } else {
        errores["tratamientoActual"] = "";
    }

    setError(prevState => {
  return {...prevState, ...errores};
});

    return seccion4;
}
export function validaSeccionCinco(campos, error, setError) {
    let seccion5 = true;
    let errores = error;

    if (!campos.chkHospi) {
        seccion5 = false;
        errores["chkHospi"] = "Debe seleccionar una opción";
    } else {
        errores["chkHospi"] = "";
        if (campos.chkHospi === "si") {
            if (!campos.fechaDesde) {
                seccion5 = false;
                errores["fechaDesde"] = "Debe ingresar fecha desde";
            } else {
                errores["fechaDesde"] = "";
            }
            if (campos.fechaDesde && campos.fechaHasta && !isAfter(campos.fechaHasta, campos.fechaDesde)) {
                seccion5 = false;
                errores["fechaDesde"] = "Fechas hasta debe ser posterior a Fecha desde";
            } else if (campos.fechaDesde && campos.fechaHasta && isAfter(campos.fechaHasta, campos.fechaDesde)) {
                errores["fechaDesde"] = "";
            }
            if (!campos.rutInstiHosp || !validRut(campos.rutInstiHosp)) {
                seccion5 = false;
                errores["rutInstiHosp"] = "Debe ingresar un RUT válido";
            } else {
                errores["rutInstiHosp"] = "";
                if (!campos.razonSocialHospi) {
                    seccion5 = false;
                    errores["razonSocialHospi"] = "Debe ingresar una razón social";
                } else {
                    errores["razonSocialHospi"] = "";
                }
            }
        }
    }
    if (!campos.chkTraslado) {
        seccion5 = false;
        errores["chkTraslado"] = "Debe seleccionar una opción";
    } else {
        errores["chkTraslado"] = "";
        if (campos.chkTraslado === "si") {
            if (!campos.trasDesde) {
                seccion5 = false;
                errores["trasDesde"] = "Debe ingresar origen traslado";
            } else {
                errores["trasDesde"] = "";
            }
            if (!campos.trasHasta) {
                seccion5 = false;
                errores["trasHasta"] = "Debe ingresar destino traslado";
            } else {
                errores["trasHasta"] = "";
            }
        }
    }
    if (!campos.chkUrgencia) {
        seccion5 = false;
        errores["chkUrgencia"] = "Debe seleccionar una opción";
    } else {
        errores["chkUrgencia"] = "";
    }

    setError(prevState => {
  return {...prevState, ...errores};
});
    return seccion5;
}