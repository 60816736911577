import { useState } from "react";
import { useQuery } from "@apollo/client";
import { aranceles } from "../../../../utils/queries";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado"

export const Arancel = ({ disabled, value, setValue }) => {
    const [arancelesPS, setArancelesPS] = useState([{ label: "Cargando...", value: value === -1 ? -1 : "" }]);
    useQuery(aranceles, {
        fetchPolicy: "cache-first",
        onCompleted: (data) => {
            const arancelArray = [];
            data.getAranceles.forEach((arancel, index)=>{
                arancelArray.push({label: arancel.arancel, value: index===0 ? -1 : arancel.arancel});
            });
            setArancelesPS(arancelArray)
        },
        onError: () => setArancelesPS([{ label: "ERROR", value: value }])
    })
    return (
        <SelectPersonalizado
            inputTest="aranceles"
            id="aranceles"
            disabled={disabled}
            value={value || ""}
            setValue={setValue}
            options={arancelesPS}
            placeholder={"Seleccione arancel"}
        />
    )
}