import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { eliminaPrestador } from "../../../../../utils/queries.js";
import icon_warningOrange from "../../../../../components/icons/icon_warningOrange.svg";
import { SecondaryButton } from "../../../../../components/Buttons/Botones";
import { useMutation } from "@apollo/client";
import BotonCarga from "../../../../../components/Buttons/BotonCarga.jsx";

export const EliminarPrestador = ({
    open,
    setOpen,
    prestador,
    setAlertData
}) => {
    const [EliminaPrestador, { loading }] = useMutation(eliminaPrestador, {
        onCompleted: () => {
            cerrarModal();
            setAlertData({
                abrir: true,
                mensaje: "Prestador eliminado correctamente.",
                severidad: "success",
            });
            if (document.getElementById("recargaPrest")) {
                document.getElementById("recargaPrest").click();
            }
            if (document.getElementById("recargaCanastas")) {
                document.getElementById("recargaCanastas").click();
            }
        },
        onError: (error) => {
            console.log(error.message)
            cerrarModal();
            setAlertData({
                abrir: true,
                mensaje: "No se eliminó el prestador, intente nuevamente.",
                severidad: "error",
            });
        }
    });
    const cerrarModal = () => {
        setOpen(false);
    };

    const handleEliminar = () => {
        EliminaPrestador({
            variables: {
                id: parseInt(prestador.id),
            }
        })
    };

    return (
        <Dialog
            data-testid="popUpModal"
            maxWidth="md"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            {" "}
            <DialogContent>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px"
                    }}
                >
                    <img alt="img" src={icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2">
                    ¿Seguro que quieres eliminar el prestador?{" "}
                </div>
            </DialogTitle>
            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
                Vas a eliminar el prestador {prestador.nombrePrestador}
            </DialogContentText>
            <DialogActions
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ justifyContent: "center!important", margin: "5px 0 15px 0" }}
            >
                <BotonCarga
                    testid="confirmarRechazoPrestador"
                    loading={loading}
                    toDo={handleEliminar}
                    label="Eliminar"
                    sx={{ marginRight: "10px" }}
                />
                <SecondaryButton
                    disabled={loading}
                    onClick={cerrarModal}
                >
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};