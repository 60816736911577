function parseaDatos(cod, fecha, general){

    var datos = "";
    
    general.forEach((dato)=>{
    
    datos += cod + "|" + fecha
            + "|" + dato.problemaSalud 
            + "|" + dato.tipoIntervencion
            + "|" + dato.canastacod
            + "|" + dato.prestaciones
            + "|" + dato.fechaInicioConvenio
            + "|" + dato.fechaTerminoConvenio
            + "|" + dato.regionConvenio
            + "|" + dato.tipoPrestador
            + "|" + dato.rutPrestador
            + "|" + dato.div
            + "|" + dato.codDEIS
            + "|" + dato.nombrePrestador
            + "|" + dato.razonSocialPrestador
            + "|" + dato.direccionPrestador
            + "|" + dato.comunaPrestador
            + "|" + dato.regionPrestador
            + "\n"
    });

    return datos;
}

function parseaXlsx(cod, fecha, general){

    var datos = [];
    datos.push(["Código","Periodo de información","Problema de Salud","Tipo de Intervención Sanitaria","Código Grupo de Prestación Principal (GPP) GES ",
    "Prestaciones en convenio","Fecha Inicio vigencia del convenio","Fecha término vigencia del convenio","Región Convenida","Tipo de prestador (individual o institucional)",
    "RUT del Prestador (individual o institucional)","DV del RUT del Prestador (individual o institucional)","Código DEIS del Prestador Institucional",
    "Nombre del Prestador Institucional ","Razón social del Prestador Institucional ","Dirección del Prestador (individual o Institucional)",
    "Comuna del Prestador (individual o Institucional)","Región del Prestador (individual o Institucional)"])
    general.forEach((dato)=>{
    
    datos.push([ 
        cod,
        fecha,
        dato.problemaSalud, 
        dato.tipoIntervencion,
        dato.canastacod,
        dato.prestaciones,
        dato.fechaInicioConvenio,
        dato.fechaTerminoConvenio,
        dato.regionConvenio,
        dato.tipoPrestador,
        dato.rutPrestador,
        dato.div,
        dato.codDEIS,
        dato.nombrePrestador,
        dato.razonSocialPrestador,
        dato.direccionPrestador,
        dato.comunaPrestador,
        dato.regionPrestador
        ]);
    })
    return datos;
}

export function buildInfo075(cod, fecha, data) {
    return {
        "txt": parseaDatos(cod, fecha, data),
        "xlsx": parseaXlsx(cod, fecha, data)
    }
}