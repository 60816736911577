import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { obtenerarchivo } from "../../utils/queries";
import { Grid } from "@mui/material";
import PrimaryButton, { IconOutlined } from "../Buttons/Botones";
import { IconView } from "../icons/iconosEsencial";
import { PreviewFile } from "../Dialog/PreviewFile";
import base64toBlob from "../../utils/Base64";

export const ObtenerArchivoGestor = ({ 
    tipoDoc, 
    isBandeja,
    isBitacora
}) => {
    const [modalVerDoc, setModalVerDoc] = useState(false);
    const [docGestor, setDocGestor] = useState(null);
    const [obtenerArchivoGestor, { loading }] = useLazyQuery(obtenerarchivo,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                let nombArchivoConForm = data.obtenerArchivo.nombExtArchivoSPO.replace(/['"]+/g, "");
                let base64ConForm = data.obtenerArchivo.base64.replace(/['"]+/g, "");
                const archivo = base64toBlob(base64ConForm, nombArchivoConForm);
                setDocGestor(URL.createObjectURL(archivo));
            }
        });

    const obtenerDocGestor = () => {
        obtenerArchivoGestor({
            variables: {
                documentId: tipoDoc,
                tipoDoc: "CLI",
            },
        });
        setModalVerDoc(true);
    };

    const cerrarVerDoc = () => {
        setModalVerDoc(false);
    };
    return (
        <Grid className={isBitacora ? "divVerdoc" : ""}>
            {isBitacora ?
                <PrimaryButton
                    data-testid="prevFileBit"
                    onClick={obtenerDocGestor}
                >
                    Ver Documento
                </PrimaryButton>
                : null}
            {isBandeja ?
                <IconOutlined
                    onClick={obtenerDocGestor}
                    data-testid="prevFileCasos"
                >
                    <IconView />
                </IconOutlined> : null}

            <PreviewFile
                openDialog={modalVerDoc}
                handleClose={cerrarVerDoc}
                urlSPO={docGestor}
                loading={loading}
            />
        </Grid>
    )
}