import { format, getTime } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { es } from "date-fns/locale";

export const parseFecha = (tzdate) => {
    return new Date(getTime(new Date(tzdate)) - getTimezoneOffset('America/Santiago', new Date(tzdate)));
}

export const ajustaFecha = (fecha) => {
    return new Date(getTime(fecha) - getTimezoneOffset('America/Santiago', fecha));
}

export const fechaConvertida = (tzdate) => {
    return format(parseFecha(tzdate), "dd/MM/yyyy");
}

export const fecha = (tzdate) => {
    if(tzdate){
        return format(new Date(tzdate), "dd/MM/yyyy");
    } else {
        return null;
    }
    
}

export const fechaKinetic = (tzdate) => {
    return format(new Date(tzdate), "dd-MM-yyyy");
}

export const fechaGuion = (tzdate) => {
    return format(parseFecha(tzdate), "dd-MM-yyyy");
}

export const fechaHoraConvertida = (tzdate) => {
    return format(parseFecha(tzdate), "dd/MM/yyyy HH:mm");
}

export const fechaHora = (tzdate) => {
    return format(new Date(tzdate), "dd/MM/yyyy HH:mm");
}

export const fechaHoraGuion = (tzdate) => {
    return format(new Date(tzdate), "dd/MM/yyyy - HH:mm");
}
export const fechaHoraGuionP = (tzdate) => {
    return format(parseFecha(tzdate), "dd/MM/yyyy - HH:mm");
}
export const DiaFechaHoraWParse = (tzdate) => {
    return format(parseFecha(tzdate), "eeee, dd/MM/yyyy - HH:mm",{locale: es});
}

export const ParseHoraLogsPS = (tzdate) => {
    return format(parseFecha(tzdate), "eeee, HH:mm",{locale: es});
}

export const Hora = (tzdate) => {
    return format(new Date(tzdate), "HH:mm");
}

export function parseFechaString(inputFormat) {
    return inputFormat.slice(0, 10).replace(/-/g, '/');
}