import { useQuery } from "@apollo/client";
import { clasificaciones, gettiposdocumento } from "../../../../utils/queries";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import { useState } from "react";

export function OpcionesDocumentos({
  disabled,
  error,
  id,
  setValue,
  testid,
  value
}) {
  const [catalogoDoc, setCatalogoDoc] = useState([]);
  const { loading } = useQuery(gettiposdocumento, {
    onCompleted: (data) => {
      var catalogo = []
      data.getCatalogoDoc.forEach((dato) => {
        if (!(dato.IdTipoDocumento === "2013" || dato.IdTipoDocumento === "2014")) {
          catalogo.push({
            label: dato.IdTipoDocumento + " - " + dato.TipoDocumento,
            value: dato.IdTipoDocumento
          })
        }
      })
      setCatalogoDoc(catalogo);
    },
    onError: (err) => {
      setCatalogoDoc([{ label: "Error...", value: "" }]);
      //console.log("error doc bit: ", err.message);
    }
  });

  if (loading && catalogoDoc.length === 0) {
    setCatalogoDoc([{ label: "Cargando...", value: "" }]);
  }

  return (
    <SelectPersonalizado
      id={id}
      inputTest={testid}
      error={error}
      value={value}
      setValue={setValue}
      options={catalogoDoc}
      disabled={disabled}
      placeholder={"Seleccione Tipo Doc"}
    />
  );
}

export function OpcionesClasificaciones({
  disabled,
  error,
  id,
  setValue,
  testid,
  value
}) {
  const [clasificacionesOp, setClasificaciones] = useState([]);
  const { loading } = useQuery(clasificaciones, {
    onCompleted: (data) => {
      var temp = []
      data.getClasificaciones.forEach((dato) => {
        temp.push({
          label: dato.descripcion,
          value: dato.id
        })
      })
      setClasificaciones(temp);
    },
    onError: (err) => {
      setClasificaciones([{ label: "Error...", value: "" }]);
      //console.log("error clasificaciones: ", err.message);
    }
  });

  if (loading && clasificacionesOp.length === 0) {
    setClasificaciones([{ label: "Cargando...", value: "" }]);
  }

  return (
    <SelectPersonalizado
      id={id}
      inputTest={testid}
      error={error}
      value={value}
      setValue={setValue}
      options={clasificacionesOp}
      disabled={disabled}
      placeholder={"Seleccione"}
    />)
}