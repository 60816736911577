import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react'
import Routers from './Router';
import { useIsAuthenticated } from "@azure/msal-react";
import { dataUsuario } from '../utils/queries';
import { useLazyQuery } from '@apollo/client';
import { rolesAPP } from '../constants/roles';

const App = ({ instance }) => {
    const isAuthenticated = useIsAuthenticated();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [rolActivo, setRolActivo] = useState(null);
    const [sucursal, setSucursal] = useState("-");
    const [correo, setCorreo] = useState("-");
    const [getSucursal] = useLazyQuery(dataUsuario);

    useEffect(() => {
        const getToken = async (currentAccount, instance) => {
            const params = {
                authority: process.env.REACT_APP_AZURE_AUTHORITY,
                scopes: ["User.Read"],
            };
            try {
                const login = await instance.acquireTokenSilent(params);
                getSucursal({
                    variables: {
                        usuario: currentAccount.localAccountId,
                        token: login.accessToken
                    },
                    onCompleted: (data) => {
                        setCorreo(data.getuserData.mail || "-")
                        setSucursal(data.getuserData.sucursal || "-")
                    }
                });
            } catch (error) {
                await instance.loginPopup();
                const login = await instance.acquireTokenSilent(params);
                getSucursal({
                    variables: {
                        usuario: currentAccount.localAccountId,
                        token: login.accessToken
                    },
                    onCompleted: (data) => {
                        setCorreo(data.getuserData.mail || "-")
                        setSucursal(data.getuserData.sucursal || "-")
                    }
                });
            }
        }

        if (isAuthenticated) {
            const currentAccount = instance.getActiveAccount();

            if (currentAccount && Array.isArray(currentAccount.idTokenClaims['roles']) && currentAccount.idTokenClaims['roles'].length > 0) {
                const valid = rolesAPP.filter(role => currentAccount.idTokenClaims['roles'].includes(role));
                if (valid.length > 0) {
                    setRolActivo(currentAccount.idTokenClaims['roles'])
                    setIsAuthorized(true);
                    getToken(currentAccount, instance);
                }

            }
        }
    }, [isAuthenticated, instance, getSucursal]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: "0px" }}>
            <Box mb="0px" data-testid="app">
                <Routers isAuthorized={isAuthorized} rol={rolActivo} sucursal={sucursal} correo={correo}/>
            </Box>
        </Box>
    )
}

export default App;