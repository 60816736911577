
import PrimaryButton from "../Buttons/Botones";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import Loader from "../Skeleton/Loader";

export const PreviewFile = ({ openDialog, handleClose, urlSPO, loading }) => {

  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Previsualizar Documento</DialogTitle>
      <DialogContent style={{ height: "700px" }}>
        <DialogContentText>
          {
            loading ?
              <Loader /> :
              <iframe
                id="iframePrevisualiza"
                data-testid="iframePrevFile"
                title="prueba"
                src={urlSPO}
                height="650px"
                width="100%"
              />
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          data-testid="cerrarPreview"
          onClick={handleClose}
        >
          Cerrar
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
