import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { obtenerarchivo } from "../../../../utils/queries";
import { PreviewFile } from "../../../../components/Dialog/PreviewFile";
import base64toBlob from "../../../../utils/Base64";
import bytesToSize from "../../../../utils/bytesToSize";
import { Link } from "react-router-dom";

const AntecedentesAdjuntos = ({ file }) => {
  const [archivo, setArchivo] = useState(null);
  const [modalAntecedente, setModalAntecedente] = useState(false);
  const { loading, error } = useQuery(obtenerarchivo, {
    variables: {
      documentId: file ? file.documento : "",
      tipoDoc: "CLI",
    },
    onCompleted: (data) => {
      const nombExtArchivoSPO = data.obtenerArchivo.nombExtArchivoSPO;
      const base64SinForm = data.obtenerArchivo.base64;
      const base64ConForm = base64SinForm.replace(/['"]+/g, "");
      const nombArchivoConForm = nombExtArchivoSPO.replace(/['"]+/g, "");
      const blob = base64toBlob(base64ConForm, nombArchivoConForm);
      const file = new File([blob], nombArchivoConForm);
      setArchivo({ file, blob: URL.createObjectURL(blob) });
    },
    onError: (error) => {
      console.log("ERROR GESTOR: ", error.message)
    }
  });

  return (
    <Grid item className="boxAntecedentes">
      {loading ?
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop="16px"
        ><CircularProgress
            data-testid="spinnerCarga"
            className="spinnerGreen"
          /></Grid> :
        null}
      {
        archivo ?
          <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <label className="labelForm">
                {archivo.file.name}
              </label>
            </Grid>
            <Grid item xs={6}>
              <Typography
                className="sizeFile"
                sx={{ margin: "0!Important", float: "left" }}
              >
                {bytesToSize(archivo.file.size)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Link
                underline="always"
                color="inherit"
                className="linkDoc"
                data-testid="prevFileForm"
                to="#"
                onClick={() => setModalAntecedente(true)}
              >
                Ver documento
              </Link>
              <PreviewFile
                openDialog={modalAntecedente}
                handleClose={() => setModalAntecedente(false)}
                urlSPO={archivo.blob}
                loading={loading}
              />
            </Grid>
          </Grid>
          : null
      }
      { 
        error ? 
        "Error al cargar archivo."
        : null
      }
    </Grid>

  );
};

export default AntecedentesAdjuntos;
