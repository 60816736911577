import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextareaAutosize
} from "@mui/material"
import "../../assets/Modales.css";
import PrimaryButton from "../Buttons/Botones";

export const TextModal = ({ open, handleClose, text }) => {
    return (
        <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            data-testid="dialogModal"
            open={open}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }
            }}
        >
            <DialogTitle>Comentario</DialogTitle>
            <DialogContent>
                <DialogContentText
                    alignContent="center"
                    className="textoSubiendoDocs"
                    sx={{ m: 1, textAlign: "center" }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ m: 1, textAlign: "center", paddingRight: "5px" }}
                    >
                        <TextareaAutosize
                            maxRows={20}
                            value={text}
                            readOnly
                        />
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    data-testid="cerrarPreview"
                    onClick={handleClose}
                >
                    Cerrar
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    )
}