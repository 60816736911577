function parseaDatos(cod, fecha, general){

    var datos = "";
    general.forEach(async (dato)=>{
            datos +=cod + "|" + fecha
                + "|" + dato.folio
                + "|" + dato.problemaSalud
                + "|" + dato.intervencion
                + "|" + dato.canasta
                + "| "
                + "| "
                + "| "
                + "| "
                + "| "
                + "| "
                + "| "
                + "| "
                + "| "
                + "\n" 
    });
    return datos;
  
}
function parseaXlsx(cod, fecha, general){

    var datos = [["Código Aseguradora","Período de información","Identificación Única GES","Problema de Salud","Tipo de Intervención Sanitaria ",
        "Código Grupo de Prestación Principal (GPP) GES","Código de Prestación Unitaria","Copago de la prestación",
        "Forma de enterar el copago","Número de Bono de Atención o Documento Comprobante de Ingreso", "Número de Reembolso",
        "Fecha Emisión del Bono/Reembolso/Documento Comprobante de Ingreso","Fecha Inicio Periodicidad del Grupo de Prestación Principal (GPP)",
        "Fecha Término Periodicidad del Grupo de Prestación Principal (GPP)","Frecuencia de la Prestación Unitaria"
        ]];
    general.forEach(async (dato)=>{
            datos.push([ 
                cod, 
                fecha, 
                dato.folio, 
                dato.problemaSalud,
                dato.intervencion,
                dato.canasta,
                "","","","","","","","",""
         ]);   
        })
        
    return datos;
}

export function buildInfo037(cod, fecha, data) {
    return {
        "txt": parseaDatos(cod, fecha, data),
        "xlsx": parseaXlsx(cod, fecha, data)
    }
}