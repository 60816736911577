import { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoResult } from "../../../../components/NoData/NoResults";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { DownloadGreen } from "../../../../components/icons/iconosEsencial";
import { useQuery, useLazyQuery } from "@apollo/client";
import { historialCargaLEP, obtenerarchivo } from "../../../../utils/queries";
import { DiaFechaHoraWParse } from "../../../../utils/fechaUtils";
import { LoaderHistorial } from "../../utils/Loader";
import { saveAs } from "file-saver";
import base64toBlob from "../../../../utils/Base64";

export default function HistorialCargasLEP({ setSnackData }) {
    const [descargaLEP, setDescargaLEP] = useState({});
    const [getArchivo] = useLazyQuery(obtenerarchivo);
    const { data, loading, error, fetchMore } = useQuery(historialCargaLEP, {
        variables: {
            limit: 10,
            offset: 0
        }
    });

    function descargaArchivo(docId, i) {
        setSnackData({
            abrir: true,
            mensaje: "Generando descarga...",
            severidad: "info",
        });
        const valor = docId;
        getArchivo({
            variables: {
                documentId: valor,
                tipoDoc: "OP",
            },
            onCompleted: (res) => {
                let base64 = res.obtenerArchivo.base64.replace(/['"]+/g, "");
                let nombArchiv = res.obtenerArchivo.nombExtArchivoSPO.replace(/['"]+/g, "");
                const blob = base64toBlob(base64, nombArchiv);

                saveAs(blob, nombArchiv);
                setDescargaLEP(oldState => ({ ...oldState, [i]: false }));

                setSnackData({
                    abrir: true,
                    mensaje: "Descarga generada, revise sus archivos descargados",
                    severidad: "success",
                });
            },
            onError: (error) => {
                console.log("error resp: " + error);
                setSnackData({
                    abrir: true,
                    mensaje: "No se generó la descarga, intente nuevamente",
                    severidad: "error",
                });
                setDescargaLEP(oldState => ({ ...oldState, [i]: false }));
            }
        });
    }

    return (
        <>
            {loading ? <LoaderHistorial /> :
                <Grid container p="24px">
                    <Grid item xs={12}>
                        <label className="titulo2">Historial de Carga</label>{" "}
                    </Grid>
                    <Grid item xs={12}>
                        <label className="LabelVademecumDetail">
                            Aquí podrás ver las cargas que se han realizado en este módulo.
                        </label>
                    </Grid>
                    <Grid item xs={12} pt="24px">
                        <Grid>
                            <label className="LabelVademecumRegisters">
                                Mostrando {(data && data.historialCargaLEP && data.historialCargaLEP.logs.length) || "0"} registros
                            </label>
                        </Grid>
                        <Grid item xs={12} pt="8px">
                            {error ?
                                <NoResult
                                    error
                                    titulo={"Error al cargar"}
                                    subtitulo={"Error al cargar Historial de cargas."}
                                    noDivider
                                /> :
                                data && data.historialCargaLEP.totalLogLEP > 0 ?
                                    <Grid
                                        container
                                        id="ScrollGrid"
                                        data-testid="ScrollGrid"
                                        className="scrollBar"
                                        border="1px solid #D2D6D9"
                                        borderRadius="8px"
                                        padding="16px"
                                        style={{
                                            maxHeight: "450px",
                                            overflowY: "scroll",
                                            paddingRight: "8px",
                                        }}
                                    >
                                        <InfiniteScroll
                                            scrollableTarget={"ScrollGrid"}
                                            dataLength={data.historialCargaLEP.logs.length}
                                            style={{ width: "700px" }}
                                            next={() => fetchMore({
                                                variables: {
                                                    offset: data.historialCargaLEP.logs.length,
                                                    limit: 5,
                                                },
                                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                                    const newLogs = [...previousResult.historialCargaLEP.logs, ...fetchMoreResult.historialCargaLEP.logs]
                                                    return {
                                                        historialCargaLEP: {
                                                            ...previousResult.historialCargaLEP,
                                                            logs: newLogs
                                                        }
                                                    };
                                                },
                                            })}
                                            hasMore={data.historialCargaLEP.logs.length !== data.historialCargaLEP.totalLogLEP}
                                            loader={<CircularProgress className="spinnerGreen" />}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>No existen más registros.</b>
                                                </p>
                                            }
                                        >
                                            {data.historialCargaLEP.logs.map((item, index) => {
                                                const text = item.descripcion.split("/");
                                                const fecha = DiaFechaHoraWParse(item.FechaIngreso) + "hrs";

                                                return (
                                                    <Grid container key={index} sx={{ marginBottom: "20px" }}>
                                                        <Grid item xs={1} textAlign="right" pr='16px' style={{ flexBasis: "5%", maxWidth: "5%" }}>
                                                            <span className={index === 0 ? "purpleDot" : "grayDot"}></span>
                                                        </Grid>
                                                        <Grid item xs={11} textAlign="left" style={{ flexBasis: "95%", maxWidth: "100%" }}>
                                                            <label className="titleList">{item.LoginAutorizacion}</label>
                                                            <label className="dateList">{fecha}</label>
                                                            <label className="changeText">Cambios: </label>
                                                            <label className="filesList">{text[0]}</label>
                                                            {text[1] ?
                                                                <OutlinedButton2
                                                                    sx={{ ml: "19px", whiteSpace: "pre" }}
                                                                    disabled={descargaLEP[index]}
                                                                    data-testid={"testid" + index}
                                                                    onClick={() => {
                                                                        setDescargaLEP(oldState => ({ ...oldState, [index]: true }));
                                                                        descargaArchivo(text[1], index)
                                                                    }}
                                                                >
                                                                    {descargaLEP[index] ? <CircularProgress className="spinnerGreen" /> :
                                                                        <>
                                                                            <DownloadGreen />
                                                                            Archivo original
                                                                        </>
                                                                    }
                                                                </OutlinedButton2>
                                                                : null}

                                                        </Grid>
                                                    </Grid>)
                                            })}

                                        </InfiniteScroll>
                                    </Grid>
                                    : <NoResult
                                        titulo={"Aún no hay registros"}
                                        subtitulo={"No se han realizado cargas de LEP."}
                                        noDivider
                                    />}

                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}