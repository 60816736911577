import {
    Alert,
    Box,
    Collapse,
    Grid,
} from "@mui/material";
import "../../assets/Repositorio.css";
import { iconStyle, style } from "../Snackbar/components/Styles";

export default function Aviso({ titulo, subtitulo, mensaje, open, setOpen, severidad, noClose }) {
    return (
        <Grid xs={12}>
            <Box sx={{ width: "99.5%" }}>
                <Collapse in={open}>
                    <Grid sx={{ paddingTop: open ? "16px" : "0px" }}>
                        <Alert
                            severity={severidad}
                            sx={style(severidad)}
                            iconMapping={iconStyle(severidad)}
                            onClose={noClose ? null : () => setOpen(false)}
                        >
                            {mensaje ?
                                mensaje
                                :
                                <>
                                    <strong className="alertTitulo" >
                                        {titulo}
                                    </strong>
                                    <br/>
                                    <label className="alertSubtitulo" >
                                        {subtitulo}
                                    </label>
                                </>
                            }
                        </Alert>
                    </Grid>
                </Collapse>
            </Box>
        </Grid>
    );
} 