import { useTheme } from '@mui/material/styles';
import {
  CustomInputSelect,
  CustomInputSelectActions,
  menuItemStyle,
  menuProps
} from './styledSelect'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@mui/material';

export const SelectPersonalizado = ({ disabled, error, id, inputTest, isAcciones, options, placeholder, phvalue = "", placeholderHidden, setValue, value }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl error={error ? true : undefined}>
      <Select
        displayEmpty
        sx={{ width: '100%' }}
        disabled={disabled}
        value={value || ""}
        onChange={handleChange}
        input={isAcciones ? <CustomInputSelectActions /> : <CustomInputSelect />}
        MenuProps={menuProps}
        inputProps={{ 'aria-label': 'Without label', "data-testid": inputTest, "id": id }}
      >
        {placeholder ?
          <MenuItem disabled hidden={placeholderHidden} value={phvalue} sx={menuItemStyle}>
            <em>{placeholder}</em>
          </MenuItem>
          : null}
        {options.map((option, index) => (
          <MenuItem
            key={index}
            sx={menuItemStyle}
            disabled={option.disabled && option.disabled === true ? true : false}
            value={option.value}
            style={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error || ""}</FormHelperText>
    </FormControl>
  );
}