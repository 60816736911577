import { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";
import { Arancel } from "../../ProblemasSalud/components/Arancel";

const BuscarLEP = ({
    onOpen, onClose, filtros, setFiltros, isEliminado
}) => {
    const [configFiltros, setConfigFiltros] = useState(filtros);
    const estadosLEP = [{ label: "Vigente", value: 2 }, { label: "No vigente", value: 1 }];
    const tipoPrest = [{ label: "IN", value: "IN" },
    { label: "R", value: "R" },
    { label: "ME", value: "ME" },
    { label: "PR", value: "PR" },
    { label: "DR", value: "DR" },
    { label: "DE", value: "DE" }];

    if (!onOpen && filtros !== configFiltros) {
        setConfigFiltros(filtros);
    }

    const detectInput = (event) => {
        let id = event.target.id;
        let value = event.target.name === 'numero' ? parseInt(event.target.value.replace(/[^0-9]/g, "")) : event.target.value;
        setConfigFiltros({
            ...configFiltros,
            [id]: value
        });
    }

    return (
        <Dialog
            className="dialogModalBig2"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogTitle className="titulo2" textAlign="center" pb="16px">
                Buscar LEP
                <br></br>
                <label className="subtitleModal">
                    Seleccione los criterios de búsqueda para encontrar registros de LEP específicos.
                </label>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    sx={{ padding: "5px", margin: "0", width: "100%" }}
                    container
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <label className="labelForm">Código GES</label>
                        <FormControl >
                            <TextField
                                id="problemaSalud"
                                data-testid="problemaSalud"
                                placeholder="Ingresar código GES"
                                name="numero"
                                value={configFiltros.problemaSalud || ""}
                                onChange={detectInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Nombre Patología GES</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar nombre patología GES"
                                id="nombreProblemaSalud"
                                data-testid="nombreProblemaSalud"
                                value={configFiltros.nombreProblemaSalud || ""}
                                onChange={detectInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Tipo de Prestación</label>
                        <SelectPersonalizado
                            placeholder={"Seleccionar tipo de prestación"}
                            value={configFiltros.tipoPrestacion || ""}
                            inputTest="tipoPrestacion"
                            options={tipoPrest}
                            setValue={(val) => {
                                setConfigFiltros({
                                    ...configFiltros,
                                    tipoPrestacion: val
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Código Prestación</label>
                        <FormControl >
                            <TextField
                                placeholder="Ingresar código prestación"
                                id="prestacion"
                                data-testid="prestacion"
                                value={configFiltros.prestacion || ""}
                                onChange={detectInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Glosa de prestación</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar glosa de prestación"
                                id="descripcion"
                                data-testid="descripcion"
                                value={configFiltros.descripcion || ""}
                                onChange={detectInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">A/H</label>
                        <FormControl>
                            <TextField
                                placeholder="Ingresar A/H"
                                id="AH"
                                data-testid="AH"
                                value={configFiltros.AH || ""}
                                onChange={detectInput}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Arancel</label>
                        <Arancel
                            id="arancel"
                            data-testid="arancelPS"
                            value={configFiltros.decreto || ""}
                            setValue={(val) => {
                                setConfigFiltros({
                                    ...configFiltros,
                                    decreto: val
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className="labelForm">Estado</label>
                        <SelectPersonalizado
                            placeholder={isEliminado ? "ELIMINADO" : "Seleccionar un estado"}
                            disabled={isEliminado}
                            options={estadosLEP}
                            inputTest="estado"
                            value={configFiltros.estado}
                            setValue={(val) => {
                                setConfigFiltros({
                                    ...configFiltros,
                                    estado: val
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pt="24px"
                    pl="25px"
                    pr="10px"
                >
                    <SecondaryButton
                        variant=" text"
                        color="default"
                        data-testid="btnCloseModal"
                        onClick={() => {
                            onClose();
                            setConfigFiltros(filtros);
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        data-testid="btnCheckDatos"
                        variant=" text"
                        color="default"
                        disabled={configFiltros === filtros}
                        onClick={() => {
                            onClose();
                            setFiltros({...configFiltros, pag: 0 });
                        }}
                    >
                        Filtrar
                    </PrimaryButton>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default BuscarLEP;
