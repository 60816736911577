import AntecedentesAdjuntos from "./AntecedentesAdjuntos";
import { Grid, Divider } from "@mui/material";

const ListaAntecedentes = ({ antecedentes }) => {

    const presentado = antecedentes.find((doc) => doc.clasificacion === 4);
    const adicional = antecedentes.filter((doc) => doc.clasificacion === 5);
    return (
        <div>

            <Grid item xs={12}>
                <p className="labelForm">Antecedente Presentado</p>
                <AntecedentesAdjuntos file={presentado} />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Divider sx={{ margin: "15px 0" }}></Divider>
                    {adicional.length > 0 ?
                    <label className="labelForm">Antecedentes Adicionales</label>
                    : null}
                </Grid>
                <Grid item xs={12}>
                    {adicional.length > 0 ? adicional.map((doc) =>
                        <AntecedentesAdjuntos file={doc} />
                    ) :
                        <Grid item className="boxNoCargas">
                            <Grid
                                container
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <label className="labelForm">No hay antecedentes adicionales precargados</label>
                                </Grid>
                            </Grid>
                        </Grid>}
                </Grid>

            </Grid>
        </div>
    )

}

export default ListaAntecedentes;