import { Fragment } from "react";
import { Grid, Chip } from "@mui/material";
import { OutlinedButton2 } from "../../../../components/Buttons/Botones";
import { Cruz } from "../../../../components/icons/iconosEsencial";

export function ChipsLEP({ filtros, setFiltros }) {
    const handleDelete = (cod) => {
        setFiltros({ ...filtros, [cod]: null, pag: 0 });
    };

    const clearFiltros = () => {
        setFiltros({
            ...filtros,
            problemaSalud: null,
            nombreProblemaSalud: null,
            tipoPrestacion: null,
            prestacion: null,
            descripcion: null,
            AH: null,
            //decreto: null,
            estado: null,
            pag: 0
        })
    };

    return (
        <Fragment>
            {filtros && (
                (filtros.problemaSalud && filtros.problemaSalud !== null) ||
                (filtros.nombreProblemaSalud && filtros.nombreProblemaSalud !== null) ||
                (filtros.tipoPrestacion && filtros.tipoPrestacion !== null) ||
                (filtros.prestacion && filtros.prestacion !== null) ||
                (filtros.descripcion && filtros.descripcion !== null) ||
                (filtros.AH && filtros.AH !== null) ||
                (filtros.decreto && filtros.decreto !== null) ||
                (filtros.estado && filtros.estado !== null)) ?
                <Fragment>
                    <Grid item xs={12}>
                        <label className="filtroFont" >Resultados filtrados por:</label>{" "}
                    </Grid>
                    <Grid item xs={12} pt="12px">
                        {filtros.problemaSalud && filtros.problemaSalud !== null ?
                            <Chip sx={{ mr: "16px" }} label="CódigoPS" variant="outlined" onDelete={() => handleDelete("problemaSalud")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.nombreProblemaSalud && filtros.nombreProblemaSalud !== null ?
                            <Chip sx={{ mr: "16px" }} label="NombrePS" variant="outlined" onDelete={() => handleDelete("nombreProblemaSalud")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.tipoPrestacion && filtros.tipoPrestacion !== null ?
                            <Chip sx={{ mr: "16px" }} label="tipoPrestacion" variant="outlined" onDelete={() => handleDelete("tipoPrestacion")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.prestacion && filtros.prestacion !== null ?
                            <Chip sx={{ mr: "16px" }} label="Prestación" variant="outlined" onDelete={() => handleDelete("prestacion")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.descripcion && filtros.descripcion !== null ?
                            <Chip sx={{ mr: "16px" }} label="Descripción" variant="outlined" onDelete={() => handleDelete("descripcion")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.AH && filtros.AH !== null ?
                            <Chip sx={{ mr: "16px" }} label="A/H" variant="outlined" onDelete={() => handleDelete("AH")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        {filtros.decreto && filtros.decreto !== null ?
                            <Chip
                                sx={{ mr: "16px" }}
                                label="Arancel"
                                variant="outlined"
                                //onDelete={() => handleDelete("decreto")}
                                deleteIcon={<Cruz sx={{ fontSize: "10px" }} />}
                            />
                            : null}
                        {filtros.estado && filtros.estado !== null ?
                            <Chip sx={{ mr: "16px" }} label="Estado" variant="outlined" onDelete={() => handleDelete("estado")} deleteIcon={<Cruz sx={{ fontSize: "10px" }} />} />
                            : null}
                        <OutlinedButton2 sx={{ textDecoration: "underline", textDecorationThickness: "from-font", pl: "12px" }} onClick={clearFiltros}>
                            Limpiar filtros
                        </OutlinedButton2>
                    </Grid>
                </Fragment> : null}
        </Fragment>
    )
}
