import { Container, Typography } from "@mui/material";
import "../../components/Skeleton/Loader.css";

const Loader = () => {
  return (
    <div>
      <Container
        className="contenedor-loader"
        sx={{ textAlign: "center", paddingBottom: "4%" }}
      >
        <div className="custom-loader-entry"></div>
        <Typography
          className="information-loader-entry"
          sx={{ marginTop: "30px" }}
        >
          Cargando
        </Typography>
      </Container>
    </div>
  );
};

export default Loader;
