import icon_warningOrange from "../../../../components/icons/icon_warningOrange.svg";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import { eliminaPS } from "../../../../utils/queries";

export const ModalEliminar = ({
    onOpen,
    onClose,
    dataPS,
    setSnackData,
    refetch,
    sucursal,
    usuario
}) => {
    const [eliminaRegPS, { loading }] = useMutation(eliminaPS, {
        onCompleted: () => {
            setSnackData({
                abrir: true,
                mensaje: "El problema de salud [" + dataPS.Codigo + "] ha sido eliminado correctamente.",
                severidad: "success",
            });
            onClose();
            refetch();
        },
        onError: () => setSnackData({
            abrir: true,
            mensaje: "Ha ocurrido un error al intentar eliminar el problema de salud. Por favor, inténtelo de nuevo más tarde.",
            severidad: "error",
        })
    });
    return (
        <Dialog
            justifyContent="center"
            data-testid="EliminarCanastaDialog"
            maxWidth="md"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <DialogContent sx={{ paddingBottom: "0" }}>
                <Grid
                    sx={{
                        m: 1,
                        textAlign: "center",
                        padding: "0",
                        width: "540px"
                    }}
                >
                    <img alt="img" src={icon_warningOrange}></img>
                </Grid>
            </DialogContent>
            <DialogTitle
                sx={{
                    textAlign: "center",
                }}
            >
                <div className="titulo2">
                    ¿Seguro de eliminar el problema de salud [{dataPS.Codigo}]?
                </div>
            </DialogTitle>
            <DialogContentText
                alignContent="center"
                sx={{ m: 1, textAlign: "center" }}
            >
                Al eliminar este problema de salud, no podrá recuperar los datos <br></br> eliminados.
            </DialogContentText>
            <DialogActions
                container
                direction="row"
                alignitems="center"
                sx={{ justifyContent: "center!important", margin: "5px 5px 15px 5px" }}
            >
                <BotonCarga
                    testid="eliminarPS"
                    label="Eliminar"
                    sx={{ marginRight: "10px" }}
                    loading={loading}
                    toDo={() => eliminaRegPS({
                        variables: {
                            id: parseInt(dataPS.id),
                            usuario: usuario,
                            sucursal: sucursal
                        }
                    })}
                />
                <SecondaryButton
                    data-testid="closeModal"
                    disabled={loading}
                    onClick={onClose}
                >
                    Cancelar
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};