import { useState } from "react";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado"
import { useQuery } from "@apollo/client";
import { consultaHitoInicio } from "../../../../utils/queries";

export default function SelectHito({
    disabled,
    hito,
    salud,
    setHito,
    setError,
    setPrestacion,
    setCanasta
}) {
    const [hitoInicio, setHitoInicio] = useState([{ label: "Cargando...", value: "" }]);
    const { data } = useQuery(consultaHitoInicio, {
        variables: { problemaSalud: salud },
        onCompleted: (res) => {
            var hitoArray = [];
            if (res.getGopInicio.length > 0) {
                res.getGopInicio.forEach((resHito) => (
                    hitoArray.push({
                        label: resHito.etapa +
                            " " +
                            resHito.nombreHito.descripcion +
                            " " +
                            resHito.plazo +
                            " " +
                            resHito.unidad,
                        value: resHito.id
                    })
                ));
                setHitoInicio(hitoArray);
            } else {
                setHitoInicio([{ label: "Sin hito para asignar", value: "" }]);
            }
        },
        onError: (error) => {
            setHitoInicio([{ label: "Error cargando opciones, favor recargar", value: "" }]);
            console.log(error.message)
        }
    });

    function cambiaHito(value) {
        setHito(value);
        const hitoSelect = data.getGopInicio.find((hitoIni) => hitoIni.id === value);
        if (hitoSelect) {
            setError();
            if (hitoSelect.cod_prestacion !== null) {
                setPrestacion(hitoSelect.cod_prestacion);
            } else {
                setPrestacion(" ");
            }
            if (hitoSelect.intervencion === null) {
                setCanasta(" ");
            } else {
                setCanasta(
                    hitoSelect.problemaSalud +
                    hitoSelect.intervencion +
                    hitoSelect.grupo_prestacion +
                    " - " +
                    hitoSelect.nombreHito.descripcion
                );
            }
        }

    }

    return (
        <SelectPersonalizado
            inputName={"descripcionHito"}
            inputTest="selectHito"
            value={hito}
            setValue={cambiaHito}
            options={hitoInicio}
            placeholder={"Selecciona una descripción del hito"}
            disabled={disabled}
        />
    )
}