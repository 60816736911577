import { useState } from "react";
import {
    IconButton,
    Grid,
    Menu,
    MenuItem
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLazyQuery } from "@apollo/client";
import { resumenHitos } from "../../../../utils/queries";
import { VerHitos } from "./VerHitos";
import { EliminarHito } from "./EliminarHito";
import { hasJGESCAEC } from "../../../../utils/hasPermisions";
import ModalEditarHito from "./ModalEditarHito";
import SkeletonBar from "../../../../components/Skeleton/SkeletonBar";

export const MenuHito = ({
    datos,
    setSnackData,
    fechaAutorizacion,
    rol,
    estado,
    usuario,
    sucursal
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [dialogHitos, setDialogHitos] = useState(false);
    const [dataHito, setDataHito] = useState(null);
    const [open, setOpen] = useState(false);
    const [GetDatosHito, { loading: cargandoHito }] = useLazyQuery(resumenHitos, {
        onCompleted: (data) => {
            setDataHito(data.consultaHitoAsignado);
        },
        onError: (err) => {
            console.log(err.message)
        }
    });
    console.log(datos)
    const closeMenu = () => {
        setAnchorEl(null);
        setOpen(false);
    }
    const abrirModal = () => {
        setModalEliminar(true);
    };

    return (
        <Grid>
            <IconButton
                id="basic-button"
                data-testid="openMenuItem"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                    GetDatosHito({ variables: { idHitoAsignado: datos.id } });
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
            className="menuForm"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    data-testid="verHitos"
                    disabled={cargandoHito}
                    onClick={() => {
                        setDialogHitos(true);
                        closeMenu();
                    }}
                >
                    {cargandoHito ?
                        <SkeletonBar
                            width={125}
                            height={30}
                        />
                        : "Ver Hitos"
                    }
                </MenuItem>
                <MenuItem
                    disabled={cargandoHito || !hasJGESCAEC(rol)|| estado===10}
                    data-testid="editarHito"
                    onClick={() => {
                        closeMenu();
                        setModalEditar(true);
                    }}
                >
                    {cargandoHito ?
                        <SkeletonBar
                            width={125}
                            height={30}
                        />
                        : "Editar Hito"
                    }
                </MenuItem>
                <MenuItem
                    disabled={cargandoHito || !hasJGESCAEC(rol)|| estado===10}
                    data-testid="eliminaHito"
                    onClick={() => {
                        closeMenu();
                        abrirModal();
                    }}
                >
                    {cargandoHito ?
                        <SkeletonBar
                            width={125}
                            height={30}
                        />
                        : "Eliminar Hito"
                    }
                </MenuItem>
            </Menu>
            {dataHito ?
                <VerHitos
                    open={dialogHitos}
                    setOpen={setDialogHitos}
                    datos={dataHito}
                    usuario={usuario}
                    sucursal={sucursal}
                /> : null}
            {dataHito ?
                <ModalEditarHito
                    onOpen={modalEditar}
                    setOpen={setModalEditar}
                    datos={dataHito}
                    fechaAutorizacion={fechaAutorizacion}
                    setSnackData={setSnackData}
                    usuario={usuario}
                    sucursal={sucursal}
                /> : null}
            {dataHito ?
                <EliminarHito
                    open={modalEliminar}
                    setOpen={setModalEliminar}
                    hito={dataHito}
                    setSnackData={setSnackData}
                    usuario={usuario}
                    sucursal={sucursal}
                /> : null}
        </Grid>
    );
}